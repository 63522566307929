import React, { useContext, useEffect, useState } from 'react'
import { API_URL } from '../../config';
import EventContext from '../../contexts/EventContext';
import UserContext from '../../contexts/UserContext';
import { BreadcrumbsProgramme } from './Breadcrumbs'
import parse from "html-react-parser";
import Loader from '../Loader';
import LayoutAccountEvent from './LayoutAccountEvent';

const ConfEvent = ({ confEvent, handleChange, userContactConferencierList }) => {

    const [isChecked, setIsChecked] = useState(false)

    const [idConferencier, setIdConferencier] = useState(null)

    const handleOnChange = (e) => {
        setIsChecked(!isChecked);
        handleChange(e)
    }

    useEffect(() => {

        userContactConferencierList.map(item => item.id_conf_event).includes(confEvent.id_conf_event) && setIsChecked(true)

        const activeUserContactConferencier = userContactConferencierList.find(item => item.id_conf_event === confEvent.id_conf_event);

        //activeUserContactConferencier && console.log(activeUserContactConferencier.id_conferencier)
        activeUserContactConferencier && setIdConferencier(activeUserContactConferencier.id_conferencier)

    }, [])

    return <div className="border flex py-2 hover:bg-gray-100 space-x-4">
        <div className="flex justify-center  items-center px-3">
            <input data-id_conferencier={idConferencier} value={confEvent.id_conf_event} type="checkbox" name="" id={confEvent.id_conf_event} onChange={handleOnChange} checked={isChecked} />
        </div>

        <div className="flex-auto px-2">
            <div className="flex lg:justify-start justify-between w-full space-x-3 ">
                <div className="text-gray-400 text-sm">
                    Heure locale : {confEvent.conf_event_date_local}, {confEvent.heure_debut_local} {confEvent.heure_fin_local}
                </div>
                <span
                    className="text-white text-xs px-2 rounded"
                    style={{ backgroundColor: confEvent.conf_event_type.color }}>{confEvent.conf_event_type.conf_event_type_nom}
                </span>
            </div>
            <label htmlFor={confEvent.id_conf_event}>  {parse(confEvent.conf_event_lang.cel_titre)}</label>
        </div>
        <div>
            {/*JSON.stringify(userContactConferencierList.map(item => item.id_conf_event))*/}</div>
    </div>
}

const ScheduleSelection = () => {

    const { currentEvent } = useContext(EventContext);

    const [confEventList, setConfEventList] = useState(null)

    const [contactConferencierList, setContactConferencierList] = useState(null);

    const [message, setMessage] = useState(null)

    const { userContactConferencierList, user, userRoleList } = useContext(UserContext);

    const getContactConferencierList = async () => {

        await fetch(`${API_URL}?action=getContactConferencierList&filter= AND c.id_contact=${user.id_contact} AND id_event=${currentEvent.id_event} AND id_conf_event NOT IN('',0)`)
            .then(res => res.json())
            .then(res => {
                setContactConferencierList(res)
            })
    }

    const handleChange = async (e) => {

        if (e.target.checked) {

            await fetch(`${API_URL}?action=createConferencier`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id_conf_event: e.target.value,
                    id_contact: user.id_contact,
                    id_event: currentEvent.id_event,
                    statut: "partcipant"

                })
            })
                .then(res => res.json())
                .then(res => {

                    console.log('contactConferencierList', contactConferencierList.length)
                    return res
                })
                .then(res => {
                    getContactConferencierList(user.id_contact)
                    return res
                })

        } else {

            await fetch(`${API_URL}?action=deleteConferencier`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id_conferencier: e.currentTarget.dataset.id_conferencier,
                })
            })
                .then(res => res.json())
                .then(res => {

                    console.log('contactConferencierList', contactConferencierList.length)
                    return res
                })
                .then(res => {
                    getContactConferencierList(user.id_contact)
                    return res
                })

        }
        console.log(e.target.checked)
    }

    const getConfEvent = async (currentEvent) => {
        setMessage(<>Loading programm... <Loader /></>)

        await fetch(`${API_URL}?action=getConfEvent&id_event=${currentEvent.id_event}`)
            .then(res => res.json())
            .then(res => {
                setConfEventList(res.filter(confEvent => !['5', '65', '69', '84', '6'].includes(confEvent.conf_event_type.id_conf_event_type) && confEvent.publier != 'n'))
                setMessage(null)
            })
    }

    useEffect(() => {

        currentEvent && getConfEvent(currentEvent);

        currentEvent && user && getContactConferencierList()

        // userContactConferencierList && console.log('userContactConferencierList', userContactConferencierList)

    }, [currentEvent, userContactConferencierList, userRoleList])


    return (
        <LayoutAccountEvent>
            <BreadcrumbsProgramme />
            {message}
            <div className="mt-3">
                {confEventList && confEventList.map((confEvent, index) => <ConfEvent
                    handleChange={handleChange}
                    userContactConferencierList={contactConferencierList
                        .filter(userContactConferencier => userContactConferencier.event.id_event == currentEvent.id_event && userContactConferencier.id_conf_event)
                        //.map(userContactConferencier => userContactConferencier.id_conf_event)
                    }
                    key={index}
                    confEvent={confEvent} />)}
            </div>
        </LayoutAccountEvent>
    )
}

export default ScheduleSelection