import parse from 'html-react-parser';
import React, { useState, useEffect, useContext } from 'react';
import Loader from './Loader';
import { API_URL } from '../config';
import EventContext from '../contexts/EventContext';
import Layout from './Layout';
import { removeDuplicateObjectFromArray, sortByAttribute } from '../utils';
import useApiUrlTranslation from './useApiUrlTranslation';
import { Link } from 'react-router-dom';


const JuryEventList = ({ presta, juryDemoList }) => {

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation()

    const sorted = sortByAttribute(juryDemoList, "heure_debut");

    return <div  >
        <p className='mt-5'><b>Sessions des pitch</b></p>

        <div className="overflow-hidden mb-10 mt-2 ring-1 ring-black ring-opacity-5 md:rounded-lg">

            <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50 hidden">
                    <tr>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Session</th>
                    </tr>
                </thead>
                <tbody className="divide-y">
                    {sorted
                        .filter(item =>
                            //   (item.jury_event.conf_event.type && ["48", "63", "66", "67", "74", "88", "92", "96", "97"].includes(item.jury_event.conf_event.type.id_conf_event_type)) &&
                            item.jury_event.thematique != 'y'
                            && item.presta.id_presta == presta.id_presta
                            && item.jury_event.conf_event.publier != 'n')
                        .map(jury_demo => {

                            const confEvent = jury_demo.jury_event.conf_event

                            return <tr key={jury_demo.id_jury_demo}
                                className='  px-3 py-2 text-sm text-gray-500 w-3/4 divide-y'
                            >
                                <td>
                                    {(confEvent.conf_event_date_local)}, {confEvent.heure_debut_local} - {confEvent.heure_fin_local}</td>
                                <td className='  px-3 py-2 text-sm text-gray-500'>


                                    {jury_demo.is_nomine === '1' ? <span className="text-white badge rounded-pill bg-success me-2" >
                                        <i className="fas fa-check-circle"></i> Nominated Solution </span> : ''
                                    }

                                    <p>{parse(jury_demo.jury_event.conf_event.conf_event_lang.cel_titre.replace(/(<([^>]+)>)/gi, "") ?? "")}
                                    </p>


                                </td>


                                <td><Link className="text-nowrap text-blue-800 rounded-full bg-white px-2.5 py-1 text-xs hover:bg-gray-50" to={`/events/${currentEvent?.id_event}/programm/${confEvent.id_conf_event}/parcours-details`}>{t('global.en_savoir_plus')}</Link></td>
                            </tr>
                        })}
                </tbody>
            </table>
        </div>
    </div>
}

const Communaute = ({ communauteList }) => {

    return <div className="bg-neutral-100 p-1 flex  items-center space-x-1 rounded">
        <span> Pays affiliés</span>
        {communauteList && communauteList
            //dedup by communaute.cycle_lang.id_cycle_lang
            .filter((item, index, self) =>
                index === self.findIndex((t) => (
                    t.cycle_lang.id_cycle_lang === item.cycle_lang.id_cycle_lang
                ))
            )
            .map(communaute => <div>  <img src={communaute?.cycle_lang?.attachedFilesCollection.find(item => item.id_attached_file_type == 65).small} alt="" class="false  w-10 lg:w-10 rounded" />

                {/* {communaute.cycle_lang.nom_cycle_lang} */}
            </div>)}
    </div>

}

const Candidat = ({ candidat, index, juryDemoList, event }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation()

    const videoUrl = candidat.presta.video_url !== '' ? <a target="_blank" href={candidat.presta.video_url} style={{ color: "#339af0" }}><small><i class="fas fa-video"></i>{candidat.presta.video_url}</small></a> : '';

    const [communauteList, setCommunaute] = useState(false);

    const getCommunaute = async () => {

        await fetch(`${apiUrlTranslation}?action=getCommunaute&filter= AND c.id_contact="${candidat.presta.id_contact.id_contact}" 
        AND ecl.id_event=1789 `)

            .then(response => response.json())
            .then(data => {
                setCommunaute(data);
            })
    }

    useEffect(() => {
        getCommunaute();
    }, [i18n.resolvedLanguage])


    return <div className="row border-x border-y  mb-2 rounded-2xl bg-white">

        <div className="col-md-3">

            <div className=' d-flex align-items-center'>
                <p className='text-muted'>#{index}</p>

                <span className="text-white hidden">{candidat.presta.id_contact.id_contact}</span>

                <div class="card m-2" style={{ width: "100%" }}>

                    {candidat.presta.id_contact.logos &&
                        <img src={candidat.presta.id_contact.logos.small} class="card-img-top" alt="..." />
                    }

                    <div class="card-body ">
                        <h6 class="card-title"> {candidat.presta.id_contact.societe}   	</h6>
                        <p class="card-text"></p>

                        <i className="fas fa-map-marker-alt text-muted"></i>
                        <span
                            style={{ texTransform: "uppercase" }}
                            className="text-muted text-sm w-6"> {candidat.presta.id_contact.ville}, {candidat.presta.id_contact.pays}</span>
                        <img src={candidat.presta.id_contact.flag}
                            alt=""
                            class="m-2 w-6"
                        />
                    </div>
                </div>




                <div class="card m-2" style={{ width: "100%" }}>
                    <img src={candidat.presta.id_contact.photos?.small} class="card-img-top" alt="..." />
                    <div class="card-body">
                        <h6 class="card-title"> {candidat.presta.id_contact.prenom}  {candidat.presta.id_contact.nom}	</h6>
                        <p class="card-text"></p>
                    </div>
                </div>

            </div>
            <Communaute communauteList={communauteList} />

        </div>

        <div className="col pt-3">


            <div className="rounded visuel hidden">
                {candidat.presta.id_contact.logos && <img
                    style={{ width: "140px" }}
                    className="py-3"
                    src={candidat.presta.id_contact.logos.small} />} </div>

            <div className="flex items-center justify-between">
                <b className="py-3 text-xl text-pink-600">{candidat.presta.presta_nom}</b>
                <Link target="_blank" className="border-x border-y rounded-full px-3 border-pink-600 text-sm py-2" to={`/events/${event?.id_event}/programm/${candidat.presta.id_presta}/demo-details`}>{t('global.en_savoir_plus')}</Link>
            </div>




            <p>{
                //remove html tags
                parse(candidat.presta.presta_accroche.substring(0, 200)
                    .replace(/(<([^>]+)>)/gi, "") ?? " "

                )}...</p>
            {videoUrl}
            <JuryEventList
                juryDemoList={juryDemoList}
                juryDemo={candidat}
                presta={candidat.presta} />
        </div>

    </div>
}


const Candidats = () => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation()

    const [candidats, setCandidats] = useState(null);

    const [juryDemoList, setJuryDemoList] = useState(null);

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    useEffect(() => {

        const getCandidats = async () => {

            await fetch(`${apiUrlTranslation}?action=getJuryDemoList&params=WHERE id_event=${currentEvent.id_event}`)
                .then(res => res.json())
                .then(all => {

                    const allFiltered = all
                        //.filter(item => item.is_visible == '1')
                        .map(item => {

                            return { ...item, id_presta: item.presta.id_presta }
                        })

                    setJuryDemoList(all)
                    setCandidats(removeDuplicateObjectFromArray(allFiltered, "id_presta"))
                })
        }
        if (currentEvent) {
            getCandidats()
        }

    }, [currentEvent, i18n.resolvedLanguage]);

    return <Layout title={<div className="text-white">{t('event_nav.annuaire_candidats')}</div>} className="">

        {candidats && candidats.length === "0" && <div className='container alert alert-success'>A découvrir prochainement</div>}

        <div className="px-5 mx-auto">
            {candidats ? candidats.map((item, index) => <Candidat
                event={currentEvent}
                index={index + 1}
                juryDemoList={juryDemoList}
                key={index}
                candidat={item} />) : <Loader />}

        </div>
    </Layout >
};

export default Candidats;