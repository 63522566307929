import React, { createContext, useContext, useEffect, useState } from 'react'
import RegistrationFormSection from './RegistrationFormSection'
import useApiUrlTranslation from './useApiUrlTranslation';
import { useParams } from "react-router-dom";

import { set, useFormContext } from 'react-hook-form';
import EventContext from '../contexts/EventContext';
import FormulaireContext from '../contexts/FormulaireContext';
import FormulaireProvider from '../contexts/FormulaireProvider';
import { CounterContext, CounterProvider } from '../contexts/CounterProvider';
import RegistrationFormStatutSection from './RegistrationFormStatutsSection';
import RegistrationFormStatut from './RegistrationFormStatut';
import { RegistrationFormSubtitleSection } from './RegistrationFormStyleSection';
import HTMLReactParser from 'html-react-parser';
import { use } from 'i18next';
import { map } from 'leaflet';
import { v4 as uuidv4 } from 'uuid';

const Statut = ({ cycle_lang }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [eventContactTypeList, setEventContactTypeList] = useState(false);

    const getEventContactTypeList = async () => {

        return fetch(`${apiUrlTranslation}?action=getEventContactTypeList&filter= WHERE id_event_contact_type IN(234)`)
            .then(res => res.json())
            .then(res => setEventContactTypeList(res))
    }

    useEffect(() => {
        getEventContactTypeList();
    }, [i18n.resolvedLanguage])

    const statutList = [
        "Cercle des Tech'Xplorateurs : Je décideur, utilisateur finaux, expert et consultants et souhaite participer aux chassent des tendances et des solutions innovantes collaboratives et partager mon expertise avec les autres délégations lors des débriefings",
        "Cercle des Game Changers : je suis une startup ou un fournisseur des solutions innovantes à fort potentiel d'accélération internationale je souhaite participer au pitch, webcast, et showcase en lien avec mon expertise.",
        "Cercles des Investisseurs : je suis un fond, un business angels, un venture capitalist, un financeur publics/privés et je souhaite rejoindre les jurés des sessions de pitch en lien avec mes domaines d'investissements",
        "Cercle des Tech'Xportateurs : je suis décideurs, utilisateurs, experts et consultants en capacité de vendre ou de distribuer les solutions des games changer qui m'intéressent dans mon pays ou dans mon écosystème. Je souhaite rejoindre les jurés des sessions de Tech’Xport Hub afin d’évaluer avec mes homologues d’autres pays le potentiel d'extension internationale des solutions présentées",
        "Cercle des Territoires d'excellence : je suis une agence de développement économique, d'export, un tiers lieux innovation ou un accélérateur… je souhaite accélérer mes entreprises dans monde ou en attirer de nouvelles dans mon écosystème",
    ]

    return <div>

        <ul className="divide-y space-y-2">
            {eventContactTypeList && eventContactTypeList.map((eventContactType, index) => <li key={index}>
                <label className="flex space-x-3 font-normal items-start mt-2">
                    <input
                        type="checkbox"
                    />
                    <span>{eventContactType.libelle} : {eventContactType.instructions_formulaire} </span>
                </label>
            </li>)
            }
        </ul>
    </div>
}

const FamilyItem = ({ cycle_lang }) => {

    const [eventContactType, setEventContactType] = useState({});

    //const { formulaire } = useContext(FormulaireContext);

    const formulaire = {};
    const { register } = useFormContext();

    useEffect(() => {

    }, [])

    return <tr className="programme-item">
        <td>{formulaire && formulaire.id_event_contact_type_main}
            <label className="flex space-x-3 font-normal items-center">
                <input
                    type="checkbox"
                    {...register("cycleLangIdEventContactTypeList")}
                    value={JSON.stringify({ id_cycle_lang: cycle_lang.id_cycle_lang, id_event_contact_type: formulaire.id_event_contact_type_main })}
                />
                <img className="rounded w-6 h-6"
                    src={`${cycle_lang.attachedFilesCollection.find(attachedFile => attachedFile.id_attached_file_type == 65).tiny}`} alt=""
                />
                <span>{cycle_lang.nom_cycle_lang}</span>

            </label>
        </td>
    </tr>
}


const CycleLangListHorsEvent = ({ title, cycleLangListPreselection, cycleLangCategorie,
    numberOfProgrammesThematiques, setNumberOfProgrammesThematiques }) => {

    const { i18n, apiUrlTranslation } = useApiUrlTranslation();

    useEffect(() => {

        cycleLangListPreselection && cycleLangListPreselection.map(cycleLang => {
            //let new = mutation of numberOfProgrammesThematiques
            setNumberOfProgrammesThematiques(cycleLang.children && cycleLang.children.filter(child => child.cycle_lang_categorie == cycleLangCategorie).length)
        })
    }
        , [i18n.resolvedLanguage, cycleLangListPreselection, numberOfProgrammesThematiques])

    return <div>
        {title && cycleLangListPreselection && cycleLangListPreselection?.some(cycleLang => cycleLang.children && cycleLang.children.filter(child => child.cycle_lang_categorie == cycleLangCategorie).length > 0) && (
            <p className='font-bold py-3 hidden'>{title} HE</p>
        )}
        {/* 
        {JSON.stringify(cycleLangListPreselection)} */}

        {cycleLangListPreselection && cycleLangListPreselection.map(cycleLang => {


            //let new = mutation of numberOfProgrammesThematiques

            //   setNumberOfProgrammesThematiques(cycleLang.children && cycleLang.children.filter(child => child.cycle_lang_categorie == cycleLangCategorie).length)


            return <div key={cycleLang.id_cycle_lang}>
                {/* -{cycleLang.children.filter(child => child.cycle_lang_categorie == cycleLangCategorie).length}- */}
                {cycleLang.children && cycleLang.children
                    //filter cycleLangCategorie = cycleLangCategorie
                    .filter(child => child.cycle_lang_categorie == cycleLangCategorie)
                    .map((child, index) => <FamilyItem cycle_lang={child} key={index} />)}</div>
        }
        )}
    </div>
}

const DisplayCycleLangEventLigneTypeItem = ({ cycleLangEventLigne, cycleLangCategorie,
    cycleLangEventLignenNmberChildren, setCycleLangEventLigneNumberChildren }) => {

    const { i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [cycleLang, setCycleLang] = useState(false);

    useEffect(() => {

        const getCycleLang = async () => {

            return fetch(`${apiUrlTranslation}?action=getCycleLang&filter= id_cycle_lang=${cycleLangEventLigne.id_cycle_lang.id_cycle_lang} AND`)
                .then(res => res.json())
                .then(res => {
                    setCycleLang(res[0])

                    setCycleLangEventLigneNumberChildren(res[0].children && res[0].children.filter(child => child.cycle_lang_categorie == cycleLangCategorie).length)
                })
        }

        getCycleLang();
    }, [i18n.resolvedLanguage])

    return <>
        {cycleLang.children && cycleLang.children.filter(child => child.cycle_lang_type == "collectif_programme" && child.cycle_lang_categorie == cycleLangCategorie).length > 0 &&
            <div className="font-bold  pt-3 pb-2"> {cycleLang.nom_cycle_lang}</div>}


        {cycleLang.children && cycleLang.children
            .filter(child => child.cycle_lang_type == "collectif_programme" && child.cycle_lang_categorie == cycleLangCategorie)
            .map(child => {
                return <FamilyItem cycle_lang={child} />
            })}
    </>
}

const DisplayCycleLangEventLigneType = ({ cycleLangEventLigneList, type, cycleLangCategorie }) => {



    //if cycleLangEventLigneList.id_cycle_lang.children lentgH = 0 return false

    // if (cycleLangEventLigneList && cycleLangEventLigneList.map(cycleLangEventLigne =>
    //     cycleLangEventLigne.id_cycle_lang.children && cycleLangEventLigne.id_cycle_lang.children.filter(child => child.cycle_lang_categorie == cycleLangCategorie).length > 0)
    // ) return null


    const { i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [cycleLangList, setCycleLangList] = useState(false);

    useEffect(() => {

        const getCycleLang = async ({ cycleLangEventLigneList }) => {

            const idCycleLangList = cycleLangEventLigneList ? cycleLangEventLigneList
                .map(cycleLangEventLigne => cycleLangEventLigne.id_cycle_lang.id_cycle_lang).join(',') : []

            return fetch(`${apiUrlTranslation}?check=y&action=getCycleLang&filter= id_cycle_lang IN(${idCycleLangList}) AND`)
                .then(res => res.json())
                .then(res => {
                    setCycleLangList(res)
                })
        }

        getCycleLang({ cycleLangEventLigneList });
    }, [i18n.resolvedLanguage])

    const [cycleLangEventLignenNmberChildren, setCycleLangEventLigneNumberChildren] = useState(0);

    useEffect(() => {

    }, [])


    return <div>

        {/* {JSON.stringify(cycleLangEventLigneList)}

        {
            cycleLangEventLigneList && cycleLangEventLigneList
                .map(cycleLangEventLigne => cycleLangEventLigne.id_cycle_lang.id_cycle_lang).join(',')
        }

        {
            cycleLangEventLigneList && cycleLangEventLigneList
                .map(cycleLangEventLigne => <div>{cycleLangEventLigne.id_cycle_lang.id_cycle_lang} - {cycleLangCategorie}</div>)
        }



        {cycleLangList && cycleLangList.map(cycleLang => <li>*{cycleLang.nom_cycle_lang} | {JSON.stringify(cycleLang.children?.length)}|</li>)}
        ---************************** */}

        {<p className='font-bold py-3 hidden'>{type} </p>}
        <table>
            {cycleLangEventLigneList && cycleLangEventLigneList
                .map((cycleLangEventLigne, index) => {

                    return <DisplayCycleLangEventLigneTypeItem
                        key={uuidv4()}
                        cycleLangEventLignenNmberChildren={cycleLangEventLignenNmberChildren}
                        setCycleLangEventLigneNumberChildren={setCycleLangEventLigneNumberChildren}
                        cycleLangCategorie={cycleLangCategorie}
                        cycleLangEventLigne={cycleLangEventLigne} />
                })}
        </table>
    </div>
}

const RegistrationFormCycleLangSectionEventLigne = ({ formulaire }) => {

    const { currentEvent } = useContext(EventContext);

    const [idCycleLangPreselectionList, setIdCycleLangPreselectionList] = useState([]);

    const [cycleLangListPreselection, setCycleLangListPreselection] = useState(false);

    const [numberOfProgrammesThematiques, setNumberOfProgrammesThematiques] = useState(0);

    //const { activeFormulaire } = useContext(FormulaireContext);

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation()

    const [cycleLangEventLigneList, setCycleLangEventLigneList] = useState(false);

    const parseIdListFromUrl = (url) => {
        const urlParams = new URLSearchParams(url.split('?')[1]);
        const idListValue = urlParams.get('id_cycle_lang_preselection_list');
        if (idListValue) {
            return idListValue.split(',');
        }
        return [];
    };



    const getCycleLangEventLigne = async ({ idEventLigne }) => {

        return fetch(`${apiUrlTranslation}?action=getCycleLangEventLigne&params= WHERE id_event_ligne=${idEventLigne} `)

            .then(res => res.json())

            .then(res => setCycleLangEventLigneList(res))
    }

    const getCycleLangListPreselection = async (idCycleLangList) => {

        const filter = `id_cycle_lang IN(${idCycleLangList}) AND &check=y`;

        fetch(`${apiUrlTranslation}?action=getCycleLang&filter=${filter}`)
            .then(res => res.json())
            .then(res => {
                setCycleLangListPreselection(res)
                // console.log(res)
            })
    }


    useEffect(() => {


        currentEvent && getCycleLangEventLigne({ idEventLigne: currentEvent.event_ligne.id_event_ligne });

        // const url2 = new URL(window.location.href);
        // const params = new URLSearchParams(url2.search);
        // let idCycleLangPreselectionList = params.get('id_cycle_lang_preselection_list') || "";


        // const idCycleLangEventLigneList = cycleLangEventLigneList && cycleLangEventLigneList.map(cycleLangEventLigne => cycleLangEventLigne.id_cycle_lang.id_cycle_lang).join(',');
        // getCycleLangListPreselection(idCycleLangPreselectionList ?? idCycleLangEventLigneList);


        //    formulaire && setFormulaire({ formulaire });
    }, [formulaire, i18n.resolvedLanguage]);


    const [idCycleLangEventLigneList, setIdCycleLangEventLigneList] = useState(''); // Define the state

    // Step 3: Create an async function to extract the value from the URL
    const fetchIdCycleLangPreselectionList = async () => {
        return new Promise((resolve) => {
            const url = new URL(window.location.href);
            const params = new URLSearchParams(url.search);
            const id = params.get('id_cycle_lang_preselection_list') || '';
            resolve(id);
        });
    };

    // Step 4: Use useEffect to call the async function when the component mounts
    useEffect(() => {
        const getIdCycleLangPreselectionList = async () => {
            const id = await fetchIdCycleLangPreselectionList();
            setIdCycleLangPreselectionList(id);
        };

        getIdCycleLangPreselectionList();
    }, []); // Empty dependency array ensures this runs only once when the component mounts

    // Step 6: Use the state value in your component
    useEffect(() => {
        const idToUse = idCycleLangPreselectionList || idCycleLangEventLigneList;

        //const idToUse = idCycleLangEventLigneList;
        if (idToUse) {
            getCycleLangListPreselection(idToUse);
        }
    }, [idCycleLangPreselectionList, idCycleLangEventLigneList]); // Add idCycleLangEventLigneList to dependencies

    useEffect(() => {
        if (currentEvent) {
            getCycleLangEventLigne({ idEventLigne: currentEvent.event_ligne.id_event_ligne });
        }
    }, [currentEvent]);

    useEffect(() => {
        // Get the query string from the URL
        const queryString = window.location.search;

        // Parse the query string
        const urlParams = new URLSearchParams(queryString);

        // Retrieve the value of id_cycle_lang_preselection_list
        const idCycleLangPreselectionList = urlParams.get('id_cycle_lang_preselection_list');

        // Split the values by comma and set the state
        if (idCycleLangPreselectionList) {
            setIdCycleLangPreselectionList(idCycleLangPreselectionList.split(','));
        }

    }, []);

    if (!formulaire || !idCycleLangPreselectionList) return null

    if (!cycleLangEventLigneList && currentEvent) return null

    return (
        <>

            <RegistrationFormSection
                formSectionId={29}
                supertitle_={HTMLReactParser(`<div className=" bg-dark font-bold p-3 text-white text-2xl rounded-t-2xl">
               ${t("form.sections.statut_section.section_title_supertitle")}
            </div>`)}

                supertitle__={HTMLReactParser(`<div className=" bg-dark font-bold p-3 text-white text-2xl rounded-t-2xl">
                ${t("form.sections.cycle_lang_event_ligne_section.domaines_interet")}
             </div>`)}
                title={formulaire.cycle_lang_event_ligne_section_title}
                supertitle={formulaire.cycle_lang_event_ligne_section_title}
                instructions={formulaire.cycle_lang_event_ligne_section_instructions}>

                {/* <FormulaireContext.Provider value={{ formulaire }}> */}

                {/*
                <p className="row pl-5  pb-3 text-2xl font-bold" > {t('form.sections.cycle_lang_event_ligne_section.validation_de_votre_statut')}</p>

                <p className="text-gray-500 pb-4 text-sm">  {formulaire.statut_section_instructions}</p>

                 < RegistrationFormStatut
                    formulaire={formulaire}
                    idStatutSelection={[77, 79, 90, 83, 84, 82, 74, 75, 76]}
                /> */}

                {numberOfProgrammesThematiques > 0 && <p p className="hidden row pl-5  pb-3 text-2xl font-bold mt-3" >{t('form.sections.cycle_lang_event_ligne_section.domaines_interet')} </p>}


                {/* {cycleLangEventLigneList && cycleLangEventLigneList

                    .filter(cycleLangEventLigne => ["programme_thematique", "programme_vertical"].includes(cycleLangEventLigne.id_cycle_lang.cycle_lang_categorie))
                    .map(cycleLangEventLigne =>

                        cycleLangEventLigne.id_cycle_lang.children && cycleLangEventLigne.id_cycle_lang.children.length > 0

                    ).lenght} */}

                <FormulaireProvider >

                    <p className='font-bold py-3'>{t('form.sections.cycle_lang_event_ligne_section.programmes_thematique')} </p>
                    {/* <DisplayCycleLangEventLigneType
                        cycleLangCategorie="programme_thematique"
                        type="Positionnement sur le programme thématique"
                        cycleLangEventLigneList={cycleLangEventLigneList && cycleLangEventLigneList}
                    /> */}

                    <CycleLangListHorsEvent
                        cycleLangCategorie="programme_thematique"
                        title="Positionnement sur les programmes thématiques"
                        numberOfProgrammesThematiques={numberOfProgrammesThematiques}
                        setNumberOfProgrammesThematiques={setNumberOfProgrammesThematiques}
                        cycleLangListPreselection={cycleLangListPreselection} />

                    <p className='font-bold py-3'>{t('form.sections.cycle_lang_event_ligne_section.programmes_verticaux')} </p>
                    {/* <DisplayCycleLangEventLigneType
                        cycleLangCategorie="programme_vertical"
                        type=" Positionnement sur les programmes verticaux"
                        cycleLangEventLigneList={cycleLangEventLigneList && cycleLangEventLigneList} /> */}

                    <CycleLangListHorsEvent
                        numberOfProgrammesThematiques={numberOfProgrammesThematiques}
                        setNumberOfProgrammesThematiques={setNumberOfProgrammesThematiques}
                        cycleLangCategorie="programme_vertical"
                        title=" Positionnement sur le programmes vertical"
                        cycleLangListPreselection={cycleLangListPreselection} />

                </FormulaireProvider>

                {/* </FormulaireContext.Provider> */}

            </RegistrationFormSection >

            {/* <RegistrationFormSection
                title="Mode de contribution à l'événement"
                instructions="Choisissez le cercle d'intelligence collective que vous souhaitez rejoindre lors de l'événement et précisez ainsi vos ambitions"
            >
                {cycleLangEventLigneList && cycleLangEventLigneList.length > 0 && (
                    <Statut cycle_lang={cycleLangEventLigneList[0].id_cycle_lang} />
                )}

            </RegistrationFormSection > */}
        </>
    )
}

export default RegistrationFormCycleLangSectionEventLigne;