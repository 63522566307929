import React, { useState, useEffect } from "react";
import { API_URL } from "../config";
import { useParams } from "react-router-dom";
import DemoCard from "./DemoCard";
import Loader from "./Loader";
import useApiUrlTranslation from "./useApiUrlTranslation";

const ContactDemo = ({ idJury }) => {
  const fetchPartenaires = async (idJury) => {
    await fetch(`${API_URL}?action=getJuryConferencierList`, {
      method: "POST",
      body: JSON.stringify({ params: `WHERE id_jury=${idJury}` }),
    })
      .then((res) => res.json())
      .then((res) => console.log(res));
  }

  useEffect(() => {
    fetchPartenaires(idJury);
  }, []);

  return <div></div>;
}

const DemoList = ({ confEventList, isNomine }) => {

  const { t, i18n, apiUrlTranslation } = useApiUrlTranslation()

  const [demoList, setDemoList] = useState([]);

  let title = t("pages.parcours-details.demos_positionnees")
  if (isNomine) title = t("pages.parcours-details.candidats_nomines")

  //details des solutions présentées
  // let { id_conf_event } = useParams();

  const fetchJuryEvent = async (idJuryEvent) => {
    await fetch(`${API_URL}?action=getJuryEvent&ije=${idJuryEvent}`)
      .then((res) => res.json())
      .then((res) => { fetchJuryDemoList(res) });
  }

  const fetchJuryDemoList = async (juryEvent) => {
    await fetch(
      `${apiUrlTranslation}?action=getJuryDemoList&params=WHERE id_jury=${juryEvent.id_jury.id_jury} AND is_visible=1`
    )
      .then((res) => res.json())
      .then((demoList) => {

        const demoListFiltered = isNomine ? demoList.filter(demo => demo.is_nomine === "1") : demoList.filter(demo => demo.is_nomine !== "1");

        setDemoList(demoListFiltered);
      })
  }

  useEffect(() => {
    fetchJuryEvent(confEventList[0].jury_event?.id_jury_event_encoded);
  }, [i18n.resolvedLanguage]);

  if (demoList.length === 0) {

    return false;
  }

  return (
    <div className="p-2 my-3">
      {/* {demoList ? console.log(demoList.filter((demo) => demo.is_nomine === isNomine)) : "lmqksd"} */}
      <p className="text-5xl py-3 animatedgradient font-extrabold">{title}</p>

      {confEventList ? (
        confEventList.map((confEvent) => (
          <div key={confEvent.id_event.id_event} className="d-flex flex-wrap democard">
            {demoList ? demoList.map((demo, i) => <DemoCard demo={demo} key={i} />) : "loading"}
            {/* <ContactDemo idJury={confEvent.jury_event?.id_jury?.id_jury} /> */}
          </div>
        ))
      ) : (
        <Loader />
      )}
    </div>
  )
}

export default DemoList;