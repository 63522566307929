import { useContext } from "react";
import EventContext from '../contexts/EventContext';
import HTMLReactParser from "html-react-parser";

const RegistrationFormEventLignePresentationSection = ({ formulaire }) => {

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;


    if (!currentEvent) return null;

    return (
        <div className="flex flex-col  lg:flex-row space-x-3 items-center">
            <div className="basis-3/12 px-3">
                <img
                    className="w-[300px]"
                    src={currentEvent.event_ligne.visuels_1024.large}
                    alt={currentEvent.event_ligne.nom} />
            </div>
            <div>
                {HTMLReactParser(currentEvent.event_ligne.descriptif_mgv)}
            </div>
        </div>
    )
}

export default RegistrationFormEventLignePresentationSection;