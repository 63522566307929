import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { API_URL } from '../config';
import EventContext from '../contexts/EventContext'
import RegistrationFormSection from './RegistrationFormSection';

const RegistrationFormCollectifPresentationSection = ({ pageTitle }) => {

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    const Header = () => {

        let img;

        if (['startup', 'jury'].includes(formName) && (id_cycle_lang == 2270 || ['collectif_planactionprive'].includes(cycleLang?.cycle_lang_type))) {

            img = cycleLang.attachedFilesCollection?.filter(attachedFile => attachedFile.id_attached_file_type == 76).map(attachedFile => attachedFile.large) ?? cycleLang.logo_better.header ?? cycleLang.logo_better.large

        } else if (['startup', 'jury', 'coordinateur-jury'].includes(formName)) {

            console.log("b");
            img = cycleLang.attachedFilesCollection?.filter(attachedFile => attachedFile.id_attached_file_type == 83).map(attachedFile => attachedFile.large);

        } else if (['speaker', 'participant'].includes(formName)) {

            img = cycleLang.attachedFilesCollection?.filter(attachedFile => attachedFile.id_attached_file_type == 91).map(attachedFile => attachedFile.large);
            //img = cycleLang.global_challenge_header.large;

        } else {

            //img = cycleLang.logo_better.header ?? cycleLang.logo_better.large

            img = cycleLang.attachedFilesCollection?.filter(attachedFile => attachedFile.id_attached_file_type == 76).map(attachedFile => attachedFile.large) ?? cycleLang.logo_better.header ?? cycleLang.logo_better.large
        }

        if (currentEvent) {

            if (img == "") {
                img = currentEvent && currentEvent.logo ? currentEvent.logos.small : currentEvent.event_ligne.el_visuels.small
            }

            img = currentEvent && currentEvent.logo ? currentEvent.logos.small : currentEvent.event_ligne.el_visuels.small

            img = `https://www.mlg-consulting.com/manager_cc/docs/archives/${currentEvent.event_ligne.visuel_1024}`;
        }

        return <><img alt="" src={img} style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'contain' }} /></>
    }

    const { id_cycle_lang, formName } = useParams();

    const [cycleLang, setCycleLang] = useState(null);

    useEffect(() => {

        const fetchContact = async () => {

            await fetch(`${API_URL}?action=getCycleLang&filter=id_cycle_lang=${id_cycle_lang} AND `)
                .then(res => res.json())
                .then(res => setCycleLang(res[0]))
        }

        id_cycle_lang && fetchContact();

    }, []);

    if (!cycleLang && !currentEvent) {
        return ''
    }

    return <div className='container pt-5'>

        {
            cycleLang && <div className="row bg-gray-200 py-12 px-4 text-gray font-bold rounded-t-2xl">
                <div className="col-md-6  my-auto flex justify-start">
                    <Header />
                </div>
                <div className="col  my-auto">
                    <h2 className="text-5xl font-bold">{pageTitle}
                        {/* {cycleLang.nom_cycle_lang} */}
                    </h2>
                </div>
            </div>
        }

    </div>
}

export default RegistrationFormCollectifPresentationSection;
