import React, { useEffect } from 'react';
import { Redirect, Route, useHistory } from "react-router-dom";
//import { ACCESS_TOKEN_NAME } from '../constants/apiContants';

const PrivateRoute = ({ children, ...rest }) => {

    const history = useHistory();

    const user = localStorage.getItem('user');

    //console.log('user', JSON.parse(user))

    useEffect(() => {

        /*
        if (user) {
            history.push('/account/profil/');
        } else {
            history.push('/login/');
        }*/

        if (!user) {

            history.push('/login');


            console.log("deconnecté");

            return false
        }

    }, [user])


    return (
        <Route
            key={Date.now()}
            {...rest}
            render={({ location }) =>
                user ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}
export default PrivateRoute;