import React from 'react'
import { Link } from 'react-router-dom'

const NavProgramme = () => {
    return (


        <div class="mb-5">

            <Link className="inline-flex items-center px-4 py-3 border border-transparent text-base leading-4 font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" to="/account/my-programms">Mes programmes</Link>
            <Link className="mx-2 inline-flex items-center px-4 py-3 border border-transparent text-base leading-4 font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" to="/account/presta-by-user-programme">Solutions</Link>
            <Link className="inline-flex items-center px-4 py-3 border border-transparent text-base leading-4 font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" to="/account/thematiques-by-user-programme">Programmes cibles</Link>
            <Link className="mx-2  inline-flex items-center px-4 py-3 border border-transparent text-base leading-4 font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" to="/account/all-programms">Tous les programmes</Link>
            {/* <Link className="btn  me-3 disabled btn-outlined-secondary">Mes formulaires</Link>
            <Link className="btn btn-outlined-secondary me-3 disabled">Mes programmes</Link> */}

        </div>




    )
}

export default NavProgramme