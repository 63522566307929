import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import useApiUrlTranslation from './useApiUrlTranslation';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Loader from './Loader';

const ConfEvent = ({ confEvent }) => {

    return <div className='flex bg-neutral-900 px-3 py-2 divide-x space-x-2'>

        <div className='dark:text-white '>{confEvent.conf_event_date}</div>
        <div className='dark:text-white px-3'>{confEvent.conf_event_lang.cel_titre}</div>
    </div>
}

const ContactPresentation = ({ contactCycleLang }) => {

    const contact = contactCycleLang.contact;

    return <li className=" flex space-x-5 w-[200px]">

        <img className="aspect-[3/2] w-10 h-10 rounded-circle object-cover" src={contact.photos.large} alt="" />
        <div className="">
            <h3 className=" text-sm font-semibold leading-8 tracking-tight dark:text-white  ">{contact.prenom} {contact.nom}</h3>
            <p className="text-xs text-gray-600  dark:text-gray-600 ">{contact.fonction} - {contact.societe}</p>
        </div>
    </li>
}


const EventCoordinateursCollectifsDetails = () => {

    const { idEvent, idCycleLang } = useParams();

    const { i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [contactCycleLangList, setContactCycleLangList] = useState(false);

    const [contactCycleLangFullList, setContactCycleLangFullList] = useState(false);

    const [cycleLang, setCycleLang] = useState(false);

    const [contactConferencierList, setContactConferencierList] = useState(false);

    const [confEventList, setConfEventList] = useState(false);

    const getContactConferencierList = async ({ idContactList }) => {

        await fetch(`${apiUrlTranslation}?action=getContactConferencierList&filter= AND id_event=${idEvent} AND c.id_contact IN(${idContactList})`)

            .then((res) => res.json())
            .then((res) => {
                setContactConferencierList(res);

                setConfEventList(res

                    .filter((contactConferencier, index, self) =>
                        index === self.findIndex((t) => (
                            t.conf_event.id_conf_event === contactConferencier.conf_event.id_conf_event
                        ))
                    )
                    .map(contactConferencier => contactConferencier.conf_event));
            });
    };

    const fetchContactCycleLangFullList = async ({ idEvent }) => {

        const url = new URL(window.location.href);
        const idCycleLangPreselectionList = url.searchParams.get('id_cycle_lang_preselection_list');

        let filter = `id_cycle_lang IN(${idCycleLangPreselectionList})   AND`;

        filter = `  c.id_contact IN(SELECT id_contact FROM conferenciers WHERE id_event=${idEvent}) AND 
        id_event_contact_type NOT IN(207) AND  `;

        await fetch(`${apiUrlTranslation}?action=fetchContactCycleLang&filter=${filter}   `)
            .then(res => res.json())
            .then(res => setContactCycleLangFullList(res))
    }

    const fetchContactCycleLang = async ({ idEvent }) => {

        const url = new URL(window.location.href);

        let filter = `id_cycle_lang IN(${idCycleLang})   AND  c.id_contact IN(SELECT id_contact FROM conferenciers WHERE id_event=${idEvent}) AND 
        id_event_contact_type IN(207) AND  `;

        await fetch(`${apiUrlTranslation}?action=fetchContactCycleLang&filter=${filter}   `)
            .then(res => res.json())
            .then(res => {
                setContactCycleLangList(res)

                const idContactList = res.map(contactCycleLang => contactCycleLang.contact.id_contact).join(',');

                getContactConferencierList({ idContactList });
            })
    }

    const getCycleLang = async () => {

        await fetch(`${apiUrlTranslation}?action=getCycleLang&filter= id_cycle_lang=${idCycleLang} AND `)

            .then((res) => res.json())
            .then((res) => {
                setCycleLang(res[0]);
            });
    };

    useEffect(() => {
        getCycleLang();

        fetchContactCycleLang({ idEvent })
        fetchContactCycleLangFullList({ idEvent })
    }, [i18n.resolvedLanguage])

    if (!cycleLang) return null

    return (
        <Layout title={cycleLang.nom_cycle_lang}>


            <div className='max-w-7xl mx-auto'>

                <div className='py-10'>
                    {contactCycleLangList && contactCycleLangList
                        //dedup by contact id_contact
                        .filter((contactCycleLang, index, self) =>
                            index === self.findIndex((t) => (
                                t.contact.id_contact === contactCycleLang.contact.id_contact
                            ))
                        )
                        .map((contactCycleLang, index) => {

                            const contact = contactCycleLang.contact;
                            return <div className='flex items-center space-x-5'>
                                <img className="rounded-full w-52 h-52" src={contact.photos.large} alt="" />

                                <div>
                                    <p className='dark:text-white font-bold'>Coordonné par </p>
                                    <p className='text-white'>{contact.prenom}     {contact.nom}</p>
                                </div>
                            </div>
                        })}
                </div>

                <ul className='flex'>
                    {
                        contactCycleLangFullList ? contactCycleLangFullList
                            .filter(contactCycleLangFullListItem => contactCycleLangFullListItem.cycle_lang.id_cycle_lang === cycleLang.id_cycle_lang)
                            //filter  contactCycleLangFullListItem id_contact not  in contactCycleLangList
                            .filter(contactCycleLangFullListItem => !contactCycleLangList.map(contactCycleLang => contactCycleLang.contact.id_contact).includes(contactCycleLangFullListItem.contact.id_contact))

                            .map(contactCycleLang => <ContactPresentation contactCycleLang={contactCycleLang} />) : <Loader className='bg-white' />}

                </ul>


                <p className='text-2xl py-5  font-bold dark:text-white'>Programme du collectif</p>

                {confEventList && <div className="space-y-1"> {confEventList.map((confEvent, index) =>
                    <ConfEvent confEvent={confEvent} />)}
                </div>
                }


            </div>
        </Layout>
    )
}

export default EventCoordinateursCollectifsDetails