import React from 'react'
import RegistrationFormSection from './RegistrationFormSection'
import { useEffect } from 'react'
import { useState } from 'react'
import { API_URL } from '../config'
import useApiUrlTranslation from './useApiUrlTranslation'
import EventContext from '../contexts/EventContext'
import { useContext } from 'react'
import HTMLReactParser from 'html-react-parser'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const confEventList__ = [
    { "titre": "Pitching session 1", "date": "2021-09-30", "heure_debut": "09:00", "heure_fin": "10:00", "numero": 1, "salle": "Salle 1" },
    { "titre": "Pitching session 2", "date": "2021-09-30", "heure_debut": "10:00", "heure_fin": "11:00", "numero": 2, "salle": "Salle 2" },
    { "titre": "Pitching session 3", "date": "2021-10-01", "heure_debut": "09:00", "heure_fin": "10:00", "numero": 3, "salle": "Salle 1" },
    { "titre": "Pitching session 4", "date": "2021-10-01", "heure_debut": "10:00", "heure_fin": "11:00", "numero": 4, "salle": "Salle 2" },
    { "titre": "Pitching session 5", "date": "2021-10-02", "heure_debut": "09:00", "heure_fin": "10:00", "numero": 5, "salle": "Salle 1" },
    { "titre": "Pitching session 6", "date": "2021-10-02", "heure_debut": "10:00", "heure_fin": "11:00", "numero": 6, "salle": "Salle 2" }
]

const STATUTS = [
    { label: "confirmed", color: "bg-green-500", symbol: "fas fa-check" },
    //  { label: "pending", color: "bg-yellow-500", symbol: "fas fa-clock" },
]

const LOGOS = ['https://www.mlg-consulting.com/manager_cc/docs/archives/thumbs/231014111423_logo-vecto-2023-49_200x0.png',
    'https://www.mlg-consulting.com/manager_cc/docs/archives/thumbs/231009162211_logo-vecto-2023-15_200x0.png',
    'https://www.mlg-consulting.com/manager_cc/docs/archives/thumbs/231009162529_logo-vecto-2023-05_200x0.png',
    'https://www.mlg-consulting.com/manager_cc/docs/archives/thumbs/231009162410_logo-vecto-2023-14_200x0.png']


const ConfEventItem = ({ confEvent }) => {

    const [partenaireList, setPartenaireList] = useState(false);

    const { i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [confEventCycleLangList, setConfEventCycleLangList] = useState(false);

    const [id_cycle_lang_preselection_list, setId_cycle_lang_preselection_list] = useState(false);

    const { idContact } = useParams();

    useEffect(() => {

        const getConfEventCycleLang = async () => {

            await fetch(`${apiUrlTranslation}?action=getConfEventCycleLang&params=WHERE id_conf_event=${confEvent.id_conf_event} `)
                .then(res => res.json())
                .then(res => {
                    setConfEventCycleLangList(res);
                })
        }

        const getPartenaires = async () => {
            await fetch(`${API_URL}?action=getPartenaires&params=AND  id_conf_event='${confEvent.id_conf_event}' AND c.auteur='${idContact}' GROUP By c.id_contact LIMIT 10`)
                .then(response => response.json())
                .then(data => {
                    setPartenaireList(data);
                })
        }

        getConfEventCycleLang();

        getPartenaires();

        //    const id_cycle_lang_preselection_list == query param id_cycle_lang_preselection_list

        const url = new URL(window.location.href);

        setId_cycle_lang_preselection_list(url.searchParams.get("id_cycle_lang_preselection_list"));
    }, [])


    //Afficher uniquement les conférences qui ont un cycle lang préselectionné

    if (id_cycle_lang_preselection_list && confEventCycleLangList && id_cycle_lang_preselection_list.split(',').filter(id_cycle_lang_preselection_item => confEventCycleLangList.map(item => item.id_cycle_lang.id_cycle_lang).includes(id_cycle_lang_preselection_item)).length == 0) {

        return null;
    }

    return <div>


        <div className="flex justify-between  bg-gray-100 items-center" key={confEvent.id_conf_event}>
            <div className="flex items-center py-3">
                {/* rand logo */}

                {confEventCycleLangList && confEventCycleLangList.map(confEventCycleLang => <div>

                    <img src={confEventCycleLang.id_cycle_lang.attachedFilesCollection.find(item => item.id_attached_file_type == 65).small} className="w-12 h-12 rounded-full" />

                    {/* {confEventCycleLang.id_cycle_lang.nom_cycle_lang} */}
                    {/* {confEventCycleLang.id_cycle_lang.id_cycle_lang} */}
                </div>)}
                <div>

                    <div className="text-lg font-bold pl-5">{confEvent.conf_event_lang.cel_titre}</div>
                    <div className="pl-5 rounded text-gray-500">{HTMLReactParser(confEvent.localTimeZone)} </div>
                </div>
            </div>
            <button className='rounded bg-indigo-500 text-white w-52 h-10 hidden'>Envoyer une invitation </button>
        </div>

        <div className="flex flex-row mb-5">

            <table className='w-full divide-y divide-gray-300'>

                <thead className="hidden">
                    <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                            Rôle
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Prénom
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Nom
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Organisme
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                            <span className="sr-only">Action</span>
                        </th>
                    </tr>
                </thead>


                <tbody className="divide-y divide-gray-200">
                    {
                        //  limit 10 items  random partenaireList

                        partenaireList && partenaireList

                            .map(partenaire => {

                                const statut = STATUTS[Math.floor(Math.random() * STATUTS.length)];

                                return <tr className="">
                                    <td className="py-2 hidden"> {partenaire.contact.statut.statut_nom}</td>
                                    <td> {partenaire.contact.prenom}</td>
                                    <td> {partenaire.contact.nom}</td>
                                    <td> {partenaire.contact.societe}</td>
                                    <td>{

                                        <span className={`text-white rounded-full px-2 py-1 text-xs font-bold ${statut.color}`}>

                                            <i className={`${statut.symbol} mr-2`}></i>
                                            {statut.label}</span>

                                    }</td>
                                </tr>
                            })}
                </tbody>
            </table>
        </div>


    </div>;
}

const RegistrationFormJuryEventCoordinatorToolsSection = () => {

    const [confEventList, setConfEventList] = useState(false);

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation()

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    const fetchConfEventList = async () => {

        let filteredList;

        await fetch(`${apiUrlTranslation}?action=getConfEvent&filter=id_event=${currentEvent.id_event} AND publier !='n' AND selectable !='n' AND type NOT IN (92)`)
            .then(res => res.json())
            .then(res => {
                filteredList = res.filter(item => ["67"].includes(item.conf_event_type.id_conf_event_type));
                setConfEventList(filteredList);
            })
    }

    useEffect(() => {

        currentEvent && fetchConfEventList();

    }, [currentEvent])

    if (!confEventList) return null;

    return (
        <RegistrationFormSection title={t('form.sections.jury_validation_section.section_title')}>

            {confEventList && confEventList.map(confEvent => <ConfEventItem confEvent={confEvent} />)}

        </RegistrationFormSection>
    )
}

export default RegistrationFormJuryEventCoordinatorToolsSection