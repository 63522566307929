import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { API_URL } from "../config";
import i18n from "./i18n";
import useApiUrlTranslation from "./useApiUrlTranslation";

function DemoCard({ demo, key }) {

  let { idEvent } = useParams();

  const { t, i18n, apiUrlTranslation } = useApiUrlTranslation()

  function GetContact({ idContact }) {
    const [contact, setContact] = useState();

    const fetchContact = async ({ idContact }) => {
      await fetch(`${apiUrlTranslation}?action=getContact&id_contact=${idContact}`)
        .then((res) => res.json())
        .then((res) => setContact(res));
    };

    useEffect(() => {
      fetchContact({ idContact });
    }, [i18n.resolvedLanguage]);

    return (
      <div className="text-gray-400 ">
        {t("pages.parcours-details.propose_par")} <strong>{contact ? contact.societe : ""}</strong>
      </div>
    );
  }

  return (
    <div key={key} className="d-flex flex-wrap democard">
      {demo
        ?
        <div
          className="group card shadow-sm p-2 m-1 
          programm-row  bg-neutral-900 border-2 border-neutral-800 hover:border-pink-500 rounded-2xl my-2"
          style={{ width: "18rem", borderRadius: "1rem" }}
          key={demo.id_jury_demo}
        >
          <Link
            target="_blank"
            className=" flex flex-col justify-between h-full "
            to={`/events/${idEvent}/programm/${demo.presta.id_presta}/demo-details`}
          > <img
              src={!demo.presta.visuels.medium.search("no_picture") ? demo.presta.visuels.medium : demo.presta.id_contact.logos.large}
              className="card-img-top p-0 max-h-20 max-w-2xl bg-white "
              alt="..."
              style={{ borderRadius: "1rem", objectFit: "contain", }}
            />
            <div className="card-body flex flex-col justify-content-between h-auto">
              <h5 className="card-title text-gray-400">{demo.presta.presta_nom}</h5>
              <GetContact idContact={demo.presta.id_contact.id_contact} />

              <p className="flex py-1  items-center  text-xs mt-2 text-gray-400 ">
                {demo.presta.id_contact.pays} <img className="ms-3" src={demo.presta.id_contact.flag} alt="" style={{ width: '20px' }} /></p>



            </div>
            <div className="card-footer">           <p className="border-neutral-700  bg-neutral-800 m-3 text-gray-400 rounded-full py-1 px-4 shadow-lg border-2 group-hover:bg-pink-500 group-hover:text-white  ">  {t('global.en_savoir_plus')}</p>
            </div>
          </Link>
        </div>
        : ""}
    </div>
  );
}

export default DemoCard;
