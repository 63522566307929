import React from 'react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
//import '@splidejs/react-splide/dist/css/splide.min.css';
import '@splidejs/react-splide/dist/css/splide.min.css';

import Loader from "./Loader";
//import './LogoSlider.css';
import LogosSliderV2 from './LogosSliderV2';




const LogoSlider = ({ partenaires = [], displayMode }) => {

    const mobilecheck = () => {
        if (
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/webOS/i) ||
            navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/iPad/i) ||
            navigator.userAgent.match(/iPod/i) ||
            navigator.userAgent.match(/BlackBerry/i) ||
            navigator.userAgent.match(/Windows Phone/i)
        ) {
            return true;
        } else {
            return false;
        }
    };

    const primaryOptions = {
        type: 'loop',
        perPage: 5,
        breakpoints: {
            1000: {
                perPage: 3,
            },
            500: {
                perPage: 2,
            },
        },
        perMove: 5,
        gap: mobilecheck() ? 5 : 10,
        pagination: false,
        autoplay: true,
        height: '15rem',

    }


    if (partenaires.length === 0) {
        return <Loader />
    }

    //if (partenaires.length <= 4 | displayMode === "fixed") {


    if (partenaires.length >= 4) {

        return <LogosSliderV2 partenaires={partenaires} />
    }

    return (

        <div className="">
            {/* 
            <Splide options={primaryOptions} >
                {
                    partenaires
                        .filter(contact => contact.societe != '')
                        .map(contact =>
                            contact.logos && <SplideSlide key={contact.id_contact} className="bg-neutral-950 rounded-2xl p-3 ">
                                {contact.logos.large.includes("no_picture") ? <div className="bg-neutral-950 flex items-center justify-center h-100 text-5xl font-black text-white">{contact.societe}</div> : <img className="object-contain h-40 w-100 bg-white p-3 rounded-2xl" src={`${contact.logos.large}`} alt="Logo" data-ic={contact.id_contact} title={contact.societe} />}

                            </SplideSlide>)}

            </Splide> */}







            <div className="mx-auto max-w-9xl   px-4 sm:px-6   lg:px-8">
                <div className="mt-6 grid grid-cols-2 gap-2 lg:grid-cols-5 lg:mt-8 ">
                    {partenaires
                        //ne pas affficher les logo qui contienne no_picture
                        .filter(contact => contact.societe != '' && !contact.logos.large.includes("no_picture"))
                        //dedup^liquer par societe
                        .filter((contact, index, self) =>
                            index === self.findIndex((t) => (
                                t.societe === contact.societe
                            ))
                        )
                        .map(contact => (
                            <div key={contact.id_contact} className="col-span-1 flex flex-col justify-center  py-8 px-8 bg-white rounded">
                                <img
                                    className=" bg-white w-fit h-fit max-h-52 object-contain" src={`${contact.logos.large}`}
                                    alt="Logo"
                                    data-ic={contact.id_contact}
                                    title={contact.societe} />
                            </div>
                        ))}
                </div>
            </div>
        </div >
    )
    //}

    return (

        <>

            <Splide options={primaryOptions} className="logo_slider">
                {
                    partenaires
                        .filter(contact => contact.societe != '')
                        .map(contact =>
                            contact.logos && <SplideSlide key={contact.id_contact} className="bg-neutral-950 rounded-2xl p-3 ">
                                {contact.logos.large.includes("no_picture") ? <div className="bg-neutral-950 flex items-center justify-center h-100 text-5xl font-black text-white">{contact.societe}</div> : <img className="object-contain h-40 w-100 bg-white p-3 rounded-2xl" src={`${contact.logos.large}`} alt="Logo" data-ic={contact.id_contact} title={contact.societe} />}

                            </SplideSlide>)}
            </Splide>
        </>

    )
}

export default LogoSlider