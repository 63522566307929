import HTMLReactParser from 'html-react-parser';
import React, { useContext, useEffect, useState } from 'react'
import { API_URL } from '../config'
import EventContext from '../contexts/EventContext';
import Layout from './Layout'
import useApiUrlTranslation from './useApiUrlTranslation';

const TempsForts = () => {

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    const [eventEventComposantList, setEventEventComposantList] = useState([]);

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation()

    useEffect(() => {

        const getEventEventComposant = async () => {

            await fetch(`${apiUrlTranslation}?action=getEventEventComposant&req_suite= WHERE id_event=${currentEvent.id_event} `)
                .then(res => res.json())
                .then(res => setEventEventComposantList(res))
        }

        currentEvent && getEventEventComposant();

    }, [i18n.resolvedLanguage, currentEvent])

    return (
        <Layout title={t('event_nav.temps_forts')}>
            <div className="mx-auto mt-0 max-w-7xl  sm:mt-24 sm:px-6">
                {eventEventComposantList.map(eventEventComposant => <div className='mb-5'>
                    <h2 className='flex mb-3'>
                        <img src={eventEventComposant.visuel} alt="" className='mr-3 w-10 h-10' />{eventEventComposant.event_composant_nom}</h2>
                    <div className='mt-2 ml-16 text-base text-gray-500'> {HTMLReactParser(eventEventComposant.descriptif != '' ? eventEventComposant.descriptif : eventEventComposant.event_composant.descriptif)}</div>
                </div>)}
            </div>
        </Layout>
    )
}

export default TempsForts