import moment from 'moment'
import React, { useEffect, useState, Fragment, useRef, s } from 'react'
import { API_URL } from '../../config'
import LayoutAccount from './LayoutAccount2'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline'

const Contact = ({ contact, index, handleSelectContact }) => {

    return <tr className="divide-x divide-gray-200">
        <td className="whitespace-nowrap  pl-4 pr-3 text-sm sm:pl-6">{index}</td>
        <td className='text-center'><input type="checkbox" onChange={(e) => handleSelectContact(e)} value={contact.id_contact} /></td>
        <td className=""> <img className="h-10 w-10 rounded-full m-auto" src={contact.photos.small} alt="" /></td>
        <td> {contact.nom}  {contact.prenom}
            <div className="text-gray-500">{contact.mail}</div>
        </td>
        <td>{contact.societe}</td>
        <td>{contact.fonction}</td>
        <td>{moment(contact.date_creat).format('L')}</td>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
            <a href="#" className="text-indigo-600 hover:text-indigo-900">
                Editer<span className="sr-only">, {contact.name}</span>
            </a>
        </td>
    </tr >
}

const UserContacts = () => {

    const [contactList, setContactList] = useState(null)

    const [contactSelectedList, setContactSelectedList] = useState([])

    const user = JSON.parse(window.localStorage.getItem('user'))

    const [open, setOpen] = useState(false)

    const cancelButtonRef = useRef(null)


    const handleSelectContact = (e) => {

        if (e.target.checked) {

            setContactSelectedList([...contactSelectedList, e.target.value])

        } else {

            setContactSelectedList(contactSelectedList.filter(contact => contact !== e.target.value))
        }
    }


    const fetchContacts = async () => {
        const res = await fetch(`${API_URL}?action=getContactList&params=WHERE auteur = ${user.id_contact} LIMIT 1000`)
        const data = await res.json()
        setContactList(data)
    }

    useEffect(() => {
        fetchContacts()
    }, [])


    return (
        <LayoutAccount>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-emerald-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <PaperAirplaneIcon className="h-6 w-6 text-emerald-600" aria-hidden="true" />
                                        </div>
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                                Envoyer un e-mail d'inscription personnalisé
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    Veuillez sélectionner l'événement et la délégation
                                                    afin de personnaliser l'e-mail d'inscription  que vous souhaitez envoyer à vos contacts.
                                                </p>
                                            </div>
                                            <select name="" id="" className="w-100 p-3 my-3 rounded">
                                                <option value="">Evénement</option>
                                            </select>

                                            <select name="" id="" className="w-100 p-3 rounded">
                                                <option value="">Délégation</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-4 sm:ml-10 sm:flex sm:pl-4">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto sm:text-sm"
                                            onClick={() => setOpen(false)}
                                        >
                                            Envoyer
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={() => setOpen(false)}
                                            ref={cancelButtonRef}
                                        >
                                            Fermer
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <div className="flex my-5">
                <button className='bg-blue-500 p-2 rounded text-white'>Nouveau contact</button>

                <select name="" id="" className="mx-5 p-2 rounded">
                    <option value="">Sélectionner une action</option>
                    <option value="" onClick={() => setOpen(!open)}>Envoyer un e-mail personnalisé d'inscription</option>
                </select>
            </div>

            {JSON.stringify(contactSelectedList)}

            <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Nom
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Prénom
                        </th>

                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Societe
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            fonction
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Date création
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">Edit</span>
                        </th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                    {contactList && contactList.map((contact, index) => <Contact
                        index={index + 1}
                        key={contact.id_contact}
                        contact={contact}
                        handleSelectContact={handleSelectContact} />)}
                </tbody>
            </table>
        </LayoutAccount>
    )
}

export default UserContacts