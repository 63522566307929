import { Dialog, DialogPanel } from '@headlessui/react';
import { ChevronDoubleRightIcon, ChevronRightIcon, DevicePhoneMobileIcon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/solid';
import React, { useState } from 'react'


function CoordonneesChecker({ contact }) {

    const [isOpen, setIsOpen] = useState(true);

    return (

        <Dialog open={isOpen} onClose={() => setIsOpen(false)}
            className="relative z-50 bg-blue-50 p-6 rounded-2xl shadow-lg ">


            <div className="fixed inset-0 flex w-screen items-center justify-center p-4 bg-black/30">

                <div className="max-w-lg space-y-4 border bg-blue-50 p-3 rounded-3xl">
                    <div className="flex justify-end">
                        <button
                            onClick={() => setIsOpen(false)}

                            className="flex font-normal text-2xl bg-blue-50 border-none shadow-none  text-black rounded-full w-12 h-12 items-center justify-center  py-2 px-4   hover:bg-bleu-100">
                            X
                        </button>



                    </div>

                    <p className="text-2xl font-bold mb-1">Coordonnées</p>
                    <p className="text-gray-700">Gérez vos numéros de mobile et vos adresses e-mail pour vérifier que vos coordonnées sont exactes et à jour.</p>

                    <ul className="space-y-1  divide-blue-50 rounded-2xl my-3">
                        <li className="bg-white px-4 py-3 rounded-t-2xl hover:bg-blue-400">
                            <div className="flex justify-between">
                                <div className="flex space-x-3">
                                    <EnvelopeIcon className="w-6" />
                                    <span>{contact.mail}</span></div>
                                <div><ChevronRightIcon className="w-6" /></div>
                            </div>
                        </li>
                        <li className="bg-white px-4 py-3 rounded-b-2xl mb-5">
                            <div className="flex justify-between">
                                <div className="flex space-x-3">
                                    <PhoneIcon className="w-6" />
                                    <span>{contact.port}</span>
                                </div>
                                <div><ChevronRightIcon className="w-6" /></div>
                            </div>
                        </li>
                    </ul>
                </div>

            </div>
        </Dialog >
    )
}

export default CoordonneesChecker