import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Layout from './Layout';
import EventContext from '../contexts/EventContext'
import { API_URL } from '../config';
import useApiUrlTranslation from './useApiUrlTranslation';


const Registration = () => {

    const eventContext = useContext(EventContext);
    const [urlCollectif, setUrlCollectif] = useState('')
    const [cycleLangEventLigneList, setCycleLangEventLigneList] = useState(null)
    const { t, i18n } = useApiUrlTranslation();
    const { currentEvent } = eventContext;


    useEffect(() => {

        const getCycleLangEventLigne = async () => {

            await fetch(`${API_URL}?action=getCycleLangEventLigne&params=WHERE id_event_ligne=${currentEvent.event_ligne.id_event_ligne}`)
                .then(res => res.json())
                .then(res => setCycleLangEventLigneList(res))
        }

        currentEvent && getCycleLangEventLigne();

        if (currentEvent) {

            switch (currentEvent.id_event) {
                case '1723': setUrlCollectif(`/collectif/2293`)
                    break;

                case '1703': setUrlCollectif(`/collectif/2297`)
                    break;
            }
        }

        if (currentEvent && currentEvent.id_event == 1727) {

            window.location.replace('https://www.mlg-consulting.com/myglobalvillage/sign.php?ie=1727');

        }


    }, [currentEvent]);



    return <Layout>

        {currentEvent && <div className="container bg-white">

            <div className="row bg-dark text-white">
                <div className="col-md-6  my-auto">
                    <img src="//www.mlg-consulting.com/manager_cc/docs/archives/220517152341_gc.png" alt='logo mlg' style={{ width: "100%" }} />
                </div>
                <div className="col  my-auto">
                    <h2 className=""> {t('pages.inscription.title_global_challenge')}</h2>
                </div>
            </div>
            <p className='fs-5 my-3'>{t('pages.inscription.subtitle_global_challenge')} {currentEvent.event_start.substring(0, 4)}
            </p>
            <div className="row">
                {cycleLangEventLigneList && cycleLangEventLigneList
                    .filter(item => ['collectif_planaction', 'collectif_planactionprive'].includes(item.id_cycle_lang.cycle_lang_type))
                    .map((item, index) => {
                        return <div className="col-md-6">
                            <div className="row border p-3 rounded h-100 hover:bg-gray-50" key={index}>
                                <div className="col-md-2">
                                    <h3>{item.id_cycle_lang.attachedFilesCollection
                                        .filter(item => item.id_attached_file_type === '81')
                                        .map(item => {
                                            return <img alt="Collectif" src={item.medium} style={{ width: '100%' }} className="rounded rounded-circle" />
                                        })
                                    }</h3>

                                </div>
                                <div className="col-md-10 m-auto">
                                    <b style={{ textTransform: 'uppercase' }}>{item.id_cycle_lang.nom_cycle_lang}</b>
                                    <p style={{ display: 'none' }}>J’ai déjà pitché ou déjà été jurés myGLobalVillage  je me connecte
                                        Nouveau sur le global challence :</p>
                                    <ul className='icon-list  pl-10 py-3  list-disc '>
                                        <li className='pb-3'><Link className="text-blue-700   hover:underline hover:underline-offset-4 " to={`/events/${currentEvent.id_event}/signup/startup/collectif/${item.id_cycle_lang.id_cycle_lang}`}>{t('pages.inscription.global_challenge_startup_registration_link')}  :  {item.id_cycle_lang.nom_cycle_lang}</Link></li>
                                        <li><Link className="text-blue-700   hover:underline hover:underline-offset-4 " to={`/events/${currentEvent.id_event}/signup/jury/collectif/${item.id_cycle_lang.id_cycle_lang}`}>{t('pages.inscription.global_challenge_jury_registration_link')} :  {item.id_cycle_lang.nom_cycle_lang}</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    })}
            </div>
            <div className="d-none row row-cols-1 row-cols-md-3 mb-3 text-center registration__options  d-flex d-flex justify-content-center">
                <div className="col">
                    <div className="card mb-4 rounded-3 shadow-sm border-light">
                        <div className="card-header py-3">
                            <h5 className="my-0 fw-normal" style={{ backgroundColor: "none !important" }}>Membre du jury</h5>
                        </div>
                        <div className="card-body">
                            <p className="py-5"> Je m'inscris  en tant que  membre du jury</p>
                            <Link to={`/events/${currentEvent.id_event}/signup/jury${urlCollectif}`} className="w-100 btn btn-lg btn-primary">Je m'inscris</Link>
                        </div>
                    </div>
                </div>

                <div className="col">
                    <div className="card mb-4 rounded-3 shadow-sm border-light">
                        <div className="card-header py-3">
                            <h5 className="my-0 fw-normal">Candidat / startup </h5>
                        </div>
                        <div className="card-body">
                            <p className="py-5">Je m'inscris en tant que candidat pour pitcher</p>
                            <Link to={`/events/${currentEvent.id_event}/signup/startup${urlCollectif}`}
                                className="w-100 btn btn-lg btn-primary">Je m'inscris</Link>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        }
    </Layout >
};

export default Registration;
