import React, { useContext, useState, useEffect } from 'react'
import RegistrationFormSection from './RegistrationFormSection'
import useApiUrlTranslation from './useApiUrlTranslation'
import { useFormContext } from 'react-hook-form'
import EventContext from '../contexts/EventContext';


const RegistrationFormEventCycleLangSection = ({ title }) => {

    const { register, formState } = useFormContext();

    const { i18n, apiUrlTranslation } = useApiUrlTranslation()

    const [eventCycleLangList, setEventCycleLangList] = useState(false)

    const [thematiqueList, setThematiqueList] = useState(false)

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    const getEventCyclelangList = async () => {

        await fetch(`${apiUrlTranslation}?action=getEventCycleLangList&params=WHERE id_event=${currentEvent.id_event} AND publier !='n'`)
            .then(res => res.json())
            .then(res => {
                setThematiqueList(res.filter(eventCycleLang => eventCycleLang.id_event_child == 0))
                setEventCycleLangList(res)
            })
    }

    useEffect(() => {

        currentEvent && getEventCyclelangList()
    }, [i18n.resolvedLanguage, currentEvent])

    return (
        <RegistrationFormSection
            title={title}>

            {thematiqueList && thematiqueList.filter(thematique => thematique.id_event_child == 0).map(thematique => {
                return <div key={thematique.id_event_cycle_lang}>
                    <div>
                        <ul>
                            <li>
                                <label className=''>
                                    <input value={thematique.id_event_cycle_lang} {...register("demonstrateurs")} type="radio" className=' mx-3' />
                                    {thematique.event_cycle_lang_nom}
                                </label>
                                <ul>
                                    {eventCycleLangList && eventCycleLangList.
                                        filter(eventCycleLangChild => eventCycleLangChild.id_event_child == thematique.id_event_cycle_lang).map(eventCycleLangChild =>
                                            <li className='pl-5'>
                                                <label className='font-normal'>
                                                    <input value={eventCycleLangChild.id_event_cycle_lang} {...register("demonstrateurs")} type="radio" className=' mx-3' />
                                                    {eventCycleLangChild.event_cycle_lang_nom}
                                                </label>
                                            </li>
                                        )}
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            }
            )}
        </RegistrationFormSection>
    )
}

export default RegistrationFormEventCycleLangSection