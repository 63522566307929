import React, { useContext, useEffect, useState } from 'react'
import Layout from './Layout'
import useApiUrlTranslation from './useApiUrlTranslation'
import EventContext from '../contexts/EventContext';



const PlanAction = ({ id_cycle_lang, cyclelangCategorieList }) => {

    const { i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [cycleLang, setCycleLang] = useState(false);

    const getCycleLang = async () => {

        return fetch(`${apiUrlTranslation}?action=getCycleLang&filter= id_cycle_lang=${id_cycle_lang} AND `)
            .then(res => res.json())
            .then(res => setCycleLang(res[0]))
    }

    useEffect(() => {
        getCycleLang();
    }, [])

    return <>

        {cycleLang.children && cycleLang.children

            .filter(child => child.cycle_lang_type == "collectif_programme" && cyclelangCategorieList.includes(child.cycle_lang_categorie))
            .map(child => <div
                className="flex flex-row space-x-5 pt-3 ">

                <div className='basis-1/6'>
                    <img src={child.logo_better_url} alt="" className='rounded' />
                </div>
                <div className='basis-5/6'>
                    <p className="font-bold">{child.nom_cycle_lang}</p>
                    <p className="text-xs text-gray-600"> {child.cycle_lang_descriptif}</p>
                </div>
            </div>)}
    </>
}

const ProgrammeThematiquesCategorie = ({ cycleLangEventLigne, cyclelangCategorieList }) => {

    return <>

        {cycleLangEventLigne && cycleLangEventLigne.map(cycleLangEventLigne =>

            <PlanAction
                cyclelangCategorieList={cyclelangCategorieList}
                id_cycle_lang={cycleLangEventLigne.id_cycle_lang.id_cycle_lang} />
        )}
    </>
}

const ProgrammeThematiques = () => {

    const { i18n, apiUrlTranslation } = useApiUrlTranslation();

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    //cycleLangEventLigne

    const [cycleLangEventLigne, setCycleLangEventLigne] = useState(false);

    const getCycleLangEventLigne = async () => {

        return fetch(`${apiUrlTranslation}?action=getCycleLangEventLigne&params=WHERE id_event_ligne=${currentEvent.event_ligne.id_event_ligne}`)
            .then(res => res.json())
            .then(res => setCycleLangEventLigne(res.filter(item => item.id_cycle_lang.cycle_lang_type == "collectif_planaction")))

    }

    useEffect(() => {

        currentEvent && getCycleLangEventLigne();

    }, [i18n.resolvedLanguage, currentEvent])


    return (
        <Layout>

            <div className="bg-white h-full pt-10">

                <div className="max-w-7xl mx-auto ">

                    <h1 className="text-3xl py-5 font-bold">Thématique du programme</h1>

                    <div className='grid grid-cols-1 lg:grid-cols-2 pt-8 gap-5'>

                        <div className='grid grid-cols-1 bg-neutral-100 space-y-5 p-3 divide-y '>
                            <ProgrammeThematiquesCategorie

                                cyclelangCategorieList={["programme_thematique"]}
                                cycleLangEventLigne={cycleLangEventLigne && cycleLangEventLigne} />
                        </div>
                        <div className='grid grid-cols-1 gap-y-5 bg-pink-100 p-3 divide-y'>
                            <ProgrammeThematiquesCategorie
                                cyclelangCategorieList={["programme_vertical"]}
                                cycleLangEventLigne={cycleLangEventLigne && cycleLangEventLigne} />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ProgrammeThematiques