import React from 'react'
import { CheckIcon } from '@heroicons/react/24/outline'
import HTMLReactParser from 'html-react-parser'

const features = [
    'Plus de 35 temps forts et rencontres multi-pays',
    'Plus de 12 sessions "chasse" de tendance et de technologies collaboratives',
    '2 soirées et cocktail networking incontournables  ',
    '12 sessions de pitch qui vous permettront d’identifier, d’élire et vous connecter avec les meilleures solutions et décideurs du CES.'
]

const Tarifs = () => {
    return (
        <div className="bg-white border">
            <div className="mx-auto max-w-7xl py-5 px-4 sm:py-24 sm:px-6 lg:px-8 d-none">
                <div className="pb-4 xl:flex xl:items-center xl:justify-between">
                    <div>
                        <h1 className="text-4xl font-bold tracking-tight sm:text-5xl">
                            <span className="text-gray-900">TARIF DELEGATIONS  </span>
                            <span className="text-indigo-600">2023 </span>
                            <span className='bg-yellow-400 p-3 text-red-700 d-none'>Dernières places !</span>
                        </h1>
                        <p className="mt-5 text-xl text-gray-500">
                            Plus de 40 pays et grands groupes affilient leur
                            délégations à #myGlobalVillage… <br /> vous aussi découvrez les packs clé en main
                            de visite de nos partenaires
                        </p>
                    </div>
                    <a
                        target="_blank"
                        href="https://www.mlg-consulting.com/myglobalvillage/sign.php?ie=1727"
                        className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-5 py-3 text-base font-medium text-white hover:bg-indigo-700 sm:mt-10 sm:w-auto xl:mt-0"
                    >
                        Je réserve mon pack
                    </a>
                </div>


                <div className="border-t border-gray-200 pt-4 xl:grid xl:grid-cols-3 xl:gap-x-8">
                    <div>
                        <h2 className="text-lg font-semibold text-indigo-600">Rejoignez l’une des délégations officielles et</h2>
                        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900">profitez d’uniques opportunités :</p>
                        <p className="mt-4 text-lg text-gray-500">
                            Décuplez votre expérience de visite du CES, de les points de convergence des agendas de +20 pays et
                            grands groupes/organisations affiliés à #myGLobalVillage.
                        </p>
                    </div>

                    <div className="mt-4 sm:mt-8 md:mt-10 md:grid md:grid-cols-2 md:gap-x-8 xl:col-span-2 xl:mt-0">
                        <ul role="list" className="divide-y divide-gray-200">
                            {features.slice(0, 2).map((feature, featureIdx) =>
                                featureIdx === 0 ? (
                                    <li key={feature} className="flex py-4 md:py-0 md:pb-4">
                                        <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                                        <span className="ml-3 text-base text-gray-500">{feature}</span>
                                    </li>
                                ) : (
                                    <li key={feature} className="flex py-4">
                                        <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                                        <span className="ml-3 text-base text-gray-500">{feature}</span>
                                    </li>
                                )
                            )}
                        </ul>
                        <ul role="list" className="divide-y divide-gray-200 border-t border-gray-200 md:border-t-0">
                            {features.slice(2).map((feature, featureIdx) =>
                                featureIdx === 0 ? (
                                    <li key={feature} className="flex py-4 md:border-t-0 md:py-0 md:pb-4">
                                        <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                                        <span className="ml-3 text-base text-gray-500">{HTMLReactParser(feature)}</span>
                                    </li>
                                ) : (
                                    <li key={feature} className="flex py-4">
                                        <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                                        <span className="ml-3 text-base text-gray-500">{HTMLReactParser(feature)}</span>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="pb-16 flex items-center justify-center  text-center pt-3 ">
                <div>
                    <h1 className="text-4xl font-bold tracking-tight sm:text-5xl">
                        <span className="text-gray-900">TARIF SOIREES  </span>
                        <span className="text-indigo-600">2023 </span>
                        <span className='bg-yellow-400 p-3 text-red-700 d-none'>Dernières places !</span>
                    </h1>
                    <p className="mt-5 text-xl text-gray-500">
                        Le dînner annuel des décideurs francophones de l'innovation en ville médiane
                    </p>
                    <a
                        target="_blank"
                        href="https://www.mlg-consulting.com/myglobalvillage/sign.php?ie=1727&iecl=6777"
                        className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-5 py-3 my-3 text-base font-medium text-white hover:bg-indigo-700 sm:mt-10 sm:w-auto xl:mt-0"
                    >
                        Je réserve ma soirée
                    </a>
                    <div className="">
                        <a target="_blank" href="https://www.mlg-consulting.com/myglobalvillage/sign.php?ie=1727&iecl=6777">
                            <img src="//www.mlg-consulting.com/manager_cc/docs/archives/230106154838_picthing-session-23-04.png"
                                className="py-4"
                                style={{ width: "750px" }} />
                        </a>
                    </div>
                </div>
            </div>


        </div>



    )
}

export default Tarifs