import React from 'react'
import Layout from './Layout'
import { useParams } from "react-router-dom";
import useGetCycleLangByIdCycleLang from '../customHooks/useGetCycleLangByIdCycleLang';
import Loader from './Loader';
import HTMLReactParser from 'html-react-parser';
const CycleLang = () => {

    const { idCycleLang } = useParams();

    //usegetCycleLang

    const { cycleLang, error, isLoading } = useGetCycleLangByIdCycleLang({ idCycleLang });

    if (isLoading) return <Loader />
    return (
        <Layout darkmode>
            <div className="container">
                <div className="flex items-center m-3">
                    <img
                        alt="Logo collecti"
                        width="10%"
                        className="rounded-2xl"
                        src={cycleLang.logo_better_url}
                    />
                    <p className="py-3 m-3 text-6xl font-extrabold animatedgradient">{cycleLang.nom_cycle_lang}</p>
                </div>
                <p className="my-3  p-3 text-lg">{HTMLReactParser(cycleLang.cycle_lang_descriptif)}</p>

            </div>
        </Layout>
    )
}

export default CycleLang