import React, { useEffect, useState } from 'react'
import RegistrationFormSection from './RegistrationFormSection'
import RegistrationFormRow from './RegistrationFormRow'
import useApiUrlTranslation from './useApiUrlTranslation';
import RegistrationFormStatut from './RegistrationFormStatut';

const RegistrationFormYourProjectSection = ({ formulaire }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [statutList, setStatutList] = useState(null);

    useEffect(() => {



    }, [i18n.resolvedLanguage]);
    return (
        <RegistrationFormSection

            instructions='
        Bienvenue dans le cercle des Tech’Xportateurs de myGlobalVillage. Les tech’Xportateurs sont à la recherche de
        solutions innovantes pour leur projet, leur entreprise ou pour les revendre à leurs clients. Afin de valoriser vos
        projets ou votre expertise lors de vos futures interventions en webcast et figurer dans l’annuaire mondial des
        tech’xpotateurs de myGlobalVillage, merci de remplir votre profil rapide ci-dessous :
'>


            <RegistrationFormRow
                name='zzzzzz'
                placeholder='Votre projet/savoir-faire en une phrase'
                defaultValue=''
                helper='Décrivez-en moins de 200 caractères une phrase qui vous caractérise et qui sera affichée lors de votre présentation (ex : N°4 européen des
                    solutions de machine-outil, spécialiste de la distribution des solutions de smartWater, je m’engage et investis 2 millions pour une ville plus
                    durable)'
                maxLength={200} />

            <p className='font-bold mt-5'>Votre profil de tech'xportateur qui s'affichera lors du webcast :</p>
            <p className='mb-3 text-gray-400 text-sm'>De quelles manières pouvez-vous aider une société innovante à se développer dans votre pays ou
                écosystème (merci de cocher le statut qui correspond le mieux à votre savoir-faire) ?</p>


            <RegistrationFormStatut formulaire={formulaire} idStatutSelection={[102, 103, 104]} />


            <div className="my-5"></div>

            <RegistrationFormRow
                name='zzzzzz'
                placeholder='Montant de votre chiffre d’affaires, du budget annuel de votre organisation/ du montant du projet
                dont vous êtes porteur  en $'
                defaultValue='Shine Eye Gal'
                helper=''
                maxLength={200} />

            <RegistrationFormRow
                name='zzzzzz'
                inputType='date'
                placeholder='Année de création de votre entreprise ou de début votre mission/mandat :'
                defaultValue=''
                helper=''
                maxLength={200} />

        </RegistrationFormSection>
    )
}

export default RegistrationFormYourProjectSection