import React, { useEffect, useState } from "react";
import { API_URL } from "../config";

function ContactCard({ idContact, showFlag, displayNumber, displayMode }) {
  const [infoContact, setInfoContact] = useState(null);

  const displayNumberList = {
    1: "12",
    2: "6",
    3: "4",
    4: "3",
    5: "2",
    6: "2",
  };

  const fetchInfoContact = async (currentIdContact) => {
    const req_id_contact = `getContact&id_contact=${currentIdContact}`;

    await fetch(
      `${API_URL}?action=${req_id_contact}`
    )
      .then((res) => res.json())
      .then((infoContact) => {
        setInfoContact(infoContact);
      });
  };

  useEffect(() => {
    fetchInfoContact(idContact);
  }, []);

  return (
    <div key={idContact}
      className=""
      className_={`col-md-${displayNumber.length > 0 ? displayNumberList[displayNumber] : "4"} mb-2 `}
    >
      <div
        className={`card shadow dark:bg-neutral-900 border-neutral-800 text-slate-400 h-full rounded-2xl `}

      >
        {infoContact && (
          <div style={{ display: displayNumber === "1" ? "flex" : "" }}>
            <img
              className="card-img-top rounded-t-2xl"
              src={infoContact.photos.medium}
              style={{
                objectFit: "cover",
                maxHeight: "300px"
              }}
              alt=""
            />
            <div className="card-body"
              style={{
                minHeight: "150px"
              }}>
              <h5 className="card-title">
                {infoContact.prenom} {infoContact.nom}
              </h5>
              {showFlag === "true" ? (
                <img
                  src={infoContact.flag}
                  style={{ maxWidth: "2vw", float: "right", }}
                  alt="Flag"
                  className=""
                />
              ) : (
                ""
              )}
              <p className="card-text">
                {infoContact.societe} - {infoContact.fonction}
              </p>
              <p className="card-text">{displayNumber < 2 ? infoContact.edito_court : null}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ContactCard;
