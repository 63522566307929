import React from 'react'
import Layout from './Layout'
import { useState } from 'react';
import useApiUrlTranslation from './useApiUrlTranslation';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import HTMLReactParser from 'html-react-parser';
import { sortByAttribute } from '../utils';
import Loader from './Loader';

const DisplayCommunaute = ({ communaute, idEventContactTypeList }) => {

    return (
        <div className="py-4 flex ">

            {communaute

                //dedup by communaute.contact.id_contact
                .filter((communaute, index, self) =>
                    index === self.findIndex((t) => (
                        t.contact.id_contact === communaute.contact.id_contact
                    ))
                )
                .filter(communaute => idEventContactTypeList.includes(communaute.id_conferencier.statut.id_event_contact_type))
                .map(communaute => {

                    const contact = communaute.contact;

                    return <div className='flex'>

                        {/* {JSON.stringify(communaute.id_communaute.forum)} */}
                        <img src={contact.photos.small} alt="" className="w-10 h-10 rounded-full" />
                        <div className="ml-4">
                            {contact.prenom}  {contact.nom}
                            <p className="text-sm text-gray-500"> {contact.fonction} <br />{contact.societe}</p>
                            <p className="text-sm text-pink-600 ">
                                <p className="hidden"> {communaute.id_conferencier.id_conferencier}</p>
                                <span className="hidden"> {communaute.id_conferencier.statut.libelle} </span>
                                <span className="hidden"> {communaute.id_conferencier.statut.id_event_contact_type}</span>
                            </p>
                        </div>
                    </div>
                })}
        </div>
    )
}

const CycleLangProgramme = ({ communaute }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [programme, setProgramme] = useState(false);

    const getConfEvent = async () => {
        await fetch(`${apiUrlTranslation}?action=getConfEvent&filter=id_event=1789 AND publier !='n' AND selectable !='n' AND type NOT IN (92) AND type IN (67)`)
            .then(res => res.json())
            .then(res => {

                let sortedList = sortByAttribute(res, 'conf_event_date_local', 'heure_debut_local');

                sortedList = sortedList.filter(confEvent =>
                    confEvent.publier != 'n' &&
                    confEvent.selectable != 'n'
                    //  &&  ['67'].includes(confEvent.type)
                );

                setProgramme(sortedList);
            })
    }


    useEffect(() => {
        getConfEvent();
    }, [i18n.resolvedLanguage])

    if (!programme) return <Loader />
    return <div>
        {programme && programme.map(confEvent => <div className='text-left border'>{confEvent.conf_event_lang.cel_titre}  </div>)}
    </div>

}

const EventCycleLang = ({ eventCycleLang }) => {

    const { idEvent } = useParams();

    const [communaute, setCommunaute] = useState([]);

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    useState(() => {

        const getCommunaute = async () => {

            await fetch(`${apiUrlTranslation}?action=getCommunaute&filter= AND ecl.id_event_cycle_lang=${eventCycleLang.id_event_cycle_lang}`)

                .then(response => response.json())
                .then(data => {
                    setCommunaute(data);
                })
        }

        getCommunaute(eventCycleLang.id_event_cycle_lang);

    }, [i18n.resolvedLanguage, eventCycleLang.id_event_cycle_lang])

    return <>


        <div class="row border-y border-neutral-100 dark:border-neutral-800  align-items-center bg-neutral-900">
            <div class="col-md-1">
                <div class="col justify-item flex items-center justify-center">

                    <img src={eventCycleLang.cycle_lang.attachedFilesCollection.find(item => item.id_attached_file_type == 65).small} alt="" class="false  w-52 lg:w-20 rounded" /></div>
            </div>
            <div class="col-md-2">
                <div class="col">
                    <p class="font-bold text-xl uppercase dark:text-neutral-100">{eventCycleLang.event_cycle_lang_nom}</p>
                    <div class="dark:text-neutral-200 hidden">
                        {eventCycleLang.event_cycle_lang_descriptif} </div>
                </div>
            </div>

            <div className="col-md-6">  <DisplayCommunaute
                communaute={communaute}
                idEventContactTypeList={["202", "207"]} />  </div>

            <div class="col-md-2 justify-item flex items-center justify-center ">
                <a class="inline-flex items-center px-3.5 py-2 border border-transparent text-sm leading-4 font-medium rounded-full text-gray-800 dark:bg-neutral-400 hover:border-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 " target="_blank" rel="noreferrer" href={`./pays-recap/${eventCycleLang.cycle_lang.id_cycle_lang}`}>  {t('global.en_savoir_plus')}</a>
            </div>
        </div >



        <div key={eventCycleLang.id_event_cycle_lang} className="hover:bg-neutral-100 hidden">

            <div className="flex  border-neutral-400 pl-3">
                <div className="flex  items-center w-52">
                    <img src={eventCycleLang.cycle_lang.attachedFilesCollection.find(item => item.id_attached_file_type == 65).small} className="w-20 h-20 rounded" />
                    <span className="font-bold ml-3">{eventCycleLang.event_cycle_lang_nom}</span>
                </div>
                <div>
                    {eventCycleLang.event_cycle_lang_descriptif}
                </div>

                <div> {t('global.en_savoir_plus')}</div>
            </div>
        </div>
    </>
}

const PaysRecap = () => {

    const [eventCycleLangList, setEventCycleLangList] = useState([])

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const { idEvent } = useParams();

    const getEventContactTypeList = async () => {

        await fetch(`${apiUrlTranslation}?action=getEventCycleLangList&params=WHERE id_event="${idEvent}" `)
            .then(response => response.json())
            .then(data => {
                setEventCycleLangList(data);
            })
    }

    useEffect(() => {
        getEventContactTypeList();
    }, [i18n.resolvedLanguage])

    return (
        <Layout title="Pays affiliés" className="dark:text-white" >

            <div className="bg-white  max-w-7xl  mx-auto">

                <div className="mx-auto min-w-full ">

                    <div className="row bg-neutral-950 py-3">
                        <div class="col-md-1 text-2xl">{t('form.sections.organisme_section.pays')}</div>
                        <div className="col-md-2 "></div>
                        <div className="col-md-6 text-2xl">{t('global.coordinateurs')}</div>
                        <div className="col-md-2"></div>
                    </div>

                    {eventCycleLangList
                        //order by  eventCycleLang.event_cycle_lang_nom
                        .sort((a, b) => a.event_cycle_lang_nom.localeCompare(b.event_cycle_lang_nom))
                        .filter(eventCycleLang => ["collectif_geographique", "pays_region_entreprise_invite"].includes(eventCycleLang.forum))
                        .map(eventCycleLang =>
                            <EventCycleLang eventCycleLang={eventCycleLang} />
                        )}
                </div>
            </div>
        </Layout>
    )
}

export default PaysRecap