import React from 'react'
import ProgrammItemHilight from './ProgrammItemHilight'
import Layout from './Layout'

const PortailProgramm = () => {
    return (
        <Layout>
            <ProgrammItemHilight />
        </Layout>
    )
}

export default PortailProgramm