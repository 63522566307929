import React, { Fragment, useEffect, useState, useContext } from 'react'
import SideBar, { DesktopNavigation, MobileNavigation } from './SideBar'
import HeaderAccount from './HeaderAccount'
import './LayoutAccount.css'
import { Link, useHistory, Redirect } from 'react-router-dom';
import UserContext from '../../contexts/UserContext'
import useLocalStorage from '../Hooks/useLocalStorage';

import { Dialog, Menu, Transition } from '@headlessui/react'

import {
    Bars3BottomLeftIcon,
    BellIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'

import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import NavTranslation, { LanguageSelect, NavTranslationLanguageSelect } from '../NavTranslation';

const userNavigation = [

    { name: 'Profil', href: '/account/profil/' },
    { name: 'Sign out', href: '#', onClick: (e) => console.log("logout(e)") }
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const LayoutAccount = (props) => {


    const [sidebarOpen, setSidebarOpen] = useState(false);

    let history = useHistory();

    const userContext = useContext(UserContext);

    const [user, setUser] = useLocalStorage('user', { nom: '' });

    const { logout } = userContext;

    const [userNavigation, setUserNavigation] = useState([

        { name: 'Profil', href: '/account/profil/' },
        { name: 'Settings', href: '/account/settings/' },
        { name: 'Sign out', href: '#', onClick: logout }
    ]);


    if (!user) {

        return <Redirect to="/" push={true} />
    }

    return <>
        {/*
      This example requires updating your template:

      ```
      <html class="h-full bg-gray-100">
      <body class="h-full">
      ```
    */}
        <div>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                                        <button
                                            type="button"
                                            className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="flex flex-shrink-0 items-center px-4">
                                    <img
                                        className="d-none h-8 w-auto"
                                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                                        alt="Your Company"
                                    />

                                    <svg className='h-10' viewBox="0 0 319 372">
                                        <path d="M126.514,56.524L279.836,102.37l3.777,159.985-150.988,53.03L35.533,188.174Z" />
                                    </svg>


                                </div>
                                <div className="mt-5 h-0 flex-1 overflow-y-auto">
                                    <SideBar classNames={classNames} />

                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className="w-14 flex-shrink-0" aria-hidden="true">
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5">
                    <div className="flex flex-shrink-0 items-center px-4">
                        <img
                            className="d-none h-8 w-auto"
                            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                            alt="Your Company"
                        />
                        <svg className='h-10  fill-gray-800 ' viewBox="0 0 319 372">
                            <path d="M126.514,56.524L279.836,102.37l3.777,159.985-150.988,53.03L35.533,188.174Z" />
                        </svg>
                    </div>
                    <div className="mt-5 flex flex-grow flex-col">
                        <SideBar media="desktop" classNames={classNames} />
                    </div>
                </div>
            </div>
            <div className="flex flex-1 flex-col md:pl-64">
                <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow">
                    <button
                        type="button"
                        className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    <div className="flex flex-1 justify-between px-4">
                        <div className="flex flex-1">
                            <form className="d-none flex w-full md:ml-0" action="#" method="GET">
                                <label htmlFor="search-field" className="sr-only">
                                    Search
                                </label>
                                <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                                        <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                                    </div>
                                    <input
                                        id="search-field"
                                        className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
                                        placeholder="Search"
                                        type="search"
                                        name="search"
                                    />
                                </div>
                            </form>
                        </div>
                        <div className="ml-4 flex items-center md:ml-6">
                            <button
                                type="button"
                                className="d-none rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <span className="sr-only">View notifications</span>
                                <BellIcon className="h-6 w-6" aria-hidden="true" />
                            </button>

                            {/* Profile dropdown */}
                            <Menu as="div" className="relative ml-3">
                                <div>
                                    <Menu.Button className="flex max-w-xs w-10 items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                        <span className="sr-only">Open user menu</span>
                                        <img
                                            className="h-8 w-8 rounded-full border border-gray-500"
                                            src={user.photo}
                                            alt=""
                                        />
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        {userNavigation.map((item, index) => (
                                            <Menu.Item key={index}>
                                                {({ active }) => (
                                                    <a

                                                        onClick={(e) => item.onClick && item.onClick(e)}
                                                        href={item.href}
                                                        className={classNames(
                                                            active ? 'bg-gray-100' : '',
                                                            'block px-4 py-2 text-sm text-gray-700'
                                                        )}
                                                    >
                                                        {item.name}
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        ))}
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </div>


                <main className="flex-1 bg-slate-50" {...props}>
                    <div className="py-6 h-full bg-white ">
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-semibold text-gray-900">{props.title}</h1>
                        </div>
                        <div className="mx-auto max-w-7xl lg:px-2 sm:px-6 md:px-8">
                            {props.children}
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </>
}

export default LayoutAccount