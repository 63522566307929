import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { API_URL } from '../../config'
import DemoCycleLangList from './DemoCycleLangList'
import LayoutAccount from './LayoutAccount'

const Contact = ({ contactCycleLang }) => {

    return <div className="mt-6 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">

                    <table className="min-w-full divide-y divide-gray-300">
                        <tbody className="divide-y divide-gray-200 bg-white">
                            <tr>
                                <td>
                                    <div className="flex items-center">
                                        <div className="h-10 w-10 flex-shrink-0">
                                            <img className="h-10 w-10 rounded-full" src={contactCycleLang.contact.photos.small} alt="" />
                                        </div>
                                        <div className="ml-4">
                                            <div className="font-medium text-gray-900">{contactCycleLang.contact.prenom} {contactCycleLang.contact.nom}</div>
                                            <div className="text-gray-500">{contactCycleLang.contact.mail}</div>
                                            {contactCycleLang.event_contact_type.libelle} ({contactCycleLang.event_contact_type.id_event_contact_type})
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>


}

const UserCycleLangDetails = () => {

    const { idCycleLang } = useParams()

    const [cycleLang, setCycleLang] = useState(null)

    const [contactCycleLangList, setContactCycleLangList] = useState(null)

    const fetchContactCycleLangByCycleLang = async () => {

        await fetch(`${API_URL}?action=fetchContactCycleLangByCycleLang&id_cycle_lang=${idCycleLang}`)
            .then(res => res.json())
            .then(res => setContactCycleLangList(res))
    }

    const getCycleLangByIdCycleLang = async () => {

        await fetch(`${API_URL}?action=getCycleLangByIdCycleLang&id_cycle_lang=${idCycleLang}`)
            .then(res => res.json())
            .then(res => {
                setCycleLang(res);
                fetchContactCycleLangByCycleLang()
            })
    }

    useEffect(() => {
        getCycleLangByIdCycleLang()
    }, [])

    if (!cycleLang) return <div>Loading...</div>

    return (
        <LayoutAccount title={cycleLang.nom_cycle_lang}>

            <img style={{ width: '150px' }} src={cycleLang.attachedFilesCollection?.find(item => item.id_attached_file_type == 65).small} alt="" />

            <div className='bg-gray-100 p-10 my-4'>
                <h1 className='text-2xl p-2 rounded-lg bg-indigo-600 shadow-lg sm:p-3 text-white p-3'>Coordinateurs</h1>
                {contactCycleLangList && contactCycleLangList.filter(contactCycleLang => ["207", "145", "228"].includes(contactCycleLang.event_contact_type.id_event_contact_type)).map(contactCycleLang => <Contact key={contactCycleLang.id_contact_cycle_lang} contactCycleLang={contactCycleLang} />)}
            </div>

            <div className='bg-gray-100  p-10 my-4'>
                <h1 className='text-2xl p-2 rounded-lg bg-indigo-600 shadow-lg sm:p-3 text-white p-3'>Offreurs affiliés à votre collectif</h1>
                {contactCycleLangList && contactCycleLangList.filter(contactCycleLang => ["178"].includes(contactCycleLang.event_contact_type.id_event_contact_type)).map(contactCycleLang => <Contact key={contactCycleLang.id_contact_cycle_lang} contactCycleLang={contactCycleLang} />)}

                <DemoCycleLangList id_cycle_lang={idCycleLang} />
            </div>

            <div className='bg-gray-100  p-10 my-4'>
                <h1 className='text-2xl p-2 rounded-lg bg-indigo-600 shadow-lg sm:p-3 text-white p-3'>Décideurs affiliés à votre collectif</h1>
                {contactCycleLangList && contactCycleLangList.filter(contactCycleLang => ["92"].includes(contactCycleLang.event_contact_type.id_event_contact_type)).map(contactCycleLang => <Contact key={contactCycleLang.id_contact_cycle_lang} contactCycleLang={contactCycleLang} />)}
            </div>
            <div className='bg-gray-100  p-10 my-4'>
                <h1 className='text-2xl p-2 rounded-lg bg-indigo-600 shadow-lg sm:p-3 text-white p-3'>Others</h1>
                {contactCycleLangList && contactCycleLangList.filter(contactCycleLang => !["92", "178", "207", "145", "228"].includes(contactCycleLang.event_contact_type.id_event_contact_type)).map(contactCycleLang => <Contact key={contactCycleLang.id_contact_cycle_lang} contactCycleLang={contactCycleLang} />)}
            </div>

        </LayoutAccount>
    )
}

export default UserCycleLangDetails