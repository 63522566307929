// https://codepen.io/abhikb/pen/yLgMvGw

import React from 'react'
import './LogosSliderV2.css'
import Loader from './Loader'

const Logo = ({ contact }) => {

    return (
        <div class="slide cursor-pointer grid justify-center mx-1 rounded-3xl md:p-3 ">
            <img class="p-2" src={contact.logos.small} alt="Logo" data-ic={contact.logos.small} title={contact.societe} /></div>
    )
}

const LogosSliderV2 = ({ partenaires }) => {

    if (partenaires.length === 0) {
        return false
    }

    return (
        <div className="px-2 sm:px-12 py-8 w-100 static slider-with-focus ">

            <div className="">

                <div className="slider grid items-center h-72"  >

                    <div className="slide-track flex">
                        {partenaires.map(contact => <Logo contact={contact} />)}
                        {/* <!-- same 9 slides doubled (duplicate) --> */}
                        {partenaires.map(contact => <Logo contact={contact} />)}
                    </div>
                    <div className="bg absolute   w-100 h-100"></div>
                </div>
            </div>

            {/* <p class="my-8 text-center text-lg text-green-600 font-medium">I found the base formula from from this <a class="underline " href='https://www.youtube.com/watch?v=3Z780EOzIQs'> video by The Mute Dev </a>  </p>
            <h3 class="mt-12 font-bold text-xl">Important Notes </h3>
            <ul class="list-disc px-4">
                <li class="mt-1">Width of the slide should be such that at any given point of time the number of slides seen should be   very near to the half of total slides</li>

                <li class="mt-1 ">Slide track width should be = width of 1 slide * total no of slides(original + duplicates )</li>
                <li class="mt-1"> Scroll animation translateX from 0px to (width of 1 slide * no of original slides )</li>

                <li class="mt-1">If slides are more than or at least 10 then doubling the slides is enough </li>
                <li class="mt-1"> If slides are less than 5-8 then tripling them might work </li>
                <li class="mt-1"> Animation time is very important , exact no of total slides worked for me 😅</li>
            </ul> */}
        </div>
    )
}

export default LogosSliderV2