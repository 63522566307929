import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PageSection from "./PageSection";
import ProgrammDay from "./ProgrammDay";
import Layout from "./Layout";
import Loader from "./Loader";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import useApiUrlTranslation from "./useApiUrlTranslation";
import moment from "moment";
import { sortByAttribute } from "../utils";



const NavDate = ({ days, confEventList }) => {

  const { i18n } = useApiUrlTranslation()

  return <div className="flex flex-col">



    {days.map((day) => {
      const confEventListFilterd = confEventList.filter(conf => moment(conf.conf_event_date_local).format('DD') == day)

      return <li class="group relative flex gap-x-4 ">
        <div class="absolute left-0 top-0 flex w-6 justify-center -bottom-6">
          <div class="w-px bg-neutral-900"></div>
        </div>
        <div class="relative flex h-6 w-6 flex-none items-center justify-center ">
          <div class="h-1.5 w-1.5 rounded-full bg-neutral-900 ring-1 ring-neutral-800 group-hover:ring-pink-800 "></div>
        </div>
        <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500 hidden"><span class="font-medium text-gray-900">Chelsea Hagon</span> created the invoice.</p>
        <time datetime="2023-01-23T10:32" class="flex-none py-0.5 text-xs leading-5 text-gray-500 hidden">7d ago</time>
        <a key={day} className="  dark:text-neutral-600 rounded-full  text-sm hover:text-pink-500 hover:dark:text-neutral-700 px-2 m-1 mb-2 block" href={`#day-${day}`}>
          <Moment
            locale={i18n.resolvedLanguage}
            format="LL"
            date={confEventListFilterd && confEventList ? confEventListFilterd[0].conf_event_date_local : ''}></Moment>
        </a>
      </li>

    })}
  </div>

}

const NavConfEventType = ({ confEventList, setConfEventTypeList, confEventTypeList, setConfEventListFiltered }) => {

  const handleChange = (e) => {

    // if e.target.value is in confEventTypeList, remove it 
    // else add it


    const newConfEventTypeList = [...confEventTypeList, e.target.value]

    if (confEventTypeList.includes(e.target.value)) {

      newConfEventTypeList = confEventTypeList.filter(item => item != e.target.value)
    }





    console.log(newConfEventTypeList)

    // if (!confEventTypeList.includes(e.target.value)) {

    //   newConfEventTypeList = [...confEventTypeList, e.target.value]

    // } else {

    //   newConfEventTypeList = confEventTypeList.filter(item => item != e.target.value)
    // }





    const confEventListFiltered = confEventList.filter(confEvent => confEventTypeList.includes(confEvent.conf_event_type.id_conf_event_type))

    setConfEventListFiltered(confEventListFiltered)

  }

  if (!confEventList) {
    return false;
  }

  return <div className="text-xs space-y-2 text-left">

    {JSON.stringify(confEventTypeList)}
    {
      confEventList
        //dedup by conf_event_type.id_conf_event_type
        .filter((confEvent, index, self) =>
          index === self.findIndex((t) => (
            t.conf_event_type.id_conf_event_type === confEvent.conf_event_type.id_conf_event_type
          ))
        )
        .map(confEvent => <div className="" style={{ color: confEvent.conf_event_type.color }}>

          <input type="checkbox" name="" id="" onChange={handleChange} value={confEvent.conf_event_type.id_conf_event_type} />  {confEvent.conf_event_type.conf_event_type_nom}
        </div>

        )
    }
  </div>
}

const Programm = () => {

  let { idEvent, programmType } = useParams();

  const [confEventList, setConfEventList] = useState(null);
  const [confEventListFiltered, setConfEventListFiltered] = useState(null);
  const [confEventTypeList, setConfEventTypeList] = useState([]);

  const [confEventCycleLangList, setConfEventCycleLangList] = useState(null)

  const [days, setDays] = useState([]);

  const { t, i18n, apiUrlTranslation } = useApiUrlTranslation()

  const [pageTitle, setPageTitle] = useState(t('pages.programme.title'));


  const fetchConfEvent = async () => {

    await fetch(`${apiUrlTranslation}?action=getConfEvent&id_event=${idEvent}&filter= AND type NOT IN(87,63,92,103)  `)
      .then((res) => res.json())
      .then((res) => {

        let formatedList = res.filter(item => item.publier != 'n'
          && !["87", "63", "92"].includes(item.type)
          && item.jury_event.thematique != "y"
          && item.heure_debut != '');

        formatedList = formatedList.map(item => { return { ...item, ['heure_debut']: item.heure_debut.replace('h', ':') } })
        formatedList = formatedList.map(item => { return { ...item, ['heure_fin']: item.heure_fin.replace('h', ':') } })

        formatedList = sortByAttribute(formatedList, 'conf_event_date_local', 'heure_debut_local');

        if (programmType == 'texplorations') {

          formatedList = formatedList.filter(item => ["92", "95", "96"].includes(item.type))
          setPageTitle("Programme des Tech'xplorations")

        } else if (programmType == 'parcours') {

          formatedList = formatedList.filter(item => ["66", "63"].includes(item.type))
          setPageTitle("Programme des parcours de visites")

        } else if (programmType == 'pitchs') {

          formatedList = formatedList.filter(item => ["48", "67", "88", "74"].includes(item.type))
          setPageTitle(t('event_nav.pitching_session'))
        }
        else if (programmType == 'techreviews') {

          formatedList = formatedList.filter(item => ["97"].includes(item.type))
          setPageTitle("Programme des  Tech'reviews")
        }
        else if (programmType == 'blitz-pitch') {

          formatedList = formatedList.filter(item => ["101"].includes(item.type))
          setPageTitle("Programme des Blitz pitchs")
        }
        else if (programmType == 'networking') {

          formatedList = formatedList.filter(item => ["84"].includes(item.type))
          setPageTitle("Programme des soirées de networking")
        }

        setConfEventList(formatedList);
        setConfEventListFiltered(formatedList);

        const days = [...new Set(formatedList.map((elem) => moment(elem.conf_event_date_local).format('DD')))]

        console.log(days)

        setDays(days);

      })
  }

  useEffect(() => {


    const getConfEventCycleLang = async () => {

      await fetch(`${apiUrlTranslation}?action=getConfEventCycleLang&params=WHERE%20id_conf_event IN (SELECT id_conf_event FROM conf_event WHERE id_event="${idEvent}")`)
        .then(response => response.json())
        .then(data => {
          setConfEventCycleLangList(data)
          //console.log('getConfEventCycleLang', conf_event.id_conf_event, data);
        })
    }
    getConfEventCycleLang()


    fetchConfEvent();
  }, [i18n.resolvedLanguage, programmType]);

  if (!confEventList) {

    <Loader></Loader>

  }

  if (confEventList && confEventList.length == 0) {

    return <Layout>
      <PageSection title={pageTitle}> <div className=" bg-neutral-900"> A découvrir prochainement</div></PageSection>
    </Layout>
  }

  return (
    <Layout>

      <PageSection title={pageTitle} darkmode>
        <div className="container">

          <div className={`py-3 mb-5 lg:mx-56 mx-5   dark:text-white ${idEvent == 1820 ? 'hidden' : ''}`}>
            {t('pages.programme.subtitle')}
          </div>

          {confEventList ? (
            <span className="p-2 mb-2" style={{ display: "none" }}>
              Allez directement à la bonne date :
            </span>
          ) : (
            <Loader />
          )}

          <div className="flex lg:hidden justify-content-around flex-wrap max-w-7xl mx-auto mb-2 ">
            {days.map((day) => {

              const confEventListFilterd = confEventList.filter(conf => moment(conf.conf_event_date_local).format('DD') == day)

              return <div key={day}>

                <a className=" dark:text-neutral-400 rounded-full border-x border-y border-neutral-900 text-sm hover:bg-pink-500 hover:dark:text-white py-2 bg-neutral-900 px-2 m-1 mb-3 block" href={`#day-${day}`}>
                  <Moment
                    locale={i18n.resolvedLanguage}
                    format="LL"
                    date={confEventListFilterd && confEventList ? confEventListFilterd[0].conf_event_date_local : ''}></Moment>
                </a>
              </div>
            }

            )}
          </div>


          <div className="flex relative">
            <div div className="sticky top-0 w-48 h-screen bg-neutral-950 overflow-y-auto hidden lg:block" >
              <NavDate days={days} confEventCycleLangList={confEventCycleLangList} confEventList={confEventList}

              />
              {/* <NavConfEventType
                confEventList={confEventList}
                confEventTypeList={confEventTypeList}
                setConfEventTypeList={setConfEventTypeList}
                setConfEventListFiltered={setConfEventListFiltered}
              /> */}
            </div>

            <div className="">
              {days.map((day) => (
                <ProgrammDay
                  key={day}
                  day={day}
                  confEventCycleLangList={confEventCycleLangList}
                  confEventList={confEventList.filter(
                    (conf) => moment(conf.conf_event_date_local).format('DD') == day
                  )}
                />
              ))}
            </div>


          </div>


        </div>
      </PageSection>
    </Layout >
  );
};

export default Programm;
