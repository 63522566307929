import React, { useContext, useEffect, useState } from 'react'
import RegistrationFormSection from './RegistrationFormSection'
import RegistrationFormRow from './RegistrationFormRow'
import HTMLReactParser from 'html-react-parser'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import useApiUrlTranslation from './useApiUrlTranslation'
import EventContext from "../contexts/EventContext";
import { API_URL } from '../config'

const EventCycleLangPrestaType = ({
    eventCycleLangPrestaType,
    eventCycleLangPrestaTypeList,
    setEventCycleLangPrestaTypeList }) => {

    const handleChange = (e) => {

        const { name, value } = e.target;

        const newEventCycleLangPrestaType = { ...eventCycleLangPrestaType }

        newEventCycleLangPrestaType.presta_type[name] = value;

        const newEventCycleLangPrestaTypeList = eventCycleLangPrestaTypeList.map(item => {

            if (item.id_event_cycle_lang_presta_type == eventCycleLangPrestaType.id_event_cycle_lang_presta_type) {

                return newEventCycleLangPrestaType
            } else {

                return item
            }
        })

        console.log(newEventCycleLangPrestaTypeList)

        setEventCycleLangPrestaTypeList(newEventCycleLangPrestaTypeList)
        console.log(name, value);
        console.log("first");



        //setFormValues({ ...formValues, [name]: value })

        //setFormValues({ ...formValues, [name]: value })

    }

    if (!eventCycleLangPrestaType) return null;

    return <div
        key={eventCycleLangPrestaType.id_event_cycle_lang_presta_type}
        className="border-b p-3">

        {/* <RegistrationFormRow

            data-name="presta_type_nom"
            name={`presta_type_nom_${eventCycleLangPrestaType.id_event_cycle_lang_presta_type}`}
            placeholder="Nom du pack"
            defaultValue={eventCycleLangPrestaType.presta_type.presta_type_nom} />

        <RegistrationFormRow

            data-name="prix_unitaire_ht"
            name={`prix_unitaire_ht_${eventCycleLangPrestaType.id_event_cycle_lang_presta_type}`}
            placeholder="Prix conseillé"
            defaultValue={eventCycleLangPrestaType.presta_type.prix_unitaire_ht} />

        <RegistrationFormRow

            isRegister
            data-name="descriptif"
            inputType="textarea"
            name={`descriptif_${eventCycleLangPrestaType.id_event_cycle_lang_presta_type}`}
            placeholder="Descriptif :"
            defaultValue={eventCycleLangPrestaType.presta_type.descriptif} /> */}


        <div className="form-group row mb-1 py-2 ">
            <label htmlFor="presta_type_nom_142" className="col-sm-3 col-form-label">Nom du pack </label>
            <div className="col-sm-9">
                <input
                    onChange={handleChange}
                    name="presta_type_nom"
                    type="text"
                    className="form-control rounded-pill" placeholder="Nom du pack" value={eventCycleLangPrestaType.presta_type.presta_type_nom} />
                <small className="text-muted"></small>
            </div>
        </div>

        <div className="form-group row mb-1 py-2 ">
            <label htmlFor="presta_type_nom_142" className="col-sm-3 col-form-label">Prix conseillé</label>
            <div className="col-sm-9">
                <input
                    onChange={handleChange}
                    name="prix_unitaire_ht" type="text" className="form-control rounded-pill" placeholder="Prix conseillé" value={eventCycleLangPrestaType.presta_type.prix_unitaire_ht} />
                <small className="text-muted"></small>
            </div>
        </div>
        <div className="form-group row mb-1 py-2 ">
            <label htmlFor="presta_type_nom_142" className="col-sm-3 col-form-label">Nom du pack </label>
            <div className="col-sm-9">

                <textarea
                    onChange={handleChange}
                    name="descriptif" cols="30" rows="10" value={eventCycleLangPrestaType.presta_type.descriptif} className="form-control"></textarea>
            </div>
        </div>




    </div>
}

const EventCycleLangPrestaTypeList = ({ eventCycleLang }) => {

    const [eventCycleLangPrestaTypeList, setEventCycleLangPrestaTypeList] = useState(null)

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    useEffect(() => {

        const getEventCycleLangPrestaType = async () => {

            await fetch(`${apiUrlTranslation}?action=getEventCycleLangPrestaType&params=WHERE id_event_cycle_lang=${eventCycleLang.id_event_cycle_lang}`)
                .then(res => res.json())
                .then(res => {
                    setEventCycleLangPrestaTypeList(res)
                })
        }

        eventCycleLang && getEventCycleLangPrestaType()
    }

        , [eventCycleLang, i18n.resolvedLanguage])

    if (!eventCycleLangPrestaTypeList || eventCycleLangPrestaTypeList.length == 0) return null

    return (
        <div className=' mt-12'>

            <hr className="border-gray-300" />
            <p className='text-xl font-bold'>Les packs de ma délégation</p>
            {eventCycleLangPrestaTypeList.map(eventCycleLangPrestaType =>
                <EventCycleLangPrestaType
                    key={eventCycleLangPrestaType.id_event_cycle_lang_presta_type}
                    setEventCycleLangPrestaTypeList={setEventCycleLangPrestaTypeList}
                    eventCycleLangPrestaTypeList={eventCycleLangPrestaTypeList}
                    eventCycleLangPrestaType={eventCycleLangPrestaType}
                />
            )}

            <RegistrationFormRow

                required={false}
                inputType="hidden"
                name='eventCycleLangPrestaTypeList'
                placeholder=""
                defaultValue={JSON.stringify(eventCycleLangPrestaTypeList)} />


        </div>
    )
}


function RegistrationFormDelegationSection({ eventCycleLang, supertitle }) {

    const eventContext = useContext(EventContext);

    const [cycleLang, setCycleLang] = useState(null);

    const { currentEvent } = eventContext;


    const getCycleLangByIdCycleLang = async () => {

        await fetch(`${API_URL}?action=getCycleLangByIdCycleLang&id_cycle_lang=${eventCycleLang.cycle_lang.id_cycle_lang}`)
            .then(res => res.json())
            .then(res => {
                setCycleLang(res)
            })
    }

    useEffect(() => {
        eventCycleLang && getCycleLangByIdCycleLang()
    }
        , [eventCycleLang])


    if (!eventCycleLang) return null;

    return (
        <RegistrationFormSection supertitle={supertitle}>



            <div class="registration-form-section bg-white rounded p-2 my-4 shadow-sm ">
                <div class="form-group row mb-1 py-2 ">
                    <label for="main_event_cycle_lang_descriptif" class="col-sm-3 col-form-label">Présentation de mon entreprise/organisation qui apparaitra dans la page "délégation" :</label>
                    <div class="col-sm-9">  {HTMLReactParser(eventCycleLang.cycle_lang.cycle_lang_descriptif ?? "")}
                    </div>
                </div>
            </div>

            <RegistrationFormRow
                isRegister
                required={false}
                inputType="textarea"
                name='main_event_cycle_lang_descriptif'
                placeholder={`Ambition et descriptif de ma délégation sur ${currentEvent?.nom} :`}
                defaultValue={eventCycleLang?.event_cycle_lang_descriptif} />

            <RegistrationFormRow

                required={false}
                inputType="hidden"
                name='main_id_event_cycle_lang'
                placeholder=""
                defaultValue={eventCycleLang?.id_event_cycle_lang} />

            <EventCycleLangPrestaTypeList eventCycleLang={eventCycleLang} />



            <div class="registration-form-section bg-white rounded p-2 my-4 shadow-sm ">
                <div class="form-group row mb-1 py-2 ">
                    <label for="main_event_cycle_lang_descriptif" class="col-sm-3 col-form-label">
                        Collectifs rattachés </label>
                    <div class="col-sm-9">
                        <ul className="list-disc">
                            {cycleLang && cycleLang.children?.map((cycleLang, index) =>
                                <li> {cycleLang.nom_cycle_lang}</li>)}
                        </ul>
                    </div>
                </div>
            </div>


        </RegistrationFormSection>
    )
}

export default RegistrationFormDelegationSection