import React, { useState } from 'react'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


function PhoneNumberInput({ contact }) {

    const [value, setValue] = useState()

    const handleChange = (value) => {

        console.log(value);
        if (!value.startsWith('+')) {
            value = '+' + value;
        }
        setValue(value);
    };


    return (

        <div className=' flex items-center' >

            <div className='basis-2/6'>

                Mobile
            </div >

            <div>

                <PhoneInput

                    countryCodeEditable={true}
                    inputProps={{
                        name: 'phone',
                        required: true,
                        autoFocus: true
                    }}
                    preferredCountries={['fr', 'be', 'ch', 'lu']}
                    enableSearch={true}
                    placeholder="Enter phone number"
                    value={value}
                    onChange={handleChange} />
            </div>
            <div className='p-3'>

                {value}</div>
        </ div>
    )
}

export default PhoneNumberInput