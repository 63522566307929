import React, { useContext, useEffect, useState } from 'react'
import Layout from './Layout'
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import useApiUrlTranslation from './useApiUrlTranslation';
import EventContext from '../contexts/EventContext';

const RegistrationCible = ({ name, href, libelle, visuels }) => {

    return <a href={href}
        className="group flex flex-col justify-between rounded-lg bg-neutral-900 border-pink-700 border-x border-y text-white text-center p-4 h-full w-full max-w-sm mx-auto hover:bg-pink-500"
    >
        <div> <img src={visuels?.medium} alt="" className="w-full mx-auto" /></div>
        <h3 className="text-base font-normal leading-6  mb-3 ">{libelle}</h3>
        <span className="text-pink-300 group-hover:text-white font-bold ">{name}</span>
    </a>
}

const RegistrationProfile = () => {

    const { idEvent } = useParams();

    const [eventCycleLangList, setEventCycleLangList] = useState(false);

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();


    const eventContext = useContext(EventContext);

    const { getEventByIdEvent, currentEvent } = eventContext;


    useEffect(() => {

        const getEventCycleLangList = async () => {

            await fetch(`${apiUrlTranslation}?action=getEventCycleLangList&params= WHERE id_event=${idEvent} AND forum="packs" AND publier!="n"`)
                .then(res => res.json())
                .then(res => {
                    setEventCycleLangList(res)
                })
        }

        getEventCycleLangList()
    }, [i18n.resolvedLanguage])

    return (
        <Layout title={t('event_nav.inscription')}>


            <div className=" ">
                <div className="pt-3 grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mx-auto max-w-7xl justify-items-center align-items-center">
                    {eventCycleLangList && eventCycleLangList

                        .map(eventCycleLang => <RegistrationCible
                            name={eventCycleLang.event_cycle_lang_nom}
                            libelle={eventCycleLang.registration_link_text}
                            visuels={eventCycleLang.visuels}
                            href={`${currentEvent ? `/events/${currentEvent.id_event}/store/${eventCycleLang.id_event_cycle_lang}` : ''} `}>
                            {eventCycleLang.event_cycle_lang_nom}
                        </RegistrationCible>)
                    }

                    {/* <RegistrationCible
                        href={`/events/${idEvent}/become-candidate/`}
                        libelle={` ${t('event_nav.devenir_candidat')}  /   ${t('event_nav.devenir_jury')}`}
                        name={t('event_nav.pitching_session')} /> */}
                </div>
            </div>
        </Layout >
    )
}

export default RegistrationProfile