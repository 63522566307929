import React, { useContext, useEffect, useState } from 'react'
import LayoutAccount from './LayoutAccount2'
import UserContext from '../../contexts/UserContext';

import useApiUrlTranslation from '../useApiUrlTranslation';
import HTMLReactParser from 'html-react-parser';
import Loader from '../Loader';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';


const JuryEvent = ({ id_jury_event, parcoursEval, parcoursEvalList }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();


    const [juryEvent, setJuryEvent] = useState(false);

    const getJuryEvent = async () => {

        await fetch(`${apiUrlTranslation}?action=getJuryEvent&ije=${id_jury_event}`)
            .then(res => res.json())
            .then(res => {
                setJuryEvent(res)
            })
    }

    useEffect(() => {

        getJuryEvent()
    }
        , [])

    //return <div>{id_jury_event} {JSON.stringify(juryEvent.id_conf_event)}</div>


    return <div>

        {/* <div>{juryEvent.id_conf_event.conf_event_lang.cel_titre}</div> */}
        <MiseEnRelation
            confEvent={juryEvent.id_conf_event}
            id_jury_event={id_jury_event}
            meeting="1"
            title="Demande de mise en relation investisseurs/décideurs"
            presta={parcoursEval.presta}
            parcoursEvalList={parcoursEvalList} />

        <MiseEnRelation
            confEvent={juryEvent.id_conf_event}
            id_jury_event={id_jury_event}
            meeting="0"
            title="Visibilité et évaluation investisseurs/décideurs"
            presta={parcoursEval.presta}
            parcoursEvalList={parcoursEvalList} />

    </div>
}


const Candidat = ({ parcoursEval, parcoursEvalList, userCycleLangList, juryDemoList }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [communauteList, setCommunauteList] = useState(false);

    const [intersection, setIntersection] = useState(false);

    const { idEvent } = useParams();

    const getCommunaute = async () => {

        await fetch(`${apiUrlTranslation}?action=getCommunaute&filter= AND cf.id_conferencier IN (SELECT id_conferencier FROM conferenciers  WHERE id_contact=${parcoursEval.presta.contact.id_contact} AND id_event=${idEvent})`)
            .then(res => res.json())
            .then(res => {
                setCommunauteList(res)
            })
    }

    useEffect(() => {

        getCommunaute();

        const array1 = userCycleLangList
            .filter(userCycleLang => userCycleLang.cycle_lang.cycle_lang_type == "collectif_geographique")
            .map(userCycleLang => userCycleLang.cycle_lang.id_cycle_lang);

        const array2 = Array.isArray(communauteList) ? communauteList.map(communaute => communaute?.cycle_lang?.id_cycle_lang) : []
        setIntersection(array1.filter(element => array2.includes(element)));


    }
        , [userCycleLangList])


    //if (intersection.length == 0) return false

    if (juryDemoList && juryDemoList
        .filter(juryDemo => juryDemo.jury_event.id_jury_event == parcoursEval.id_jury_event
            && juryDemo.presta.id_presta == parcoursEval.presta.id_presta
            && juryDemo.is_nomine == "1"
            && juryDemo.is_visible == "1"
        ).length == 0) return false

    return <div className="mb-5 flex space-x-3   border-b-4 pb-3">


        {/* {juryDemoList && JSON.stringify(juryDemoList)} */}


        {/* {JSON.stringify(parcoursEval.id_jury_event)}

        {JSON.stringify(juryDemoList.map(juryDemo => juryDemo.jury_event.id_jury_event))} */}

        {/* {juryDemoList && juryDemoList
            .filter(juryDemo => juryDemo.jury_event.id_jury_event == parcoursEval.id_jury_event
                && juryDemo.presta.id_presta == parcoursEval.presta.id_presta
                && juryDemo.is_nomine == "1"
                && juryDemo.is_visible == "1"
            )
            .map(juryDemo => <div className="text-green-700 font-bold">{juryDemo.presta.presta_nom}
                {juryDemo.presta.id_contact.nom} {juryDemo.is_nomine} {juryDemo.is_visible}</div>)} */}


        <div className="basis-1/12">
            <img src={parcoursEval.presta.contact.logos.large} alt="" className="w-100" />
        </div>

        <div className="basis-11/12">
            <div className="text-xl text-pink-700 font-bold"> {parcoursEval.presta.contact.societe} </div>

            {/* <div className="text-xl text-pink-700"> {parcoursEval.conf_event.contact.societe} </div> */}

            <div className="flex space-x-3 items-center"> <img src={parcoursEval.presta.contact.flag} alt="" className="h-6 w-6" /> <span>{parcoursEval.presta.contact.pays}</span></div>

            <div className="flex items-center space-x-2">
                <img src={parcoursEval.presta.contact.photos.small} alt=""
                    className="rounded-full w-6 h-6" />

                <span>{parcoursEval.presta.contact.prenom} {parcoursEval.presta.contact.nom}</span>
            </div>
            <div className="hidden">
                {HTMLReactParser(parcoursEval.presta.contact.edito_long)}
            </div>

            {parcoursEvalList && parcoursEvalList
                //dedup by id_jury_event
                .filter((v, i, a) => a.findIndex(t => (t.id_jury_event === v.id_jury_event)) === i)
                .map(parcoursEvalJe => {


                    const verif = juryDemoList && juryDemoList
                        .filter(juryDemo => juryDemo.jury_event.id_jury_event == parcoursEvalJe.id_jury_event
                            && juryDemo.presta.id_presta == parcoursEvalJe.presta.id_presta
                            && juryDemo.is_nomine == "1"
                            && juryDemo.is_visible == "1"
                        )
                    // .map(juryDemo => <div className="text-green-700 font-bold">{juryDemo.presta.presta_nom}
                    //     {juryDemo.presta.id_contact.nom} {juryDemo.is_nomine} {juryDemo.is_visible}</div>)

                    if (verif.length == 0) return false
                    return <div>

                        {/* {JSON.stringify(verif.length)} */}
                        <JuryEvent

                            key={parcoursEvalJe.id_jury_event}
                            id_jury_event={parcoursEvalJe.id_jury_event}
                            parcoursEval={parcoursEval}
                            parcoursEvalList={parcoursEvalList} />

                    </div>
                })}
        </div>
    </div>

}


const MiseEnRelation = ({ parcoursEvalList, presta, title, meeting, id_jury_event, confEvent }) => {

    if (parcoursEvalList && parcoursEvalList
        .filter(parcoursEval => parcoursEval.presta.id_presta === presta.id_presta
            && parcoursEval.id_jury_event === id_jury_event
            && parcoursEval.meeting == meeting
        ).length == 0) return false

    return (<div className="">

        {meeting == 1 ? <div className="text-lg mb-2 font-bold mt-5">{confEvent?.conf_event_lang?.cel_titre}</div> : ''}

        <div className="border p-2 rounded">

            <p className="font-bold mt-3 text-neutral-500">{title}</p>

            <table className='w-100 mt-3'>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Nom</th>
                        <th>Prénom</th>
                        <th>Société</th>

                        <th className='text-right'>Note /20</th>
                    </tr>
                </thead>

                <tbody className='divide-y divide-dashed mt-5 '>
                    {parcoursEvalList && parcoursEvalList
                        .filter(parcoursEval => parcoursEval.presta.id_presta === presta.id_presta
                            && parcoursEval.id_jury_event === id_jury_event
                            && parcoursEval.meeting == meeting
                        )
                        .map((parcoursEval, index) => {

                            return <tr>
                                <td>#{index + 1}</td>
                                <td>{parcoursEval.nom}</td>
                                <td>{parcoursEval.prenom}</td>
                                <td>{parcoursEval.societe} </td>

                                <td className='text-right'>
                                    {parseInt(parcoursEval.meeting || 0) + parseInt(parcoursEval.comprehension || 0) + parseInt(parcoursEval.timing || 0) + parseInt(parcoursEval.support || 0)}
                                </td>
                            </tr>
                        }
                        )}
                </tbody>
            </table>

        </div></div>)
}

const DisplayUserCollectif = ({ userCycleLangList }) => {

    return <div className="flex items-center space-x-3 bg-neutral-200 my-3 p-3">
        {userCycleLangList && userCycleLangList
            .filter((v, i, a) => a.findIndex(t => (t.id_cycle_lang === v.id_cycle_lang)) === i)
            .map(userCycleLangList =>
                <div>

                    <img src={userCycleLangList.cycle_lang.logo_better.large} alt="" className="w-20" />
                </div>)}

    </div>
}

const DashboardPitch = () => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const { idEvent } = useParams();

    const userContext = useContext(UserContext);

    const { user, userContactConferencierList, userCycleLangList, userRoleList, logout } = userContext;

    const [parcoursEvalList, setParcoursEvalList] = useState(false);

    const [juryDemoList, setJuryDemoList] = useState(false);

    const getParcoursEval = async () => {

        await fetch(`${apiUrlTranslation}?action=getParcoursEval&params=WHERE id_event=${idEvent}`)
            .then(res => res.json())
            .then(res => {
                setParcoursEvalList(res)
            })
    }


    const getJuryDemoList = async () => {

        await fetch(`${apiUrlTranslation}?action=getJuryDemoList&params=WHERE id_event=${idEvent}`)
            .then(res => res.json())
            .then(res => {
                setJuryDemoList(res)
            })
    }

    useEffect(() => {
        getParcoursEval();

        getJuryDemoList();
    }, [])

    return (
        <LayoutAccount title="Performance de mes exposants">

            {!parcoursEvalList ? <Loader /> :

                <div className="divide-y-3 divide-neutral-200 divide-solid mt-10">

                    <DisplayUserCollectif userCycleLangList={userCycleLangList
                        .filter(userCycleLang => userCycleLang.cycle_lang.cycle_lang_type == "collectif_geographique")
                    } />

                    {parcoursEvalList && parcoursEvalList

                        //dedup by id_presta
                        .filter((v, i, a) => a.findIndex(t => (t.id_presta === v.id_presta)) === i)
                        .sort((a, b) => a.id_presta - b.id_presta)

                        .map(parcoursEval => <Candidat
                            userCycleLangList={userCycleLangList}
                            parcoursEvalList={parcoursEvalList}
                            juryDemoList={juryDemoList}
                            parcoursEval={parcoursEval} />)
                    }
                </div>}
        </LayoutAccount>
    )
}

export default DashboardPitch