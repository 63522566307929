import React, { useEffect, useState } from 'react'
import { API_URL } from '../config';
import ContactsAnnuaire from './ContactsAnnuaire'
import Layout from './Layout'

const CoordinateursCollectifs = () => {

    const [contacts, setContacts] = useState([]);

    const fetchContacts = async () => {

        await fetch(`${API_URL}?action=fetchContactCycleLang&filter= c.id_contact !=0 AND `)
            .then(res => res.json())
            .then(res => setContacts(res))
    }

    useEffect(() => {
        fetchContacts()
    }, [])

    return (
        <Layout>
            <ContactsAnnuaire contacts={contacts} />)
        </Layout>
    )
}

export default CoordinateursCollectifs
