import { createRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import useApiUrlTranslation from "./useApiUrlTranslation";

const OrganismeWebSiteChecker = () => {

    const [organismeWebSiteWatched, setOrganismeWebSiteWatched] = useState(false);

    const { register, formState, watch, getValues } = useFormContext();

    const { t, i18n } = useApiUrlTranslation();

    const dialogRef = createRef();

    const toggleShowDialog = () => {

        if (dialogRef.current.open) {
            dialogRef.current.close();
            setOrganismeWebSiteWatched(false);
        }
        else {
            dialogRef.current.showModal();
        }
    }

    const handleCheckWebSite = (e) => {

        e.preventDefault();

        let src = watch('web');

        window
            .open(src, '_blank')
            .focus();

        return false;

        setOrganismeWebSiteWatched(src);

        toggleShowDialog();

    }


    return <div>
        <a onClick={handleCheckWebSite} className="text-sm rounded-full px-4 py-1 text-white bg-emerald-500 cursor-pointer">{t('form.sections.organisme_section.web_verif')}</a>

        <dialog ref={dialogRef} className=" backdrop:bg-black/50 backdrop:backdrop-blur-md">
            <div className='text-gray-500'>
                {organismeWebSiteWatched}
            </div>
            <iframe className="border" width="560" height="315" src={organismeWebSiteWatched} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

            <button onClick={toggleShowDialog}>Fermer</button>
        </dialog>
    </div>

}

export default OrganismeWebSiteChecker;