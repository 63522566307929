import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import { use } from 'i18next';
import useApiUrlTranslation from './useApiUrlTranslation';
import HTMLReactParser from 'html-react-parser';


const Contact = ({ contact }) => {


    return <div className="flex flex-row space-x-2 py-3">
        <div className="basis-2/12"><img src={contact.photos.large} alt="" className="w-40 h-40 object-cover rounded-full" /></div>
        <div className="basis-10/12">
            <p>{contact.prenom} {contact.nom}</p>
            <p>{contact.societe} </p>
            <div className='text-gray-400 text-sm'>{HTMLReactParser(contact.edito_court)}</div>
            <div className='flex  space-x-2 items-center text-gray-400 text-sm mt-3'> <img src={contact.flag} alt="" className="w-6" />  <span>{contact.pays} </span></div>
        </div>
    </div>
}

const Investisseurs = () => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [contacStatutList, setContacStatutList] = useState([]);

    const getContactList = async () => {

        await fetch(
            `${apiUrlTranslation}?action=getContactStatutList&filter=WHERE id_statut IN(79)`
        )
            .then((res) => res.json())
            .then((res) => {

                //dedup by id_contact.id_contact
                const unique = [...new Map(res.map(item => [item.id_contact.id_contact, item])).values()];
                //order bat id_contact.nom
                unique.sort((a, b) => a.id_contact.nom.localeCompare(b.id_contact.nom));
                setContacStatutList(unique)
            });
    };

    useEffect(() => {
        getContactList();
    }
        , []);


    return (
        <Layout title="Annuaire des investisseurs">

            <div className=" text-white dark:bg-neutral-950">

                <div className="max-w-7xl mx-auto h-full divide-y divide-neutral-900">
                    {contacStatutList && contacStatutList.map(contacStatut => <Contact contact={contacStatut.id_contact}></Contact>)}
                </div>
            </div>
        </Layout>
    )
}

export default Investisseurs