import React, { useContext, useEffect, useState } from 'react'
import LayoutAccountEvent from './LayoutAccountEvent'

import UserContext from '../../contexts/UserContext'
import { Link, useParams } from 'react-router-dom'
import { API_URL } from '../../config'
import HTMLReactParser from 'html-react-parser'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import eventContext from '../../contexts/EventContext'
import { HomeIcon } from '@heroicons/react/20/solid'
import Breadcrumbs, { BreadcrumbsProgramme } from './Breadcrumbs'
import { use } from 'i18next'
import useApiUrlTranslation from '../useApiUrlTranslation'

const ConfEvent = ({ confEvent, userContactConferencierList, user, statut = "" }) => {

    return (
        <div
            className="border relative flex items-center space-x-3 rounded-lg  bg-white px-2 pt-3 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
        >
            <div className='w-full'>
                <div className="flex border-b justify-between py-2  bg-slate-700 px-2 rounded-t ">
                    <div className='text-white  font-bold'>{confEvent.conf_event_date_local} | {confEvent.heure_debut_local} {`-`} {confEvent.heure_fin_local}</div>
                    <div className='text-sm text-white border px-2 py-1 rounded-full' style={{ backgroundColor: confEvent.conf_event_type.color }}>  {confEvent.conf_event_type_nom}</div>
                </div>

                <div className="py-2 text-pink-600">
                    {HTMLReactParser(confEvent.conf_event_lang.cel_titre)}
                </div>


                <div className=" flex  border-slate-400/20 py-3 px-3.5">
                    <div class="   font-semibold text-slate-500"> Mes statuts : </div>
                    <div className="text-gray-500 ">
                        {userContactConferencierList.filter(userContactConferencier => userContactConferencier.id_conf_event == confEvent.id_conf_event).map(userContactConferencier => <span className="pl-2">{userContactConferencier.statut.libelle}</span>)}
                    </div>
                </div>

                <div className='flex items-center space-x-3 bg-gray-50 px-2 rounded my-3  justify-between text-sm'>

                    <div className=" text-gray-400">
                        <i className="fas fa-map-marker-alt pr-1 text-gray-500"></i>
                        {confEvent.salle.salle_nom}
                    </div>
                    <img src={confEvent.salle.img} alt="" className="h-8 rounded-full my-2" />
                </div>

                {
                    ["67", "88", "74"].includes(confEvent.type)
                    && userContactConferencierList && userContactConferencierList.filter(userContactConferencier => userContactConferencier.statut.id_event_contact_type == 92).length > 0 &&

                    <div div className='text-center mt-2'>
                        <a target="_blank" href={`https://www.event2one.com/vote/#/ije/${confEvent.jury_event.id_jury_event_encoded}/${user.id_contact}`} className="bg-blue-500 rounded-full  px-3 p-2 text-white"> Mes outils de vote en ligne</a>
                    </div>
                }
            </div>
        </div>
    )
}

const Schedule = () => {

    const { currentEvent } = useContext(eventContext);

    const { user, userContactConferencierList } = useContext(UserContext);

    const { apiUrlTranslation, i18n } = useApiUrlTranslation()

    const [confEventList, setConfEventList] = useState(null);

    const [contactConferencierList, setContactConferencierList] = useState(null);

    const { idEvent } = useParams();



    useEffect(() => {

        const getConfEvent = async (contactConferencierList) => {

            await fetch(`${apiUrlTranslation}?action=getConfEvent&filter= id_event=${idEvent} AND id_conf_event IN(${contactConferencierList.filter(userContactConferencier => userContactConferencier.conf_event.id_conf_event).map(userContactConferencier => userContactConferencier.conf_event.id_conf_event)})   `)
                .then(response => response.json())
                .then(response => setConfEventList(response)
                )
        }

        const getContactConferencierList = async () => {

            await fetch(`${API_URL}?action=getContactConferencierList&filter= AND c.id_contact=${user.id_contact} AND id_event=${currentEvent.id_event} AND id_conf_event NOT IN ('0','')`)
                .then(res => res.json())
                .then(contactConferencierList => {
                    setContactConferencierList(contactConferencierList)

                    getConfEvent(contactConferencierList)

                })
        }
        contactConferencierList && getConfEvent(contactConferencierList)
        user && currentEvent && getContactConferencierList()
        //console.log("userContactConferencierList", userContactConferencierList)
    }, [i18n.resolvedLanguage, user, currentEvent])

    return (
        <LayoutAccountEvent className="">
            <BreadcrumbsProgramme />
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mt-3">
                {confEventList && contactConferencierList && confEventList.map((confEvent) =>
                    <ConfEvent
                        // userContactConferencierList={userContactConferencierList.filter(userContactConferencier => userContactConferencier.conf_event.id_conf_event === confEvent.id_conf_event)}
                        userContactConferencierList={contactConferencierList}
                        key={confEvent.id_conf_event}
                        confEvent={confEvent}
                        user={user} />
                )}
            </div>
        </LayoutAccountEvent>
    )
}

export default Schedule