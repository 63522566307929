import React, { useEffect, useState } from 'react'
import { API_URL } from '../../config'
import { useParams, useLocation } from "react-router-dom";
import moment from 'moment';
import Moment from 'react-moment';
import { sortByAttribute } from '../../utils';
import EventTimer from '../EventTimer';

const JuryEvent = ({ juryEvent }) => {


    if (!juryEvent) {

        return false
    }

    return <li title={juryEvent.id_jury_event} className={`mx-2 bg-white/90 my-2 flex flex-row rounded-l-2xl p-2 ${moment(`${juryEvent.conf_event.conf_event_date} ${juryEvent.conf_event.heure_debut.replace('h', ':')}`).isAfter(moment()) ? '' : 'd-none'}`}>
        <div className=' p-3  ' >
            <div className='text-6xl basis-1/4 bg-blue-700 rounded-l-2xl p-3 text-white'>{juryEvent.conf_event.heure_debut}</div>
            {/*juryEvent.conf_event.conf_event_jour*/}
            { /*<Moment format='YY-MM-DD' />*/}
            {/*moment().format('YYYY-MM-DD')*/}
            {/*moment(`${juryEvent.conf_event.conf_event_date} ${juryEvent.conf_event.heure_debut.replace('h', ':')}`).isAfter(moment()) ? 'A venir' : 'Terminé' */}
            {/*moment().add(1, 'minutes').format('HH:mm').replace(':', 'h')*/}
        </div>
        <div className='  p-3 basis-3/4'>
            <div className="text-2xl text-slate-500">{juryEvent.conf_event.type.conf_event_type_nom}</div>
            <div className="text-4xl ">{juryEvent.conf_event.conf_event_lang.cel_titre}</div>

        </div>

        <div className=''><EventTimer
            className=''
            date={juryEvent.conf_event.conf_event_date}
            heure_debut={juryEvent.conf_event.heure_debut}
        />
            <img src={juryEvent.qrcode_webapp} alt="" className='shadow' />
        </div>
    </li>

}

const DeparturesAll = ({ filter,
    title,
    idConfEventTypeList = ['66', '63'] }) => {

    const { idEvent } = useParams();
    const [juryEventList, setJuryEventList] = useState(null)

    const location = useLocation();

    useEffect(() => {

        const updateList = (jeList) => {
            const interval = setInterval(() => {

                console.log(moment().format('HH:mm:ss'));

                console.log(jeList);

                if (!jeList) {
                    return false
                    console.log(jeList)
                }

                const formatedList = [...jeList]
                    .filter(item =>
                        item.conf_event.conf_event_date === moment().format('YYYY-MM-DD') &&
                        moment(`${item.conf_event.conf_event_date} ${item.conf_event.heure_debut.replace('h', ':')}`).isAfter(moment())
                    )

                setJuryEventList(sortByAttribute(filter ? formatedList : jeList, 'conf_event_heure'))
            }, 1000);

            return () => clearInterval(interval);

        }


        const getJuryEventList = async () => {

            await fetch(`${API_URL}?action=getJuryEventList&params=where id_event=${idEvent}`)
                .then(res => res.json())
                .then(res => {

                    //    const formatedDate = `${confEvent.conf_event_date} ${confEvent.heure_debut.replace('h', ':')}`;


                    let formatedListWhitDateFilter = res
                        .map(item => { return { ...item, conf_event_heure: item.conf_event.heure_debut } })
                        .filter(item => idConfEventTypeList.includes(item.conf_event.type.id_conf_event_type))
                        .filter(item =>
                            item.conf_event.conf_event_date === moment().format('YYYY-MM-DD') &&
                            moment(`${item.conf_event.conf_event_date} ${item.conf_event.heure_debut.replace('h', ':')}`).isAfter(moment())
                        )


                    const formatedListWhitNoDateFilter = res
                        .map(item => { return { ...item, conf_event_heure: item.conf_event.heure_debut } })
                        .filter(item => idConfEventTypeList.includes(item.conf_event.type.id_conf_event_type))

                    let formatedList = filter ? formatedListWhitDateFilter : formatedListWhitNoDateFilter;

                    const formatedListBeta = [...formatedList,
                    {
                        conf_event: {
                            conf_event_date: moment().format('YYYY-MM-DD'),
                            heure_debut: moment().add(10, 'minutes').format('HH:mm').replace(':', 'h'),
                            type: { conf_event_type_nom: "Init..." },
                            conf_event_lang: { cel_titre: "Init..." }

                        },
                        conf_event_heure: moment().add(10, 'minutes').format('HH:mm').replace(':', 'h'),
                    }, {
                        conf_event: {
                            conf_event_date: moment().format('YYYY-MM-DD'),
                            heure_debut: moment().add(20, 'minutes').format('HH:mm').replace(':', 'h'),
                            type: { conf_event_type_nom: "Init..." },
                            conf_event_lang: { cel_titre: "Init..." }

                        },
                        conf_event_heure: moment().add(20, 'minutes').format('HH:mm').replace(':', 'h'),
                    }
                    ]

                    setJuryEventList(sortByAttribute(formatedList, 'conf_event_heure'));
                    return formatedList
                }
                ).then(res => updateList(res))
        }

        idEvent && getJuryEventList()

    }, [])

    if (!idEvent) {
        return false
    }

    return (
        <div className='bg-slate-200 h-screen '>
            <div className='text-right   text-5xl right-20 text-blue-700 mb-3 pr-20 pt-4'>{moment().format("HH:mm")} </div>

            <h1 className='pt-8 text-7xl text-center pb-4 font-mono'>
                {title}
            </h1>

            <ul className="min-w-full border-separate" style={{ borderSpacing: 0 }}>
                {juryEventList && juryEventList.map((juryEvent, i) => <JuryEvent key={i} juryEvent={juryEvent} />)}
            </ul>
        </div>
    )
}

export default DeparturesAll