import React, { useState, useEffect } from 'react'
import { API_URL } from '../config'
import Layout from './Layout'

const News = () => {

    const [articles, setArticles] = useState([])
    const fetchEventPresta = async () => {
        await fetch(`${API_URL}?action=getEventPrestas&id_event=1635`)
            .then(res => res.json())
            .then(articles => setArticles(articles))
    }

    useEffect(() => {
        fetchEventPresta()

    }, [])

    return (
        <Layout title="Presse">
            <div className="container">
                <p>Vous souhaitez vous tenir informé de l'actualité du salon, consulter les communiqués ou dossier de presse, effectuer une demande d’accréditation ou encore entrer en contact avec nous ? Cet espace est fait pour vous.</p>
                <div className="row">
                    {articles.filter(article => article.comm_presse === "y").map(article =>
                        <div className="col-md-3">
                            <div className="border text-center p-5 mb-2">
                                <p className="text-muted">{article.date_parution}</p>
                                <a target="_blank" rel="noreferrer" href={article.event_presta_url_externe} className="text-decoration-none fw-bold"> {article.event_presta_nom}</a>
                            </div>
                        </div>)}
                </div>
            </div>
        </Layout>
    )
}

export default News
