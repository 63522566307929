import React, { useEffect } from 'react'

const NotFound = () => {

    useEffect(() => {

        const head = document.querySelector('head');
        const meta = document.createElement('meta');
        meta.name = 'robots';
        meta.content = 'noindex, nofollow';
        head.appendChild(meta);
    }, []);



    return (
        <>

            <div className="flex items-center justify-center h-screen max-w-7xl mx-auto">
                <div className="text-center">
                    <h1 className="text-7xl font-bold text-gray-200">
                        <img className='invert-custom  w-1/2 mx-auto pb-10' src="https://www.mlg-consulting.com/manager_cc/docs/archives/230906125631_output-onlinepngtools.png" /></h1>

                    <h1 className="text-7xl font-bold text-gray-200">Oups, la page que vous recherchez n’existe pas</h1>
                    <p className="text-gray-300 text-xl mt-5">Revenir en arrière ou aller sur

                        <a href="https://www.myglobalvillage.com">www.myglobalvillage.com </a>pour choisir une nouvelle direction</p>
                </div>
            </div>

        </>
    )
}

export default NotFound