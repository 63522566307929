import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ContactCard from "./ContactCard";
import EntrepriseLogoCard from "./EntrepriseLogoCard";

function EntrepriseLogo({ statut }) {
  let { idEvent } = useParams();
  let { id_conf_event } = useParams();

  statut = statut.split(",");

  const statutList = [
    "candidat-pitch",
    "offreur_de_solution",
    "charge_organisation",
    "chroniqueur_tv",
    "referent-lieu",
    "curateur",
    "demande_accreditation_presse",
    "edito",
    "intervenant",
    "jury",
    "partenaire_media",
    "president_collectif",
    "president_forum",
    "president_programme",
    "president_jury",
    "participant",
    "pack",
    "tete_affiche",
  ];

  const currentStatutList = statutList.filter((statutItem) =>
    statut.includes(statutItem)
  );

  const [contactList, setContactList] = useState([]);

  const fetchContactList = async () => {
    const req = `getContactConferencierList&filter=%20and%20id_event=${idEvent} AND id_conf_event=${id_conf_event}`;

    await fetch(
      `https://www.mlg-consulting.com/smart_territory/form/api.php?action=${req}`
    )
      .then((res) => res.json())
      .then((contactEvent) => {
        const filteredContactEvent = contactEvent.filter((contact) =>
          currentStatutList.includes(contact.conferencier_statut)
        );
        researchInfoContact({ infoContactEvents: filteredContactEvent });
      });
  };

  const researchInfoContact = ({ infoContactEvents }) => {
    let uniqueIdInfoContactEvents = [
      ...new Set(
        infoContactEvents.map(
          (infoConctactEvent) => infoConctactEvent.id_contact
        )
      ),
    ];

    const filteredUniqueIdInfoContactEvents = uniqueIdInfoContactEvents.filter(
      (uniqueIdInfoContactEvent) => uniqueIdInfoContactEvent !== ""
    );

    setContactList(filteredUniqueIdInfoContactEvents);
  };

  useEffect(() => {
    fetchContactList();
  }, []);

  return <div>
  <section className="contact-list-fix__container container">
    <div
      className="ContactListFix row"
      style={{ display: "flex", justifyContent: "center" }}
    >
      {contactList
        ? contactList.map((idContact) => (
            <EntrepriseLogoCard idContact={idContact} displayNumber={5}/>
          ))
        : "Loading"}
    </div>
  </section>
</div>;
}

export default EntrepriseLogo;
