import React from "react";
import Layout from "./Layout";

function JuryRegistration() {
  return (
    <Layout>
      <div>JuryRegistration</div>
    </Layout>
  );
}

export default JuryRegistration;
