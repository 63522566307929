import HTMLReactParser from 'html-react-parser';
import React from 'react'
import useApiUrlTranslation from './useApiUrlTranslation';

const LieuInfo = ({ icon, data, title }) => {

    if (!data) {
        return '';
    }

    return <>
        <br />
        <p className='mt-3'><i class={icon}></i> <strong>{title}</strong></p>
        {HTMLReactParser(data)}
    </>
}

const LieuInfos = ({ salle }) => {

    const { t, i18n } = useApiUrlTranslation();

    return (
        <div>
            <LieuInfo
                title={t("pages.infos_pratiques.lieu")}
                data={salle.id_lieu.lieu_adresse}
                icon='fas fa-map-marker-alt' />

            <LieuInfo
                title="Accès pietons"
                data={salle.id_lieu.lieu_transp_pieds}
                icon='fas fa-shoe-prints' />

            {/* <LieuInfo
                title={t("pages.infos_pratiques.acces_routier")}
                data={salle.id_lieu.lieu_transp_route}
                icon='fa fa-car' />
            <LieuInfo
                title={t("pages.infos_pratiques.bus")}
                data={salle.id_lieu.lieu_transp_bus}
                icon='fa fa-bus' />
            <LieuInfo
                title={t("pages.infos_pratiques.ferre")}
                data={salle.id_lieu.lieu_transp_train}
                icon='fa fa-train' />
            <LieuInfo
                title={t("pages.infos_pratiques.aeroport")}
                data={salle.id_lieu.lieu_transp_avion}
                icon='fa fa-plane' />
            <LieuInfo
                title=""
                data={salle.id_lieu.lieu_transp_co_voiturage}
                icon='' />
            <LieuInfo
                title=""
                data={salle.id_lieu.hebergement}
                icon='' />
            <LieuInfo
                title=""
                data={salle.id_lieu.parking}
                icon='' />
            */}
            {HTMLReactParser(salle.id_lieu.google_map ?? "")}
        </div>
    )
}

export default LieuInfos