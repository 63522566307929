import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import LayoutAccount from './LayoutAccount'
import UserEventJuryEvents from './UserEventJuryEvents'
import { useParams } from 'react-router-dom';
import LayoutAccountEvent from './LayoutAccountEvent';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import userContext from '../../contexts/UserContext';
import { arrayIntersection } from '../../utils';

const findCommonElements = (arr1, arr2) => {
    return arr1.some(item => arr2.includes(item))
}

const TabItem = (props) => {

    return <li>
        <Link
            className=' flex w-full border shadow-sm justify-between w-100 my-4 text-blue-500 rounded-full py-4 px-3 items-center text-2xl font-bold'
            to={props.to}>
            <span className="pl-5"> {props.children}</span>
            <ChevronRightIcon className=" h-8 ml-2 mr-5 text-blue-500" />
        </Link>
    </li>
}

const Tabs = () => {

    let { idEvent } = useParams();

    const { user, userContactConferencierList } = useContext(userContext);


    if (!idEvent) {

        // return false
    }

    useEffect(() => {

    }, [])

    return <ul className='tabs my-5'>
        {/* 
        <TabItem to={`../event/${idEvent}/pitch`}>
            Sessions de pitch
        </TabItem> */}

        {
            userContactConferencierList && findCommonElements(userContactConferencierList.map(item => item.statut.id_event_contact_type), ["207"]) == true &&
            <TabItem to={`../event/${idEvent}/dashboard-pitch`}>
                Dashboard pitch
            </TabItem>
        }

        {/* 
        <TabItem to={`../event/${idEvent}/schedule`}>
            Mon programme
        </TabItem> */}

        {/* <TabItem to={`../event/${idEvent}/parcours`}>
            Parcours de visite
        </TabItem> */}

        {
            userContactConferencierList && findCommonElements(userContactConferencierList.map(item => item.statut.id_event_contact_type), ["234"]) == true &&
            <TabItem to={`../event/${idEvent}/techxploration`}>
                Tech'xploration
            </TabItem>
        }




        {/* arrayIntersection(userContactConferencierList.map(userContactConferencier => userContactConferencier.statut.id_event_contact_type), ["237", "234"]).length > 0 &&
            <TabItem to={`../event/${idEvent}/techxploration`}>
                Tech'xploration
</TabItem> */}

    </ul>
}

const UserEvent = () => {

    const { user, userContactConferencierList } = useContext(userContext);

    useEffect(() => {
        console.log(userContactConferencierList)
    }, [])


    return (

        <LayoutAccountEvent>
            <Tabs />
        </LayoutAccountEvent>
    )
}

export default UserEvent