import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import useApiUrlTranslation from "./useApiUrlTranslation";
import Loader from "./Loader";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/solid";
import HTMLReactParser from "html-react-parser";

const SelectedCycleLang = ({ cycleLang }) => {

    if (!cycleLang) return null;

    return <div>
        <p className="text-gray-500 mt-5 mb-2">Votre domaine d'expertise ou d'action</p>
        <div className="flex items-center space-x-2">
            <img src={cycleLang.logo_better.tiny} alt="" className="w-6" />
            <div className="text-sm"> {cycleLang.nom_cycle_lang}</div>
        </div>
    </div>
}

//create useFormulaireList hook

const useFormulaireList = ({ params }) => {

    const { i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [formulaireList, setFormulaireList] = useState(null);

    const getFormulaireList = () => {

        fetch(`${apiUrlTranslation}?action=getFormulaireList&params=${params}`)
            .then(res => res.json())
            .then(res => {
                setFormulaireList(res);

            })

    }

    useEffect(() => {
        getFormulaireList();
    }, [i18n.resolvedLanguage]);

    return { formulaireList };
}


const FormulairesSection = ({ setFormulaireSelected }) => {

    const { formulaireList } = useFormulaireList({ params: "WHERE id_formulaire IN (87,88,89)" });

    return (
        <div>
            <ul className="pl-5">
                {formulaireList && formulaireList.map(formulaire => <label key={formulaire.id_formulaire}>
                    <li className="text-sm font-normal flex space-x-1">
                        <input
                            type="radio"
                            name="formulaire"
                            value={formulaire.id_formulaire}
                            onChange={() => setFormulaireSelected(formulaire)} />

                        <img src={formulaire.instructions_generales_section_visuel_thumbs.tiny} alt="" className="w-6" />
                        <span> {formulaire.name_short}</span>
                    </li>
                </label>)}
            </ul>
        </div>
    )
}

const StatutSection = ({ idStatutSelection = [77, 79, 90, 83, 84, 82, 74, 75, 76, 80] }) => {

    const { i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [statutList, setStatutList] = useState(null);

    useEffect(() => {

        const fetchStatut = async () => {

            let statutListFormated = [];
            let check = '';

            if (typeof idStatutSelection === "string") {
                check = 'string';
                statutListFormated = idStatutSelection.split(",").map(id => parseInt(id));
            } else {
                statutListFormated = idStatutSelection;
            }

            await fetch(`${apiUrlTranslation}?action=getStatuts&filter= id_statut IN (${statutListFormated && statutListFormated.join(",")}) `)
                .then(res => res.json())
                .then(res => setStatutList(res))
        }

        fetchStatut();

    }, [i18n.resolvedLanguage]);


    return <div className="row divide-y ">

        <p className="text-gray-500 my-3">Je précise le statut qui correspond le mieux à ma situation.</p>

        {/* {JSON.stringify(contactStatutList.map(statut => statut.id_statut))} */}

        {statutList && statutList
            .filter(statut => idStatutSelection.includes(parseInt(statut.id_statut)))
            //trier par statut_libelle
            .sort((a, b) => a.statut_libelle.localeCompare(b.statut_libelle))
            // order  by statut_order asc

            .sort((a, b) => a.statut_order - b.statut_order)
            .map(statut => {

                const attachedFile = statut.attachedFileCollection && statut.attachedFileCollection.find(attachedFile => attachedFile && attachedFile.id_attached_file_type.id_attached_file_type == 103)
                return <div
                    key={statut.id_statut}
                    className="hover:bg-gray-100" >
                    <div className="form-check flex ">

                        <label className="flex form-check-label text-sm items-center" style={{ fontWeight: "normal" }}>
                            <input

                                type="radio"
                                id=""
                                name="statutList"
                                value={statut.id_statut}
                                data-placeholder="" />
                            {attachedFile && <img src={attachedFile.file_name} alt={attachedFile.attachedFile_nom} className="w-6" />}   {statut.statut_libelle != "" ? HTMLReactParser(statut.statut_libelle ?? "") : HTMLReactParser(statut.statut_nom ?? "")}
                        </label>
                    </div>
                </div>
            }
            )}
    </div>
}

const JoinMyglobalVillage = ({ cycleLang, formulaire }) => {

    let { id_cycle_lang } = useParams();

    const joinMyglobalVillageButtonRef = useRef(null);

    const { i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [cycleLangList, setCycleLangList] = useState(null);

    const [cycleLangSelected, setCycleLangSelected] = useState(null);

    const [formulaireList, setFormulaireList] = useState(null);


    const [formulaireSelected, setFormulaireSelected] = useState(null);

    const getFormulaireList = () => {

        fetch(`${apiUrlTranslation}?action=getFormulaireList`)
            .then(res => res.json())
            .then(res => {
                setFormulaireList(res);
            })

    }


    const getCycleLang = async () => {

        await fetch(`${apiUrlTranslation}?action=getCycleLang&filter= cycle_lang_type in('collectif_planaction') AND cycle_lang_state in('active') AND `)
            .then((res) => res.json())
            .then((contactCycleLangList) => {
                setCycleLangList(contactCycleLangList);
            })
    }

    const handleToggleMyglobalVillageButton = () => {

        if (joinMyglobalVillageButtonRef.current.open) {
            joinMyglobalVillageButtonRef.current.close();
        } else {
            joinMyglobalVillageButtonRef.current.showModal();
        }
    }

    useEffect(() => {
        getFormulaireList();
        getCycleLang();
    }, [i18n.resolvedLanguage]);

    return <div className="max-w-7xl mx-auto ">

        <button
            onClick={handleToggleMyglobalVillageButton}
            className="bg-pink-700 rounded-full px-4"> Choisissez votre formule de participation annuelle
        </button>

        <dialog
            id="dialog"
            className="rounded-3xl dialog max-w-4xl w-full backdrop:bg-black/50 backdrop:backdrop-blur-md"
            ref={joinMyglobalVillageButtonRef}>

            <div className="p-3 ">
                <div className="flex justify-between rounded-full ">
                    <div>
                        <div className=" mb-1 text-3xl font-bold flex items-center ">
                            <img src={cycleLang.attachedFilesCollection.find(attachedFile => attachedFile.id_attached_file_type == 65).small} alt="" className="w-20" /> Plan d'actions collectives -  {cycleLang.nom_cycle_lang}
                        </div>
                        <p className="font-bold mb-3 mt-5">Sélectionnez la formule de participation annuelle correspondant à vos objectifs</p>
                    </div>
                    <button className="w-10 h-10 rounded-full" onClick={handleToggleMyglobalVillageButton}>  X</button>
                </div>

                <FormulairesSection setFormulaireSelected={setFormulaireSelected} />

                {/* <StatutSection /> */}

                {/* {id_cycle_lang && <SelectedCycleLang cycleLang={cycleLangList && cycleLangList.find(cycleLang => cycleLang.id_cycle_lang === id_cycle_lang)} />} */}

                {!cycleLangList && <Loader />}

                <ul className="mt-3 divide-y pl-5 d-none">
                    {!id_cycle_lang && cycleLangList && cycleLangList.map((cycle_lang, i) => (
                        <li
                            key={i}
                        >
                            <label className={`flex items-center space-x-1 py-1  font-normal hover:text-pink-600 ${cycle_lang.id_cycle_lang == cycleLangSelected ? 'text-pink-600' : ''} text-sm`} key={i} value={cycle_lang.id_cycle_lang}>
                                <input type="radio"
                                    name="cl" id=""
                                    checked={cycle_lang.id_cycle_lang == cycleLangSelected}
                                    onChange={() => setCycleLangSelected(cycle_lang.id_cycle_lang)} />

                                <img src={cycle_lang.logo_better.tiny} alt="" className="w-6" />
                                {cycle_lang.nom_cycle_lang}</label>
                        </li>
                    ))}
                </ul>

                {/* <div className="mt-10 flex  space-x-10">
                    <a rel="noreferrer noopener"
                        className={`${!cycleLangSelected ? 'pointer-events-none bg-neutral-400' : 'bg-pink-700'} mx-auto flex space-x-4 text-white px-4 py-2 rounded-full`}
                        target="_blank"
                        href={`/login-redirect?redirectUrl=https://www.myglobalvillage.com/signup/onboarding-initial/c/{id_contact}?formisvisible=1&id_formulaire=13&form-name=onboarding-initial&lng=fr&id_cycle_lang_preselection_list=${id_cycle_lang},${cycleLangSelected} `}>  Suivant <ChevronDoubleRightIcon className="text-xs w-4 ml-3" />
                    </a>
                </div> */}

                <div className="mt-10 flex  space-x-10">
                    <a rel="noreferrer noopener"
                        className={`${!formulaireSelected ? 'pointer-events-none bg-neutral-400' : 'bg-pink-700'} mx-auto flex space-x-4 text-white px-4 py-2 rounded-full`}
                        target="_blank"
                        href={`https://www.myglobalvillage.com/signup/${formulaireSelected?.route}/?form-name=${formulaireSelected?.route}&formisvisible=1&id_formulaire=${formulaireSelected?.id_formulaire}&lng=fr&id_cycle_lang_preselection_list=${id_cycle_lang}`}>  Suivant <ChevronDoubleRightIcon className="text-xs w-4 ml-3" />
                    </a>
                </div>



            </div>
        </dialog>
    </div>
}

export default JoinMyglobalVillage;