import { useParams } from "react-router";
import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { API_URL } from "../config";
import { Link } from "react-router-dom";

function ConferenceDetails() {
  const [videoConference, setVideoConference] = useState([]);
  const [videos, setVideos] = useState([]);
  let { id_conference } = useParams();
  let { idEvent } = useParams();

  const fetchVideosConfEvent = async () => {
    const url = ` ${API_URL}?action=getConfEvent&filter= ce.id_conf_event=${id_conference}`;

    await fetch(url)
      .then((res) => res.json())
      .then((conf) => {
        setVideoConference(conf);
        console.log(conf);
      });
  };

  const fetchVideosConferences = async () => {
    const url = ` ${API_URL}?action=getVideosConferences&filter=WHERE id_conf_event=${id_conference}`;

    await fetch(url)
      .then((res) => res.json())
      .then((conf) => {
        conf.map((conf) => fetchVideos(conf.id_video.id_video));
      });
  };

  const fetchVideos = async (id_video) => {
    const url = ` ${API_URL}?action=getVideosEvents&id_video=${id_video}`;

    await fetch(url)
      .then((res) => res.json())
      .then((video) => setVideos(video));
  };

  useEffect(() => {
    fetchVideosConferences();
    fetchVideosConfEvent();
  }, []);

  return (
    <Layout>
      <div className="container">
        {videoConference[0] && (
          <div>
            <h1>{videoConference[0].conf_event_lang?.cel_titre}</h1>
            <span>{videoConference[0].conf_event_date}</span>
          </div>
        )}
        {videos &&
          videos.map((video) => (
            <div className="card" style={{ width: "18rem" }}>
              <iframe
                className="card-img-top"
                src={video.id_video.embed_url}
                title="video de la conférence"
              ></iframe>
              <div className="card-body">
                <h5 className="card-title">{video.id_video.titre}</h5>
                <p className="card-text">{video.id_video.date_creation}</p>
                <Link
                  className="btn btn-primary"
                  to={`/events/${idEvent}/replay/${video.id_video.id_video}/replay-details`}
                >
                  Plus de détails sur cette vidéo
                </Link>
              </div>
            </div>
          ))}
      </div>
    </Layout>
  );
}

export default ConferenceDetails;
