import { useEffect, useState } from "react";

const useSectionIsVisible = () => {
    const [sectionIsVisible, setSectionIsVisible] = useState(false);
    useEffect(() => {
    }, [])

    return { sectionIsVisible, setSectionIsVisible };
}


export default useSectionIsVisible