import React, { useEffect, useContext } from "react";
import EventContext from "../contexts/EventContext";
import moment from "moment";
import Loader from "./Loader";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import Moment from "react-moment";

const EventItem = ({ event, logo }) => {

  const { t, i18n } = useTranslation();

  return <Link
    className="group h-full"
    to={`/events/${event.id_event}`}
  >
    <div className=" overflow-hidden card group rounded-2xl border-x border-y  bg-neutral-100  dark:border-neutral-800 dark:bg-neutral-900" style={{ height: '100%' }}>
      <img
        style={{ objectFit: 'cover' }}
        src={logo}
        className="rounded-t-2xl scale-105"
        alt={event.nom} />
      <div className="card-body dark:bg-neutral-900 dark:text-gray-200 shadow-slate-50">
        <h3 className=" group-hover:text-pink-500 text-2xl">{event.nom}</h3>
        <span>

          {event.precision_date != "" ? event.precision_date : <Moment locale="fr" format="dddd D MMMM YYYY">{event.event_start}</Moment>}

        </span><br />
        <span className="hidden">{event.lieu?.lieu_nom}</span>
      </div>
      <div className=" dark:bg-neutral-900 rounded-b-2xl px-4">
        <p className="mt-4 rounded-full px-4 py-2 my-2 border-x border-y border-neutral-800 bg-neutral-900 group-hover:bg-pink-700 group-hover:border-pink-700 bg-primary-500 text-white hover:bg-primary-600 transition duration-200 ease-in-out">

          {t('global.en_savoir_plus')}

        </p>
      </div>
    </div>
  </Link>
}

function NextEvent() {

  const eventContext = useContext(EventContext);

  const { getEvents, events } = eventContext;

  useEffect(() => {
    getEvents("&futur=y");
  }, []);

  const primaryOptions = {
    type: "loop",
    perPage: 5,
    breakpoints: {
      1000: {
        perPage: 2,
      },
      500: {
        perPage: 1,
      },
    },
    perMove: 1,
    pagination: false,
    gap: 10,
    autoplay: true
  };

  // return (
  //   <div className="container">
  //     {events.length > 0 ? (

  //       <div options={primaryOptions} className="grid grid-cols-1 logo_slider gap-2 lg:grid-cols-5">
  //         {events
  //           .filter(
  //             (event) =>
  //               moment(event.event_start).isAfter() &&
  //               event.afficher !== "n" &&
  //               event.event_ligne?.id_event_format?.id_event_format !== 4
  //           )
  //           .map((event) => {

  //             const logo = event.logos.large ? event.logos.large : event.event_ligne.el_visuels.large;

  //             return (
  //               <div
  //                 className="d-flex  justify-content-start"
  //                 key={event.encrypted_id_event}
  //               >
  //                 <EventItem event={event} logo={logo} />
  //               </div>
  //             );
  //           })}
  //       </div>) : (
  //       <Loader />
  //     )}
  //   </div>
  // );

  return (
    <div className="container">

      {events.length > 0 ? (
        <div>
          <Splide options={primaryOptions} className="logo_slider">
            {events
              .filter(
                (event) =>
                  moment(event.event_start).isAfter() &&
                  event.afficher !== "n" &&
                  event.event_ligne?.id_event_format?.id_event_format !== 4
              )
              .map((event) => {

                const logo = event.logos.large ? event.logos.large : event.event_ligne.el_visuels.large;

                return (
                  <SplideSlide
                    className="d-flex  justify-content-start"
                    key={event.encrypted_id_event}
                  >
                    <EventItem event={event} logo={logo} />

                  </SplideSlide>
                );
              })}
          </Splide>
        </div>
      ) : (
        <Loader />
      )}


      <div className="pt-5">
        <Link to="/calendar" className="mt-5 bg-pink-700 text-white py-3 px-5 rounded-full">{t('nav.next_events')}</Link></div>
    </div>



  );
}

export default NextEvent;