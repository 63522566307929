import React from 'react'
import Layout from './Layout'
import { useState } from 'react';
import useApiUrlTranslation from './useApiUrlTranslation';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import HTMLReactParser from 'html-react-parser';
import { sortByAttribute } from '../utils';
import Loader from './Loader';
import { Link } from '@material-ui/core';

const CommunauteItem = ({ communaute, contactList, eventCycleLang }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [communauteList, setCommunaute] = useState(false);

    const getCommunaute = async () => {

        await fetch(`${apiUrlTranslation}?action=getCommunaute&filter= AND c.id_contact="${communaute.contact.id_contact}" AND ecl.id_event_cycle_lang=${eventCycleLang.id_event_cycle_lang}`)

            .then(response => response.json())
            .then(data => {
                setCommunaute(data);
            })
    }

    useEffect(() => {
        getCommunaute();
    }, [i18n.resolvedLanguage])


    if (communauteList && !communauteList.map(item => item.id_communaute.id_event_cycle_lang).includes(eventCycleLang.id_event_cycle_lang)) return false;

    return <tr className='' key={communaute.id_conferencier.id_conferencier} >

        <td className='hidden'>{eventCycleLang.id_event_cycle_lang}*{communauteList && communauteList.map(item => item.id_communaute.id_event_cycle_lang)}</td>
        <td className="pl-5"><img src={communaute.contact.photos.small} alt="" className="w-8 h-8 rounded-full" />
            {/* {communaute.contact.id_contact} 
             */}
        </td><td className="ml-4 text-sm text-gray-500">{communaute.contact.prenom}</td>
        <td className="ml-4 text-sm text-gray-500"> {communaute.contact.nom}</td>
        <td className="ml-4 text-sm text-gray-500"> {communaute.contact.fonction}</td>
        <td className="ml-4 text-sm text-gray-500"> {communaute.contact.societe}</td>
        <td className="ml-4 text-sm text-gray-500">
            {communaute.contact.pays && <div className="flex items-center space-x-1">
                <img src={communaute.contact.flag} alt="" className="w-4 h-6" />
                <span>{communaute.contact.pays}</span>
            </div>}

            {/* {communaute.contact.id_contact}  - {communaute.id_conferencier.id_conferencier} */}
        </td>
        <td className="ml-4 text-sm text-gray-500 hidden">{communaute.id_conferencier.statut?.libelle}
            <ul className="text-xs text-gray-300 divide-y">
                <li>id_event_contact_type : {communaute.id_conferencier.statut?.id_event_contact_type}</li>
                {/* <li>id_conf_event : {communaute.id_conferencier.id_conf_event}</li> */}
                <li> id_contact: {communaute.contact.id_contact} </li>
                <li> id_conferencier: {communaute.id_conferencier.id_conferencier} </li>
                {/* <li>id_cycle_lang : {communaute.cycle_lang?.id_cycle_lang}</li>
                                    <li>id_event_cycle_lang : {communaute.id_communaute?.id_event_cycle_lang}</li> */}
            </ul>

            {contactList.map(communaute_item => <ul className="divide-y">
                <li>conferencier_communaute : {communaute_item.conferencier_communaute}</li>
                <li>id_contact: {communaute_item.contact.id_contact}</li>
                <li> id_cycle_lang: {communaute_item.id_cycle_lang}</li>
            </ul>)}

        </td>
    </tr>


}

const CandidatList = ({ communaute, confEvent, juryDemoList }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    useEffect(() => {

    }, [])

    if (!juryDemoList || juryDemoList
        .filter(juryDemo => juryDemo.jury_event.conf_event.id_conf_event == confEvent.id_conf_event)
        .filter(juryDemo => communaute.map(item => item.contact.id_contact).includes(juryDemo.presta.id_contact.id_contact)).length == 0
    ) {
        return false;
    }

    return <div>

        {/* {JSON.stringify(communaute.map(item => item.contact.id_contact))} */}
        {/* 
        {juryDemoList
            .filter(juryDemo => juryDemo.jury_event.conf_event.id_conf_event == confEvent.id_conf_event)
            .filter(juryDemo => communaute.map(item => item.contact.id_contact).includes(juryDemo.presta.id_contact.id_contact)).length

        } */}

        <table className="w-full">
            <tr className="border-b border-gray-200 ">
                <th colSpan="5" scope="colgroup" className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3 first-letter:capitalize">{t('global.les_candidats')}</th>
            </tr>
            <tbody className="divide-y">
                {juryDemoList && juryDemoList
                    .filter(juryDemo => juryDemo.jury_event.conf_event.id_conf_event == confEvent.id_conf_event)
                    .filter(juryDemo => communaute.map(item => item.contact.id_contact).includes(juryDemo.presta.id_contact.id_contact))
                    .map((juryDemo, index) => <tr key={index}>

                        <td className='hidden'>{communaute.map(item => item.contact.id_contact).includes(juryDemo.presta.id_contact.id_contact) ? 'true' : 'false'}</td>
                        <td className="text-sm text-gray-500 pl-5">{juryDemo.presta?.id_contact.societe}</td>
                        <td className="hidden"> {juryDemo.jury_event?.conf_event?.id_conf_event}</td>
                        <td className="text-sm text-gray-500">{juryDemo.presta?.presta_nom}</td>
                        <td><img src={juryDemo.presta?.visuels.small} className="w-10 h-10 rounded" />
                            {/* {juryDemo.presta.id_contact.id_contact} */}
                        </td>
                        <td className="">

                            <div className="text-xs text-gray-500 pl-5 flex items-center space-x-1">


                                <img src={juryDemo.presta?.id_contact.flag} alt="" className="w-6" />
                                <span>{juryDemo.presta?.id_contact.pays}</span>
                            </div></td>

                        <td>
                            <a className="mx-3 border-x border-y text-nowrap px-2 rounded-full py-1 text-sm" target="_blank" href={`/events/${confEvent.id_event.id_event}/programm/${juryDemo.presta?.id_presta}/demo-details`}>{t('global.en_savoir_plus')} </a></td>

                        <td className="hidden">
                            {/* {JSON.stringify(communaute.filter(item => item.contact.id_contact == juryDemo.presta.id_contact.id_contact))} */}

                            {communaute.map(communaute_item => <ul>
                                <li>id_cycle_lang : {communaute_item.cycle_lang.id_cycle_lang}</li>
                                <li>id_event_cycle_lang : {communaute_item.id_communaute.id_event_cycle_lang}</li>
                                <li>id_contact : {communaute_item.contact.id_contact}</li>
                            </ul>)} </td>
                    </tr>)}
            </tbody>
        </table>
    </div>

    return <table class="table">

        {juryDemoList && juryDemoList.map(juryDemo => {
            <tbody>   <tr class="border-b border-gray-200">
                <th colspan="5" scope="colgroup" class="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Les candiats</th></tr>
                <tr class=""><td>
                    <img src="https://www.mlg-consulting.com/manager_cc/contacts/img_uploaded/thumbs/photo_225275_231205161024_200x0.png" alt="" class="w-8 h-8 rounded-full" /></td>
                    <td class="ml-4 text-sm text-gray-500">Huberteeeeeeeeeeeeee </td><td class="ml-4 text-sm text-gray-500"> GOFFINET </td>
                    <td class="ml-4 text-sm text-gray-500"> Trade &amp; Investment Commissioner</td>
                    <td class="ml-4 text-sm text-gray-500"> Wallonia Export &amp; Investment Agency - SF</td>
                    <td class="ml-4 text-sm text-gray-500">Coordinateur de la délégation<ul class="text-xs text-gray-300">
                        <li>id_event_contact_type : 202</li>
                        <li>id_conf_event : </li><li> id_contact: 225275 </li><li> id_conferencier: 260991 </li></ul>
                    </td>
                </tr>
            </tbody>
        })}

    </table>


}

const DisplayCommunaute = ({ communaute, title, partenaireList, confEvent, eventCycleLang, idEventContactTypeList = [] }) => {

    const [contactList, setContactList] = useState(false);

    const { idEvent, id_cycle_lang } = useParams();

    useEffect(() => {

        const newContactList = partenaireList && partenaireList
            .filter(item => item.id_conf_event.id_conf_event == confEvent.id_conf_event)
            .map(item => {

                return { contact: item.contact, id_conferencier: { ...item, statut: item.conferencier_statut, id_conf_event: item.id_conf_event.id_conf_event } }
            });

        setContactList([...newContactList, ...communaute]);

    }, [])


    if (
        !contactList || contactList.filter(communaute => communaute.id_conferencier.id_conf_event == confEvent.id_conf_event)
            .filter(communaute => idEventContactTypeList.includes(communaute.id_conferencier.statut.id_event_contact_type)).length == 0

    ) {
        return false;
    }

    return (<>

        {/* {contactList && contactList.map(communaute => <div>  {communaute.contact.prenom}  {communaute.contact.nom}
            {communaute.id_conferencier.id_conf_event}
            {communaute.id_conferencier.statut.libelle}
            {communaute.id_conferencier.conferencier_statut.libelle}</div>)} */}



        <table className="w-full">
            <tbody className=''>

                <tr className="border-b border-gray-200">
                    <th colSpan="5" scope="colgroup" className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">{title}</th>
                </tr>

                {contactList && contactList

                    //dedup by communaute.contact.id_contact
                    .filter((communaute, index, self) =>
                        index === self.findIndex((t) => (
                            t.contact.id_contact === communaute.contact.id_contact
                        ))
                    )
                    .filter(communaute => communaute.id_conferencier.id_conf_event == confEvent.id_conf_event)
                    .filter(communaute => idEventContactTypeList.includes(communaute.id_conferencier.statut.id_event_contact_type))
                    //.filter(communaute => communaute.id_cycle_lang == id_cycle_lang)
                    .map(communaute => {

                        return <CommunauteItem
                            eventCycleLang={eventCycleLang}
                            communaute={communaute}
                            contactList={contactList}
                        />
                    })}
            </tbody>
        </table>
    </>
    )
}

const CycleLangProgramme = ({ communaute, title, juryDemoList, partenaireList, eventCycleLang, confEventTypeList = [] }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [programme, setProgramme] = useState(false);

    const getConfEvent = async () => {
        await fetch(`${apiUrlTranslation}?action=getConfEvent&filter=id_event=1789 AND publier !='n' AND selectable !='n' AND type NOT IN (92) AND type IN (67, 101)`)
            .then(res => res.json())
            .then(res => {

                let sortedList = sortByAttribute(res, 'conf_event_date_local', 'heure_debut_local');

                sortedList = sortedList.filter(confEvent =>
                    confEvent.publier != 'n' &&
                    confEvent.selectable != 'n'
                    && confEventTypeList.includes(confEvent.type)
                );

                setProgramme(sortedList);
            })
    }

    useEffect(() => {
        getConfEvent();
    }, [i18n.resolvedLanguage])

    if (!programme) return <Loader />
    return <div>

        <h2 className="font-bold text-4xl my-5 text-white first-letter:capitalize">{title} </h2>

        <div className="divide-y divide-neutral-700">
            {programme && programme.map(confEvent => <div className="bg-white rounded">
                <div className='bg-blue-50  flex text-left  justify-between p-2  '>

                    <div>  <p className="text-xl ">{confEvent.conf_event_lang.cel_titre}</p>
                        <div className="text-neutral-400">{HTMLReactParser(confEvent.localTimeZone)}</div>
                    </div>

                    <div className='flex items-center'>
                        <a target="_blank" className="bg-blue-500 text-white flex rounded-full whitespace-nowrap px-3 items-center py-1 " href={`/events/${confEvent.id_event.id_event}/programm/${confEvent.id_conf_event}/parcours-details`}>{t('global.en_savoir_plus')}</a>
                    </div>
                </div>

                <div>

                    <DisplayCommunaute
                        eventCycleLang={eventCycleLang}
                        confEvent={confEvent}
                        partenaireList={partenaireList}
                        communaute={communaute}
                        title="Les coordinateurs"
                        idEventContactTypeList={["250"]} />

                    <CandidatList
                        confEvent={confEvent}
                        juryDemoList={juryDemoList}
                        communaute={communaute} />

                    <DisplayCommunaute
                        eventCycleLang={eventCycleLang}
                        confEvent={confEvent}
                        partenaireList={partenaireList}
                        communaute={communaute}
                        title={t('event_nav.membres_du_jury')}
                        idEventContactTypeList={["92"]} />

                    <DisplayCommunaute
                        eventCycleLang={eventCycleLang}
                        confEvent={confEvent}
                        partenaireList={partenaireList}
                        communaute={communaute}
                        title="Speakers" />
                </div>

            </div>)}
        </div>
    </div>
}

const EventCycleLang = ({ eventCycleLang }) => {

    const { idEvent } = useParams();

    const [communaute, setCommunaute] = useState([]);

    const [juryDemoList, setJuryDemoList] = useState(false);

    const [partenaireList, setPartenaireList] = useState(false);

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    useEffect(() => {

        const getPartenaires = async () => {

            await fetch(`${apiUrlTranslation}?action=getPartenaires&params=AND  id_event='${idEvent}'`)

                .then(response => response.json())
                .then(data => {
                    setPartenaireList(data);
                })
        }

        const getJuryDemoList = async () => {

            await fetch(`${apiUrlTranslation}?action=getJuryDemoList&params= WHERE id_event=${idEvent}`)
                .then(response => response.json())
                .then(data => {
                    setJuryDemoList(data);
                })
        }

        const getCommunaute = async () => {

            await fetch(`${apiUrlTranslation}?action=getCommunaute&filter= AND ecl.id_event_cycle_lang=${eventCycleLang.id_event_cycle_lang}`)

                .then(response => response.json())
                .then(data => {
                    setCommunaute(data);
                })
        }
        getPartenaires();
        getJuryDemoList();
        getCommunaute(eventCycleLang.id_event_cycle_lang);

    }, [eventCycleLang.id_event_cycle_lang, i18n.resolvedLanguage])

    if (!partenaireList || !juryDemoList) return <Loader />;

    return <>
        <div className="mx-3 lg:mx-0 ">
            <div className="flex flex-col md:flex-row  border-neutral-400 pb-5">
                <div className="mr-2 ml-2 lg:mr-10">
                    <img
                        className="w-36 rounded"
                        src={eventCycleLang.cycle_lang.attachedFilesCollection.find(item => item.id_attached_file_type == 65).small} />
                </div>
                <div className="basis-5/6">
                    <span className="font-bold text-6xl pb-5 animatedgradient dark:text-white">
                        {t('pages.pays_recap.title', { event_cycle_lang_nom: eventCycleLang.event_cycle_lang_nom })}


                    </span>
                </div>
            </div>

            <Coordinateurs communaute={communaute} />

            <CycleLangProgramme
                eventCycleLang={eventCycleLang}
                partenaireList={partenaireList}
                juryDemoList={juryDemoList}
                communaute={communaute}
                confEventTypeList={["67"]}
                title={t('event_nav.pitching_session')} />

            <CycleLangProgramme
                partenaireList={partenaireList}
                juryDemoList={juryDemoList}
                communaute={communaute}
                confEventTypeList={["101"]}
                title="Blitz pitch" />

            {/* <h3>les inscrits</h3>

            <h2 className="font-bold text-4xl my-5 ">sur les delegatuion </h2>
            <h3>Les particpants</h3> */}

        </div>

        <tr key={eventCycleLang.id_event_cycle_lang} className="hover:bg-neutral-100 hidden">

            <td className="whitespace-nowrap border-l-2 border-neutral-400 text-center">
                <a
                    className="rounded-full bg-blue-500 hover:bg-blue-700 text-white py-2 px-3 text-sm"
                    href={`https://www.myglobalvillage.com/events/${idEvent}/login-redirect?redirectUrl=https://www.myglobalvillage.com/events/${idEvent}/signup/jury/c/{id_contact}?formisvisible=1&id_formulaire=11&form-name=jury&lng=fr`}>
                    {t('event_nav.devenir_jury')}
                </a>
            </td>
            <td className="whitespace-nowrap border-l-2 border-neutral-400 text-center">
                <a
                    className=" rounded-full bg-blue-500 hover:bg-blue-700 text-white py-2 px-3 text-sm "
                    href={`https://www.myglobalvillage.com/events/${idEvent}/login-redirect?redirectUrl=https://www.myglobalvillage.com/events/${idEvent}/signup/startup/c/{id_contact}?formisvisible=1&id_formulaire=26&form-name=startup&lng=fr`}>
                    {t('event_nav.devenir_candidat')}
                </a>
            </td>
        </tr>
    </ >

}


const Coordinateurs = ({ communaute }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    useEffect(() => {

    }, [i18n.resolvedLanguage])

    return <div className="mb-5 lg:border-x lg:border-y border-neutral-900 rounded p-0 lg:p-10">
        <div className="font-bold text-4xl pb-2  dark:text-white first-letter:capitalize"> {t('pages.pays_recap.comite_coordination')}</div>
        {
            communaute && communaute
                //dedup by communaute_item.contact.id_contact
                .filter((communaute_item, index, self) =>
                    index === self.findIndex((t) => (
                        t.contact.id_contact === communaute_item.contact.id_contact
                    ))
                )
                .filter(communaute_item => ["207"].includes(communaute_item.id_conferencier.statut.id_event_contact_type))
                .map(communaute_item => <div className="flex flex-col lg:flex-row items-center border-b border-neutral-900 ">

                    <div className="mr-3 ml-3 lg:mr-1 ">
                        <img className='w-14 rounded' src={communaute_item.contact.photos.small} alt="" />
                    </div>
                    <div className="  basis-5/6 m-3">

                        <div className=' dark:text-white'> {communaute_item.contact.nom} {communaute_item.contact.prenom}, {communaute_item.contact.societe}</div>
                        <div className=" dark:text-gray-400 hidden">{HTMLReactParser(communaute_item.contact.edito_long)}</div>
                        <div className="dark:text-gray-400 ">{communaute_item.id_conferencier.statut.libelle}</div>
                        <div className="hidden">{communaute_item.id_conferencier.statut.id_event_contact_type}</div>
                    </div>
                </div>)
        }
    </div>

}
const PaysRecapDetails = () => {

    const [eventCycleLangList, setEventCycleLangList] = useState([])

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const { idEvent, id_cycle_lang } = useParams();

    const getEventContactTypeList = async () => {

        await fetch(`${apiUrlTranslation}?action=getEventCycleLangList&params=WHERE id_cycle_lang="${id_cycle_lang}" AND id_event="${idEvent}"`)
            .then(response => response.json())
            .then(data => {

                setEventCycleLangList(data);
            })
    }

    useEffect(() => {

        getEventContactTypeList();

    }, [i18n.resolvedLanguage])

    return (
        <Layout title="" className="">

            <div className="max-w-7xl mx-auto">

                {eventCycleLangList
                    .filter(eventCycleLang => ["collectif_geographique", "pays_region_entreprise_invite"].includes(eventCycleLang.forum))
                    .map(eventCycleLang =>
                        <EventCycleLang eventCycleLang={eventCycleLang} key={eventCycleLang.id_event_cycle_lang} />
                    )}
            </div>

        </Layout>
    )
}

export default PaysRecapDetails