import React from 'react'


const ConferencierItem = ({ contact, conferencier, smallLayout }) => {

    return smallLayout ? <div className="border flex md:flex-row flex-col p-2 m-1 rounded shadow-sm bg-white">
        <div className="">
            {conferencier.contact.photos.small && <div className=''><img src={conferencier.contact.photos.small} alt={conferencier.contact.societe} className='rounded-full  w-10 h-10 ' /></div>}
            {conferencier.contact.logos.small && <div className=''><img src={conferencier.contact.logos.small} alt={conferencier.contact.societe} className=' w-10 h-10 object-contain' /></div>}
        </div>

        <div className='px-1'>

            <p className='font-bold text-xs'>{conferencier.contact.prenom}  {conferencier.contact.nom}</p>
            <span className='uppercase text-xs'>{conferencier.contact.societe}</span>
            <p className='text-xs hidden'>{conferencier.contact.fonction}</p>
            <p className='hidden'>{conferencier.id_conferencier}</p>

            <div className='justify-between mt-2 hidden'>
                <p className='text-neutral-700 py-1 bg-gray-200 text-xs' title={conferencier.statut.id_event_contact_type}>**{conferencier.statut.libelle} - id: {conferencier.statut.id_event_contact_type}**</p>
                <div className='flex hidden'><img src={conferencier.contact.flag} alt="" className='w-6 mr-3' />
                    {conferencier.contact.pays}

                </div>
            </div>
            <p className='text-neutral-500 hidden'>{conferencier.contact.mail} <br />{conferencier.contact.port}</p>



        </div>

    </div>

        : <div className="flex border rounded shadow-sm p-2 bg-white">
            <div>
                {conferencier.contact.photos.small && <div className='flex justify-center'><img src={conferencier.contact.photos.small} alt={conferencier.contact.societe} className='  w-20 h-20 object-contain' /></div>}
                {conferencier.contact.logos.small && <div className=''><img src={conferencier.contact.logos.small} alt={conferencier.contact.societe} className=' w-20 h-20  object-contain' /></div>}
            </div>

            <div className='px-3'>

                <p className='font-bold'>{conferencier.contact.prenom}  {conferencier.contact.nom}</p>
                <span className='uppercase'>{conferencier.contact.societe}</span>
                <p className='hidden'>{conferencier.contact.fonction}, {conferencier.contact.societe}</p>
                <p className='hidden'>id_contact : {conferencier.contact.id_contact} - id_conf: {conferencier.id_conferencier}</p>

                <div className='justify-between mt-2 hidden'>
                    <p className='text-neutral-700 text-sm py-1  bg-gray-200' title={conferencier.statut.id_event_contact_type}>**{conferencier.statut.libelle} - id: {conferencier.statut.id_event_contact_type}**</p>
                    <div className='flex hidden'><img src={conferencier.contact.flag} alt="" className='w-6 mr-3' />
                        {conferencier.contact.pays}

                    </div>
                </div>
                <p className='text-neutral-500 hidden'>{conferencier.contact.mail} <br />{conferencier.contact.port}</p>

            </div>

        </div>

}

export default ConferencierItem