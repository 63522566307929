import React, { useEffect, useState } from "react";
import LayoutAccount from "./LayoutAccount";
import { useParams } from "react-router-dom";
import { API_URL } from "../../config";
import FormInputTextareaRichEditor from "../FormInputTextareaRichEditor";
import EasyCrop from "../EasyCrop";
import { dataURItoBlob, dataUrlToFile } from "../Crop";

const Thematique = ({ thematique }) => {

  return <li className="p-1"><label htmlFor={thematique.id_cycle_lang}><input type="checkbox" name="" id={thematique.id_cycle_lang} /> {thematique.nom_cycle_lang}</label></li>;
}

const DemoCycleLang = ({ demoCycleLangList }) => {

  useEffect(() => {

    console.log('demoCycleLangList', demoCycleLangList)
  }
    , [demoCycleLangList])


  if (!demoCycleLangList) return null;


  return <div className="">
    <h2 className="text-3xl font-semibold text-gray-900 mb-3">Positionnement de ma solution sur les plans d'actions, programmes et thématiques</h2>

    <ul className="pl-3">
      {demoCycleLangList.map(item => <li key={item.id_presta_cycle_lang} className="py-1"> - {item.cycle_lang.nom_cycle_lang} <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">{item.cycle_lang.cycle_lang_type} ( {item.cycle_lang.id_cycle_lang} )</span></li>)}
    </ul>
  </div>
}

const Programme = ({ programme, cycle_lang }) => {

  return <div><div className="border-b bg-light p-3 font-semibold">
    <input type="checkbox" name="" id="" /> {programme.programm_title}
  </div>
    <ul className="d-none">
      {programme.cycle_lang.children.map(thematique => <Thematique key={thematique.id_cycle_lang} thematique={thematique}></Thematique>)}
    </ul>
  </div>

}

const CycleLang = ({ id_cycle_lang, demoCycleLangList, getDemoCycleLangList }) => {

  const { idPresta } = useParams();

  const [cycleLang, setCycleLang] = useState(null);

  const getCycleLangByIdCycleLang = async () => {

    await fetch(`${API_URL}?action=getCycleLangByIdCycleLang&id_cycle_lang=${id_cycle_lang}`)
      .then((response) => response.json())
      .then((data) => {
        setCycleLang(data)
      }
      )
  }

  const handleChange = async (e) => {

    //console.log(e.target.checked, e.target.value);

    await fetch(`${API_URL}?action=updatePrestaCycleLang`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id_presta: idPresta,
        id_cycle_lang: e.target.value,
        is_checked: e.target.checked

      })
    })
      .then(data => getDemoCycleLangList())
  }

  useEffect(() => {
    getCycleLangByIdCycleLang()

  }, [])

  if (!cycleLang) return null;

  return <div>
    <h2 className="text-xl font-semibold text-gray-900 mb-3">Plan d'action {cycleLang.nom_cycle_lang}</h2>

    {cycleLang.children && cycleLang.children
      .filter(child => child.cycle_lang_type == 'collectif_programme')
      .map((child, index) => <div className="my-2">
        <label
          key={child.id_cycle_lang}
          htmlFor={child.id_cycle_lang} className="">
          <input onChange={handleChange}
            type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded mr-3"
            name=""
            value={child.id_cycle_lang}
            id={child.id_cycle_lang}
            checked={demoCycleLangList.map(item => item.id_cycle_lang).includes(child.id_cycle_lang) ? true : false} />
          {child.nom_cycle_lang} ({child.id_cycle_lang})
        </label>
      </div>)}
  </div>

}

const ThematiquesDemoManager = ({ id_cycle_lang, demoCycleLangList, getDemoCycleLangList }) => {

  let { idContact } = useParams();

  const uza = idContact ? { id_contact: idContact } : JSON.parse(window.localStorage.getItem('user'))

  const [programmeInnovationList, setProgrammeInnovationList] = useState([]);

  const [contactCycleLangList, setContactCycleLangList] = useState(null);

  const getProgrammeInnovation = async () => {

    await fetch(`${API_URL}?action=getProgrammeInnovation&id_cycle_lang=2270`)
      .then((response) => response.json())
      .then((data) => {
        setProgrammeInnovationList(data);
      }
      )
  }

  const fetchContactCycleLang = async () => {

    await fetch(`${API_URL}?action=fetchContactCycleLang&filter=contact_cycle_lang.id_contact=${uza.id_contact} AND `)
      .then(resp => resp.json())
      .then(resp => setContactCycleLangList(resp)
      )
  }

  useEffect(() => {
    getProgrammeInnovation()
    // console.log('idContact', idContact);
    fetchContactCycleLang()
  }
    , []);


  return <div>

    <h2 className="text-3xl font-semibold text-gray-900 mb-3">Positionnement de ma solution sur des programmes d'innovations  d'action annuel</h2>

    <div className="alert alert-info">Je coche la ou les cases ci-dessous correspondant à l'univers d'usage de ma solution ou de mon produit :</div>


    {contactCycleLangList && contactCycleLangList
      .filter(contactCycleLang => contactCycleLang.cycle_lang.cycle_lang_type == "collectif_planactionprive")
      .map(contactCycleLang => <CycleLang
        key={contactCycleLang.id_contact_cycle_lang}
        id_cycle_lang={contactCycleLang.cycle_lang.id_cycle_lang}
        demoCycleLangList={demoCycleLangList}
        getDemoCycleLangList={getDemoCycleLangList} />)}


    { /* programmeInnovationList.map(programme => <div><Programme key={programme.id_demande_referencement_programme_innovation} programme={programme}></Programme></div>) */}
  </div>
}

const InputArea = ({ label, textValue, placeHolder, name, changeDemo }) => {

  const [text, setText] = useState(textValue);

  const { idPresta } = useParams();

  const handleChange = (e) => {
    setText(e.target.value);
    changeDemo(e);
    handleSubmit(e.target.value);
  };

  const handleSubmit = async (text) => {
    const data = {
      id_presta: idPresta,
      [name]: text,
    };

    await fetch(`${API_URL}?action=updatePresta`, {
      method: "POST",
      body: JSON.stringify(data),
    })
  }

  return (
    <div className="form-group row m-4">
      <label className="col-sm-3 col-form-label" htmlFor={name}>
        {label}
      </label>
      <div className="col-sm-9">
        <input
          className="form-control rounded-pill"
          name={name}
          type="text"
          placeholder={placeHolder}
          value={text}
          onChange={(e) => handleChange(e)}
        ></input>
      </div>
    </div>
  )
}

const TextArea = ({ label, textValue, placeHolder, name, changeDemo }) => {
  const [text, setText] = useState(textValue);

  const { idPresta } = useParams();

  const handleChange = (e) => {
    setText(e.target.value);
    changeDemo(e);
    handleSubmit(e.target.value);
  };

  const handleSubmit = async (text) => {
    const data = {
      id_presta: idPresta,
      [name]: text,
    }

    await fetch(`${API_URL}?action=updatePresta`, {
      method: "POST",
      body: JSON.stringify(data),
    })
  }

  return (
    <div className="form-group row m-4">
      <label className="col-sm-3 col-form-label" htmlFor={name}>
        {label}
      </label>
      <div className="col-sm-9">


        <textarea
          className="form-control rounded"
          name={name}
          type="text"
          placeholder={placeHolder}
          value={text}
          style={{ overflowY: "scroll" }}
          onChange={(e) => handleChange(e)}
        ></textarea>
      </div>
    </div>
  );
};

const FileArea = ({ label, name, accept, changeDemo }) => {
  const [text, setText] = useState("");

  const { idPresta } = useParams();

  const handleChange = (e) => {
    setText(e.target.value);
    changeDemo(e);
    handleSubmit(e.target.value);
  };

  const handleSubmit = async (text) => {
    const data = {
      id_presta: idPresta,
      [name]: text,
    };

    await fetch(`${API_URL}?action=updatePresta`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  };

  return (
    <div className="form-group row m-4">
      <label className="col-sm-3 col-form-label" htmlFor={name}>
        {label}
      </label>
      <div onChange={(e) => handleChange(e)} className="col-sm-9">
        <input
          className="form-control rounded"
          name={name}
          type="file"
          accept={accept}
        ></input>
      </div>
    </div>
  );
};

const FormCheck = ({ defaultValue, label, name, labels, changeDemo }) => {
  const length = labels.length;

  return (
    <div className="form-group row m-4">
      <label className="col-sm-3 col-form-label" htmlFor={name}>
        {label}
      </label>
      <div className="col-sm-9">
        <div className="form-check">
          {labels.map((label, i) => (
            <FormCheckLabel
              key={i}
              value={i}
              defaultValue={defaultValue}
              name={name}
              label={label}
              length={length}
              changeDemo={changeDemo}
            />
          ))}
        </div>
      </div>
    </div >
  );
};

const FormCheckLabel = ({
  value,
  label,
  name,
  length,
  changeDemo,
  defaultValue,
}) => {

  //Permet de gérer l'affichage du check du bouton radio
  const defaultPackValue = (pack) => {
    switch (pack) {
      case "Pack Basic – 4 mètres carrés":
        return 0;
      case "Pack Basic ? 4 mètres carrés":
        return 0;
      case "Pack Silver – 8 mètres carrés":
        return 1;
      case "Pack Silver ? 8 mètres carrés":
        return 1;
      case "Pack Gold – 12 mètres carrés":
        return 2;
      case "Pack Gold ? 12 mètres carrés":
        return 2;
      case "Pack Spécial - étude spécifique sur devis":
        return 3;
      case "Pack Spécial ? étude spécifique sur devis":
        return 3;
      default:
        return "marche pas";
    }
  };

  const { idPresta } = useParams();

  const handleChange = (e) => {
    changeDemo(e);
    handleSubmit(e);
  };

  const handleSubmit = async (e) => {
    const data = {
      id_presta: idPresta,
      [name]: e.target.value,
    };

    await fetch(`${API_URL}?action=updatePresta`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  };

  return (
    <div onChange={(e) => handleChange(e)} className="form-check">
      <label className="form-check-label">
        <input
          name={name}
          className="form-check-input"
          type="radio"
          checked={
            length > 2
              ? defaultPackValue(defaultValue) === value
              : parseInt(defaultValue) === value
          }
          value={length > 2 ? label : value}
        ></input>{" "}
        {label}
      </label>
    </div>
  );
};

const UserDemoDetails = () => {
  const { idPresta } = useParams();

  const [demo, setDemo] = useState(null);

  const [packValue, setPackValue] = useState(demo?.equipauto_expose);

  const [image, setImage] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [srcFile, setSrcFile] = useState(null);

  const [croppedImage, setCroppedImage] = useState(null);

  const handleImageUpload = async (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
    setSrcFile(e.target.files[0]);
  };


  const handleSubmit = async ({ data, object, fieldName }) => {

    //console.log(data)
    //console.log(fieldName)

    const d = {
      id_presta: idPresta,
      [fieldName]: data,
    };

    await fetch(`${API_URL}?action=updatePresta`, {
      method: "POST",
      body: JSON.stringify(d),
    });
  }




  const handleChange = (e) => {
    const newObject = { ...demo, [e.target.name]: e.target.value };

    setDemo(newObject);
  };


  const [demoCycleLangList, setDemoCycleLangList] = useState([]);

  const getDemoCycleLangList = async () => {

    await fetch(`${API_URL}?action=fetchPrestaCycleLang&id_presta=${idPresta}`)
      .then((response) => response.json())
      .then((data) => {
        setDemoCycleLangList(data);
      }
      )
  }

  const handleSaveFile = async ({ e, file_name, file }) => {

    const formData = new FormData();

    const visuel = document.getElementById(file_name).files[0];
    const name = document.getElementById(file_name).name;

    formData.append('id_presta', idPresta);
    //formData.append(name, visuel);
    formData.append('file_name', file_name);

    formData.append(name, dataURItoBlob(imageData), visuel.name);


    await fetch(`${API_URL}?action=updatePresta`, {
      method: "POST",
      body: formData,
    }).then(() => {
      setCroppedImage(null);
      setImage(null);
    })

  }

  useEffect(() => {
    const fetchDemo = async () => {
      await fetch(`${API_URL}?action=getDemo&id_demo=${idPresta}`)
        .then((res) => res.json())
        .then((res) => setDemo(res));
    };

    fetchDemo();
    getDemoCycleLangList()
  }, []);

  if (!demo) {
    return null;
  }

  return (
    <LayoutAccount>
      <h1 className="text-4xl font-semibold text-gray-900 mb-3">{demo.presta_nom}</h1>

      <div className="">



        <div className="flex">
          <div className="font-bold pr-5"> Visuel : </div>
          <div>
            <img src={croppedImage ?? demo.visuels.large} alt="cover" className="mx-5 w-20 border" />
          </div>
          <div>
            <label className="_coverImage-holder_ text-white font-normal bg-gray-500 rounded-full p-2 px-3">
              Upload Image
              <input
                id="presta_visuel"

                type="file"
                name="presta_visuel"
                onChange={handleImageUpload}
                accept="img/*"
                style={{ display: "none" }}
              />
            </label>
          </div>
        </div>



        {/*
      
        {croppedImage && <img src={croppedImage} alt="cover" className="w-12" />}
        */}
        <EasyCrop
          croppedImage={croppedImage}
          setCroppedImage={setCroppedImage}
          image={image}
          srcFile={srcFile}
          setImageData={setImageData}
        ></EasyCrop>

        {image && <button onClick={(e) => handleSaveFile({ e: e, file_name: 'presta_visuel', file: image })}>sauvegarder</button>}
      </div>

      <InputArea
        label={"Titre accrocheur"}
        placeHolder={"Titre accrocheur"}
        name={"presta_nom"}
        textValue={demo.presta_nom}
        changeDemo={handleChange}

      />

      <InputArea
        label={"Video"}
        placeHolder={"Titre accrocheur"}
        name={"video_url"}
        textValue={demo.video_url}
        changeDemo={handleChange}

      />


      {/*
      <TextArea
        label={
          "Descriptif « vendeur » du pitch de 1min30 qui sera effectué pendant la durée du salon"
        }
        placeHolder={"Votre texte"}
        name={"presta_accroche"}
        textValue={demo.presta_accroche}
        changeDemo={handleChange}
      />
       */}

      <b>Descriptif « vendeur » du pitch de 1min30 qui sera effectué pendant la durée du salon</b>
      <FormInputTextareaRichEditor
        object={demo}
        fieldValue={demo.presta_accroche}
        fieldName={'presta_accroche'}
        setObject={setDemo}
        isRegister={false}
        name={'presta_accroche'}
        handleSubmit={handleSubmit}

      />

      {/*  <FileArea
        label={"Visuel illustrant ma technologie, service ou produit innovant"}
        accept={".jpg,.jpeg,.png"}
        name="presta_visuel"
        changeDemo={handleChange}
      />
          <FileArea
        label={"Support de présentation au format Powerpoint"}
        accept={".ppt,.pptx"}
        name="presta_visuel2"
        changeDemo={handleChange}
      />
      */}

      {/* 
      <TextArea
        label={"Marché(s) d'application"}
        placeHolder={"Votre texte"}
        name={"equipauto_marches_applications"}
        textValue={demo.equipauto_marches_applications}
        changeDemo={handleChange}
      />
      <InputArea
        label={"Date de lancement"}
        placeHolder={"Date de lancement"}
        name={"equipauto_date_lancement"}
        textValue={demo.equipauto_date_lancement}
        changeDemo={handleChange}
      />
      <InputArea
        label={"En quoi ce produit ou service est-il innovant ? "}
        placeHolder={"En quoi ce produit ou service est-il innovant ?"}
        name={"equipauto_innovation_description"}
        textValue={demo.equipauto_innovation_description}
        changeDemo={handleChange}
      />
      <FormCheck
        defaultValue={demo.equipauto_solution_deja_presentee}
        name="equipauto_solution_deja_presentee"
        labels={["Non", "Oui"]}
        label={"Votre produit ou service a-t-il déjà été présenté ? "}
        changeDemo={handleChange}
      />
      <InputArea
        label={"Si oui, à quelle occasion ?"}
        placeHolder={"Si oui, à quelle occasion ?"}
        name={"equipauto_solution_deja_presentee_description"}
        textValue={demo.equipauto_solution_deja_presentee_description}
        changeDemo={handleChange}
      />

      <FormCheck
        defaultValue={demo.equipauto_expose}
        name="equipauto_expose"
        labels={["Non", "Oui"]}
        label={"Votre société est-elle exposante à EQUIP AUTO 2022 ? "}
        changeDemo={handleChange}
      />
      
            <FormCheck
        defaultValue={demo.equipauto_pack_exposant}
        name="equipauto_pack_exposant"
        labels={[
          "Pack Basic – 4 mètres carrés",
          "Pack Silver – 8 mètres carrés",
          "Pack Gold – 12 mètres carrés",
          "Pack Spécial - étude spécifique sur devis",
        ]}
        label={"Choix du Pack exposant"}
        changeDemo={handleChange}
      /> 
       


      <TextArea
        label={"Responsable produit"}
        placeHolder={"Votre texte"}
        name={"equipauto_responsable_produit"}
        textValue={demo.equipauto_responsable_produit}
        changeDemo={handleChange}
      />
       */}

      {/* <textarea className="form-control">{demo.presta_nom}</textarea>

      <textarea className="form-control">{demo.presta_accroche}</textarea>

      <textarea className="form-control">{demo.presta_texte}</textarea>

      <textarea className="form-control">{demo.video_embeded_code}</textarea>

      <input type="text" className="form-control" value={demo.video_url} />

      <textarea className="form-control">{demo.presta_commentaires}</textarea> */}


      <DemoCycleLang demoCycleLangList={demoCycleLangList} />

      {<ThematiquesDemoManager demoCycleLangList={demoCycleLangList} getDemoCycleLangList={getDemoCycleLangList} />}
    </LayoutAccount>
  );
};

export default UserDemoDetails;
