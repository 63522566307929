import React from 'react';
import { useTranslation } from 'react-i18next';
import RegistrationFormInput from './RegistrationFormInput';
import RegistrationFormRow from './RegistrationFormRow'
import RegsistrationFormSection from './RegistrationConfirmation';


const zoneDecorList = [
    { nom: "Zone de contrôle de pilotage  : Management" },
    { nom: "Zone extérieur (cour, quai, zone de réception et expédition)" },
    { nom: "Zone intérieure entrepôt/ rack/ conditionnement" }
]

const RegistrationFormZonesDecors = () => {

    const { t, i18n } = useTranslation();

    return <RegsistrationFormSection
        title="Zone de décor sur le démonstrateur "
        instructions="Je détaille ci-dessous la zone immersive dans lequelle je souhaiterais positionner ma solution.">

        {zoneDecorList.map((zoneDecor, index) =>
            <RegistrationFormInput
                name="zone_decor"
                inputType="radio"
                value={zoneDecor.nom}
                placeholder={zoneDecor.nom} />
        )}
    </RegsistrationFormSection>
};

export default RegistrationFormZonesDecors;