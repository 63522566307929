const FormulaireReducer = (state, action) => {

    switch (action.type) {

        case 'SET_ACTIVE_FORMULAIRE':
            return {
                ...state,
                activeFormulaire: action.activeFormulaire,
            };

        default:
            return state;

    }
}

export default FormulaireReducer