import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import EventContext from '../contexts/EventContext';

const BilanChiffreRow = styled.div`
font-size: 30px;
color: #fff;
}
`

const BilanChiffreItemNumber = styled.div`
font-size: 50px;
font-weight: 800;
}
`


const BilanChiffresCles = () => {

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    const [chiffres, setChiffres] = useState({ studios: 15, pays: 23, constributeurs: 1389, viewers: 17700 });

    useEffect(() => {


        switch (currentEvent.id_event) {

            case '1710': setChiffres({ ...chiffres, ['studios']: 3, ['pays']: 11, ['constributeurs']: 257, ['viewers']: 1144 });
                break;

            case '1723': setChiffres({ ...chiffres, ['studios']: 7, ['pays']: 27, ['constributeurs']: 452, ['viewers']: 21145 });
                break;

            case '1765': setChiffres({ ...chiffres, ['studios']: 5, ['pays']: 15, ['constributeurs']: 117, ['viewers']: 7549 });
                break;

            case '1703': setChiffres({ ...chiffres, ['studios']: 5, ['pays']: 21, ['constributeurs']: 570, ['viewers']: 27000 });
                break;

            case '1695': setChiffres({ ...chiffres, ['studios']: 11, ['constributeurs']: 257 });
                break;
        }



    }, [currentEvent, chiffres])


    return (

        <div className="container">
            <h1 className="text-white display-3" style={{ fontWeight: "800" }}>Bilan : Les chiffres clés de l'édition  2022</h1>

            <BilanChiffreRow className="row">

                <div className="col text-center">
                    <BilanChiffreItemNumber>{chiffres.studios}</BilanChiffreItemNumber> <br /> Studios connectés
                </div>

                <div className="col text-center">
                    <BilanChiffreItemNumber>{chiffres.pays}</BilanChiffreItemNumber> <br /> Pays
                </div>

                <div className="col text-center">
                    <BilanChiffreItemNumber>{chiffres.constributeurs}</BilanChiffreItemNumber> <br /> Contributeurs engagés
                </div>


                <div className="col text-center">
                    <BilanChiffreItemNumber>{chiffres.viewers}</BilanChiffreItemNumber> <br /> viewers
                </div>

            </BilanChiffreRow>

        </div>
    )
}

export default BilanChiffresCles
