import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom';
import { API_URL } from '../../config';
import LayoutAccount from './LayoutAccount'

import FormInputTextareaRichEditor from '../FormInputTextareaRichEditor';

const UserProfile = ({ placeholder }) => {

    const [contact, setContact] = useState(null);

    const [paysList, setPaysList] = useState([]);

    const [contactStatutList, setContactStatutList] = useState([]);

    const [statutList, setStatutList] = useState([]);

    const [newStatut, setNewStatut] = useState("")

    let { idContact } = useParams();


    const [message, setMessage] = useState("")

    const uza = idContact ? { id_contact: idContact } : JSON.parse(window.localStorage.getItem('user'))

    const handleChange = (e) => {

        e.preventDefault();

        console.log(contact);
        let updatedContact = { ...contact };
        updatedContact = { ...contact, [e.target.name]: e.target.value };
        setContact(updatedContact)

        console.log(updatedContact);
    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        const data = {
            formData: contact
        };

        await fetch(`${API_URL}?action=updateContact`, {

            method: 'POST',
            body: JSON.stringify(data)
        })

            .then(res => setMessage(<div className='rounded bg-green-100 text-green-900 p-3 my-3 text-center'>Modification réalisée</div>))

        //updateContactVisuel(e);

    }

    const getStatuts = async () => {

        await fetch(`${API_URL}?action=getStatuts`)
            .then(res => res.json())
            .then(res => setStatutList(res))

    }


    const createContactStatut = async (e) => {

        await fetch(`${API_URL}?action=createContactStatut`, {

            method: 'POST',
            body: JSON.stringify({ id_contact: uza.id_contact, id_statut: newStatut })
        })
            .then(res => res.json())
            .then(res => getContactStatutList())
    }

    const deleteContactStatut = async (e) => {

        e.preventDefault();

        const id_contact_statut = e.currentTarget.getAttribute('id_contact_statut');

        await fetch(`${API_URL}?action=deleteContactStatut`, {

            method: 'POST',
            body: JSON.stringify({ id_contact_statut: id_contact_statut })
        })
            .then(res => res.json())
            .then(res => getContactStatutList())
    }

    const updateContactVisuel = async (e) => {

        const name = e.target.name;

        const visuel = document.getElementById(name).files[0]; //fetch file

        const formData = new FormData();

        formData.append('action', "updateContactVisuel");
        formData.append('id_contact', contact.id_contact);
        formData.append(name, visuel);
        await fetch(`${API_URL}?action = updateContactVisuel`, {

            method: 'POST',
            body: formData,
        })
            .then(res => res.json())
            .then(res => {

                setContact({ ...contact, [name]: res.fileName })
            })
    }

    const getpays = async () => {

        await fetch(`${API_URL}?action=getCountries`)
            .then(res => res.json())
            .then(res => setPaysList(res))
    }

    const getContactStatutList = async () => {

        await fetch(`${API_URL}?action=getContactStatutList&filter=WHERE id_contact = ${uza.id_contact}`)
            .then(res => res.json())
            .then(res => setContactStatutList(res))
    }

    const displayStatuts = () => {

        const statuts = contactStatutList.map(statut => <tr key={statut.id_statut}><td>{statut.statut_nom}</td>
            <td><button className="btn btn-secondary btn-sm" id_contact_statut={statut.id_contact_statut} onClick={deleteContactStatut}><i className="fas fa-trash-alt"></i></button> </td></tr>);

        return <div>

            <button type="button" className="btn btn-secondary mb-3" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Ajouter un nouveau statut
            </button>

            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Ajouter un nouveau statut</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <select className="form-select" onChange={(e) => setNewStatut(e.target.value)}>{statutList.map((statut, index) => <option key={index} value={statut.id_statut}>{statut.statut_nom}</option>)}</select>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                            <button type="button" className="btn btn-primary" onClick={createContactStatut}>Sauvegarder</button>
                        </div>
                    </div>
                </div>
            </div>

            <table className="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>Nom</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>{statuts}</tbody>
            </table>
        </div>;
    }

    useEffect(() => {

        const fetchContact = async () => {

            await fetch(`${API_URL}?action=getContact&id_contact=${uza.id_contact} `)
                .then(res => res.json())
                .then(user => setContact(user))
                .then(getpays())
                .then(getContactStatutList())
                .then(getStatuts())
        }

        uza && fetchContact()
    }, [])

    return (

        <LayoutAccount title="Mon profil">

            {message}

            {contact && <div className="">

                <div className="mt-3 text-right mb-2 pr-3 ">
                    <button type="submit" className="btn btn-primary w-fit" onClick={handleSubmit}>Sauvegarder</button>
                </div>

                <div className="card shadow-sm">
                    <div className="card-body">
                        <div className="row">

                            <div className="col-md-6 flex justify-between space-x-2">

                                <label htmlFor="photo">
                                    Photo
                                    <img alt='profil du contact'
                                        className="rounded rounded-circle w-40" src={`http://www.mlg-consulting.com/manager_cc/contacts/img_uploaded/${contact.photo}`} />

                                </label>

                                <div className="image-upload">

                                    <input id="photo" type="file" name="photo" onChange={(e) => updateContactVisuel(e)} />
                                </div>
                            </div>
                            <div className="col-md-6 flex justify-between space-x-2 border-t">
                                <label htmlFor="logo">
                                    Logo

                                    <img alt='logo de l\"entreprise' className="rounded w-40" src={`http://www.mlg-consulting.com/manager_cc/contacts/img_uploaded/${contact.logo}`} />
                                </label>
                                <div className="image-upload">

                                    <input id="logo" type="file" name="logo" onChange={(e) => updateContactVisuel(e)} />

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <form>
                    <h2 className="h3 mb-4">Informations générales</h2>

                    <div className="card shadow-sm">
                        <div className="card-body">


                            <div className="row">
                                <div className="col-md-4 mb-3">
                                    <div>
                                        <label htmlFor="prenom">Prénom</label>
                                        <input
                                            className="form-control"
                                            name="prenom" id="prenom" type="text" placeholder="Prénom" required=""
                                            value={contact.prenom}
                                            onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <div>
                                        <label htmlFor="nom">Nom</label>
                                        <input
                                            onChange={handleChange}
                                            className="form-control"
                                            name="nom"
                                            id="nom" type="text" placeholder="Nom" required="" value={contact.nom} />
                                    </div>
                                </div>

                                <div className="col-md-4 mb-3">
                                    <div>
                                        <label htmlFor="fonction">Fonction</label>
                                        <input
                                            onChange={handleChange}
                                            className="form-control"
                                            name="fonction"
                                            id="fonction"
                                            type="text"
                                            placeholder="Fonction" required="" value={contact.fonction} />
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="mail">E-mail</label>
                                        <input
                                            onChange={handleChange}
                                            className="form-control" name="mail" id="mail"
                                            type="mail" placeholder="name@company.com" required="" value={contact.mail} />
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="port">Mobile</label>
                                        <input
                                            onChange={handleChange}
                                            className="form-control" name="port"
                                            id="port" type="number" placeholder="+12-345 678 910" required="" value={contact.port} />
                                    </div>
                                </div>
                            </div>


                            <div className="row mb-3">

                                <div className="col-md-4">
                                    <label htmlFor="sn_twitter" className="control-label">Twitter</label>
                                    <input
                                        onChange={handleChange}
                                        type="text" className="form-control"
                                        name="sn_twitter"
                                        id="sn_twitter" placeholder="Twitter" value={contact.sn_twitter} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="sn_linkedin" className="control-label">Linkedin</label>
                                    <input
                                        onChange={handleChange}
                                        type="text" className="form-control"
                                        name="sn_linkedin"
                                        id="sn_linkedin" placeholder="Linkedin" value={contact.sn_linkedin} />
                                </div>
                                <div className="col-md-4 ">
                                    <label htmlFor="sn_facebook" className="control-label">Facebook</label>
                                    <input
                                        onChange={handleChange}
                                        type="text" className="form-control"
                                        name="sn_facebook"
                                        id="sn_facebook" placeholder="Facebook" value={contact.sn_facebook} />
                                </div>
                            </div>


                            <div className="row">
                                <label className="form-label">Bio </label>
                                {

                                    <FormInputTextareaRichEditor
                                        object={contact}
                                        fieldValue={contact.edito_court}
                                        fieldName={'edito_court'}
                                        setObject={setContact}
                                    />

                                }
                                {/** 
                                <CKEditor
                                    className="form-control "
                                    editor={ClassicEditor}
                                    data={contact.edito_court}
                                    onReady={editor => {
                                        // You can store the "editor" and use when it is needed.
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();

                                        const contactUpdated = { ...contact, edito_court: data }
                                        setContact(contactUpdated)
                                        console.log(contactUpdated)

                                        // console.log({ event, editor, data, contact, contactUpdated });
                                    }}
                                    onBlur={(event, editor) => {
                                        console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        console.log('Focus.', editor);
                                    }}
                                ></CKEditor>
                                */}

                                {/**<textarea
                                    onChange={handleChange}
                                    className="form-control " name="edito_court" id="edito_court" cols="30" rows="3" value={contact.edito_court}></textarea> */}
                            </div>
                        </div>
                    </div>

                    <h2 className="h3">Société</h2>

                    <div className="card shadow">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-9 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="societe">Société</label>
                                        <input
                                            onChange={handleChange}
                                            className="form-control " id="societe" name="societe" type="text" placeholder="societe" required="" value={contact.societe} />
                                    </div>
                                </div>

                            </div>


                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="adresse">Adresse</label>
                                        <input
                                            onChange={handleChange}
                                            className="form-control" name="adresse" id="adresse" type="text" placeholder="Adresse" required="" value={contact.adresse} />
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label htmlFor="cp">Code postal</label>
                                        <input
                                            onChange={handleChange}
                                            className="form-control" name="cp" id="cp" type="text" placeholder="Code postal" required="" value={contact.cp} />
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="ville">Ville</label>
                                        <input
                                            onChange={handleChange}
                                            className="form-control" name="ville" id="ville" type="text" placeholder="Ville" required="" value={contact.ville} />
                                    </div>
                                </div>

                                <div className="col-md-2 mb-2">
                                    <div className="form-group">
                                        <label htmlFor="pays">Pays</label>
                                        <select name="pays" className="form-select" value={contact.pays} onChange={handleChange}>

                                            {paysList && paysList.map((pays, index) => <option key={index} value={pays.label}>{pays.value}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <label className="form-label">Descriptif société </label>
                                <FormInputTextareaRichEditor
                                    object={contact}
                                    fieldValue={contact.edito_long}
                                    fieldName={'edito_long'}
                                    setObject={setContact}
                                    isRegister={false}
                                />
                                {/** <textarea
                                    onChange={handleChange}
                                    className="form-control" name="edito_long" id="edito_long" cols="30" rows="3" value={contact.edito_long}></textarea> 
                                    */}

                            </div>


                        </div></div>

                    {/*contactStatutList.some(item => item.id_statut == 80) &&
                        <>
                            <h2 className="h3">Statuts</h2>

                            <div className="card shadow">
                                <div className="card-body">
                                    <div className="row">

                                        {displayStatuts()}
                                    </div>
                                </div>
                            </div>
                        </>
    */  }

                    <div className="mt-3 text-right pr-3">
                        <button type="submit" className="hover:bg-indigo-700" onClick={handleSubmit}>Sauvegarder</button>
                    </div>
                </form>
            </div >}
        </LayoutAccount >

    )
}

export default UserProfile