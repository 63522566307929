import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useFormContext } from "react-hook-form";

const FormInputTextareaRichEditorWithReactHookForm = ({ fieldName, fieldValue, required }) => {

    const { register, setValue } = useFormContext();

    return <><CKEditor

        editor={ClassicEditor}
        data={fieldValue}
        {...register(fieldName, {
            required: required
        })}

        onChange={(event, editor) => {
            setValue(fieldName, editor.getData())
        }}

        onReady={editor => {
            // You can store the "editor" and use when it is needed.
            //console.log('Editor is ready to use!', editor);
        }}

        onBlur={(event, editor) => {
            //console.log('Blur.', editor);
        }}

        onFocus={(event, editor) => {
            //console.log('Focus.', editor);
        }}
    /></>
}

export default FormInputTextareaRichEditorWithReactHookForm