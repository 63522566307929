import React, { useContext, useEffect } from 'react'
import Layout from './Layout'
import EventCycleLangItem from './EventCycleLangItem'
import EventContext from '../contexts/EventContext';
import Loader from './Loader'
import RegistrationLinkListCollectif from './RegistrationLinkListCollectif';
import { Link, useParams } from 'react-router-dom';
import useApiUrlTranslation from "../components/useApiUrlTranslation";

const Contributions = () => {

    const { idEvent } = useParams();

    const { i18n } = useApiUrlTranslation()

    const { currentEventEventCycleLangList, getEventCycleLangList, getPartenaires,
        currentEventContactList } = useContext(EventContext);

    useEffect(() => {
        getEventCycleLangList(idEvent)
        getPartenaires(idEvent)
    }, [i18n.resolvedLanguage])

    return (
        <Layout
            title="Table des contributions">

            <div className='mt my-5 max-w-7xl mx-auto bg-blue-100 border-t-4 border-blue-500 rounded-b text-blue-700 px-4 py-3 shadow-md'>Cette page résume les contributions collaboratives des  différents pays, régions et coordinateurs de myGlobalVillage</div>

            {
                !currentEventEventCycleLangList ? <Loader /> : <div className='bg-gray-300 md:p-2 md:m-3'>
                    {currentEventEventCycleLangList
                        && currentEventEventCycleLangList.map((eventCycleLang) => <EventCycleLangItem
                            RegistrationLinkList={RegistrationLinkListCollectif}
                            key={eventCycleLang.id_event_cycle_lang}
                            eventCycleLang={eventCycleLang}
                            currentEventContactList={currentEventContactList}
                        />)}
                </div>
            }
        </Layout>
    )
}

export default Contributions