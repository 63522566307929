import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_URL } from "../../config";
import UserContext from "../../contexts/UserContext";
import Button from "../Ui/Button";
import LayoutAccount from "./LayoutAccount";

const Demo = ({ demo }) => {

  return (
    <tr>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
        <div className="flex items-center">
          <div className="h-10 w-10 flex-shrink-0">
            <img className="h-10 w-10 rounded-full" src={demo.presta_visuel} alt="" />
          </div>
          <div className="ml-4">
            <div className="font-medium text-gray-900">{demo.presta_nom}</div>
            <div className="text-gray-500">{demo.presta_nom}</div>
          </div>
        </div>
      </td>

      <td>
        <Link to={`demos/${demo.id_presta}`} className="text-indigo-600 hover:text-indigo-900"> éditer</Link>
      </td>
    </tr>
  )
}

const DemoList = () => {
  const userContext = useContext(UserContext);

  const { user } = userContext;

  const [demoList, setDemoList] = useState(null);

  const fetchDemo = async () => {
    await fetch(
      `${API_URL}?action=getPrestaList&params=WHERE id_contact =${user.id_contact}`
    )
      .then((res) => res.json())
      .then((res) => setDemoList(res));
  };

  useEffect(() => {

    if (user) {
      fetchDemo();
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      presta_nom: e.target[0].value,
      id_contact: user.id_contact,
    };

    await fetch(`${API_URL}?action=createPresta`, {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => fetchDemo());
  };

  return (
    <LayoutAccount>

      <form className="form-check" onSubmit={(e) => handleSubmit(e)}>
        <label className="m-2">
          Ajouter une démo
          <input placeholder="Nouvelle demo" className="form-control rounded-pill mt-2" type="text" name="name" />
        </label>
        <Button className="btn btn-primary m-3" type="submit">Ajouter</Button>
      </form>

      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">

              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Démo</th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
                  </tr>
                </thead>
                <tbody>
                  {demoList && demoList.map((demo) => <Demo demo={demo} />)}
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>
    </LayoutAccount>
  )
}

export default DemoList;
