import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { removeDuplicateObjectFromArray } from "../utils";
import Loader from "./Loader";
import PageSection from "./PageSection";
import useApiUrlTranslation from "./useApiUrlTranslation";
import HTMLReactParser from 'html-react-parser';
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import Layout from './Layout'


const Contact = ({ contact, description, cycle_lang }) => {



    return <div className="flex w-full rounded-2xl bg-white items-center justify-center p-3 ">
        <img className="object-contain  p-3 h-52 w-52" src={contact.logos.small}></img>

        {/* {description} */}
        <div className="w-100 line-clamp-3">
            {description != "" ? description : HTMLReactParser(cycle_lang?.cycle_lang_descriptif ?? "")}
        </div>
    </div>
}




const Partenaires = ({ statut = "" }) => {

    const { i18n, apiUrlTranslation } = useApiUrlTranslation();
    const { idEvent } = useParams();


    const [partenaireList, setPartenaireList] = useState(null);

    const [contactCycleLangList, setContactCycleLangList] = useState([]);

    const fetchContactCycleLangList = async ({ idContactList }) => {

        await fetch(`${apiUrlTranslation}?action=fetchContactCycleLang&filter= c.id_contact IN(${idContactList}) AND cl.cycle_lang_type IN('collectif_partenaire') AND & group_by=GROUP BY societe`)
            .then(res => res.json())
            .then(res => {

                const dedupArrayByICL = removeDuplicateObjectFromArray(res, 'id_cycle_lang');

                const part = dedupArrayByICL.map(partenaire => {

                    return {
                        contact: partenaire.contact
                    }
                })

                setContactCycleLangList(res);
            })
    }





    const fetchPartenaireList = async () => {

        // const params = `AND id_event=${idEvent} AND cf.statut in(${statut}) GROUP BY societe`
        const params = `AND id_event=${idEvent}  GROUP BY societe`


        const req = `getPartenaires&params=${params}`;



        await fetch(
            `${apiUrlTranslation}?action=${req}`
        )
            .then((res) => res.json())
            .then((partenaireList) => {
                partenaireList.sort();

                const part = partenaireList.map(partenaire => {

                    return {
                        contact: partenaire.contact,
                    }
                })

                setPartenaireList(part);

                return part;
            })
            .then(part => {
                const idContactList = part.map(partenaire => partenaire.contact.id_contact).join(',');

                fetchContactCycleLangList({ idContactList });
            })
    }


    useEffect(() => {
        fetchPartenaireList();
    }, [idEvent, statut])




    return (
        <Layout>

            <div className="grid grid-cols-6 max-w-7xl mx-auto hidden">
                {partenaireList && partenaireList.map((partenaire, index) =>

                    <Contact contact={partenaire.contact}></Contact>)}
            </div>

            <div className="grid grid-cols-2 max-w-7xl mx-auto gap-2 mt-5">
                {contactCycleLangList && partenaireList ? contactCycleLangList.map((contactCycleLang, index) =>

                    <>
                        {/* {JSON.stringify(contactCycleLang)} */}
                        {/* {JSON.stringify(contactCycleLang.cycle_lang)} */}
                        <Contact
                            cycle_lang={contactCycleLang.cycle_lang}
                            description={contactCycleLang.contact_cycle_lang_profil}
                            contact={contactCycleLang.contact}></Contact>
                    </>) : <Loader />}
            </div>
        </Layout>
    )
}

export default Partenaires