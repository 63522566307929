
const RegistrationFormBodySectionIsvisible = ({

    sectionIsVisible,
    setSectionIsVisible,
    visibleLabel = "Je recherche",
    notVisibleLabel = "Je ne recherche pas" }) => {

    return (
        <div className="flex  space-x-4 p-4">
            <label className="flex items-center space-x-2 font-normal">
                <input
                    checked={!sectionIsVisible}
                    name="sectionIsVisible"
                    onClick={() => setSectionIsVisible(false)}
                    type="radio" className="form-checkbox h-5 w-5 text-indigo-600" />
                <span className="text-gray-700">{notVisibleLabel}</span>
            </label>
            <label className="flex items-center space-x-2 font-normal">
                <input
                    checked={sectionIsVisible}
                    name="sectionIsVisible"
                    onClick={() => setSectionIsVisible(true)}
                    type="radio" className="form-checkbox h-5 w-5 text-indigo-600" />
                <span className="text-gray-700">{visibleLabel}</span>
            </label>


        </div>
    )
}

export default RegistrationFormBodySectionIsvisible 
