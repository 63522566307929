import React, { useContext, useState } from 'react'
import LayoutAccountEvent from './LayoutAccountEvent'
import RegistrationFormRow from '../RegistrationFormRow'
import UserContext from '../../contexts/UserContext';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import useApiUrlTranslation from '../useApiUrlTranslation';

const Attentes = () => {

    const userContext = useContext(UserContext);

    const { t, apiUrlTranslation, i18n } = useApiUrlTranslation()

    const { user } = userContext;

    const [contact, setContact] = useState(false);

    const getContact = async () => {

        await fetch(`${apiUrlTranslation}?action=getContact&id_contact=${user.id_contact}`)
            .then(res => res.json())
            .then(res => {
                setContact(res)
            })
    }

    useEffect(() => {
        getContact()
    }, [])

    if (!contact) return null

    return (
        <LayoutAccountEvent>

            {JSON.stringify(contact)}
            {/* 
            <RegistrationFormRow
                inputType="text"
                name="global_funding_montant_capital_recherche"
                placeholder={t('form.sections.techfund_raiser.montant_capital_recherche')}
                required={false}
                defaultValue={contact?.global_funding_montant_capital_recherche} /> */}

        </LayoutAccountEvent>
    )
}

export default Attentes