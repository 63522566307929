import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../../config";
import Loader from "../Loader";
import RegistrationFormInput from "../RegistrationFormInput";
import RegistrationFormRow from "../RegistrationFormRow";
import RegsistrationFormSection from "../RegistrationConfirmation";
import useApiUrlTranslation from "../useApiUrlTranslation";
import LayoutAccount from "./LayoutAccount";
import UserProgrammeContacts from "./UserProgrammeContacts";
import UserProgrammeThematiques from "./UserProgrammeThematiques";
import UserProgrammeThrematiques2 from "./UserProgrammeThrematiques2";


const UserProgrammeEdit = () => {

  const [programme, setProgramme] = useState(null);

  const { idProgramme } = useParams();

  const { i18n, apiUrlTranslation } = useApiUrlTranslation();


  const handleChange = (e) => {

    e.preventDefault();


    setProgramme({ ...programme, [e.target.name]: e.target.value })
  }

  const handleSubmit = async () => {

    const formData = {
      programme: programme
    }

    await fetch(`${API_URL}?action=updateProgramme`, {
      method: 'POST',
      body: JSON.stringify(formData)
    })
      .then(res => res.json())
  }

  useEffect(() => {

    const fetchProgramme = async () => {
      const result = await axios(`${apiUrlTranslation}?action=getProgrammeInnovation&id_demande_referencement_programme_innovation=${idProgramme}`)
        .then((result) => {
          const doss = result.data[0];
          setProgramme(doss);

          {
            /*
                  fetchTematiques(doss.id_cycle_lang);
   
                  setCycleLang(doss.id_cycle_lang);
   
                  fetchContactCycleLang(doss.id_cycle_lang)
                  */
          }
        });
    };
    idProgramme && fetchProgramme();
  }, []);

  if (!programme) {
    return <Loader />;
  }

  return (
    <LayoutAccount>

      <div className="row mb-5">
        <div className="col-md-1"> <img src={`https://www.mlg-consulting.com/manager_cc/docs/archives/${programme.logo}`} style={{ width: '100%' }} /></div>
        <div className="col">

          <h1>{programme.programm_title}</h1>
          <h3>{programme.programm_resume}</h3>
        </div>
      </div>


      <p className="text-left">
        <button onClick={handleSubmit} className="btn btn-primary">Sauvegarder les modifications</button>
      </p>

      <RegsistrationFormSection title="I - OBJECTIFS DE VOTRE PROGRAMME D’INNOVATION">


        <div class="row mb-3">
          <label for="inputEmail3" class="col-sm-2 col-form-label">I.1 : Titre du programme :</label>
          <div class="col-sm-10">
            <textarea type="text"
              className="form-control"
              name="programm_title"
              value={programme.programm_title}
              onChange={handleChange} />

            <small className="text-muted">80 charactères maximum</small>
          </div>
        </div>

        <div class="row mb-3">
          <label for="inputEmail3" class="col-sm-2 col-form-label">   II.2 : Titre détaillé et attractif du programme :</label>
          <div class="col-sm-10">
            <textarea type="text" className="form-control" name="programm_resume" value={programme.programm_resume} onChange={handleChange} />
            <small className="text-muted">Rédiger un titre plus long et plus attractif que la thématique (quelques mots) qui vende le
              bénéfice du programme pour la cible qu'elle vise <br />
              Exemple : les 12 leviers de performance énergétique les plus novateurs pour un smartBuilding</small>
          </div>
        </div>

        <div class="row mb-3">
          <label for="inputEmail3" class="col-sm-2 col-form-label">  I.3 Cibles du programme d'innovation :</label>
          <div class="col-sm-10">
            <textarea id="" cols="30" rows="10" className="form-control" name="cible" value={programme.cible} onChange={handleChange} ></textarea>
            <small className="text-muted">Détailler les fonctions et services concernés au sein d’une entreprises, d’un collectivité ou
              d’une association, d’une unité de production ou d’un appel à projet :</small>
          </div>
        </div>

        <div class="row mb-3">
          <label for="inputEmail3" class="col-sm-2 col-form-label">I.4 Descriptif de l'ambition du programme d'innovation :</label>
          <div class="col-sm-10">
            <textarea name="ambition" id="" cols="30" rows="10" className="form-control" value={programme.ambition} onChange={handleChange} ></textarea>
            <small className="text-muted">Cette rubrique détaille le parti-pris avec lequel vous proposez un programme d'innovation et un démonstrateur.
              Nous suggérons de le rédiger de manière « attractive » afin d'inciter les industriels à la soutenir et les acteurs
              innovant de votre futur collectif de s'y engager.</small>
          </div>
        </div>


        <p className="text-center">
          <button onClick={handleSubmit} className="btn btn-primary">Sauvegarder les modifications</button>
        </p>


      </RegsistrationFormSection>


      <RegsistrationFormSection title="II – DETAIL DE VOTRE CHAINE DE VALEUR ET TABLE DES BESOINS À COUVRIR">
        <div className="alert alert-info">Cette table détaille les étapes de la chaine de valeur que vous voulez mettre en lumière dans votre programme
          d’innovation et qui seront rematérialisées dans le démonstrateur, les tech revus, les conférences etc.
          Ces étapes servent de nomenclature sur lesquelles les solutions détectées seront positionnées tout au long de
          l’année</div>
        <UserProgrammeThrematiques2 id_cycle_lang={programme.id_cycle_lang} />
      </RegsistrationFormSection>

      <RegsistrationFormSection title="Contributeurs identifiés">
        <UserProgrammeContacts />
      </RegsistrationFormSection>

    </LayoutAccount>
  );
};

export default UserProgrammeEdit;
