import parse from 'html-react-parser';
import React, { useState, useEffect, useContext } from 'react';
import Loader from './Loader';
import EventContext from '../contexts/EventContext';
import Layout from './Layout';
import { removeDuplicateObjectFromArray, sortByAttribute } from '../utils';
import useApiUrlTranslation from './useApiUrlTranslation';


const DisplayJuryEventList = ({ sessionList, title, id_conf_event_type }) => {

    if (!sessionList) {
        return <Loader />
    }
    return <div>

        <p className='mt-3'><b>{title}</b></p>
        <div className="overflow-hidden mb-10 mt-2 ring-1 ring-black ring-opacity-5 md:rounded-lg">


            <table className="min-w-full divide-y divide-gray-300">

                <thead className="bg-gray-50">
                    <tr>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Titre</th>
                        {
                            id_conf_event_type == 67 &&
                            <>
                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Date</th>
                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Horaires</th>
                            </>
                        }
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">

                    {

                        sessionList.map(item => {

                            return <tr>
                                <td
                                    className='whitespace-nowrap px-3 py-2 text-sm text-gray-500 w-3/4'
                                    key={item.id_jury.id_jury}>
                                    {parse(item.id_jury.nom ?? "")}

                                    <p className='d-none'>{item.jury_event.thematique}</p>

                                    <div className="alert alert-info d-none">id jury : {item.id_jury.id_jury} <br />
                                        id_presta : {item.presta.id_presta}
                                    </div>
                                </td>
                                {item.jury_event.thematique != 'y' &&
                                    <>
                                        <td className='whitespace-nowrap px-3 py-2 text-sm text-gray-500'>
                                            {item.jury_event.conf_event.conf_event_date}
                                        </td>
                                        <td className='whitespace-nowrap px-3 py-2 text-sm text-gray-500'>
                                            {item.jury_event.conf_event.heure_debut} - {item.jury_event.conf_event.heure_fin}
                                            { /* parse(item.jury_event.conf_event.defaultTimeZones ?? "") */}
                                        </td>
                                    </>
                                }
                            </tr>
                        })
                    }

                </tbody>
            </table>
        </div>
    </div>

}
const Demo = ({ demo }) => {

    return <>
        <div class="rounded visuel">
            <img alt='thumbnail vrroom' style={{ width: "40px" }} src="//www.mlg-consulting.com/manager_cc/contacts/img_uploaded/thumbs/220302105326_vrroom-white_200x0.png" />
        </div>
        <b>{demo.presta_nom}</b>
        <p>{parse(demo.presta_texte)}</p>
        <p>{parse(demo.presta_accroche)}</p>
    </>
}

const DefaultDemo = ({ contact }) => {

    const [demo, setDemo] = useState(null)

    const { i18n, apiUrlTranslation } = useApiUrlTranslation()

    useEffect(() => {

        const getPrestaList = async () => {

            await fetch(`${apiUrlTranslation}?action=getPrestaList&params= WHERE id_contact=${contact.id_contact}`)
                .then(res => res.json())
                .then(res => setDemo(res[0]))

        }
        getPrestaList()

    }, [i18n.resolvedLanguage])

    if (!demo) {
        return false
    };

    return <Demo demo={demo} />
}

const Participant = ({ contact, index, juryDemoList }) => {

    const [demo, setDemo] = useState(null)

    const [sessionList, setSessionList] = useState(null)

    const { i18n } = useApiUrlTranslation();

    useEffect(() => {

        if (!juryDemoList) {

            return false
        }

        const demoList = juryDemoList
            .filter(item => item.presta.id_contact.id_contact === contact.id_contact)
            .map(item => item.presta);

        setSessionList(juryDemoList.filter(item => item.presta.id_contact.id_contact === contact.id_contact))

        demoList && setDemo(demoList[0]);

    }, [i18n.resolvedLanguage, juryDemoList])

    return <div className="row border shadow-sm mb-2">
        <div className="col-md-3 border">

            <div className="card mb-3 border-0" style={{ maxWidth: "540px" }}>
                <div className="row g-0">
                    <div className="col-md-4">

                        <img src={contact.photos.small}

                            style={{ objectFit: "cover", height: "100%" }}
                            className="img-fluid rounded-start rrrr"
                            alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">#{index}
                            <h5 className="card-title">{contact.prenom} {contact.nom}</h5>
                            <p className="card-text">{contact.societe} </p>
                            <p className="card-text"><small className="text-muted">    <i className="fas fa-map-marker-alt text-muted"></i>
                                <span className="text-muted">  {contact.ville},  {contact.pays} <img src={contact.flag} alt="" style={{ height: "20px" }} className="px-3" /></span></small></p>
                            <p><img src={contact.logos.small} alt="" style={{ width: "100px" }} /></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col border">
            {demo ? <Demo demo={demo} /> : <DefaultDemo contact={contact} />}

            {sessionList && sessionList.filter(item => item.jury_event.thematique == 'y').length > 0 &&
                <DisplayJuryEventList
                    id_conf_event_type={66}
                    title="Venez nous rencontrer à l'ocassion d'un parcours de visites sur une des thématiques ci-dessous : "
                    sessionList={sessionList.filter(item => item.jury_event.thematique == 'y')}></DisplayJuryEventList>
            }

            {sessionList && sessionList.filter(item => item.jury_event.conf_event.type.id_conf_event_type == 67 && item.jury_event.thematique != 'y').length > 0 &&
                <DisplayJuryEventList
                    id_conf_event_type={67}
                    title="Nous pitcherons lors des sessions suivantes : "
                    sessionList={sessionList.filter(item => item.jury_event.conf_event.type.id_conf_event_type == 67 && item.jury_event.thematique != 'y')}></DisplayJuryEventList>

            }
        </div>
    </div >
}


const Candidats = () => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation()

    const [candidats, setCandidats] = useState(null);

    const [juryDemoList, setJuryDemoList] = useState(null);

    const [participantList, setParticipantList] = useState(null)

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    useEffect(() => {

        const fetchParticipant = async () => {

            await fetch(`${apiUrlTranslation}?action=getContactConferencierList&filter= AND  id_event=${currentEvent.id_event} AND cf.statut IN("offreur_de_solution", "candidat-pitch") AND  cf_etat is null`)
                .then(res => res.json())
                // .then(res => setParticipantList(res))
                .then(res => setParticipantList(removeDuplicateObjectFromArray(res, 'id_contact')))

        }

        const getCandidats = async () => {

            await fetch(`${apiUrlTranslation}?action=getJuryDemoList&params=WHERE id_event=${currentEvent.id_event}`)
                .then(res => res.json())
                .then(all => {

                    const allFiltered = all.filter(item => item.is_visible === '1').map(item => {

                        return { ...item, id_presta: item.presta.id_presta }
                    })

                    const allWHitDate = all.map(item => {
                        return { ...item, date_creation: item.jury_event.conf_event.conf_event_date }
                    });

                    const allSorted = sortByAttribute(allWHitDate, 'date_creation')

                    console.log('allSorted', allSorted);

                    setJuryDemoList(allSorted);
                    //console.log(all)
                    setCandidats(removeDuplicateObjectFromArray(allFiltered, "id_presta"))
                })
        }
        if (currentEvent) {
            getCandidats()
            fetchParticipant()
        }


    }, [currentEvent, i18n.resolvedLanguage]);


    return <Layout title={`${t('event_nav.annuaire_candidats')} / Exposants`}>

        {participantList ? participantList.map((item, index) => {
            return <Participant
                index={index + 1}
                contact={item.contact}
                key={index}
                juryDemoList={juryDemoList} />

        }) : <Loader />}
    </Layout >
};

export default Candidats;

