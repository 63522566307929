import { use } from 'i18next';
import React, { useEffect } from 'react'

const LinkedInRedir = () => {



    const getUserData = async () => {

        const accessToken = new URLSearchParams(window.location.search).get('code');
        try {
            const response = await fetch('https://api.linkedin.com/v2/me', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            const userData = await response.json();
            console.log(userData);
            // Traitez ici les données de l'utilisateur
        } catch (error) {
            console.error('Erreur lors de la récupération des données utilisateur', error);
        }
    };

    useEffect(() => {
        getUserData();
    }, []);


    return (
        <div>LinkedInRedir</div>



    )
}

export default LinkedInRedir