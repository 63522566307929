import { useTranslation } from "react-i18next";

const useApiUrlTranslation = () => {

    const { t, i18n } = useTranslation();

    const apiUrlTranslation = `https://www.myglobalvillage.com/${i18n.resolvedLanguage === 'fr' ? '' : `${i18n.resolvedLanguage}/`}api/`

    return { t, i18n, apiUrlTranslation }
}

export default useApiUrlTranslation