import React, { useContext, useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { API_URL } from '../config';
import Layout from './Layout'
import HTMLReactParser from 'html-react-parser';
import EventContext from '../contexts/EventContext';
import { Bars4Icon, CalendarIcon, CheckIcon, PlusIcon, UsersIcon, ViewColumnsIcon } from '@heroicons/react/24/outline'
import useApiUrlTranslation from './useApiUrlTranslation';
import Programm from './Programm';
import ProgrammContent from './ProgrammContent';
import './EventCycleLangDetail.css'

import EventCycleLangPacks from './EventCycleLangPacks';

const DisplayContacts = ({ eventCycleLangContacts, event }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    return (
        <div className=" dark:bg-neutral-950 dark:text-neutral-400">
            <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8 lg:py-12">
                <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-8">
                    <div className="space-y-5 sm:space-y-4">
                        <h2 className="dark:text-white text-3xl font-bold tracking-tight sm:text-4xl">{t('pages.delegation_details.soutiens')} {event.event_an} </h2>
                        <p className="text-xl text-gray-500 dark:text-gray-400 ">
                            {t('pages.delegation_details.soutiens_desc')}
                        </p>
                    </div>
                    <div className="lg:col-span-2">
                        <ul role="list" className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-12 sm:space-y-0 lg:gap-x-8">
                            {eventCycleLangContacts
                                //.filter(person => !["155", "208", "150"].includes(person.conferencier_statut.id_event_contact_type))

                                .map((person) => (
                                    <li key={person.contact.id_contact}>

                                        <div className="flex items-center space-x-4 lg:space-x-6">
                                            <img className="h-16 w-16 rounded-full lg:h-20 lg:w-20 object-cover" src={person.photos.large} alt="" />
                                            <div className="space-y-1 text-lg font-medium leading-6">
                                                <h3 className='capitalize dark:text-white '>{person.contact.prenom} {person.contact.nom}</h3>
                                                <p className="text-gray-700 dark:text-gray-400  text-sm">{person.contact.fonction} - {person.contact.societe}</p>
                                                <p className="text-indigo-600 hidden">{person.conferencier_statut.libelle}  </p>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

const CoordinateurSection = ({ eventCycleLang, event }) => {

    const [eventCycleLangContacts, setEventCycleLangContacts] = useState(null)

    useEffect(() => {

        const getEventCyclelangContacts = async () => {

            const req = `id_event_cycle_lang IN(SELECT id_event_cycle_lang FROM event_cycle_lang WHERE  id_cycle_lang IN(  SELECT id_cycle_lang FROM event_cycle_lang WHERE  id_event_cycle_lang = ${eventCycleLang.id_event_cycle_lang})`
            const req1 = `id_event_cycle_lang=${eventCycleLang.id_event_cycle_lang} AND id_event=${event.id_event}`

            await fetch(`${API_URL}?action=getEventCyclelangContactsEventLigne&${req1} `)
                .then(res => res.json())
                .then(res => {
                    setEventCycleLangContacts(res)
                })
        }

        eventCycleLang && getEventCyclelangContacts()
    }

        , [eventCycleLang])

    return (
        <>
            {eventCycleLangContacts && <DisplayContacts event={event} eventCycleLangContacts={eventCycleLangContacts} />}
        </>
    )
}


const Descriptif = ({ cycleLang, eventCycleLang }) => {

    return (

        <div className='relative pb-10 pt-0'>
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">

                {eventCycleLang && eventCycleLang.event_cycle_lang_descriptif ?
                    <div className='mx-auto mt-5 text-gray-500 text-left'>
                        {HTMLReactParser(eventCycleLang?.event_cycle_lang_descriptif ?? cycleLang.cycle_lang_descriptif)}
                    </div> :
                    <div className='mx-auto mt-5  text-xl text-gray-500 text-left'>
                        {HTMLReactParser(eventCycleLang?.event_cycle_lang_descriptif ?? cycleLang.cycle_lang_descriptif)}
                    </div>}
            </div>
        </div>
    )
}

const DisplayCommunaute = ({ communaute }) => {

    return <div>
        {communaute && communaute.map(communaute =>
            <div key={communaute.id_communaute} className='flex flex-col items-center'>
                <img className=" w-100 object-cover rounded-2xl" src={communaute.contact.photos.large} alt="" />
                <div className='ml-3'>
                    <div className="text-sky-500 dark:text-sky-400 font-bold">
                        {communaute.contact.prenom}  {communaute.contact.nom}
                    </div>


                    <div className='dark:text-white'>{communaute.id_conferencier.statut.libelle}</div>
                    <div className='dark:text-gray-400'>{communaute.contact.fonction},  {communaute.contact.societe}</div>

                </div>
            </div>)}

    </div>

}

const DescriptifEventCycleLang = ({ cycleLang, eventCycleLang, event, communaute }) => {

    if (!event) return false;

    return (

        <div className='relative pb-10 pt-0 dark:bg-neutral-950'>
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">

                <p className='text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl lg:text-5xl py-10'>
                    Gros plan sur la délégation {eventCycleLang.event_cycle_lang_nom} - {event?.nom}
                </p>
                <div className='flex flex-col-reverse lg:flex-row'>
                    <div className='mx-auto basis-2/5 pr-10'>
                        <DisplayCommunaute communaute={communaute} />
                    </div>
                    <div className='mx-auto  basis-3/5" text-gray-500 dark:text-gray-400 text-left text-lg'>
                        {HTMLReactParser(eventCycleLang?.event_cycle_lang_descriptif ?? cycleLang.cycle_lang_descriptif)}
                    </div>
                </div>


                <div className="mt-20 mb-5">
                    <a
                        href={`https://www.mlg-consulting.com/myglobalvillage/sign.php?ie=${event.id_event}&iecl=${eventCycleLang?.id_event_cycle_lang}`}
                        className="text-lg items-center justify-center rounded-full border border-transparent bg-pink-600 hover:bg-pink-500 px-5 py-3 font- text-white "
                    >
                        Je réserve mon pack
                    </a>
                </div>


            </div>
        </div>
    )
}

const DescriptifCollectif = ({ cycleLang, contactCycleLangList }) => {

    return (

        <div className='relative pb-5 pt-5'>
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                <p className='text-3xl font-bold tracking-tight dark:text-neutral-400 sm:text-4xl lg:text-5xl pb-10'> {cycleLang.nom_cycle_lang} en quelques mots</p>

                <div className='mx-auto mt-5 text-gray-500 text-left ul'>
                    {HTMLReactParser(cycleLang.cycle_lang_descriptif)}
                </div>

                {contactCycleLangList && contactCycleLangList.filter(contactCycleLang => ["146", "228"].includes(contactCycleLang.event_contact_type.id_event_contact_type)).map(contactCycleLang =>

                    <figure className="flex bg-slate-100 flex-col md:flex-row mx-auto my-1 justify-items-center mt-5">

                        <img className="w-24 h-24 md:w-48 md:h-auto md:max-h-52 md:rounded-none rounded-full mr-20 object-cover"
                            src={contactCycleLang.contact.photos.large}
                            alt="" />
                        <div className='p-5'>

                            <blockquote>
                                <p className='text-lg font-medium'>
                                    {contactCycleLang.contact.edito_court}
                                </p>
                            </blockquote>
                            <figcaption className="font-medium text-right">
                                <div className="text-sky-500 dark:text-sky-400 font-bold">
                                    {contactCycleLang.contact.prenom}  {contactCycleLang.contact.nom}
                                </div>
                                <div className="text-gray-600">
                                    {contactCycleLang.contact.fonction}    {contactCycleLang.contact.societe}
                                    <br /> Coordinateur du collectif {cycleLang.nom_cycle_lang}
                                </div>
                            </figcaption>
                        </div>
                    </figure>
                )}


            </div>
        </div >
    )
}

const Programme = ({ cycleLang, programmeItems, eventCycleLang, event }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    useEffect(() => {

    }, [programmeItems, cycleLang])


    if (!programmeItems || !cycleLang) {
        return null
    }

    return (
        <div className="relative bg-gray-50 py-16 sm:py-12 lg:py-8 dark:bg-neutral-950 ">
            <div className="relative">
                <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">

                    <div className="pb-5 ">
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <div className="text-center">
                                <p className="font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl lg:text-5xl">
                                    {t('pages.delegation_details.au_programme')} <span className="" style={{ color: `#${cycleLang?.cycle_lang_color_1}` }}>{eventCycleLang?.event_cycle_lang_nom}</span> -   {event?.nom}
                                </p>
                            </div>
                        </div>
                    </div>

                    <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500 d-none">
                        Pendant toute la durée de l'événement retrouvez  Phasellus lorem quam molestie id quisque diam aenean nulla in. Accumsan in quis quis nunc, ullamcorper
                        malesuada. Eleifend condimentum id viverra nulla.
                    </p>


                </div>
                <div className="mx-auto grid max-w-md gap-8 px-4 sm:max-w-lg sm:px-6 lg:max-w-7xl lg:grid-cols-4 lg:px-8">
                    {programmeItems.map((post) => (
                        <div key={post.id} className="flex flex-col overflow-hidden rounded-2xl shadow-lg border-x border-y dark:border-neutral-800">
                            <div className="flex-shrink-0">
                                <img className="h-48 w-full object-cover" src={post.imageUrl} alt="" />
                            </div>
                            <div className="flex flex-1 flex-col justify-between  p-6">
                                <div className="flex-1">

                                    <div className=" block">
                                        <p className="text-xl font-semibold text-gray-900 hidden">{post.title}</p>
                                        <p className=" text-lg text-gray-500  dark:text-gray-400 ">{post.preview}</p>
                                    </div>
                                </div>
                                <div className="mt-6 flex items-center d-none">
                                    <div className="flex-shrink-0">
                                        <a href={post.author.href}>
                                            <img className="h-10 w-10 rounded-full" src={post.author.imageUrl} alt={post.author.name} />
                                        </a>
                                    </div>
                                    <div className="ml-3">
                                        <p className="text-sm font-medium text-gray-900">
                                            <a href={post.author.href} className="hover:underline">
                                                {post.author.name}
                                            </a>
                                        </p>
                                        <div className="flex space-x-1 text-sm text-gray-500">
                                            <time dateTime={post.datetime}>{post.date}</time>
                                            <span aria-hidden="true">&middot;</span>
                                            <span>{post.readingLength} read</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
const Pricing = ({ cycleLang, idEvent, event, eventCycleLang }) => {

    const [includedFeatures, setIncludedFeatures] = useState([]);

    const updateIncludedFeatures = () => {

        const includedFeatures = [
            `Les matinales (pitchs, speed briefing et café) incluant l'élection des meilleures solutions ${cycleLang.nom_cycle_lang}`,
            `Votre pass d'entrée au CES et les visites thématiques préparées par les organisateurs du collectif ${cycleLang.nom_cycle_lang}`,
            `Les afterworks et les soirées de networking `,
            `L'accès à l'annuaire des collectifs sectoriels `,
            `L'accès à l'une des soirées de prestige au choix `,
        ]

        setIncludedFeatures(includedFeatures);
    }

    useEffect(() => {
        updateIncludedFeatures()
    }, [event])

    if (!event) {
        return null
    }
    return (
        <div className="bg-gray-100 ">

            <div className="pt-12 sm:pt-16 lg:pt-10">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="text-center">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl d-none">
                            Au programme de la délégation <span className="" style={{ color: `#${cycleLang.cycle_lang_color_1}` }}>{eventCycleLang?.event_cycle_lang_nom}</span> à  {event.nom}
                        </h2>
                        <p className="mt-4 text-xl text-gray-600 d-none">
                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Magnam voluptas, explicabo ipsam tempora quod suscipit magni earum commodi dignissimos consequuntur
                        </p>
                    </div>
                </div>
            </div>


            <EventCycleLangPacks eventCycleLang={eventCycleLang} cycleLang={cycleLang} />

        </div>
    )


}

const EventCycleLangDetail = () => {

    const [cycleLang, setCycleLang] = useState(null)

    const [eventCycleLang, setEventCycleLang] = useState(null)

    const [programmeItems, setProgrammeItems] = useState(null);

    const [communaute, setCommunaute] = useState(null);

    const { idCycleLang, idEvent, id_event_cycle_lang } = useParams();

    const { currentEvent } = useContext(EventContext);

    const [contactCycleLangList, setContactCycleLangList] = useState(null)

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();



    const updateProgrammeItems = ({ cycleLang }) => {

        const programmItems = [

            {
                id: 1,
                title: HTMLReactParser(`${t('pages.delegation_details.best_solutions')} <span className="text-cyan-600">${cycleLang.nom_cycle_lang}</span>`),
                href: '#',
                date: 'Mar 16, 2020',
                datetime: '2020-03-16',
                category: { name: 'Article', href: '#' },
                imageUrl:
                    'https://www.mlg-consulting.com/manager_cc/docs/archives/thumbs/190102171608_dji-0053_350x0.png',
                preview:
                    t('pages.delegation_details.best_solutions_desc'),
                author: {
                    name: 'Roel Aufderehar',
                    imageUrl:
                        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
                    href: '#',
                },
                readingLength: '6 min',
            },
            {
                id: 2,
                title: HTMLReactParser(`${t('pages.delegation_details.jury')}  <span className="text-cyan-600">${cycleLang.nom_cycle_lang} </span>`),
                href: '#',
                date: 'Mar 10, 2020',
                datetime: '2020-03-10',
                category: { name: 'Video', href: '#' },
                imageUrl:
                    'https://www.mlg-consulting.com/manager_cc/docs/archives/191023143533_seppfgbt.png',
                preview:
                    HTMLReactParser(`${t('pages.delegation_details.jury_desc')} <span className="text-cyan-600">  </span> `),
                author: {
                    name: 'Brenna Goyette',
                    imageUrl:
                        'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
                    href: '#',
                },
                readingLength: '4 min',
            },
            {
                id: 3,
                title: t('pages.delegation_details.temps_forts'),
                href: '#',
                date: 'Feb 12, 2020',
                datetime: '2020-02-12',
                category: { name: 'Case Study', href: '#' },
                imageUrl:
                    'https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80',
                preview:
                    t('pages.delegation_details.temps_forts_desc'),
                author: {
                    name: 'Daniela Metz',
                    imageUrl:
                        'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
                    href: '#',
                },
                readingLength: '11 min',
            },
            {
                id: 4,
                title: t('pages.delegation_details.join_us'),
                href: '#',
                date: 'Feb 12, 2020',
                datetime: '2020-02-12',
                category: { name: 'Case Study', href: '#' },
                imageUrl:
                    'https://www.mlg-consulting.com/manager_cc/docs/archives/191023143856_pouce.png',
                preview:
                    t('pages.delegation_details.join_us_desc'),
                author: {
                    name: 'Daniela Metz',
                    imageUrl:
                        'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
                    href: '#',
                },
                readingLength: '11 min',
            }
        ]

        setProgrammeItems(programmItems);
    }


    useEffect(() => {

        const getCommunaute = async () => {

            await fetch(`${apiUrlTranslation}?action=getCommunaute&filter= AND id_event_cycle_lang = ${id_event_cycle_lang} `)
                .then(res => res.json())
                .then(res => {
                    setCommunaute(res)
                })
        }

        const getEventCycleLang = async () => {
            await fetch(`${apiUrlTranslation}?action=getEventCycleLangList&params= WHERE id_event_cycle_lang = ${id_event_cycle_lang} `)
                .then(res => res.json())
                .then(res => {

                    const ecl = res[0];
                    setEventCycleLang(ecl)
                    fetchContactCycleLang(ecl.cycle_lang.id_cycle_lang)
                    setCycleLang(ecl.cycle_lang)
                    updateProgrammeItems({ cycleLang: ecl.cycle_lang });
                })
        }

        const fetchContactCycleLang = (idCycleLang) => {
            fetch(`${API_URL}?action=fetchContactCycleLang&filter=id_cycle_lang=${idCycleLang} AND `)
                .then(res => res.json())
                .then(res => {
                    setContactCycleLangList(res)
                })
        }

        const getCycleLang = async () => {
            await fetch(`${apiUrlTranslation}?action=getCycleLang&filter= id_cycle_lang  = ${idCycleLang} AND `)
                .then(res => res.json())
                .then(res => {
                    setCycleLang(res[0])
                    updateProgrammeItems({ cycleLang: res[0] });
                    currentEvent && getEventCycleLang()
                    fetchContactCycleLang(idCycleLang)
                })
        }

        id_event_cycle_lang && getEventCycleLang()
        id_event_cycle_lang && getCommunaute()
        // idCycleLang && getCycleLang()

    }, [i18n.resolvedLanguage, idCycleLang, idEvent, currentEvent, id_event_cycle_lang])

    if (!id_event_cycle_lang || !cycleLang) {
        return false;
    }

    return (
        <Layout>
            <div className="relative bg-neutral-950 border-b-2 border-b-neutral-800" style={{ backgroundColor: `#${cycleLang.cycle_lang_color_1}` }}>
                <div className='relative
                flex flex-col lg:flex-row   items-center mx-auto max-w-2xl px-4 pt-5 pb-16 text-center sm:px-6 sm:pt-32 lg:max-w-7xl lg:px-8'>
                    <img src={eventCycleLang.logo} className="w-40 h-40  bg-white rounded-full" />
                    <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl flex flex-col items-start px-10">
                        <span className="block text-3xl text-white "> {t('pages.collectifs_contextuels.delegations')}</span>
                        <span className="d-none lg:inline bg-gradient-to-r from-purple-600 via-pink-600 to-blue-600 bg-clip-text text-transparent">{eventCycleLang?.event_cycle_lang_nom}</span>
                        <span className="my-element text-8xl capitalize ">{eventCycleLang?.event_cycle_lang_nom}</span>
                    </h1>


                </div>

            </div>

            <DescriptifEventCycleLang
                communaute={communaute && communaute.filter(communaute => communaute.id_conferencier.statut.id_event_contact_type === "256")}
                event={currentEvent}
                cycleLang={cycleLang}
                eventCycleLang={eventCycleLang} />

            <Programme
                event={currentEvent}
                cycleLang={cycleLang}
                programmeItems={programmeItems}
                eventCycleLang={eventCycleLang} />

            {/*  <ProgrammContent /> */}

            {
                <DescriptifCollectif
                    event={currentEvent}
                    cycleLang={cycleLang}
                    eventCycleLang={eventCycleLang}
                    contactCycleLangList={contactCycleLangList} />
            }


            <Pricing
                event={currentEvent}
                idEvent={idEvent}
                cycleLang={cycleLang}
                eventCycleLang={eventCycleLang} />



            {/*
            currentEvent && <CoordinateurSection
                event={currentEvent}
                eventCycleLang={eventCycleLang} />
            */
            }

        </Layout >
    )
}

export default EventCycleLangDetail