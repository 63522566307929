import React from 'react'
import Layout from './Layout'
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid'
import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import useApiUrlTranslation from './useApiUrlTranslation';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import HTMLReactParser from 'html-react-parser';
import Loader from './Loader';
import { removeDuplicateObjectFromArray, removeDuplicateObjectsByProperty } from '../utils';

const event_cycle_lang_nom = "Canada";

const people = [

    {
        name: 'Jane Cooper',
        title: 'Paradigm Representative',
        role: 'Soutien',
        email: 'janecooper@example.com',
        telephone: '+1-202-555-0170',
        imageUrl:
            'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    // More people...
]

const StyleHeader = styled.div`
  background-color: #000;
  color: #fff;
  padding: 10px;
  font-size: 20px;
  margtin-top: 10px;
  margin-bottom: 10px;

`;


const ProgrammItem = ({ confEvent, partenaireList }) => {

    const { apiUrlTranslation, i18n } = useApiUrlTranslation()

    const [confEventCycleLangList, setConfEventCycleLangList] = useState(null)

    const getConfEventCycleLang = async () => {

        await fetch(`${apiUrlTranslation}?action=getConfEventCycleLang&params=WHERE id_conf_event=${confEvent.id_conf_event}`)
            .then(res => res.json())
            .then(res => {
                setConfEventCycleLangList(res)
            })
    }


    useEffect(() => {
        getConfEventCycleLang()
    }, []);

    return <tr className="divide-x divide-gray-200">

        <td className="p-2 w-10">{confEvent.conf_event_lang.cel_titre}
            <div className='text-gray-400 text-sm'> {HTMLReactParser(confEvent.localTimeZone)}</div>
        </td>
        <td className="p-2">{
            confEventCycleLangList && confEventCycleLangList.map(confEventCycleLang =>
                <div>
                    <img className="w-20"
                        title={confEventCycleLang.id_cycle_lang.nom_cycle_lang}
                        src={confEventCycleLang.id_cycle_lang.logo_better.large} alt="" /></div>)
        }</td>
        <td className="p-2">
            {/* Decision makers et Tech'xplainer    */}

            {partenaireList ? <ContactList
                idEventContactTypeList={["92", "250"]}
                partenaireList={partenaireList.filter(partenaire => partenaire.id_conf_event.id_conf_event == confEvent.id_conf_event)} />
                : <Loader />
            }
        </td>
        <td className="p-2">{/*Tech'provider */}
            {partenaireList ? <ContactList
                idEventContactTypeList={["178"]}
                partenaireList={partenaireList.filter(partenaire => partenaire.id_conf_event.id_conf_event == confEvent.id_conf_event)} />
                : <Loader />
            }</td>
        <td className="p-2">
            {/*Coordinateur du pannel pour le pays */}
            {partenaireList ? <ContactList
                idEventContactTypeList={["202"]}
                partenaireList={partenaireList.filter(partenaire => partenaire.id_conf_event.id_conf_event == confEvent.id_conf_event)} />
                : <Loader />
            }</td>
    </tr>

}

const ContactList = ({ partenaireList, idEventContactTypeList }) => {

    if (!partenaireList) {

        return <Loader />
    }
    return (
        <div>
            {partenaireList && partenaireList
                .filter(partenaire => idEventContactTypeList.includes(partenaire.conferencier_statut.id_event_contact_type))
                .map(partenaire => <div className='flex flex-col'>
                    <div className='hidden flex-row '>
                        <div className='w-10 h-10 rounded-full bg-gray-200 mr-3'></div>
                        <div className='flex flex-col'>
                            <div className='font-bold'>{partenaire.contact.nom}</div>
                            <div className='text-gray-400 text-sm'>{partenaire.contact.fonction}</div>
                        </div>
                    </div>

                    <div className='flex flex-row'>
                        <div className='w-10 h-10 rounded-full bg-gray-200 mr-3'>
                            <img className="rounded-full w-16 h-10" src={partenaire.contact.photos.small} alt="" />
                        </div>
                        <div className='flex flex-col'>
                            <div className='font-bold'>{partenaire.contact.nom}</div>
                            <div className='text-gray-400 text-sm'>{partenaire.fonction}</div>
                        </div>
                    </div>
                </div>
                )
            }
        </div>
    )
}

const ProgrammeList = ({ confEventList, confEventTypeList, partenaireList }) => {


    if (!confEventList) {
        return <Loader />
    }

    return <table>
        <thead className='bg-gray-300 p-3'>
            <tr >

                <td className=''>Nom</td>
                <td>Verticales sectorielles</td>
                <td>Decision makers et Tech'xplainer </td>
                <td>Tech'provider</td>
                <td>Coordinateur du pannel pour le pays</td>
            </tr>
        </thead>

        <tbody className="divide-y divide-gray-200 bg-white">
            {confEventList && confEventList
                .filter(confEvent => confEventTypeList.includes(confEvent.type))
                .map((confEvent, index) => <ProgrammItem key={index} confEvent={confEvent} partenaireList={partenaireList} />)}

        </tbody>

    </table>
}
const SoutiensList = ({ partenaireList, idEventContactTypeList }) => {
    const [contacts, setContacts] = useState([]);

    useEffect(() => {

        const newList = partenaireList.filter(partenaire => ["143"].includes(partenaire.conferencier_statut.id_event_contact_type))
        setContacts(removeDuplicateObjectFromArray(newList, 'id_contact'))
        console.log("partenaireList", partenaireList)
    }, []);


    return <div className='grid grid-cols-7 gap-2'>
        {partenaireList && contacts

            .map(partenaire => (
                <div key={partenaire.id_connferencier} className='flex flex-col my-3 bg-gray-50' >
                    <div className='flex items-center justify-center h-30 overflow-hidden'>
                        <img className='w-100'
                            src={partenaire.contact.photos.small} alt='' />
                    </div>
                    <div className=''>{partenaire.contact.prenom} {partenaire.contact.nom}</div>
                    <div>{partenaire.contact.fonction}, {partenaire.contact.societe}</div>
                    <div>Soutien</div>
                </div>
            ))}
    </div>
}

const EventCycleLangDetail2 = () => {

    const [confEventList, setConfEventList] = useState(null);

    const [partenaireList, setPartenaireList] = useState(null);

    const { apiUrlTranslation, i18n } = useApiUrlTranslation()

    const { idEvent } = useParams();

    const getConfEvent = async () => {
        await fetch(`${apiUrlTranslation}?action=getConfEvent&id_event=${idEvent}`)
            .then(res => res.json())
            .then(res => {
                setConfEventList(res)
            })
    }

    const getPartenaires = async () => {
        await fetch(`${apiUrlTranslation}?action=getPartenaires&params=AND id_event=${idEvent}`)
            .then(res => res.json())
            .then(res => {

                const newRes = res.map(partenaire => {

                    const newPartenaire = { ...partenaire, id_contact: partenaire.contact.id_contact }
                    return newPartenaire

                })

                console.log('newRes', newRes)

                const dedupRes = removeDuplicateObjectFromArray(newRes, 'id_contact')

                console.log('dedupRes', dedupRes)

                setPartenaireList(newRes)
            })

    }

    useEffect(() => {
        getConfEvent();
        getPartenaires();
    }, [])

    return (
        <Layout>
            <div className='mx-auto max-w-7xl px-2'>
                <div className='flex bg-black'>
                    <div><img className='w-28' src="https://www.mlg-consulting.com/manager_cc/docs/archives/230502090329_usa.png" alt="" /></div>
                    <div className='text-3xl text-white'>Agenda des événements et annuaire des acteurs</div>
                </div>

                Le Coordinateur de la délégation : 	Jessica Chin Foo coordonne pour myGlobalVillage la présence de la délégation {event_cycle_lang_nom}

                <img src="https://www.mlg-consulting.com/manager_cc/contacts/img_uploaded/thumbs/210106024031_7e63630f-d82d-452b-b732-f1b222c7b481_50x0.png" alt="" />

                <StyleHeader>Les soutiens et participants de la délégation </StyleHeader>

                Sur place le jour J, connectés à distance, mobilisés à l’année… les tech’xplorateurs de myGlobalVillage détectent des solutions innovantes, investissent dans les startups en compétition, se positionnent en distributeurs des solutions pour leur pays ou proposent d’en être les premiers utilisateurs pour leur entreprise ou écosystème.
                Coté startups ou fournisseurs de solutions innovantes ces derniers pitchent, participent aux tech’reviews et viennent à la rencontre des Tech’xporters de tous les pays présents sur l’événement.

                {partenaireList ?
                    <SoutiensList
                        partenaireList={
                            partenaireList
                        }

                    /> : <Loader />}

                <StyleHeader>Contribution aux sessions de pitch  </StyleHeader>
                <ProgrammeList

                    partenaireList={partenaireList}
                    confEventList={confEventList}
                    confEventTypeList={["67", "88", "74"]} />

                <StyleHeader>Contribution aux tech’review </StyleHeader>
                <ProgrammeList
                    partenaireList={partenaireList}
                    confEventList={confEventList}
                    confEventTypeList={["92", "96", "97"]} />

                <StyleHeader>Contribution aux webcast (pannel, opening session, debriefing…) </StyleHeader>
                <ProgrammeList
                    partenaireList={partenaireList}
                    confEventList={confEventList}
                    confEventTypeList={["68", "2", "5", "95", "84", "62", "1", "91", "78", "94", "89", "93", "83", "65", "69", "82", "13"]} />
            </div>
        </Layout>
    )
}

export default EventCycleLangDetail2