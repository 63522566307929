import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { API_URL } from "../config";
import CollectifCandidatCard from "./CollectifCandidatCard";
import Layout from "./Layout";
import Loader from "./Loader";

function CollectifCandidat() {
  const { idEvent } = useParams();
  const [candidatList, setCandidatList] = useState([]);

  useEffect(() => {
    const fetchPartenaireList = async () => {
      const url = `${API_URL}?action=getPartenaires&params= AND id_event=${idEvent} AND cf.statut IN('candidat-pitch','offreur_de_solution')`;

      await fetch(url)
        .then((res) => res.json())
        .then((partenaireList) => {
          console.log(partenaireList);
          partenaireList.map((partenaire) =>
            fetchDemoList(partenaire.contact?.id_contact)
          )
        });
    };

    const fetchDemoList = async (idContact) => {
      const url = `${API_URL}?action=getPrestaList&params= WHERE id_contact=${idContact}`;

      await fetch(url)
        .then((res) => res.json())
        .then((candidats) => {
          console.log(candidats)
          if (candidats.length !== 0) {
            // setCandidatList(candidatList => ([...candidatList, candidats]))
            setCandidatList((candidatList) => [...candidatList, candidats]);
          }
          return;
        });
    };

    fetchPartenaireList();
  }, []);

  return (
    <Layout title="Annuaire des candidats" className="container">
      <div className="row border-bottom p-2">
        <div className="col-md-10 fw-bold">Prestations</div>
        <div className="col-md-2 fw-bold">liens</div>
      </div>
      {candidatList.length > 0 ? (
        candidatList.map((candidats, i) => (
          <CollectifCandidatCard key={i} candidats={candidats} />
        ))
      ) : (
        <Loader />
      )}
    </Layout>
  );
}

export default CollectifCandidat;
