import React, { useEffect, useState } from 'react'
import Moment from 'react-moment';
import { API_URL } from '../../config';

const ThematiqueContacts = ({ cycle_lang }) => {

    const [contactCycleLangList, setContactCycleLangList] = useState(null);

    const fetchContactCycleLangByCycleLang = async () => {

        await fetch(`${API_URL}?action=fetchContactCycleLangByCycleLang&id_cycle_lang=${cycle_lang.id_cycle_lang}`)
            .then(res => res.json())
            .then(res => {

                console.log(res)
                setContactCycleLangList(res)
            })
    }

    useEffect(() => {

        fetchContactCycleLangByCycleLang();



    }, [])

    if (contactCycleLangList && contactCycleLangList.length == 0) {
        return false
    }
    return (

        <div className="bg-light pt-2">

            <table className='table table-bordered table-sm'>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Sociétés positionnées sur la thématique</th>
                        <th>Ville</th>
                        <th>Pays</th>
                        <th>Date création</th>
                    </tr>
                </thead>

                <tbody>
                    {contactCycleLangList && contactCycleLangList.map((item, index) =>
                        <tr key={item.id_contact_cycle_lang}>
                            <th>{index + 1}</th>
                            <td>{item.contact.societe}</td>
                            <td>{item.contact.ville}</td>
                            <td>{item.contact.pays}</td>
                            <td><Moment date={item.date_creation} format="D MMM Y" /></td>
                        </tr>
                    )}
                </tbody>

            </table>
        </div>
    )
}

export default ThematiqueContacts