export const API_URL = 'https://www.mlg-consulting.com/smart_territory/form/api.php';

//export const API_URL = 'https://www.myglobalvillage.com/api/'


export const DIR_ARCHIVES = '//www.mlg-consulting.com/manager_cc/docs/archives/';

export const DIR_DOCS_EVENT = "//www.mlg-consulting.com/manager_cc/events/img_uploaded/";

export const DIR_IMG_UPLOADED_CONTACTS = "//www.mlg-consulting.com/manager_cc/contacts/img_uploaded/";
