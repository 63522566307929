import React from 'react'
import Layout from './Layout';


const people = [


    {
        name: 'Marc Lionel Gatto',
        role: 'Founder - Chief Executive Officer',
        imageUrl:
            'https://media.licdn.com/dms/image/C4E03AQHF7cnI_hfxvw/profile-displayphoto-shrink_800_800/0/1632592016203?e=1719446400&v=beta&t=arf7VWMxzKrGWA0-xJaa7Okl39maDNTiSBq9yOSKkQQ',
    },

    {
        name: 'Alain Bagnaud',
        role: 'President',
        imageUrl:
            'https://media.licdn.com/dms/image/C4D03AQGbPxWuu0U8Uw/profile-displayphoto-shrink_800_800/0/1517236457599?e=1719446400&v=beta&t=6-p7qCZsIQeGu24QH4NfF-YEDYNdTzLbKEB2ZXAiwzA',
    },

    {
        name: 'Denis Stevens',
        role: 'Chief Operating Officer',
        imageUrl:
            'https://media.licdn.com/dms/image/C4E03AQE9wavu2PWcrQ/profile-displayphoto-shrink_800_800/0/1516997124964?e=1719446400&v=beta&t=V6HzKV0UjUFgm13CAnaudU2Wt2cw_sfxw2e7Ph4owLs',
    },

    {
        name: 'Annabelle Serres',
        role: 'Head of Sales',
        imageUrl:
            'https://media.licdn.com/dms/image/C4E03AQGfuLryZYNbfg/profile-displayphoto-shrink_800_800/0/1633100177920?e=1719446400&v=beta&t=PWdGi3MIvVpKlR7s-sEDyr54BINBuR4U9OXGTh4jJn8',
    },


    {
        name: 'David Davillé',
        role: 'Chief Technology Officer',
        imageUrl:
            'https://media.licdn.com/dms/image/C5603AQEyeUvw0Qe4cw/profile-displayphoto-shrink_800_800/0/1639093836916?e=1719446400&v=beta&t=xeOKmp4XmSTt0_5-R1guCSVGJ7lMomAWGPuXhq-y4w0',
    },



]




const LeadershipTeam = () => {
    return (
        <Layout>
            <div className="bg-white py-24 sm:py-32">
                <div className="pt-10 mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
                    <div className="max-w-2xl">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Notre Leadership Team</h2>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            Libero fames augue nisl porttitor nisi, quis. Id ac elit odio vitae elementum enim vitae ullamcorper
                            suspendisse.
                        </p>
                    </div>
                    <ul role="list" className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
                        {people.map((person) => (
                            <li key={person.name}>
                                <div className="flex items-center gap-x-6">
                                    <img className="h-16 w-16 rounded-full" src={person.imageUrl} alt="" />
                                    <div>
                                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">{person.name}</h3>
                                        <p className="text-sm font-semibold leading-6 text-indigo-600">{person.role}</p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

        </Layout>
    )
}

export default LeadershipTeam