import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../config";

function CollectifAnnuaireItemContact({ id_cycle_lang }) {
  // console.log(id_cycle_lang);
  // const [infoContact, setInfoContact] = useState([]);
  let { cycle_lang_type } = useParams();

  const displayContact = ({ contact, contact_cycle_lang }) => {
    const id_event_contact_type = parseInt(
      contact_cycle_lang.id_event_contact_type
    );

    // const edito = contact.edito_contact
    //   ? `<h6>Descriptif / Ambitions </h6>${contact.edito_contact}`
    //   : ``;

    const res = `<div><img style="width:40px; height:40px" title="${contact.prenom} ${contact.nom} ${contact.societe}"  src="${contact.photos.tiny}" class="rounded rounded-circle m-2"> 
         </div>`;

    if (contact_cycle_lang.id_cycle_lang) {
      if (
        cycle_lang_type === "collectif_geographique" &&
        [207].includes(id_event_contact_type)
      ) {
        document.getElementById(
          `coordinateur_${contact_cycle_lang.id_cycle_lang}`
        ).innerHTML += res;
      } else if (
        cycle_lang_type === "collectif_expert" &&
        [206].includes(id_event_contact_type)
      ) {
        document.getElementById(
          `coordinateur_${contact_cycle_lang.id_cycle_lang}`
        ).innerHTML += res;
      } else if (
        cycle_lang_type === "collectif_technologique" &&
        [205].includes(id_event_contact_type)
      ) {
        document.getElementById(
          `coordinateur_${contact_cycle_lang.id_cycle_lang}`
        ).innerHTML += res;
      } else if (
        cycle_lang_type === "collectif_sectoriel" &&
        [146].includes(id_event_contact_type)
      ) {
        document.getElementById(
          `coordinateur_${contact_cycle_lang.id_cycle_lang}`
        ).innerHTML += res;
      } else {
        //document.getElementById(`div_${contact_cycle_lang.id_cycle_lang}`).innerHTML += res;
      }
    }
  };

  const fetchContact = async ({ contact_cycle_lang }) => {
    await fetch(
      `${API_URL}?action=getContact&id_contact=${contact_cycle_lang.id_contact}`
    )
      .then((res) => res.json())
      .then((contact) => {
        displayContact({
          contact: contact,
          contact_cycle_lang: contact_cycle_lang,
        })
      })
  }


  useEffect(() => {

    const fetchContactCycleLangByCycleLang = async () => {
      await fetch(
        `${API_URL}?action=fetchContactCycleLangByCycleLang&id_cycle_lang=${id_cycle_lang}&filter=AND id_event_contact_type IN(207, 206, 205, 147, 146)`
      )
        .then((res) => res.json())
        .then((contactCycleLangList) => {
          contactCycleLangList.map((contact_cycle_lang) =>
            contact_cycle_lang.id_contact !== "undefined"
              ? fetchContact({ contact_cycle_lang })
              : ""
          );
        });
    }
    fetchContactCycleLangByCycleLang();
  }, [id_cycle_lang]);

  return <div key={id_cycle_lang}></div>;
}

export default CollectifAnnuaireItemContact;
