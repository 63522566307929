import React, { useEffect, useState } from 'react'
import LayoutAccount from './LayoutAccount2'
import LayoutAccountEvent from './LayoutAccountEvent'
import { BreadcrumbsPitch } from './Breadcrumbs'
import useApiUrlTranslation from '../useApiUrlTranslation'

const Pitch = () => {

    const [parcoursEval, setParcoursEval] = useState(false);

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation()

    // const { currentEvent } = useContext(eventContext);

    const getParcoursEval = async () => {


        //await   fetch(`${API_URL}?action=getParcoursEval&params= AND id_event=${currentEvent.id_event} AND id_contact=${user.id_contact}`)
        await fetch(`${apiUrlTranslation}?action=getParcoursEval&params= WHERE id_event=1789`)
            .then(res => res.json())
            .then(parcoursEval => {
                setParcoursEval(parcoursEval)
            })

    }

    useEffect(() => {
        getParcoursEval()
    }, [])

    return (
        <LayoutAccountEvent>
            <BreadcrumbsPitch />

            {parcoursEval && parcoursEval.map(parcoursEval => <div>

                <table>
                    <td>  {parcoursEval.presentation}</td>
                    <td>  {parcoursEval.email}</td>
                </table>

            </div>)}
        </LayoutAccountEvent>
    )
}

export default Pitch