import React, { useContext, useEffect } from 'react'
import LayoutAccount from './LayoutAccount2'
import UserContext from '../../contexts/UserContext';
import eventContext from '../../contexts/EventContext';
import { use } from 'i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const UserEventRoles = () => {

    const userContext = useContext(UserContext);

    const { idEvent } = useParams();

    const { getEventByIdEvent, currentEvent } = useContext(eventContext);

    const { user, userContactConferencierList, userCycleLangList, userRoleList, getUserRoles, logout } = userContext;

    useEffect(() => {

        if (!currentEvent) {
            getEventByIdEvent(idEvent)
        }



    }, [currentEvent])


    if (!currentEvent) {
        return <div>Chargement...</div>

    }

    return (
        <LayoutAccount>


            <h1 className="py-5 text-2xl font-bold">Mes rôles et engagements dans le cadre de <span className="text-pink-600"> {currentEvent.nom}</span></h1>
            {userRoleList && userRoleList
                .filter(userRole => userRole.event.id_event === currentEvent.id_event)
                .map(userRole =>

                    <li>

                        {userRole.statut.libelle}</li>
                )}


        </LayoutAccount>
    )
}

export default UserEventRoles