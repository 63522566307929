import React from 'react'

const Input = ({ label, value, handleChange, name, inputType }) => {



    if (inputType === 'textarea') {


        return (
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label htmlFor={label} className=" capitalize block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                    {label}
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <textarea
                        onChange={handleChange}
                        id={label}
                        name={name}

                        autoComplete="given-name"
                        className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                        value={value} />
                </div>
            </div>
        )

    }

    return (
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
            <label htmlFor={label} className=" capitalize block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                {label}
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
                <input
                    onChange={handleChange}
                    id={label}
                    name={name}
                    type="text"
                    autoComplete="given-name"
                    className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    value={value} />
            </div>
        </div>
    )
}



export default Input