import moment from 'moment';
import React, { useState, useEffect } from 'react'
import Moment from 'react-moment';
import { useParams } from "react-router-dom";
import { API_URL } from '../../config';

const UserProgrammeContacts = () => {

    const { idProgramme } = useParams();


    const [programme, setProgramme] = useState(null)

    const [contactCycleLang, setContactCycleLang] = useState(null)


    const fetchProgramme = async () => {

        await fetch(`${API_URL}?action=getDemandesReferencement&id_demande_referencement_programme_innovation=${idProgramme}`)
            .then(response => response.json())
            .then(result => {

                const doss = result[0];

                setProgramme(doss);

                fetchContactCycleLang(doss.id_cycle_lang)
            })
    }

    const fetchContactCycleLang = async (id_cycle_lang) => {

        const icl = id_cycle_lang ? id_cycle_lang : programme.id_cycle_lang;

        const resultat = await fetch(`${API_URL}?action=fetchContactCycleLangByCycleLang&id_cycle_lang=${icl}`)
            .then(res => res.json())
            .then(result => setContactCycleLang(result))

        return resultat
    }

    useEffect(() => {

        fetchProgramme()

    }, [])

    return (
        <table className='table table-bordered table-sm table-hover table-striped'>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Prénom</th>
                    <th>Nom</th>
                    <th>Rôle</th>
                    <th>Société</th>
                    <th>E-mail</th>
                    <th>Mobile</th>
                    <th>Pays</th>
                    <th>Date création</th>
                </tr>
            </thead>

            <tbody>
                {contactCycleLang && contactCycleLang.map((ccl, i) =>
                    <tr key={i}>
                        <th>{i + 1}</th>
                        <td>{ccl.contact.prenom}</td>
                        <td>{ccl.contact.nom}</td>
                        <td><span class="badge bg-primary">{ccl.event_contact_type.libelle}</span></td>
                        <td>{ccl.contact.societe}</td>
                        <td>{ccl.contact.mail}</td>
                        <td>{ccl.contact.port}</td>
                        <td>{ccl.contact.pays}</td>
                        <td> <Moment date={ccl.date_creation} format="D MMM Y" /></td>
                    </tr>)}
            </tbody>
        </table>
    )
}

export default UserProgrammeContacts