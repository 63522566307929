import React from 'react'
import LayoutAccount from './LayoutAccount'
import NavProgramme from './NavProgramme'
import UserProgrammes from './UserProgrammes'

const PrestaByUserProgramme = () => {

    return <LayoutAccount title="Solutions affectées"
        NavProgramme={<NavProgramme />}>
        <UserProgrammes />
    </LayoutAccount>

}

export default PrestaByUserProgramme