import { useCallback, useState } from "react";
import Slider from "@material-ui/core/Slider";
import Cropper from "react-easy-crop";

import { createImage, getRadianAngle, rotateSize } from "./Crop";

import "./EasyCrop.css";

const EasyCrop = ({ image, setImageData, croppedImage, setCroppedImage, set, srcFile }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    //const [croppedImage, setCroppedImage] = useState(null);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const getCroppedImg = async (
        imageSrc,
        pixelCrop,
        rotation = 0,
        flip = { horizontal: false, vertical: false }
    ) => {
        const image = await createImage(imageSrc);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (!ctx) {
            return null;
        }

        const rotRad = getRadianAngle(rotation);

        const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
            image.width,
            image.height,
            rotation
        );

        // set canvas size to match the bounding box
        canvas.width = bBoxWidth;
        canvas.height = bBoxHeight;

        ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
        ctx.rotate(rotRad);
        ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
        ctx.translate(-image.width / 2, -image.height / 2);

        ctx.drawImage(image, 0, 0);

        const data = ctx.getImageData(
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height
        );


        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;

        ctx.putImageData(data, 0, 0);

        console.log("toDataURL", canvas.toDataURL(srcFile.type))
        setImageData(canvas.toDataURL(srcFile.type))

        const croppedImage = new Promise((resolve, reject) => {
            canvas.toBlob((file) => {
                resolve(URL.createObjectURL(file));
            }, "image/png");
        });

        const dataUrl = canvas.toDataURL(srcFile.type);

        return croppedImage
    }


    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                image,
                croppedAreaPixels,
                rotation
            );
            console.log("donee", { croppedImage });

            setCroppedImage(croppedImage);
        } catch (e) {
            console.error(e);
        }
    }, [croppedAreaPixels, rotation, image]);

    const onClose = useCallback(() => {
        setCroppedImage(null);
    }, []);

    return (
        <div>
            {srcFile && srcFile.type}
            <button
                className="rounded-full bg-blue-500 text-white font-bold py-2 px-4 m-2"
                style={{
                    display: image === null || croppedImage !== null ? "none" : "block",
                }}
                onClick={showCroppedImage}
            >
                Recadrer
            </button>
            <div
                className="container"
                style={{
                    display: image === null || croppedImage !== null ? "none" : "block",
                }}
            >
                <div className="crop-container">
                    <Cropper
                        image={image}
                        crop={crop}
                        rotation={rotation}
                        zoom={zoom}
                        zoomSpeed={4}
                        maxZoom={3}
                        zoomWithScroll={true}
                        showGrid={true}
                        aspect={1 / 1}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        onRotationChange={setRotation}
                    />
                </div>
                <div className="controls">
                    <label>
                        Rotate
                        <Slider
                            value={rotation}
                            min={0}
                            max={360}
                            step={1}
                            aria-labelledby="rotate"
                            onChange={(e, rotation) => setRotation(rotation)}
                            className="range"
                        />
                    </label>
                    <label>
                        Zoom
                        <Slider
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="zoom"
                            onChange={(e, zoom) => setZoom(zoom)}
                            className="range"
                        />
                    </label>
                </div>
            </div>
            <div className="cropped-image-container">
                {croppedImage && (
                    <img className="cropped-image" src={croppedImage} alt="cropped" />
                )}
                {croppedImage && <button
                    className="rounded-full bg-blue-500 text-white font-bold py-2 px-4 m-2"
                    onClick={onClose}>Close X </button>}
            </div>
        </div>
    );
};

export default EasyCrop;