import React, { useEffect, useState } from 'react'
import LayoutAccount from './LayoutAccount2'
import { use } from 'i18next';
import useApiUrlTranslation from '../useApiUrlTranslation';
import { useParams } from "react-router-dom";
import HTMLReactParser from 'html-react-parser';
import Loader from '../Loader';


const DisplayConferencier = ({ conferencier }) => {

    return <tr className="">

        <td className="py-3">

            <div className="flex space-x-3 items-center ">
                <img src={conferencier.contact.photos.small} alt="" className="rounded-full h-20 w-20 object-cover" />
                <div>
                    <p className="text-sm font-bold">  {conferencier.contact.nom} {conferencier.contact.prenom}</p>
                    <p className="mt-1 truncate text-sm leading-5 text-gray-500">{conferencier.contact.fonction}</p>

                    <p className="mt-1 truncate text-sm font-bold leading-5 text-gray-500"> {conferencier.contact.societe}</p>

                    <div className=" line-clamp-2 text-xs leading-5 text-gray-500 mt-3">
                        {HTMLReactParser(conferencier.contact.edito_court ?? "")}

                        {HTMLReactParser(conferencier.contact.edito_long ?? "")}
                        {/* {conferencier.conferencier_statut.libelle}
                        {conferencier.conferencier_statut.id_event_contact_type} */}

                    </div>
                </div>
            </div>

        </td>
        <td className="whitespace-nowrap py-2 pl-2 pr-3 text-sm font-bold text-gray-900 sm:pl-0"></td>
        <td className="  whitespace-nowrap px-3 py-2 text-sm text-gray-500 truncate"></td>
        <td className="hidden whitespace-nowrap px-3 py-2 text-sm text-gray-500 truncate">
            <img src={conferencier.contact.logos.small} alt="" className=" h-10    object-cover" />
            {conferencier.contact.societe}</td>

        <td className=" hidden px-3 py-2 text-sm text-gray-500 truncate">
            <img src={conferencier.contact.flag} alt="" className=" h-10    object-cover" />
            {conferencier.contact.pays}</td>

        <td className="hidden">{conferencier.conferencier_statut.libelle} {conferencier.conferencier_statut.id_event_contact_type}</td>

    </tr >
}


const AnnuaireCategorie = ({ conferencierList, title, id_event_contact_type_List }) => {


    return (
        <div>

            <h1 className="mb-3 font-bold text-2xl">{title}</h1>


            <table className="min-w-full divide-y divide-gray-300">
                <tbody className="divide-y divide-gray-200">
                    {conferencierList && conferencierList

                        //trier par nom
                        .sort((a, b) => a.contact.nom.localeCompare(b.contact.nom))

                        //dedup by id_contact
                        .filter((v, i, a) => a.findIndex(t => (t.contact.id_contact === v.contact.id_contact)) === i)

                        //filter contact nom ne contient pas "davill"
                        .filter(conferencier => conferencier.contact.nom.toLowerCase().indexOf("davill") == -1)
                        .filter(conferencier => id_event_contact_type_List.includes(conferencier.conferencier_statut.id_event_contact_type))
                        .map((conferencier, index) =>
                            <DisplayConferencier key={conferencier.id_conferencier} conferencier={conferencier} />
                        )}
                </tbody>
            </table>
        </div>
    )
}

const Annuaire = () => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const { idEvent, annuaireFilter } = useParams();

    const [conferencierList, setConferencierList] = useState(false);

    const [idEventContactTypeList, setIdEventContactTypeList] = useState([]);
    const [annuaireTitle, setAnnuaireTitle] = useState("");

    const getConferenciers = async () => {
        //AND cf.statut IN(92, 'jury', 'jure')
        await fetch(`${apiUrlTranslation}?action=getPartenaires&params=AND cf.id_event=${idEvent}  GROUP BY CONCAT(prenom, nom) ORDER BY c.nom ASC`)
            .then(res => res.json())
            .then(res => {
                setConferencierList(res)
            })
    }

    useEffect(() => {



        switch (annuaireFilter) {
            case "jury":
                setIdEventContactTypeList(["92"]);
                setAnnuaireTitle("Les membres du jury");
                break;
            case "game-changers":
                setIdEventContactTypeList(["178", "208"]);
                setAnnuaireTitle("Les game changers");
                break;
            case "partenaire":
                setIdEventContactTypeList(["92"])
                break;
            default:
                setIdEventContactTypeList(["92"])
        }

        getConferenciers();


    }
        , [])



    return (
        <LayoutAccount>

            {conferencierList ? <AnnuaireCategorie
                id_event_contact_type_List={idEventContactTypeList}
                title={annuaireTitle}
                conferencierList={conferencierList} /> : <div className="h-screen flex flex-col items-center justify-center"><Loader /></div>}

        </LayoutAccount>
    )
}

export default Annuaire