import React, { useState, useEffect } from 'react'
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { API_URL } from '../config';
import { useParams } from "react-router-dom";
import Loader from "./Loader";
import LogoSlider from "./LogoSlider";

import './LogoSlider.css';
import { removeDuplicateObjectFromArray } from '../utils';

const PartenairesLogoSlider = ({ params, displayContactsCycleLang }) => {

    let { idEvent } = useParams();

    const [partenaires, setPartenaires] = useState([]);

    const [partenairesPermanents, setPartenairesPermanents] = useState([]);

    const fetchPartenaires = async () => {

        await fetch(`${API_URL}?action=getPartenaires&params= ${params ?? `AND id_event=${idEvent} GROUP BY c.id_contact`}`)
            .then(res => res.json())
            .then(partenaires => {

                const part = partenaires.map(partenaire => {

                    return {
                        logos: partenaire.contact.logos,
                        id_contact: partenaire.contact.id_contact,
                        societe: partenaire.contact.societe
                    }
                })

                setPartenaires(part);
            })
    }

    const fetchContactsCycle = async () => {

        await fetch(`${API_URL}?action=fetchContactCycleLang&filter= id_event_contact_type IN(227) AND & group_by=GROUP BY societe`)
            .then(res => res.json())
            .then(res => {

                const dedupArrayByICL = removeDuplicateObjectFromArray(res, 'id_cycle_lang');

                const part = dedupArrayByICL.map(partenaire => {

                    return {
                        logos: partenaire.contact.logos,
                        id_contact: partenaire.contact.id_contact
                    }
                })

                setPartenairesPermanents(part);
            })
    }

    useEffect(() => {
        console.log(partenaires, partenairesPermanents)
        fetchPartenaires();
        displayContactsCycleLang && fetchContactsCycle();
    }, [])

    if (partenaires.length === 0 && partenairesPermanents.length === 0 && idEvent !== 1715) {
        return ''
    }

    if (["1779", "1822"].includes(idEvent)) {

        return <LogoSlider partenaires={[...partenaires]} />
    }

    return <LogoSlider partenaires={[...partenaires, ...partenairesPermanents]} />
}

export default PartenairesLogoSlider