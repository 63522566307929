import React, { useEffect, useContext } from 'react';
import Layout from './Layout'
import EventContext from '../contexts/EventContext';
import EventCard from './EventCard'
import Loader from './Loader';
import Moment from 'react-moment';
import moment from 'moment';
import { useTranslation } from 'react-i18next';



const Calendar = ({ eventFormatFilter, pageTitle }) => {

    const { t, i18n } = useTranslation();

    const eventContext = useContext(EventContext);

    const { getEvents, events, loading } = eventContext;

    useEffect(() => {
        getEvents("&futur=y")

        console.log("eventFormatFilter", eventFormatFilter)
    }, [i18n.resolvedLanguage, eventFormatFilter])

    return (
        <Layout title={t('nav.next_events')} darkmode>

            <p className="dark:text-white my-5 mx-auto max-w-7xl"> {t('sections.evenements_a_venir.subtitle')}</p>


            {events.length === 0 ? <div className="container"><Loader /></div> : ''}
            <div className="container divide-y-neutral-900">

                {/*filter(event => new Date(event.event_start) >= new Date() && event.afficher != 'n')*/}
                {events.length > 0 && events
                    .filter(event => eventFormatFilter ? eventFormatFilter.includes(event.event_ligne?.id_event_format?.id_event_format) : true)
                    .filter(event => event.afficher !== 'n' && event.event_ligne?.id_event_format?.id_event_format !== 4)
                    .map(event => {

                        return <div key={event.id_event}>
                            <EventCard event={event} /></div>
                    })}
            </div>

        </Layout>
    )
}

export default Calendar