import React, { useState, useEffect } from "react";
import Layout from "./Layout";
import { useParams } from "react-router-dom";
import { API_URL } from "../config";
import HTMLReactParser from "html-react-parser";
import Loader from "./Loader";
import useApiUrlTranslation from "./useApiUrlTranslation";

const DemoDetails = () => {

  const { i18n, apiUrlTranslation } = useApiUrlTranslation()

  let { id_presta } = useParams();

  const [demo, setDemo] = useState(null);


  function extractYouTubeVideoId(url) {
    // Modèle de regex pour extraire l'ID de la vidéo YouTube
    var regex = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\r\n]+)/;

    // Utiliser la regex pour extraire l'ID de la vidéo
    var match = url.match(regex);

    if (match && match[1]) {
      return match[1];
    } else {
      // Gérer le cas où l'URL n'est pas valide
      return null;
    }
  }


  useEffect(() => {

    const fetchGetDemo = async () => {

      await fetch(`${apiUrlTranslation}?action=getDemo&id_demo=${id_presta}`)
        .then((res) => res.json())
        .then((res) => setDemo(res));
    }

    fetchGetDemo();
  }, [i18n.resolvedLanguage]);


  if (!demo) {
    return false
  }


  return <Layout className="  mx-auto dark:text-neutral-300 px-5">
    <div className="flex p-3 border-bottom mb-3">
      <img
        className="rounded"
        alt="visuel de la démonstration"
        src={!demo.visuels.medium.search("no_picture") ? demo.visuels.medium : demo.contact.logos.large}
        style={{ maxWidth: "200px", objectFit: "contain" }}
      />

      <h1 className="text-5xl p-3 align-right text-end">
        {demo.contact.societe} Présente : {demo.presta_nom}
      </h1>
    </div>

    <h2 className="p-2 my-5">Descriptif de la démo</h2>
    <p className="text-start p-2">{HTMLReactParser(demo.presta_accroche ?? "")}</p>

    {demo.video_url && (
      <div>


        <h2 className="p-2 my-5 ">Vidéo de présentation</h2>
        <iframe
          className="rounded-2xl"
          width="100%"
          height="720"
          src={`https://www.youtube.com/embed/${extractYouTubeVideoId(demo.video_url)}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    )}

    <h2 className="p-2 my-5">A propos de {demo.contact?.societe}</h2>
    <span className="p-2 fw-bold">
      {demo.contact?.prenom} {demo.contact?.nom} -{" "}
      {demo.contact?.societe}
    </span>
    <p className="p-2">{HTMLReactParser(demo.contact?.edito_long ?? "")}</p>
  </Layout>


}

export default DemoDetails;


