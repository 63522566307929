import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';
import { API_URL } from '../../config';
import UserContext from '../../contexts/UserContext';
import LayoutAccount from './LayoutAccount'
import { CalendarIcon, MapPinIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { sortByAttribute } from '../../utils/';
const UserEvents = () => {

    const userContext = useContext(UserContext);

    const { user } = userContext;

    const [events, setEvents] = useState([]);

    const [eventsFutur, setEventsFutur] = useState([]);

    const [eventsPast, setEventsPast] = useState([]);

    const DisplayEvents = ({ events, title }) => {

        return <>
            <p className='my-2 text-3xl '>{title}</p>
            <ul className='divide-y divide-gray-200 mb-5'>


                {events && events.map(event => {

                    const now = moment().format('Y-MM-DD');
                    const eventDate = moment(event.event_start).format('Y-MM-DD');
                    return <li className='' key={event.id_event}>
                        <Link to={`event/${event.id_event}`} className="flex hover:bg-gray-50 p-2">
                            <div className='min-w-0 flex-1 sm:flex sm:items-center sm:justify-between py-2'>

                                <div className=''>
                                    <div className='text-gray-900 font-bold'>{event.nom}</div>
                                    <div className={`d-none alert alert-${moment(now).isBefore(eventDate) ? 'success' : 'danger'}`}>
                                        {now} -
                                        {eventDate} -

                                        {moment(eventDate).isAfter('2021-10-19') ? 'true' : 'false'}
                                        {event.event_start}
                                    </div>
                                    <div className='flex flex-col sm:flex-row'>
                                        <div className='mt-2 flex items-center text-sm text-gray-500 sm:mt-0'>
                                            <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" /> {event.precision_date}
                                        </div>
                                        <div className='sm:ml-5 mt-2 flex items-center text-sm text-gray-500'>
                                            <MapPinIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" /> {event.lieu.lieu_nom} - {event.lieu.lieu_pays}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div>

                                        {
                                            moment(eventDate).isAfter('2024') &&

                                            <div className="flex justify-between items-center hover:bg-blue-600 bg-blue-500 text-white p-2 px-3 rounded-full  text-xs lg:mt-0">
                                                Editer
                                                <ChevronRightIcon className='h-4' />
                                            </div>}

                                    </div>
                                </div>
                            </div>
                        </Link>  </li>
                }
                )}


            </ul >
        </>
    }

    useEffect(() => {
        const fetchEvents = async () => {

            await fetch(`${API_URL}?action=getEvents&id_contact=${user.id_contact}`)
                //await fetch(`${API_URL}?action=getEvents&params=WHERE events.id_event !=399 AND `)
                .then(res => res.json())
                .then(res => {
                    setEvents(res)

                    const pastEvents = sortByAttribute(res, 'event_start', 'asc');

                    setEventsFutur(res.filter(event => {
                        const start = moment(event.event_start);
                        return event.event_start != '0000-00-00 00:00:00' && start.isAfter(moment().format('YYYY-MM-DD'))
                    }))

                    setEventsPast(pastEvents.reverse().filter(event => {
                        const start = moment(event.event_start);
                        return event.event_start != '0000-00-00 00:00:00' && start.isBefore(moment().format('YYYY-MM-DD'))
                    }))

                })
        }


        fetchEvents();


    }, [])

    return (
        <LayoutAccount title="">

            {/** <h2 className='my-2'>Mes événements</h2>*/}
            {/*events && displayEvents(events) */}

            {eventsFutur && sortByAttribute(eventsFutur, 'event_start') && <DisplayEvents
                title="Mes événements à venir"
                events={eventsFutur} />}

            {eventsFutur.length == 0 ? <div className="alert alert-info">Aucun événemnt à venir</div> : ""}

            <hr className="text-gray-400 pb-3" />
            {eventsPast && sortByAttribute(events, 'event_start') && <DisplayEvents
                title="Mes événements passés"
                events={eventsPast} />}

            {eventsPast.length == 0 ? <div className="alert alert-info">Aucun événement passé</div> : ""}


        </LayoutAccount>
    )
}

export default UserEvents