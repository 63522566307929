import React from 'react'
import './VideoCover.css'

const VideoCover = ({ children, hide }) => {

    if (hide) {

        return false
    }

    return (<>

        <div className="relative flex  justify-center h-screen  ">
            <video
                className="absolute top-0 left-0 w-full h-full object-cover z-0 "
                playsInline
                autoPlay
                muted
                loop
            >
                <source
                    src="//www.mlg-consulting.com/manager_cc/docs/archives/french_village_video_cover_2018_1.mp4"
                    type="video/mp4"
                />
            </video>

            <div className="relative z-10 text-white text-center">
                {children}
            </div>
        </div>
    </>

    )
}

export default VideoCover