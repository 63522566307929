import React from 'react'
import PageSection from './PageSection'

const Interstellar = () => {
    return (
        <PageSection>
            <div className="">

                <div className="relative max-w-7xl mx-auto">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="w-full border-t border-neutral-900 py-5" />
                    </div>
                    <div className="relative flex justify-center">
                        <span className="bg-white px-2 text-sm text-neutral-800"></span>
                    </div>
                </div>



                <img className="mx-auto mb-5 w-96"
                    src="https://static.wixstatic.com/media/70ca53_fc4dce298da54a9bb3c38a7c1c0c05db~mv2.png/v1/crop/x_8,y_22,w_1869,h_306/fill/w_281,h_50,fp_0.50_0.50,q_85,usm_0.66_1.00_0.01,enc_auto/Interstellar%20Soiree_Light.png" alt="" />



                <a className="hidden bg-yellow-200 px-4 py-3 my-3 rounded-full text-xl">Register now</a>

                <iframe
                    className='mt-5 w-100 aspect-[3/2]'
                    src="https://www.youtube.com/embed/58OpE1HGGTA?si=YDBW88V6lXSQF-qG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

            </div>
        </PageSection>
    )
}


export default Interstellar 