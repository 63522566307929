import EventContext from "../contexts/EventContext";
import React, { useContext } from "react";

function SocialMedias() {

  const eventContext = useContext(EventContext);
  const { currentEvent } = eventContext;

  return (
    <div>
      {currentEvent && (
        <div className="container d-flex justify-content-center">

          <a
            href={
              currentEvent.event_ligne?.facebook_id
                ? `facebook.com/${currentEvent.event_ligne?.facebook_id}`
                : ""
            }
          >
            <i className="fab fa-facebook-square fa-7x"></i>
          </a>


          <a
            href={
              currentEvent.event_ligne?.id_youtube
                ? `youtube.com/${currentEvent.event_ligne?.id_youtube}`
                : ""
            }
          >
            <i className="fab fa-youtube-square  px-5 fa-7x"></i>
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href={
              currentEvent?.event_ligne?.twitter_id
                ? `twitter.com/${currentEvent.event_ligne?.twitter_id}`
                : ""
            }
          >
            <i className="fab fa-twitter-square  fa-7x"></i>
          </a>

        </div>
      )
      }
      <h2>{currentEvent?.event_ligne?.hashtag && currentEvent.event_ligne?.hashtag}</h2>
    </div >
  );
}

export default SocialMedias;
