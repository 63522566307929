import React, { createContext, useReducer } from 'react';
import DarkModeContext from "./DarkModeContext";
import DarkModeReducer from "./DarkModeReducer";
import useLocalStorage from '../components/Hooks/useLocalStorage';



const DarkModeProvider = ({ children }) => {

    const [darkMode, setDarkMode] = useLocalStorage('darkMode', true);

    const initialState = {
        // Définir vos propriétés d'état initial ici
        darkMode: darkMode,
        toggleDarkMode: () => { },
    };

    const [state, dispatch] = useReducer(DarkModeReducer, initialState);

    const toggleDarkMode = () => {

        setDarkMode(!state.darkMode || false);
        dispatch({
            type: 'TOGGLE_DARK_MODE',
            payload: !state.darkMode,
        });
    };

    return (
        <DarkModeContext.Provider value={{ darkMode: state.darkMode, toggleDarkMode }}>
            {children}
        </DarkModeContext.Provider>
    );
};

export default DarkModeProvider;
