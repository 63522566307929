import { Splide, SplideSlide } from "@splidejs/react-splide";
import HTMLReactParser from "html-react-parser";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { API_URL } from "../config";
import EventContext from "../contexts/EventContext";


function VideoSlider() {

    let { idEvent } = useParams();

    const [videoList, setVideoList] = useState(null);

    const eventContext = useContext(EventContext);
    const { currentEvent } = eventContext;

    const fetchVideos = async () => {
        const url = ` ${API_URL}?action=getVideosEvents&id_event=${idEvent}`;

        await fetch(url)
            .then((res) => res.json())
            .then((video) => setVideoList(video));
    };

    useEffect(() => {
        fetchVideos();
    }, []);


    const primaryOptions = {
        type: 'loop',
        perPage: 5,
        breakpoints: {
            1000: {
                perPage: 3,
            },
            500: {
                perPage: 2,
            },
        },
        perMove: 5,

        pagination: false,
        autoplay: true,
        height: 'auto',
    }

    if (!videoList) {
        return null
    }

    return (
        <Splide options={primaryOptions}>

            {videoList &&
                videoList.map((video, i) => (
                    <SplideSlide className="row">
                        <Link
                            to={`/events/${idEvent}/replay/${video.id_video.id_video}/replay-details`}
                            target="_blank"
                            key={i}
                            className=" col text-dark text-decoration-none text-wrap p-0" >
                            <div class="card border-0">
                                <img alt="card-img-top" src={video.id_video.thumbnail_medium_url} style={{ maxHeight: "160px", objectFit: "cover" }} />
                                <div className="card-body">
                                    <b class="card-title">{HTMLReactParser(video.id_video.titre)}</b>
                                </div>
                                {/*<div className="card-footer">
                                    <small className="text-muted">{video.id_video.date_creation}</small>
                                </div>*/ }
                            </div>
                        </Link>
                    </SplideSlide>
                ))
            }
        </Splide>
    )
}

export default VideoSlider;
