import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import RegistrationFormSection from './RegistrationFormSection';
import useApiUrlTranslation from './useApiUrlTranslation';
import HTMLReactParser from 'html-react-parser';

const RegistrationFormStatut = ({ idStatutSelection, formulaire, contact }) => {

    const [statutList, setStatutList] = useState(null);

    const { register } = useFormContext();

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [contactStatutList, setContactStatutList] = useState([]);


    const handleChange = (e) => {

    }

    const getContactStatutList = async ({ contact }) => {

        await fetch(`${apiUrlTranslation}?action=getContactStatutList&filter= WHERE id_contact=${contact.id_contact}`)
            .then(res => res.json())
            .then(res => {
                setContactStatutList(res);
            })
    }

    useEffect(() => {

        const fetchStatut = async () => {

            let statutListFormated = [];
            let check = '';

            if (typeof idStatutSelection === "string") {
                check = 'string';
                statutListFormated = idStatutSelection.split(",").map(id => parseInt(id));
            } else {
                statutListFormated = idStatutSelection;
            }

            await fetch(`${apiUrlTranslation}?action=getStatuts&filter= id_statut IN (${statutListFormated && statutListFormated.join(",")}) `)
                .then(res => res.json())
                .then(res => setStatutList(res))
        }

        fetchStatut();

        contact && getContactStatutList({ contact });

    }, [i18n.resolvedLanguage, contact]);

    return <div className="row divide-y">

        {/* {JSON.stringify(contactStatutList.map(statut => statut.id_statut))} */}

        {statutList && statutList
            .filter(statut => idStatutSelection.includes(parseInt(statut.id_statut)))
            //trier par statut_libelle
            .sort((a, b) => a.statut_libelle.localeCompare(b.statut_libelle))
            // order  by statut_order asc

            .sort((a, b) => a.statut_order - b.statut_order)
            .map(statut => {

                const attachedFile = statut.attachedFileCollection && statut.attachedFileCollection.find(attachedFile => attachedFile && attachedFile.id_attached_file_type.id_attached_file_type == 103)
                return <div div
                    key={statut.id_statut}
                    className="hover:bg-gray-100" >
                    <div className="form-check flex ">

                        {attachedFile && <img src={attachedFile.file_name} alt={attachedFile.attachedFile_nom} className="w-8" />}

                        <label className="form-check-label" style={{ fontWeight: "normal" }}>
                            <input  {...register("statutList")}
                                onChange={handleChange}
                                checked_={contactStatutList && contactStatutList.map(statut => statut.id_statut).includes(statut.id_statut)}
                                type="radio"
                                id=""
                                name="statutList"
                                value={statut.id_statut}
                                data-placeholder="" />  {statut.statut_libelle != "" ? HTMLReactParser(statut.statut_libelle ?? "") : HTMLReactParser(statut.statut_nom ?? "")}
                        </label>
                    </div>
                </div>
            }
            )}
    </div >

}

export default RegistrationFormStatut;