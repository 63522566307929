import React from "react";

function PartenaireCard({ Partenaire }) {

  console.log(Partenaire)

  return (
    <div className="card shadow-sm p-2  rounded-4 border-0">

      <div className="aspect-w-3 aspect-h-2  h-52">
        <img
          src={Partenaire?.photos?.large ? Partenaire.photos.large : ""}
          className="w-full h-full object-cover rounded-4 ma"
          alt="..."
        />
      </div>

      <div className="card-body">
        <h4 className="card-title mt-8 font-display text-xl font-bold tracking-tight text-slate-900">
          {Partenaire.contact.nom} {Partenaire.contact.prenom}
        </h4>
        <h5 className="card-title fw-bolder ">{Partenaire.conferencier_statut.event_contact_type_nom} {/*Partenaire.conferencier_statut.id_event_contact_type*/}</h5>
        <p className="card-text mt-1 text-base tracking-tight text-slate-500">{Partenaire.contact.societe}</p>
        <p className="d-flex pt-2  text-slate-500 text-xs"> {Partenaire.contact.pays}  <img className="pl-3" src={Partenaire.contact.flag} alt="" style={{ width: '30px' }} /></p>
      </div>
    </div >
  )
}

export default PartenaireCard;
