import parse from 'html-react-parser';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from './Layout';
import './Palmares.css';

const Palmares = ({ getAllCandidats }) => {

    const { idEvent } = useParams();

    const [palmares, setPalmares] = useState();

    const getPalmares = async () => {

        await fetch(`https://www.mlg-consulting.com/websites/g_event_beta/views/all_winners/index.php${idEvent ? `?id_event=${idEvent}` : ``}${getAllCandidats ? `&get_all_candidats=1` : ``}`)
            .then(res => res.json())
            .then(res => setPalmares(res))
    }

    useEffect(() => {
        getPalmares()
    }, []);

    return <Layout><div>{palmares && parse(palmares)}</div></Layout>
};

export default Palmares;
