import React, { useState, useEffect, useContext } from 'react'
import Layout from './Layout'
import useApiUrlTranslation from './useApiUrlTranslation';
import { Link, useParams } from 'react-router-dom';
import { Divider } from '@material-ui/core';
import { removeDuplicateObjectFromArray, sortByAttribute } from '../utils';

import EventContext from '../contexts/EventContext';
import HTMLReactParser from 'html-react-parser';
import Loader from './Loader';
import ConferencierItem from './ConferencierItem';

import RegistrationLinkListCollectif from './RegistrationLinkListCollectif';

const EventCycleLangCoordinateur = ({ eventCycleLang, event, DisplayContacts }) => {

    return "EventCycleLangCoordinateurEventCycleLangCoordinateurEventCycleLangCoordinateur"
}

const RegistrationLinkListDelegation = (eventCycleLang, currentEvent) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    return <> <a target="_blank" className="text-right basis-1/6 hover:text-black flex flex-col justify-center p-1" href={`https://www.mlg-consulting.com/myglobalvillage/sign.php?ie=${currentEvent.id_event}&iecl=${eventCycleLang.id_event_cycle_lang}`}>
        <p className="text-blue-700 pt-2 pb-2">
            {t('pages.collectifs_contextuels.je_souhaite_participer')} {' '}
            {eventCycleLang.event_cycle_lang_nom}</p>
    </a></>
}


const EcosystemItem = ({ item }) => {

    const eventContext = useContext(EventContext);

    const { idEvent } = useParams();

    const { getEventByIdEvent, currentEvent } = eventContext;

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [communauteList, setCommunauteList] = useState(false);

    const getCommunaute = async (id_conferencier) => {

        await fetch(`${apiUrlTranslation}?action=getCommunaute&filter= AND cf.id_conferencier=${item.id_conferencier}&vv=${Date.now()}`, {
            cache: 'no-cache'
        })
            .then(res => res.json())
            .then(res => {
                setCommunauteList(res)
            })
    }

    useEffect(() => {
        getCommunaute();
    }, [i18n.resolvedLanguage, item])


    return <li className='shadow-sm  flex flex-row rounded p-2 border border-neutral-800 hover:border-neutral-700 justify-bettween bg-white'>

        <div className='flex flex-col md:flex-row basis-3/4 '>
            <div className=' basis-1/2'>
                {communauteList && communauteList.map(communaute => <div className='flex flex-col md:flex-row'>
                    <div>
                        <div className='mb-2 font-bold uppercase'>{communaute.cycle_lang.nom_cycle_lang}</div>
                        <img src={communaute.cycle_lang.logo_better.small} alt="" className='w-28 mr-3 ' />
                    </div>
                    <RegistrationLinkListCollectif eventCycleLang={communaute.id_communaute} />


                    <div className='mx-4 hidden'>
                        <ul className='list-disc list-inside'>
                            <li className='hidden'><Link target="_blank" rel="noreferrer" to={`/login-redirect?redirectUrl=https://myglobalvillage.com/events/${currentEvent.id_event}/signup/jury/ecl/${communaute?.id_communaute?.id_event_cycle_lang}/c/{id_contact}`} className="text-blue-600 underline">{t('event_nav.devenir_jury')}   {communaute.cycle_lang.nom_cycle_lang}</Link></li>
                            <li className='hidden'><Link target="_blank" rel="noreferrer" to={`/login-redirect?redirectUrl=https://myglobalvillage.com/events/${currentEvent.id_event}/signup/startup/ecl/${communaute?.id_communaute?.id_event_cycle_lang}/c/{id_contact}`} className="text-blue-600 underline ">{t('event_nav.devenir_candidat')}   </Link></li>
                            <li><Link target="_blank" rel="noreferrer" to={`/events/${currentEvent.id_event}/signup/jury/ecl/${communaute?.id_communaute?.id_event_cycle_lang}/`} className="text-blue-600 underline">{t('event_nav.devenir_jury')}   {communaute.cycle_lang.nom_cycle_lang}</Link></li>
                            <li><Link target="_blank" rel="noreferrer" to={`/events/${currentEvent.id_event}/signup/startup/ecl/${communaute?.id_communaute?.id_event_cycle_lang}/`} className="text-blue-600 underline ">{t('event_nav.devenir_candidat')}   </Link></li>
                        </ul>
                    </div>
                </div>)}
            </div>


            {<ConferencierItem conferencier={item} />}

        </div>

        <div className='basis-1/4 hidden'>  <a className='text-blue-500'>Plus</a></div>
    </li>
}



const EcosystemContactList = () => {

    const { currentEvent, currentEventEventCycleLangList, getEventCycleLangList } = useContext(EventContext);

    const { idEvent, idEventComposant } = useParams();

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [ecosystem, setEcosystem] = useState(false);

    const [eventContactTypeEventComposantList, setEventContactTypeEventComposantList] = useState(null);

    const [eventCycleLangList, setEventCycleLangList] = useState(null)

    const getEventContactTypeEventComposant = async () => {

        await fetch(`${apiUrlTranslation}?action=getEventContactTypeEventComposant&filter=WHERE id_event_composant=${idEventComposant} `)

            .then(res => res.json())
            .then(res => {
                setEventContactTypeEventComposantList(res)
                getPartenaires({ eventContactTypeEventComposantList: res })
            })
    }


    const getPartenaires = async ({ eventContactTypeEventComposantList }) => {

        const idEventContactTypeList = eventContactTypeEventComposantList && eventContactTypeEventComposantList.map(item => item.event_contact_type.id_event_contact_type).join(',');

        const idEventContactTypeNomList = eventContactTypeEventComposantList && eventContactTypeEventComposantList.map(item => item.event_contact_type.event_contact_type_nom).join('","');

        console.log(idEventContactTypeList, idEventContactTypeNomList)

        const suite = ` AND ( cf.statut IN (${idEventContactTypeList}) OR cf.statut IN ("${idEventContactTypeNomList}")) `;

        //const suite = ` AND ( cf.statut IN (${idEventContactTypeList})) `;

        await fetch(`${apiUrlTranslation}?action=getPartenaires&params= AND id_event=${idEvent} AND societe NOT LIKE '%test%' ${suite} `)
            .then(res => res.json())
            .then(res => {

                res.sort((a, b) => a.contact.societe.localeCompare(b.contact.societe));

                setEcosystem(res.filter(item => !["92", "178"].includes(item.conferencier_statut.id_event_contact_type)));

            })
    }

    useEffect(() => {

        currentEvent && getEventCycleLangList(idEvent);
        getEventContactTypeEventComposant();
    }, [i18n.resolvedLanguage, currentEvent])


    return (
        <Layout title={t('pages.coordinateurs_pitch.title')} className="bg-white">

            <div className='mt my-5 max-w-7xl mx-auto bg-blue-100 border-t-4 border-blue-500 rounded-b text-blue-700 px-4 py-3 shadow-md'>
                {HTMLReactParser(t('pages.coordinateurs_pitch.instructions'))}
            </div>

            <div className=''>
                <div className=" mx-3 my-5">
                    {HTMLReactParser(t('pages.coordinateurs_pitch.instructions_1'))}
                </div>

                {<ul className=' px-4 grid md:grid-cols-1  grid-cols-1 gap-2 '>
                    {eventContactTypeEventComposantList && ecosystem && ecosystem.map((item, index) => <EcosystemItem key={index} item={item} />)}
                </ul>
                }
            </div>

            {HTMLReactParser(t('pages.coordinateurs_pitch.instructions_2',
                { idEvent, event_nav_devenir_jury: t('event_nav.devenir_jury'), event_nav_devenir_candidat: t('event_nav.devenir_candidat') }))}


        </Layout>
    )
}

export default EcosystemContactList