import React, { useState } from 'react'
import Layout from './Layout'


// const initialState = { value: 0 }

// function counterReducer(state = initialState, action) {
//     // Check to see if the reducer cares about this action  
//     if (action.type === 'counter/incremented') {
//         // If so, make a copy of `state`    
//         return {
//             ...state,
//             // and update the copy with the new value      
//             value: state.value + 1
//         }
//     }
//     // otherwise return the existing state unchanged  return state
// }


const Counter = () => {
    // State: a counter value  
    const [counter, setCounter] = useState(0)
    // Action: code that causes an update to the state when something happens  
    const increment = () => { setCounter(prevCounter => prevCounter + 1) }
    // View: the UI definition  
    return (
        <Layout>
            <div className="container">
                Value: {counter} <button onClick={increment} className="btn btn-primary">Increment</button>
            </div>
        </Layout>
    )
}

export default Counter