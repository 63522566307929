import React, { useState, useEffect } from 'react'
import RegsistrationFormSection from './RegistrationFormSection'
import useApiUrlTranslation from './useApiUrlTranslation';
import { useFormContext } from "react-hook-form";
import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';


const DisplayRoleList = ({ activeProgramme, roleList, register, id_cycle_lang, displayInstructionFormulaire }) => {

    return <div>
        <div className="alert alert-info">Je souhaite me positionner sur le programme en tant que : </div>

        <ul>
            {activeProgramme && roleList && roleList.map(role => {

                const personalizedRole = displayInstructionFormulaire({
                    id_cycle_lang: activeProgramme.id_cycle_lang,
                    role: role
                })

                return <li style={{ listStyleType: "none" }}>
                    <input
                        {...register("contactProgrammeInnovationList")}
                        value={JSON.stringify({
                            id_programme_innovation: activeProgramme.id_demande_referencement_programme_innovation,
                            id_event_contact_type: role.id_event_contact_type,
                            id_cycle_lang: activeProgramme.id_cycle_lang
                        })}
                        type="checkbox" id="" /> {/* <b>{role.libelle} </b>:  {role.instructions_formulaire}*/}

                    {
                        id_cycle_lang && id_cycle_lang === "2270" ?
                            <> <b>{personalizedRole.libelle}</b> : {personalizedRole.instructions}</> : <><b>{role.libelle} </b>:  {role.instructions_formulaire}</>
                    }

                </li>
            })}
        </ul>
    </div>
}


const DisplayChildren = ({ cycleLang }) => {

    const { register } = useFormContext();

    const { t } = useApiUrlTranslation();

    return <div>
        <div className="alert alert-info">{parse(t('form.sections.plan_action.modal_instruction'))}</div>

        {cycleLang.children.map(child => <div key={child.id_cycle_lang} className="form-check">
            <label>  <input className="form-check-input" type="checkbox"

                {...register("cycleLangList")}
                value={child.id_cycle_lang}
                id="defaultCheck1" />{child.nom_cycle_lang}</label></div>)}

    </div>
}


const ModalPlanActionRoles = ({ programme, activeProgramme, roleList }) => {

    const { register } = useFormContext();

    const { id_cycle_lang, formName } = useParams();

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const displayInstructionFormulaire = ({ role }) => {

        let newRole = {
            ...role,
            libelle: role.libelle,
            instructions: role.instructions_formulaire
        }

        switch (role.id_event_contact_type) {

            case '229': newRole = {
                ...role,
                instructions: 'en tant que soutien, je m’engage à me déplacer le [date de l’événement] pour visiter le démonstrateur et en tant que « grand électeur » à élire les solutions présentées les plus performantes'
            }
                break;

            case '230': newRole = {
                ...role,
                libelle: 'Soutien officiel',
                instructions: `en tant que soutien officiel, je m’engage à constituer avec les
        décideurs de mon entreprise/territoire/organisation un ou plusieurs groupes de visite
        du démonstrateur. Je bénéficierai à cette occasion d’un ou plusieurs créneau(x) de
        visite privative, à l’heure qui me convient. Chacun des participants de mon groupe
        pourra élire et se mettre en relation avec les meilleures solutions d’avenir pour notre
        territoire/entreprise/organisation. J’ai noté que je prochainement recevrai la liste des
            créneaux de visite privatif du démonstrateur` }
                break;

            case '231': newRole = {
                ...role,
                libelle: 'Contributeur « détecteur de soutien »',
                instructions: `Je représente une organisation
        professionnelle, un grand-groupe, un pôle de compétitivité ou un territoire
        d’excellence. Je participe aux réunions dans lesquelles seront identifiés les
        thématiques et les groupes de visite qui pourront bénéficier de parcours privatifs du
        démonstrateur.`
            }
                break;

            case '232': newRole = {
                ...role,
                libelle: 'Contributeur « chasseur de solutions performantes »',
                instructions: `je suis une organisation
        professionnelle, un intégrateur, cluster ou pôle de compétitivité… je souhaite
        participer aux réunions de co-construction du démonstrateur. J’aide à identifier des
        solutions performantes/innovantes pour dans le démonstrateur`
            }
                break;
            case '233': newRole = {
                ...role,
                libelle: "Coordinateur général du démontreur",
                instructions: `je souhaite participer seul ou dans un comité de coordination à l’élaboration complète du démonstrateur.Je souhaite
        proposer une liste de solutions performantes « pressenties » issus de mon
        réseau/ écosystème à présenter sur le démonstrateur.Je souhaite également proposer
        des groupes d’acteurs susceptible de « visiter le démonstrateur le jour J ». Je rejoins
        également le « comité de sélection des démonstrations » qui arbitrera par ses votes
        éventuellement plusieurs solutions identiques afin de retenir celles qui seront
        présentées sur le démonstrateur le jour J.`
            }
                break;
        }

        return newRole;
    }

    return <div>
        <div className="modal fade" id={`planActionModal${programme.id_demande_referencement_programme_innovation}`} tabIndex="-1" aria-labelledby="planActionModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="planActionModalLabel"> {parse(t('form.sections.plan_action.modal_title'))} <b>{activeProgramme && activeProgramme.programm_title} </b></h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">

                        <img src={`http://www.mlg-consulting.com/manager_cc/docs/archives/${activeProgramme && activeProgramme.logo}`} alt="" style={{ width: '80px' }} />

                        {activeProgramme
                            && ['startup'].includes(formName)
                            && ['2270'].includes(id_cycle_lang)
                            && <DisplayChildren cycleLang={programme.cycle_lang} />}

                        {
                            formName === "planaction"
                            && <DisplayRoleList
                                activeProgramme={activeProgramme}
                                roleList={roleList}
                                register={register}
                                id_cycle_lang={id_cycle_lang}
                                displayInstructionFormulaire={displayInstructionFormulaire}
                            />
                        }


                    </div>
                    <div className="modal-footer">
                        {/**  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t('global.fermer')}</button>*/}
                    </div>
                </div>
            </div>
        </div>
    </div >
}

const Programme = ({ programme, setActiveProgramme, activeProgramme, roleList }) => {

    const { register } = useFormContext();
    const { formName } = useParams();

    const dataBsTarget = formName === 'startup-parcours' ? '' : `#planActionModal${programme.id_demande_referencement_programme_innovation}`;

    return <tr>
        <td>
            <input
                id={`p_${programme.id_demande_referencement_programme_innovation}`}
                type="checkbox"
                {...register("programmeList")}
                data-bs-target={dataBsTarget}
                data-bs-toggle="modal"
                onClick={() => setActiveProgramme(programme)}
                value={programme.id_demande_referencement_programme_innovation}
            /></td>
        <td><img src={`http://www.mlg-consulting.com/manager_cc/docs/archives/${programme.logo}`} alt="" style={{ width: '30px' }} /></td>
        <td><label htmlFor={`p_${programme.id_demande_referencement_programme_innovation}`}>
            <b>{programme.programm_title}</b><br />
            {parse(programme.ambition)}</label>

            <ModalPlanActionRoles
                activeProgramme={activeProgramme}
                roleList={roleList}
                programme={programme} />
        </td>
    </tr>
}

const RegistrationFormPlanActionSection = ({ setActiveProgramme, activeProgramme }) => {

    const { id_cycle_lang } = useParams();

    const [cycleLang, setCycleLang] = useState(null);

    const [roleList, setRoleList] = useState(null);

    const [programmeList, setProgrammeList] = useState(null);

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const { register } = useFormContext();

    useEffect(() => {

        const fetchProgrammes = async () => {

            await fetch(`${apiUrlTranslation}?action=getDemandesReferencement&id_cycle_lang=${id_cycle_lang} &params= AND publier_cl!=n`)
                .then(res => res.json())
                .then(res => setProgrammeList(res.filter(res => res.cycle_lang.publier_cl !== 'n')))
        }

        const fetchRoleList = async () => {

            await fetch(`${apiUrlTranslation}?action=getEventContactTypeList&filter= WHERE id_event_contact_type IN(230, 231, 232, 233)`)
                .then(res => res.json())
                .then(res => {

                    const resFilterd = id_cycle_lang == 2270 ? res.filter(item => item.id_event_contact_type != 229) : res

                    setRoleList(resFilterd);
                })
        }

        const fetchCycleLang = async (id_cycle_lang) => {

            await fetch(`${apiUrlTranslation}?action=getCycleLang&filter=id_cycle_lang=${id_cycle_lang} AND `)
                .then(res => res.json())
                .then(res => setCycleLang(res[0]))
        }

        fetchCycleLang();
        fetchRoleList();
        fetchProgrammes();

    }, [cycleLang, activeProgramme, i18n.resolvedLanguage]);

    return (
        <RegsistrationFormSection
            title={id_cycle_lang === '2270' ? t("form.sections.plan_action.section_title_equipauto") : t("form.sections.plan_action.section_title")}
            instructions={t("form.sections.plan_action.section_instructions")}>

            <table className="table table-sm">
                <tbody>
                    {id_cycle_lang && programmeList && programmeList.map(item => <Programme
                        key={item.id_demande_referencement_programme_innovation}
                        setActiveProgramme={setActiveProgramme}
                        activeProgramme={activeProgramme}
                        programme={item}
                        roleList={roleList} />)}
                </tbody>
            </table>
        </RegsistrationFormSection>
    )
}

export default RegistrationFormPlanActionSection