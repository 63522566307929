import React, { useContext } from 'react'
import useApiUrlTranslation from './useApiUrlTranslation';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import EventContext from '../contexts/EventContext';

const RegistrationLinkListCollectif = ({ eventCycleLang }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();
    const { currentEvent } = useContext(EventContext);

    return <div className='flex flex-col  '>

        <Link target="_blank" rel="noreferrer" to={`/login-redirect?redirectUrl=https://myglobalvillage.com/events/${currentEvent.id_event}/signup/jury/ecl/${eventCycleLang.id_event_cycle_lang}/c/{id_contact}`} className="text-blue-600 underline hidden">{t('event_nav.devenir_jury')}   {eventCycleLang?.cycle_lang?.nom_cycle_lang}</Link>
        <Link target="_blank" rel="noreferrer" to={`/login-redirect?redirectUrl=https://myglobalvillage.com/events/${currentEvent.id_event}/signup/startup/ecl/${eventCycleLang.id_event_cycle_lang}/c/{id_contact}`} className="text-blue-600 underline hidden ">{t('event_nav.devenir_candidat')}   </Link>
        <Link target="_blank" rel="noreferrer" to={`/events/${currentEvent.id_event}/signup/jury/ecl/${eventCycleLang.id_event_cycle_lang}/`} className="border p-1 rounded-full px-3  m-2 bg-gray-100">{t('event_nav.devenir_jury')}   {eventCycleLang.event_cycle_lang_nom}</Link>
        <Link target="_blank" rel="noreferrer" to={`/events/${currentEvent.id_event}/signup/startup/ecl/${eventCycleLang.id_event_cycle_lang}/`} className=" border p-1 rounded-full px-3 m-2  bg-gray-100">{t('event_nav.devenir_candidat')}    </Link>

    </div>
}



export default RegistrationLinkListCollectif