import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { API_URL } from '../config';

import RegistrationFormSection from './RegistrationFormSection';
import { BeakerIcon, DevicePhoneMobileIcon, EnvelopeIcon, EnvelopeOpenIcon } from '@heroicons/react/24/solid'
import useApiUrlTranslation from './useApiUrlTranslation';



const Coordinateur = ({ contact, event_contact_type, cycle_lang }) => {

    return <li className="flex p-2">
        <img className="h-10 w-10 rounded-full" src={contact.photos.small} alt="" />
        <div className="ml-3">
            <p className="text-sm font-medium text-gray-900">{contact.prenom} {contact.nom}</p>
            <p className="mt-2 flex items-center text-sm text-gray-500">
                <EnvelopeIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <span className="truncate">{contact.mail}</span>
            </p>
            <p className="mt-2 flex items-center text-sm text-gray-500">
                <DevicePhoneMobileIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <span className="truncate">{contact.port ?? contact.tel}</span>
            </p>
            <div className="rounded-full xl p-2 my-3 bg-neutral-200 hidden">
                {event_contact_type?.libelle} -
                {cycle_lang?.nom_cycle_lang}
            </div>
        </div>

    </li>

}

const RegistrationFormCoordinateurPresentationSection = () => {

    const { id_cycle_lang, formName, id_event_cycle_lang } = useParams();

    const [contactList, setContactList] = useState([]);

    const [coordinateurEventCycleLangList, setCoordinateurEventCycleLangList] = useState([]);

    const [contactCycleLangList, setContactCycleLangList] = useState([]);

    const [auteur, setAuteur] = useState(null);

    const [coord, setCoord] = useState(null);

    const { i18n, apiUrlTranslation } = useApiUrlTranslation()

    const fetchContact = async (coord) => {

        //retirer les items vides de coord

        await fetch(`${apiUrlTranslation}?action=getContactList&params= WHERE id_contact IN(${coord && coord
            .filter(coord => coord)
            .map(coord => coord).join(',')})  LIMIT 10`)
            .then(res => res.json())
            .then(res => setContactList(res))
    }


    const fetchContactCycleLang = async (id_event_cycle_lang) => {

        const url = new URL(window.location.href);
        const idCycleLangPreselectionList = url.searchParams.get('id_cycle_lang_preselection_list');

        await fetch(`${apiUrlTranslation}?action=fetchContactCycleLang&filter=id_cycle_lang IN(${idCycleLangPreselectionList}) AND `)
            .then(res => res.json())
            .then(res => setContactCycleLangList(res))
    }

    const getCommunaute = async (coord) => {

        await fetch(`${apiUrlTranslation}?action=getCommunaute&filter= AND  ecl.id_event_cycle_lang=${id_event_cycle_lang}`)
            .then(res => res.json())
            .then(res => setCoordinateurEventCycleLangList(res.filter(coord => ['202'].includes(coord.id_conferencier.statut.id_event_contact_type))
                .map(coord => coord.contact)))

    }

    useEffect(() => {

        const url = new URL(window.location.href);
        const auteur = url.searchParams.get('auteur');
        setAuteur(auteur);

        getCommunaute();
        fetchContactCycleLang();

        //218972 ines

        //let coord = id_cycle_lang === 2265 ? 221179 : 218972;




        switch (id_cycle_lang) {

            case '2265': fetchContact([221179])
                break;
            //222358 > molly
            // 222251> Thorel
            case '2270':
                if (formName == 'planaction') {
                    fetchContact([222358])
                } else if (formName == 'coordinateur-parcours') {
                    fetchContact([1389, 222358])
                } else if (formName == 'startup-onboarding') {
                    fetchContact([1389])
                } else {
                    fetchContact([222251, 222401, 222402])
                }

                break;

            default: if (formName == 'startup-onboarding') {
                fetchContact(auteur ? [auteur] : ["1389"])
            } else {
                fetchContact(auteur ? [auteur] : ["1389"])
            }

                break;
        }

    }, [i18n.resolvedLanguage, id_cycle_lang, auteur]);

    if (!id_cycle_lang) {

        //return false;
    }

    return <RegistrationFormSection title="Informations">


        {/* {JSON.stringify(contactCycleLangList)} */}

        <div className='overflow-hidden bg-white   sm:rounded-md'>
            <ul role="list" className="divide-y divide-gray-200">
                {contactCycleLangList && contactCycleLangList

                    .filter(contactCycleLang => ['207'].includes(contactCycleLang.id_event_contact_type))

                    //deduplicate by contact.id_contact
                    .filter((contactCycleLang, index, self) =>
                        index === self.findIndex((t) => (
                            t.contact.id_contact === contactCycleLang.contact.id_contact
                        ))
                    )
                    .filter(contactCycleLang => auteur ? contactCycleLang.contact.id_contact == auteur : true)
                    .filter(contactCycleLang => ['collectif_planaction', 'collectif_geographique'].includes(contactCycleLang.cycle_lang.cycle_lang_type))
                    .map(contactCycleLang => <Coordinateur
                        key={contactCycleLang.contact.id_contact}
                        contact={contactCycleLang.contact}
                        cycle_lang={contactCycleLang.cycle_lang}
                        event_contact_type={contactCycleLang.event_contact_type} />)}






                {coordinateurEventCycleLangList.length > 0 && coordinateurEventCycleLangList.map(contact =>

                    <Coordinateur contact={contact} />
                )}

                {contactList && contactList
                    //contact.id_contact not in contactCycleLangList.contact.id_contact 

                    .filter(contact => !contactCycleLangList.map(contact => contact.contact.id_contact).includes(contact.id_contact))
                    .filter(contact => !coordinateurEventCycleLangList.map(contact => contact.id_contact).includes(contact.id_contact))
                    .map(contact => <Coordinateur key={contact.id_contact} contact={contact} />)}
            </ul>
        </div>

    </RegistrationFormSection>
}

export default RegistrationFormCoordinateurPresentationSection;