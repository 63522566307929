import React, { useContext, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "./ProgrammDay.css";
import Loader from "./Loader";
import EventContext from "../contexts/EventContext";
import parse from "html-react-parser";
import Moment from "react-moment";
import useApiUrlTranslation from "./useApiUrlTranslation";
import { ArrowsPointingInIcon, BeakerIcon, FaceFrownIcon, MapIcon, MapPinIcon } from "@heroicons/react/24/solid";
import EnableLanguages from "./EnableLanguages";

const ConfEventCycleLang = ({ conf_event, getName, confEventCycleLangList }) => {

  const { apiUrlTranslation, i18n } = useApiUrlTranslation()

  const GetVisuelType = () => {

    return <div className="">
      {confEventCycleLangList && confEventCycleLangList.map(cecl => cecl.id_cycle_lang.attachedFilesCollection

        //filter by type
        .map(file => {

          const img = <img src={file.small} style={{ maxWidth: "100%" }} className="me-3" />

          if (["83"].includes(conf_event.type) && file.id_attached_file_type === 87) {
            return img
          }

          if (["91"].includes(conf_event.type) && file.id_attached_file_type === 92) {
            return img
          }

          if (['78'].includes(conf_event.type) && file.id_attached_file_type === "93") {
            return img
          }

          if (["67"].includes(conf_event.type) && file.id_attached_file_type === "84") {
            return img
          }

        }))
      }</div>
  }

  useEffect(() => {

  }, [i18n.resolvedLanguage, confEventCycleLangList])


  if (!confEventCycleLangList) return false;

  if (getName) {

    return <div className=" flex flex-wrap">
      {
        confEventCycleLangList && confEventCycleLangList.map(cecl => <div className="text-neutral-400 rounded-2xl bg-neutral-400/10 px-3 py-1 mx-2 mb-2 flex w-fit ">

          {cecl.id_cycle_lang.attachedFilesCollection.filter(file => file.id_attached_file_type == "65").map(file => file.small ? <img src={file.small} style={{ width: "20px", height: "20px" }} className="me-3 rounded-full" /> : '')}

          {cecl.id_cycle_lang.nom_cycle_lang_court != "" ? cecl.id_cycle_lang.nom_cycle_lang_court : cecl.id_cycle_lang.nom_cycle_lang} </div>
        )
      }
    </div>
  }


  return <GetVisuelType />

}


const ConfEvent = ({ confEvent, idEvent, confEventCycleLangList }) => {
  //demo pour pitch/parcours de visite
  const conf_event_array = ["48", "57", "63", "64", "66", "67", "74", "77", "88", "92", "97", "101", "116"];
  const conf_event_route = conf_event_array.includes(confEvent.type)
    ? `/events/${idEvent}/programm/${confEvent.id_conf_event}/parcours-details`
    : `/events/${idEvent}/programm/${confEvent.id_conf_event}/programm-details`;

  return (
    <div className="relative flex flex-row items-center space-x-5 bg-neutral-100  dark:bg-neutral-900 border-y border-x border-neutral-200 dark:border-neutral-800 hover:border-pink-500 rounded-2xl my-2" type={confEvent.type}>

      <div className="text-xl font-bold ml-3  basis-2/12  text-center text-pink-500">
        {confEvent.heure_debut_local} - {confEvent.heure_fin_local}

      </div>
      <div className=" text-left basis-10/12">
        <Link
          target="_blank"
          className="text-decoration-none dark:text-white  rounded-xl p-2 flex lg:flex-row   flex-col  align-items-center justify-between"
          to={conf_event_route}
        >

          <ConfEventCycleLang
            conf_event={confEvent}
            confEventCycleLangList={
              confEventCycleLangList &&
              confEventCycleLangList
                .filter(confEventCycleLang => ['collectif_geographique'].includes(confEventCycleLang.id_cycle_lang.cycle_lang_type))

                .filter(confEventCycleLang => confEventCycleLang.id_conf_event.id_conf_event == confEvent.id_conf_event)

            } />


          <div className="w-full mr-2">

            <div className="flex flex-wrap text-xs dark:bg-neutral-900/10  rounded ">
              <span
                className=" h-6 rounded-full flex items-center justify-center  px-3"
                style={{
                  backgroundColor: confEvent.conf_event_type.color,
                  width: "auto",
                  marginRight: "10px"
                }}
              >
                {confEvent.conf_event_type.conf_event_type_nom}{" "}
              </span>

              <ConfEventCycleLang
                conf_event={confEvent}
                confEventCycleLangList={confEventCycleLangList &&
                  confEventCycleLangList
                    .filter(confEventCycleLang => ['collectif_geographique', 'collectif_planaction'].includes(confEventCycleLang.id_cycle_lang.cycle_lang_type))
                    .filter(confEventCycleLang => confEventCycleLang.id_conf_event.id_conf_event == confEvent.id_conf_event)
                }
                getName />
            </div>


            <div className={`${confEvent.conf_event_type.id_conf_event_type == 95 ? '' : 'font-bold lg:text-xl'} pb-2 text-left  dark:text-white  hover:text-blue-700`}>
              {parse(confEvent.conf_event_lang.cel_titre)}
            </div>

            <div className={`${confEvent.conf_event_type.id_conf_event_type == 95 ? 'hidden' : ''} flex flex-col lg:flex-row sm:items-start lg:items-center lg:divide-x lg:space-x-5`}>
              <div className=" text-neutral-400 py-2 text-xs">{parse(confEvent.localTimeZone.replace("text-nowrap", ""))}</div>
              <div className={`hidden flex flex-wrap text-xs mt-2 ${idEvent == 1820 ? 'hidden' : ''}`}> {parse(confEvent.otherTimeZones)}</div>
            </div>
            {/* {confEvent.conf_event_type.id_conf_event_type} */}
            {["67", "83", "101", "74"].includes(confEvent.conf_event_type.id_conf_event_type) && <EnableLanguages confEvent={confEvent} />}

          </div>

          <div className="col-md-2  rounded-2xl bg-neutral-800 ">

            {confEvent.salle.salle_nom != '' && <div className="flex flex-col lg:relative ">
              {confEvent.salle.img && <img className="  h-100 rounded-2xl object-contain " src={confEvent.salle.img} alt="" />}
              <div className="pl-2 lg:absolute bottom-0 bg-neutral-800/80 rounded-b-2xl py-1 ">
                <div className="flex flex-col items-center">
                  <div className="flex text-xs text-center items-center"><MapPinIcon className="w-5 hidden" /> {confEvent.salle.id_lieu.lieu_nom} - {confEvent.salle.salle_nom}</div>
                </div>
              </div>
            </div>}

          </div>


        </Link>
      </div>

    </div>
  );
};


const Programm = ({ confEventList, day, confEventCycleLangList }) => {

  let { idEvent } = useParams();

  const { t, i18n, apiUrlTranslation } = useApiUrlTranslation()

  const eventContext = useContext(EventContext);

  const { currentEvent } = eventContext;


  useEffect(() => {


  }, [])

  return (
    <div key={day} className="container text-start px-3">


      <div
        className="text-white mx-auto mb-3 mt-5"

      >
        <div colSpan="4" scope="col">
          <div className="text-pink-700 text-6xl font-black mb-3 my-element " id={`day-${day}`}>
            <Moment
              locale={i18n.resolvedLanguage}
              format="LL"
              date={confEventList[0].conf_event_date} />
          </div>
        </div>
      </div>


      <div className="">
        {confEventList ? (
          confEventList.map((confEvent) => (
            <ConfEvent key={confEvent.id_conf_event}
              confEvent={confEvent}
              idEvent={idEvent}
              confEventCycleLangList={confEventCycleLangList} />
          ))
        ) : (
          <Loader />
        )}
      </div>

    </div>
  );
};

export default Programm;
