import React from 'react'
import SideBar from './SideBar'
import HeaderAccount from './HeaderAccount'
import './LayoutAccount.css'
import LayoutAccount2 from './LayoutAccount2'

const LayoutAccount = (props) => {

    return <LayoutAccount2 {...props}>{props.children}</LayoutAccount2>
    return <div>
        <HeaderAccount />
        <div className="account-layout">
            <SideBar />
            <div className="account-layout__right">
                {props.NavProgramme}
                <h2 className="account-layout__title">{props.title}</h2>
                {props.children}
            </div>
        </div>
    </div>
}

export default LayoutAccount