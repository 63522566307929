import React, { useEffect, useState } from 'react'
import LayoutAccount from './LayoutAccount'
import NavProgramme from './NavProgramme'
import UserProgrammes from './UserProgrammes'
import { InformationCircleIcon } from '@heroicons/react/solid'
import { API_URL } from '../../config'
import Loader from '../Loader'
import { useParams } from 'react-router-dom'

const Programme = ({ programme, contactCycleLangList, fetchContactCycleLangByCycleLang }) => {

    let { idContact } = useParams();

    const uza = idContact ? { id_contact: idContact } : JSON.parse(window.localStorage.getItem('user'))

    const [eventContactTypeList, setEventContactTypeList] = useState(null);

    const [activeStatut, setActiveStatut] = useState("");
    const [activeContactCycleLang, setActiveContactCycleLang] = useState("");

    const getEventContactTypeList = async () => {
        await fetch(`${API_URL}?action=getEventContactTypeList&filter=WHERE id_event_contact_type IN(167, 172, 231)`)
            .then((res) => res.json())
            .then((res) => setEventContactTypeList(res))
    }


    const handleChange = async (e) => {

        const newActiveStatut = { ...activeStatut };
        const value = e.target.value
        console.log('new value', value,
            'new id_contact_cycle_lang', newActiveStatut.id_contact_cycle_lang,
            'old id_contact_cycle_lang', activeStatut.id_contact_cycle_lang,
            programme.cycle_lang.id_cycle_lang, uza.id_contact)

        console.log('activeContactCycleLang', activeContactCycleLang)

        await fetch(`${API_URL}?action=deleteContactCycleLang&id_contact_cycle_lang=${activeContactCycleLang.id_contact_cycle_lang}`)
            .then(res => fetchContactCycleLangByCycleLang())
            .then(res => initStatut())
            .then(res => {
                fetch(`${API_URL}?action=createContactCycleLang`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id_event_contact_type: [value],
                        id_contact: uza.id_contact,
                        id_cycle_lang: programme.cycle_lang.id_cycle_lang,

                    })
                })

            })
            .then(res => fetchContactCycleLangByCycleLang())
            .then(res => initStatut())


        // setActiveStatut(e.target.value)

    }

    const initStatut = () => {

        const newActiveContactCycleLang = contactCycleLangList && contactCycleLangList.find(contactCycleLang => programme.cycle_lang.id_cycle_lang == contactCycleLang.cycle_lang.id_cycle_lang && uza.id_contact == contactCycleLang.contact.id_contact)

        newActiveContactCycleLang && setActiveStatut(newActiveContactCycleLang)

        newActiveContactCycleLang && setActiveContactCycleLang(newActiveContactCycleLang)

        console.log('contactCycleLangList', contactCycleLangList)
        console.log('newActiveContactCycleLang', newActiveContactCycleLang)
    }

    useEffect(() => {
        getEventContactTypeList();
        initStatut();

        console.log('contactCycleLangList', contactCycleLangList)
        console.log('activeStatut', activeStatut)

    }, [])

    return <tr>
        <td><img src={programme.logos.tiny} alt="" />{/*contactCycleLangList.filter(contactCycleLang => contactCycleLang)*/}</td>
        <td>{programme.programm_title} ({programme.cycle_lang.id_cycle_lang})</td>
        <td>
            <select value={activeContactCycleLang.id_event_contact_type}
                name=""
                id=""
                onChange={handleChange}
                className={`mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md ${activeStatut != "" ? "bg-green-600 text-white" : ""}`}>
                <option value=""> Niveau de participation </option>
                {eventContactTypeList && eventContactTypeList.map(eventContactType => <option value={eventContactType.id_event_contact_type}>{eventContactType.libelle}</option>)}
            </select></td>
    </tr>
}
const AllProgramms = ({ }) => {

    let { idContact } = useParams();

    const uza = idContact ? { id_contact: idContact } : JSON.parse(window.localStorage.getItem('user'))


    const [programmeInnovationList, setProgrammeInnovationList] = useState(null);
    const [contactCycleLangList, setContactCycleLangList] = useState(null);

    const getProgrammeInnovation = async () => {
        await fetch(`${API_URL}?action=getProgrammeInnovation`)
            .then(res => res.json())
            .then(all => { setProgrammeInnovationList(all) })
    }



    const fetchContactCycleLangByCycleLang = async () => {

        await fetch(`${API_URL}?action=fetchContactCycleLang&filter= c.id_contact=${uza.id_contact} AND`)
            .then(res => res.json())
            .then(res => {

                console.log(res)
                setContactCycleLangList(res)
            })
    }

    useEffect(() => {

        getProgrammeInnovation()
        fetchContactCycleLangByCycleLang()

    }, [])



    return (
        <LayoutAccount title="Je me prépositionne sur un programme"
            NavProgramme={<NavProgramme />}>


            <div className="rounded-md bg-blue-50 p-4 my-5">

                <p className=''> Trois niveaux de participation possible pour les décideurs sur les programmes d’innovation collaboratifs de #myGlobalVillage : </p>

                <ul>
                    <li>- Coordinateur de programmes d’innovation [Positionnements national, francophone ou international selon souhait ] : élus ou technicien engagés à l’année et officiellement en charge du bon suivi du programme, de son agenda d’actions (pitch, relation avec les tech’plorateurs, calendrier de la tenue des commissions trimestrielles de validation…) et de pré-sélection des technologies ;</li>
                    <li className='py-3'>- Contributeur de programme d’innovation : décideurs engagés à l’année notamment lors des temps forts de #myGobalVillage : jurés officiel (visibilité forte sur les sites et lors des événements) en présentiel ou à distance lors des sessions de pitch, membre officiel de la commission de validation des technologies…</li>
                    <li>- Observateur/soutien de programme d’innovation : accès à l’ensemble des données du programme d’innovation collaboratif, aux analyses, aux podcasts. Engagement occasionnel (juré, tech’xplorateur, experet-certificateur) lorsque l’agenda le leur permet</li>
                </ul>
            </div>

            <table className='table table-bordered'>
                <tbody>
                    {programmeInnovationList ? programmeInnovationList.map(programme => <Programme
                        programme={programme}
                        contactCycleLangList={contactCycleLangList}
                        fetchContactCycleLangByCycleLang={fetchContactCycleLangByCycleLang} />) : <Loader />}
                </tbody>
            </table>



        </LayoutAccount>
    )
}

export default AllProgramms