import React from "react";
import ContactsPhotoSlider from "./ContactsPhotoSlider";
import Layout from "./Layout";
import PageSection from "./PageSection";
import useApiUrlTranslation from "./useApiUrlTranslation";

function CommiteOrganisationEvent() {

  const { t } = useApiUrlTranslation()

  return (
    <Layout title={t('pages.comite_orga.title')}>
      <PageSection>

        <ContactsPhotoSlider displayMode="fixed"
          statutList={[
            "coordinateur-de-la-da-la-gation", "coordinateur_evenementiel", "coordinateur_techxploration",
            "coordinateur_jury", "coordinateur_geographique", "coordinateur_techxploration", "coordinateur_candidats", "coordinateur_pitch"]} />
      </PageSection>

    </Layout>
  );
}

export default CommiteOrganisationEvent;
