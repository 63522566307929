import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import i18n from './i18n';
import { Link, useParams } from 'react-router-dom'
import EventContext from '../contexts/EventContext'

const lngs = {
    fr: { nativeName: 'Français', idFlag: 'fr' },
    en: { nativeName: 'English', idFlag: 'gb' },
    es: { nativeName: 'Espagnol', idFlag: 'es' },
    de: { nativeName: 'Deutsch', idFlag: 'de' },
    pt: { nativeName: 'Portugal', idFlag: 'pt' },
    it: { nativeName: 'Italia', idFlag: 'it' },
    ja: { nativeName: 'Japan', idFlag: 'jp' },
    ko: { nativeName: 'Korea', idFlag: 'kr', iplocate: 'ko' },
    nl: { nativeName: 'Nederlands', idFlag: 'nl' },
    hi: { nativeName: 'Hindi', idFlag: 'in' },
    iw: { nativeName: 'Hebrew', idFlag: 'il' },
    ar: { nativeName: 'Arabic', idFlag: '' },
    'zh-CN': { nativeName: 'china', idFlag: 'cn' }
}

export const NavTranslationLanguageSelect = ({ displayLanguageName = false }) => {

    useEffect(() => {

    }, [i18n.resolvedLanguage])
    return (
        <div className="flex">
            {displayLanguageName && <span className="">{lngs[i18n.resolvedLanguage].nativeName}</span>}
            <div className="dropdown " >
                <a className="btn  btn-sm  dropdown-toggle ms-2 border-x-1  dark:border-neutral-800 rounded-2xl border-neutral-200"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ textTransform: 'uppercase' }}>
                    <img
                        alt='drapeau'
                        style={{
                            width: '25px',
                            display: `${lngs[i18n.resolvedLanguage].idFlag == '' ? 'none' : 'block'}`

                        }}
                        className="px-1"
                        title={lngs[i18n.resolvedLanguage].nativeName}
                        src={`https://www.iplocate.com/assets/img/flag/128/${lngs[i18n.resolvedLanguage].idFlag}.png`} />
                    {/*lngs[i18n.resolvedLanguage].nativeName*/}
                    { /*i18n.resolvedLanguage*/}
                    {lngs[i18n.resolvedLanguage].idFlag == '' ? lngs[i18n.resolvedLanguage].nativeName : ''}
                </a>

                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    {Object.keys(lngs).filter(lngs => ['fr', 'en', 'es', 'de', 'pt', 'nl', 'it', 'ko', 'ja', 'zh-CN', 'hi', 'iw', 'ar'].includes(lngs)).map(lng => (
                        <li key={lng}>
                            <a
                                className="dropdown-item flex text-gray-500 hover:text-gray-700"
                                style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }}
                                type="submit" onClick={() => { i18n.changeLanguage(lng); console.log(lng) }}>

                                <img
                                    style={{
                                        width: '30px',
                                        display: `${lngs[lng].idFlag == '' ? 'none' : 'block'}`
                                    }}
                                    alt="drapeau"
                                    className="px-1 mr-2"
                                    title={lngs[lng].nativeName}
                                    src={`https://www.iplocate.com/assets/img/flag/128/${lngs[lng].idFlag ?? lng}.png`} /> {lngs[lng].nativeName}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

const NavTranslation = () => {

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    const { id_cycle_lang } = useParams();

    const { t } = useTranslation();

    useEffect(() => {

    }, [i18n.resolvedLanguage])

    return (

        <div className="flex flex-col  lg:flex-row-reverse bd-highlight py-2  pe-3 dark:bg-neutral-950">

            <NavTranslationLanguageSelect />

            {!["2270", '3014', '3015'].includes(id_cycle_lang) &&
                <Link to={currentEvent ? `/events/${currentEvent.id_event}/login` : ' / login'}
                    className={`${currentEvent?.event_categorie == "cdt" ? "hidden" : ''} text-lg items-center justify-center rounded-full border-pink-600 hover:border-pink-500 border-2 px-5 py-2 dark:text-white mx-2 hidden`}   >{t('global.connexion')}</Link>}
            {currentEvent && currentEvent?.event_etat !== "bilan" && !["2270", "3013", "3014", "3015"].includes(id_cycle_lang) && <Link
                style={{ textTransform: "capitalize" }}
                className={`${currentEvent?.event_categorie == "cdt" || currentEvent ? "hidden" : ''} text-lg items-center justify-center rounded-full  bg-pink-600 hover:bg-pink-500 px-5 py-2 font- text-white `} to={`/events/${currentEvent?.id_event}/registration`}>{t('event_nav.inscription')}</Link>}
        </div>
    )
}

export default NavTranslation