import React, { useEffect, useState, useContext } from "react";
import { capitalize } from "../utils";
import "./ContactsPhotoSlider.css";
import { useParams } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import Loader from "./Loader";
import EventContext from '../contexts/EventContext'
import styled from 'styled-components';
import parse from "html-react-parser";
import useApiUrlTranslation from "./useApiUrlTranslation";

const Societe = styled.span`
  font-weight:normal;
  text-align: center;
  display:block;
`;

const Contact = ({ contact, part }) => {

  const title = `${contact.fonction}, ${contact.societe}`;

  if (!contact) {

    return false;
  }

  return (

    <div className="overflow-hidden group rounded-2xl border-x border-y  bg-neutral-100  dark:border-neutral-800 dark:bg-neutral-900" style={{ borderWidth: "1px!important" }}>

      <Photo contact={contact} part={part} />

      <div className="flex flex-col justify-between ">
        <h5 className="text-left dark:text-white p-3 font-bold ">
          {capitalize(contact?.prenom)} {capitalize(contact?.nom)}{" "}
        </h5>

        <p className="text-left ps-3 pb-3 px-3 dark:text-neutral-400">{title}</p>
        <div className="flex space-x-3 ml-3 mb-3 dark:text-neutral-400 text-xs items-center"><img src={contact.flag} alt="" className="w-5 " /> <div>{contact.pays}</div></div>

      </div>
    </div >

  );
}

const Photo = ({ contact, part }) => {

  return <>

    {// si photo contient silhouette_homme ou silhouette_femme alors on affiche le logo de la société 

      contact.photos?.large.search("silhouette") !== -1 ? <div className="group-hover:scale-110 transition duration-100 ease-out 
mt-5 p-10 flex justify-center items-center rounded-full bg-neutral-800/10 w-40 h-40 mx-auto text-neutral-800 ">
        {contact.prenom.substr(0, 1)} {contact.nom.substr(0, 1)}


      </div> : <img
        src={`${contact.photos?.large}`}
        className="group-hover:scale-110 transition duration-100 ease-out photo-slider card-img-top rounded-t-2xl"
        alt="..."
        title={part?.id_conferencier}
      />

    }

  </>
}

const ContactSliderItem = ({ contact, part }) => {

  //const fonction = parse(contact.fonction.substr(0, 100));

  const fonction = parse(contact.fonction ?? "");

  const title = <span>{fonction}, <b>{contact.societe}</b></span>


  return (
    <SplideSlide>
      <div className="overflow-hidden card group rounded-2xl border-x border-y  bg-neutral-100  dark:border-neutral-800 dark:bg-neutral-900" style={{ height: "100%" }}>

        <Photo contact={contact} part={part} />

        <div className="card-body text-center" style={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
          <h5 className="card-title">
            <b className="dark:text-white">
              {(contact?.prenom)} <br />
              {(contact?.nom)}{" "}
            </b>
          </h5>
          <span style={{ display: 'none' }}>{part?.id_conferencier}</span>
          <small className="  dark:text-neutral-400">{title}</small>

          <img src={contact.flag} alt="" className="mt-1 w-8" />

        </div>
      </div>
    </SplideSlide>
  );
}

const ContactsPhotoSlider = ({ displayMode, statutList, contactList }) => {


  const { i18n, apiUrlTranslation } = useApiUrlTranslation()

  const [contacts, setContacts] = useState();

  const [contactHighlightList, setContactHighlightList] = useState();

  const [contactCycleLangList, setContactCycleLangList] = useState();

  let { idEvent, id_cycle_lang = 1635 } = useParams();

  const eventContext = useContext(EventContext);

  const { currentEvent, getEventByIdEvent } = eventContext;

  const id_event = idEvent ? idEvent : 1635;

  const getContactCycleLang = async () => {

    const filter = `(id_cycle_lang IN ( SELECT id_cycle_lang FROM cycle_lang_event_ligne WHERE id_event_ligne =${currentEvent.event_ligne.id_event_ligne}) AND id_cycle_lang_2 != 0)
OR (id_cycle_lang_2  IN ( SELECT id_cycle_lang FROM cycle_lang_event_ligne WHERE id_event_ligne =${currentEvent.event_ligne.id_event_ligne}) AND id_cycle_lang != 0)`;

    // const filter = ` ((id_cycle_lang=${id_cycle_lang} AND id_cycle_lang_2 !=0) OR (id_cycle_lang_2=${id_cycle_lang} AND id_cycle_lang !=0))`;

    await fetch(`${apiUrlTranslation}?action=getContactCycleLang&filter= ${filter}`)
      .then((res) => res.json())
      .then(res => setContactCycleLangList(res))
  }


  const fetchContactsHilight = async () => {

    await fetch(`${apiUrlTranslation}?action=getContactList&params= WHERE contact_highlight=1`)
      .then((res) => res.json())
      .then(res => setContactHighlightList(res))

  }


  const fetchContacts = async () => {
    const statutfilter =
      statutList !== ""
        ? ` AND afficher !='n' AND cf.statut IN(${statutList.map((statut) => `"${statut}"`)})`
        : "";

    const filter = `${statutfilter} AND id_event = ${id_event}`;

    let urlFilter = `${apiUrlTranslation}?action=getContactConferencierList&filter=${filter} GROUP BY CONCAT(prenom, nom)`;

    const filterPastEditions = `AND contact_highlight ='1' AND id_event IN (SELECT id_event FROM events WHERE id_event_ligne = (SELECT id_event_ligne FROM events where id_event=${id_event}))`;

    const urlPastEditions = `${apiUrlTranslation}?action=getContactConferencierList&filter=${filterPastEditions} GROUP BY CONCAT(prenom, nom)`;

    if (1635) {
      //urlFilter = `${apiUrlTranslation}?action=getContactList&params= WHERE contact_highlight=1`
    }

    try {
      const res = await fetch(urlFilter).then((res) => res.json());

      console.log(res);

      if (res.length <= 2) {

        const pastEditionsRes = await fetch(urlPastEditions).then((res) => res.json());
        const newRes = id_event == 1635 ? pastEditionsRes.map((contact) => ({ ...contact, part: {} })) : pastEditionsRes.map((contact) => ({ ...contact, part: contact.contact }));
        setContacts(newRes);
      } else {


        // const newRes = id_event == 1635 ? res.map((contact) => ({ ...contact, part: {} })) : res.map((contact) => ({ ...contact, part: contact.contact }));
        const newRes = res.map((contact) => ({ ...contact, contact: contact.contact }));
        setContacts(newRes);
      }
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  useEffect(() => {
    fetchContacts();
    fetchContactsHilight();
    getContactCycleLang();

  }, [i18n.resolvedLanguage, idEvent]);



  if (contactList) {

    return <Splide
      options={{
        pagination: false,
        type: 'loop',
        autoplay: true,
        pauseOnHover: false,
        resetProgress: false,
        arrows: 'slider',
        perPage: 6,
        breakpoints: {
          1000: {
            perPage: 3,
          },
          500: {
            perPage: 2,
          },
        },

      }}
      hasSliderWrapper
      hasAutoplayProgress
    >
      {contactList && contactList
        .map((contact, index) => {

          if (contactList.length > 20 && contact.photo.search("silhouette") !== -1) {
            return false
          }

          return <ContactSliderItem key={`yo${index}`} contact={contact} />
        }
        )}
    </Splide>

  }

  if (!contacts) {
    return <Loader />
  }


  if (idEvent == 1635) {

    return <>

      <Splide
        options={{
          pagination: false,
          type: 'loop',
          autoplay: true,
          pauseOnHover: false,
          resetProgress: false,
          arrows: 'slider',
          perPage: 6,
          breakpoints: {
            1000: {
              perPage: 3,
            },
            500: {
              perPage: 2,
            },
          },

        }}
        hasSliderWrapper
        hasAutoplayProgress
      >
        {contactHighlightList.map((contact, index) => {

          if (contactHighlightList.length > 20 && contact.photo.search("silhouette") !== -1) {
            return false
          }

          return <ContactSliderItem key={`yo${index}`} part={contact?.part} contact={contact} />
        }
        )}
      </Splide>
    </>
  }



  return (
    <>




      {displayMode === "fixed" ? (
        <div className="container">
          <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-3">
            {contacts
              .filter(contact => contact.contact.photo.search("silhouette") === -1)
              .map((contact, index) => (
                <Contact
                  key={index}
                  part={contact.part}
                  contact={contact.contact}
                  currentEvent
                  idEvent />
              ))}
          </div>
        </div>
      ) : (
        <>

          {/* <Splide
            options={{
              pagination: false,
              type: 'loop',
              autoplay: true,
              pauseOnHover: false,
              resetProgress: false,
              arrows: 'slider',
              perPage: 6,
              breakpoints: {
                1000: {
                  perPage: 3,
                },
                500: {
                  perPage: 2,
                },
              },

            }}
            hasSliderWrapper
            hasAutoplayProgress
          >
            {contacts.map((contact, index) => {

              if (contacts.length > 20 && contact.contact.photo.search("silhouette") !== -1) {
                return false
              }

              return <ContactSliderItem key={`yo${index}`} part={contact.part} contact={contact.contact} />
            }
            )}
          </Splide> */}

          <Splide
            options={{
              pagination: false,
              type: 'loop',
              autoplay: true,
              pauseOnHover: false,
              resetProgress: false,
              arrows: 'slider',
              perPage: 6,
              breakpoints: {
                1000: {
                  perPage: 3,
                },
                500: {
                  perPage: 2,
                },
              },

            }}
            hasSliderWrapper
            hasAutoplayProgress
          >
            {contactCycleLangList && contactCycleLangList
              .map(contactCycleLang => contactCycleLang.contact)
              .filter((contact, index, self) =>
                index == self.findIndex((t) => (
                  t.id_contact == contact.id_contact
                ))
              )
              .map((contact, index) => {

                if (contacts.length > 20 && contact.photo.search("silhouette") !== -1) {
                  return false
                }

                return <ContactSliderItem key={`yo${index}`} contact={contact} />
              }
              )}
          </Splide>
        </>
      )}
    </>
  );
};

export default ContactsPhotoSlider;