import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { API_URL } from '../config';

import lng_fr from '../translations/fr/translation.json'
import lng_en from '../translations/en/translation.json'
import lng_de from '../translations/de/translation.json'
import lng_it from '../translations/it/translation.json'
import lng_pt from '../translations/pt/translation.json'
import lng_ja from '../translations/ja/translation.json'
import lng_es from '../translations/es/translation.json'
import lng_hi from '../translations/hi/translation.json'
import lng_kr from '../translations/kr/translation.json'
import lng_nl from '../translations/nl/translation.json'
import zh_CN from '../translations/zh_CN/translation.json'
import lng_iw from '../translations/iw/translation.json'
import lng_ar from '../translations/ar/translation.json'

const fetchTranslations = async (id) => {

    const url = id ? `https://www.myglobalvillage.com/${id}/api/` : API_URL

    await fetch(`${url}?action=getTranslation`)
        .then(res => res.json())
        .then(res => console.log('translations'))
        .catch(error => console.error('error', error));
}

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'fr',
        //lng: 'en', // default language
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {

            en: {
                translation: lng_en
            },
            fr: {
                translation: lng_fr
            },
            de: {
                translation: lng_de
            },
            it: {
                translation: lng_it
            },
            pt: {
                translation: lng_pt
            },
            ja: {
                translation: lng_ja
            },
            es: {
                translation: lng_es
            },
            ko: {
                translation: lng_kr
            },
            nl: {
                translation: lng_nl
            },
            'zh-CN': {
                translation: zh_CN
            },
            hi: {
                translation: lng_hi
            },
            iw: {
                translation: lng_iw
            },
            ar: {
                translation: lng_ar
            }
        }
    });

export default i18n;
