import React, { useEffect, useState, useContext } from 'react'
import RegistrationFormSection from './RegistrationFormSection'
import RegistrationFormRow from './RegistrationFormRow'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import EventContext from '../contexts/EventContext'
import useApiUrlTranslation from './useApiUrlTranslation'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const programmeList = [
    "#Movie development: T4M1: Key evolution and best solution in screenplay writing, pre-production, and casting industry",
    "#Movie production: Financing, protection, management, marketing of the movies... what's new, what's next?",
    "#Movie making: Best 12 disruptive and cost-killer innovations in film making, virtual, CGI, and post-production",
    "#Movie distribution: Platform, cinema experience, screenings... global focus on future leverage of movie distribution",
    "#Web3, NFT, blockchain, Crypto, Tokenomics... what's in it for movies and Entertainment industry?",
    "#AnimationTech: Best tech for comics, mangas, and cartoon industry",
    "#XR & Apps for movies: Gaming, metaverse, XR, holograms, mobile apps... future tech for entertainment and movies",
    "ShowTech, EventTech... New Experience, major innovation, and disruptions for movie and entertainment industry"
]

const RegistrationFormCasterSectionAcheteur = ({ title, instructions, contact }) => {

    const [global_funding_traction, set_global_funding_traction] = useState(contact?.global_funding_traction);
    const [global_funding_product, set_global_funding_product] = useState(contact?.global_funding_product);
    const [global_funding_probleme_type, set_global_funding_probleme_type] = useState(contact?.global_funding_probleme_type);

    const { register, formState } = useFormContext();

    const eventContext = useContext(EventContext);

    const { id_event_cycle_lang } = useParams();

    const [cycleLangList, setCycleLangList] = useState(null);

    const [eventCycleLang, setEventCycleLang] = useState(null);

    const { currentEvent } = eventContext;

    const { t } = useTranslation();

    const { i18n, apiUrlTranslation } = useApiUrlTranslation()


    //{t('form.sections.demo_section.solution_deja_presentee_description')} 

    const TractionList = [
        { label: t('form.sections.techfund_raiser.ca_recurrent_1m'), value: 1 },
        { label: t('form.sections.techfund_raiser.ca_recurrent_plus_100k'), value: 2 },
        { label: t('form.sections.techfund_raiser.ca_recurrent_moins_100k'), value: 3 },
        { label: t('form.sections.techfund_raiser.revenus_pilotes'), value: 4 },
        { label: t('form.sections.techfund_raiser.croissance_client'), value: 5 },
        { label: t('form.sections.techfund_raiser.pas_de_revenus'), value: 6 },
    ]
    const ProblemList = [
        { label: t('form.sections.techfund_raiser.probleme_serieux'), value: 1 },
        { label: t('form.sections.techfund_raiser.probleme_solvable'), value: 2 },
    ]

    const SolutionList = [
        { label: t('form.sections.techfund_raiser.produit_resolve_all'), value: 1 },
        { label: t('form.sections.techfund_raiser.produit_resolve_partiellement'), value: 2 },
        { label: t('form.sections.techfund_raiser.no_product'), value: 3 },
        { label: t('form.sections.techfund_raiser.taille_marche'), value: 4 },
        { label: t('form.sections.techfund_raiser.marche_en_croissance'), value: 5 },
    ]

    const DisplayList = ({ list, inputName, selectedOption, setSelectedOption }) => {

        return <fieldset class="space-y-2 mb-3 mt-2">

            {list.map((item, index) =>
                <>
                    <div class="relative flex items-start">
                        <div class="flex h-5 items-center">
                            <input
                                {...register(inputName)}
                                checked={selectedOption == item.value}
                                onChange={(e) => setSelectedOption(e.target.value)}
                                id={item.label}
                                value={item.value}
                                name={inputName}
                                type="radio"
                                class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                        </div>
                        <div class="ml-3 text-sm">
                            <label for={item.label} class="font-medium text-gray-700" >{item.label}</label>
                        </div>
                    </div>
                </>)
            }
        </fieldset>
    }

    const getCycleLang = async (idCycleLang) => {
        await fetch(`${apiUrlTranslation}?action=getCycleLang&filter=id_cycle_lang=${idCycleLang} AND`)
            .then(res => res.json())
            .then(res => {
                setCycleLangList(res[0].children)
            })
    }



    useEffect(() => {

        const getEventCycleLangList = async () => {
            await fetch(`${apiUrlTranslation}?action=getEventCycleLangList&params=WHERE id_event_cycle_lang=${id_event_cycle_lang} `)
                .then(res => res.json())
                .then(res => {
                    const eventCycleLang = res[0];
                    console.log('eventCycleLang', eventCycleLang)
                    setEventCycleLang(eventCycleLang)
                    getCycleLang(getCycleLang(eventCycleLang.cycle_lang.id_cycle_lang))
                })
        }

        id_event_cycle_lang && getEventCycleLangList()
        console.log('contact', contact)

        console.log('id_event_cycle_lang', id_event_cycle_lang)

    }, [contact, currentEvent, id_event_cycle_lang])

    //if (!contact) return (<div>Loading...</div>)

    if (!currentEvent || !eventCycleLang) return false
    return (
        <RegistrationFormSection
            title="Votre contribution à myGlobalVillage"
            instructions={`Je précise ma contribution pour les 12 prochains mois en matière de ${eventCycleLang.event_cycle_lang_nom}`}>

            <p className='text-base font-semibold leading-7 text-gray-900'> Réservé aux décideurs, investisseurs et distributeurs : membre du jury {(new Date(currentEvent.event_start)).getFullYear()}</p>

            <p className='mt-1 text-sm leading-6 text-gray-600'> Reconduction annuelle non marchande : je n'aurai pas la possibilité ou le temps de rester connecté aux programmes d'innovation annuels mais soutient myGlobal Village :</p>
            <label className="font-normal p-1"><input type="checkbox" {...register("idEventContactTypeList")} name="idEventContactTypeList" value="78" />Je soutiens myGlobalVillage et souhaite me pré-positonner pour participer à l'édition prochaine de {currentEvent.nom}. J'apparaitrai dans la rubrique « ils soutiennent l'événement »</label>
            <label className="font-normal p-1"><input type="checkbox" {...register("idEventContactTypeList")} name="idEventContactTypeList" value="92" /> Je souhaite me pré-positonner comme jurés des sessions de pitch de la prochaine édition (en présentiel ou à distance) dès que les horaires seront définis et figurer dans la rubrique « il seront jury lors de l'événements »</label>

            <p className='text-base font-semibold leading-7 text-gray-900 mt-3'>Official contributor next edition (free membership 6 month, exposure and access to the global database of solutions) :</p>
            <label className="font-normal p-1"><input type="checkbox" {...register("idEventContactTypeList")} name="idEventContactTypeList" value="124" /> Je souhaite être coordinateur des candiats et des jurés de sessions de pitch pour le compte de mon pays / mon écosystème. Je m'engage à cette occasion à recruter les membres du jury et les candidats pour mon pays lors de la prochaine édition de l'événement.</label>
            <label className="font-normal p-1"><input type="checkbox" {...register("idEventContactTypeList")} name="idEventContactTypeList" value="167" /> Je souhaite être contributeur d'un programme d'innovation (annual Tech'xplainer) en lien avec mon activité Je m'engage pendant l'année à identifier et coopter les solutions innovantes/performantes de mon écosystème afin de les mobiliser lors la prochaine édition. Je sélectionne les programmes d'innovation ci-dessous qui me concernent :</label>

            <p className='text-base font-semibold leading-7 text-gray-900 mt-3'>Revenue sharing contribution :</p>
            <label className="font-normal p-1"><input type="checkbox" {...register("idEventContactTypeList")} name="idEventContactTypeList" value="202" /> Je souhaite être coordinateur d'une délégation lors de l'éditon  {(new Date(currentEvent.event_start)).getFullYear()} pour le compte de ma société/mon pays /mon organisation/mon écosystème. Je m'engage à cette occasion à recruter participant à la délégation myGlobalVillage {currentEvent.nom} et à bénéficier du programme de revenue sharing.</label>
            <label className="font-normal p-1"><input type="checkbox" {...register("idEventContactTypeList")} name="idEventContactTypeList" value="167" /> Je souhaite être coordinateur d'un programme d'innovation (program coordinator) en lien avec mon activité Je m'engage pendant l'année à rechercher des partenaires (nnovation squad et tech'xpander circle). J'aide myglobalvillage à on-boarder des tech'providers (solutions innovantes/performantes) dans les opérations de marketing collaboratif et de chasse en groupe de myGlobalVillage {eventCycleLang.event_cycle_lang_nom}. Je sélectionne les programmes d'innovation annuel ci-dessous qui me concernent :</label>

            <ul className="mt-3 pl-5">
                {
                    cycleLangList && cycleLangList.map((cycleLang, index) => <li key={cycleLang.id_cycle_lang}>
                        <label className="font-normal py-1">
                            <input type="checkbox" {...register("eventCycleLang")} value={cycleLang.id_cycle_lang} /> {cycleLang.nom_cycle_lang}
                        </label></li>)
                }
            </ul>

            <p className='text-base font-semibold leading-7 text-gray-900 mt-3'> Appel à partenaire du Tech'xpender circle  :</p>
            <label className="font-normal">
                <input type="checkbox" {...register("idEventContactTypeList")} value="251" /> Tech'xpender circle : En tant qu'investisseurs, utilisateur-final, ou revendeur des solutions {eventCycleLang.event_cycle_lang_nom} identifiées par les «Tech'xplorer» de myGlobalVillage je souhaite soutenir financièrement un ou plusieurs programme d'innovation. Recontactez moi afin de me présenter les formules de partenariat qui me permette tout l'année :
            </label>
            <ul className="pl-5" style={{ listStyle: "circle" }}>
                <li>De suivre les programmes d'innovation,</li>
                <li>D'accéder à la base de données détaillé des start'ups et solutions innovantes de myGlobalVillage</li>
                <li>D'être associé aux tech'review aux webcast de restitution des programmes d'innovation pendant 12, 36 ou 45 mois.</li>
                <li>D'être juré aux sessions de pitch que ce soit en présentiel ou à distance.  et avoir accès à la base des solution identifiées afin d'être le premiers dans mon pays/écosystème à les intégrer.</li>
            </ul>

            <label className="font-normal">
                <input type="checkbox" {...register("idEventContactTypeList")} value="252" />	 Tech'xporter Innovation squad : je suis offreur, start'up ou distributeur de solutions innovantes et souhaite rejoindre une innovation squad (ecosysteme de chasse en groupe multipays). Recontactez moi afin de participer à l'année aux actions de marketing collaboratif et de prospection collectives (Tech'review, Tech'xportation days, {eventCycleLang.event_cycle_lang_nom} corridors …) au coté des meilleures solutions mondiales de mon industrie.
            </label>

        </RegistrationFormSection>
    )
}

export default RegistrationFormCasterSectionAcheteur