import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { API_URL } from "../config";
import EventContext from "../contexts/EventContext";
import Layout from "./Layout";

function Replay() {
  let { idEvent } = useParams();
  const [videoList, setVideoList] = useState([]);

  const eventContext = useContext(EventContext);
  const { currentEvent } = eventContext;

  const fetchVideos = async () => {
    const url = ` ${API_URL}?action=getVideosEvents&id_event=${idEvent}`;

    await fetch(url)
      .then((res) => res.json())
      .then((video) => setVideoList(video));
  };

  const mobilecheck = () => {
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    } else {
      return false;
    }
  };

  console.log(mobilecheck())
  useEffect(() => {
    fetchVideos();
  }, []);

  return (
    <Layout>
      <div className="container">
        {currentEvent ? (
          <h1 className="fw-bold text-center m-3">Replays : {currentEvent.nom}</h1>
        ) : (
          "Loading"
        )}
        <div className="row">
          {videoList &&
            videoList.map((video, i) => (
              <div className="col-md-3">
                <Link
                  to={`/events/${idEvent}/replay/${video.id_video.id_video}/replay-details`}
                  target="_blank"
                  key={i}
                  className="text-dark text-decoration-none"
                  style={mobilecheck() ? { minHeight: "30vh" } : { minHeight: "30vh", maxWidth: "15vw" }}
                >
                  <div class="card border-0">
                    <img alt="thumbnail video" class="card-img-top" src={video.id_video.thumbnail_medium_url} />
                    <div className="card-body">
                      <b style={{ textOverflow: "ellipsis" }} className="fw-bold text-wrap">{video.id_video.titre}</b>
                      <p><small className="text-muted">{video.id_video.date_creation}</small></p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </Layout>
  );
}

export default Replay;
