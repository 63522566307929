import parse from 'html-react-parser';
import Layout from './Layout'
import useApiUrlTranslation from './useApiUrlTranslation';

const Whois = () => {

    const { t } = useApiUrlTranslation();

    return (
        <Layout class="widget">
            <div class="mx-32 dark:text-neutral-400">
                {parse(t('pages.who_is.qui_sommes_nous'))}
            </div>
        </Layout >
    )
}

export default Whois
