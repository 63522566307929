import React, { useContext, useEffect, useState } from 'react'
import LayoutAccount from './LayoutAccount'
import UserContext from '../../contexts/UserContext'
import { Link } from 'react-router-dom';
import { API_URL } from '../../config';
import HTMLReactParser from 'html-react-parser';
import Loader from '../../components/Loader';

const Event = ({ id_event }) => {

    const [event, setEvent] = useState(null)

    const getEvents = async () => {
        await fetch(`${API_URL}?action=getEvents&id_event=${id_event}`)
            .then(response => response.json())
            .then(data => setEvent(data[0]))
    }

    useEffect(() => {
        getEvents()
    }, [])

    if (!event) {
        return <Loader />
    }

    return event.nom
}

const Actions = ({ idCycleLang }) => {

    const [confEventCycleLangList, setConfEventCycleLangList] = useState(null)

    const getConfEventCycleLang = async () => {

        await fetch(`${API_URL}?action=getConfEventCycleLang&params=WHERE id_cycle_lang=${idCycleLang}`)
            .then(res => res.json())
            .then(data => setConfEventCycleLangList(data))
    }

    useEffect(() => {

        idCycleLang && getConfEventCycleLang();
    }, [])

    return <ul className='text-left'>
        {confEventCycleLangList && confEventCycleLangList.map(item => {

            let action;

            switch (item.id_conf_event.type.id_conf_event_type) {

                case "67": action = "Global Challenge "
                    break;
                case "2": action = "Global Podcast"
                    break;
            }

            return <li className='p-2'>- {action} : <Event id_event={item.id_conf_event.id_event} /> { /* ({item.id_conf_event.type.id_conf_event_type}) */}</li>
        })}
    </ul>
}

const Body = ({ cycleLang, contactCycleLangList }) => {
    return <div className='p-2 bg-white'>
        <table className="min-w-full divide-y divide-gray-300">
            <tbody className="divide-y divide-gray-200 bg-white">
                <tr className='align-top'>
                    <td className='border-r-4 border-indigo-500 w-1/3'><b>Coordinateur(s) du collectif</b>
                        {contactCycleLangList && contactCycleLangList
                            .filter(contactCycleLang => ["206", "207", "227", "228", "146", "205", "167", "226", "177", "209"].includes(contactCycleLang.id_event_contact_type))
                            .map(contactCycleLang => {
                                return <div className="flex items-center border-b py-2">
                                    <div className="h-10 w-10 flex-shrink-0">
                                        <img className="h-10 w-10 rounded-full" src={contactCycleLang.contact.photos.small} alt="" />
                                    </div>
                                    <div className="ml-4">
                                        <div className="font-medium text-gray-900">{contactCycleLang.contact.prenom} {contactCycleLang.contact.nom} - {contactCycleLang.contact.societe}

                                        </div>
                                        <div className="font-tiny text-gray-500">{contactCycleLang.contact.mail}</div>
                                    </div>
                                </div>
                            })}
                    </td>
                    <td className='text-center border-r-4 border-indigo-500 w-1/3'><b>Contributeurs du collectif</b>

                        <Contributeurs />
                    </td>
                    <td className='text-center w-1/3 px-2'><b className='bg-blue'>Actions du collectif</b>
                        <Actions idCycleLang={cycleLang.id_cycle_lang} />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
}
const Contributeurs = () => {

    return <div>
        <ul className='text-left list-disc'>
            <li className='p-2 flex'>Offreurs engagés : <span className='text-white h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center ring-8 ring-white'>12</span></li>
            <li className='p-2 flex'>Offreurs pressentis : <span className='text-white h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center ring-8 ring-white'>20</span></li>
            <li className='p-2 flex'>Décideurs engagés : <span className='text-white h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center ring-8 ring-white'>14</span></li>
            <li className='p-2 flex'>Décideurs pressentis : <span className='text-white h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center ring-8 ring-white'>44</span></li>
        </ul>
    </div>
}

const CycleLang = ({ cycleLang }) => {

    const [contactCycleLangList, setContactCycleLangList] = useState(null)

    const fetchContactCycleLangByCycleLang = async () => {

        await fetch(`${API_URL}?action=fetchContactCycleLangByCycleLang&id_cycle_lang=${cycleLang.id_cycle_lang}`)
            .then(res => res.json())
            .then(res => setContactCycleLangList(res))
    }

    useEffect(() => {
        fetchContactCycleLangByCycleLang()

    }, [])

    return <div className='w-full overflow-hidden rounded-lg ring-1 ring-slate-900/10 mb-3'>
        <div className="bg-indigo-600">
            <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between flex-wrap">
                    <div className="w-0 flex-1 flex items-center">
                        <span className="flex p-2 rounded-lg bg-indigo-800">
                            <img style={{ width: '50px' }} src={cycleLang.attachedFilesCollection.find(item => item.id_attached_file_type == 65).small} alt="" />
                        </span>
                        <p className="ml-3 font-medium text-white truncate">
                            <span className="hidden md:inline text-3xl">{cycleLang.nom_cycle_lang}</span>
                        </p>
                    </div>
                    <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                        <Link
                            className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50"
                            to={`/account/collectif/${cycleLang.id_cycle_lang}`}
                        >
                            Editer
                        </Link>
                    </div>

                </div>
            </div>
            <Body cycleLang={cycleLang} contactCycleLangList={contactCycleLangList} />
        </div>
    </div>

}
const UserCycleLang = () => {

    const userContext = useContext(UserContext);

    const { userCycleLangList } = userContext;

    useEffect(() => {

    }, [userCycleLangList])

    return (
        <LayoutAccount title="Mes plans d'actions">
            <p className='text-2xl pt-2 pb-2 mb-5'>Plan d’actions et référents engagés sur les plan d'actions</p>

            {userCycleLangList && userCycleLangList.map(item => <CycleLang key={item.id_cycle_lang} cycleLang={item.cycle_lang} />)}

        </LayoutAccount>
    )
}

export default UserCycleLang