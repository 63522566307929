import React, { useReducer } from 'react'

import FormulaireReducer from './FormulaireReducer';
import FormulaireContext from './FormulaireContext';

const initialState = {
    activeFormulaire: {},
    setActiveFormulaire: () => { }
};

const FormulaireProvider = ({ children }) => {

    const [state, dispatch] = useReducer(FormulaireReducer, initialState);

    const setActiveFormulaire = ({ activeFormulaire }) => {
        dispatch({
            type: 'SET_FORMULAIRE',
            activeFormulaire: activeFormulaire
        });
    }


    return (
        <FormulaireContext.Provider value={{
            activeFormulaire: state.formulaire,
            setActiveFormulaire: setActiveFormulaire

        }}>
            {children}
        </FormulaireContext.Provider>
    )
}

export default FormulaireProvider