import { Fragment } from 'react'
import { CheckIcon, MinusIcon } from '@heroicons/react/20/solid'
import Layout from './Layout'

const tiers = [
    // {
    //     name: 'Starter',
    //     id: 'tier-basic',
    //     href: '#',
    //     priceMonthly: '390€',
    //     description: 'Quis suspendisse ut fermentum neque vivamus non tellus.',
    //     mostPopular: false,
    // },
    // {
    //     name: 'Expert Supply',
    //     id: 'tier-essential',
    //     href: '#',
    //     priceMonthly: '890€',
    //     description: 'Quis eleifend a tincidunt pellentesque. A tempor in sed.',
    //     mostPopular: true,
    // },
    // {
    //     name: 'Leader Premium',
    //     id: 'tier-premium',
    //     href: '#',
    //     priceMonthly: '1190€',
    //     description: 'Orci volutpat ut sed sed neque, dui eget. Quis tristique non.',
    //     mostPopular: false,
    // },

    {
        name: 'Freemium 6 mois',
        id: 'freemium',
        href: '#',
        priceMonthly: '0€',
        description: 'Testez gratuitement pendant 6 mois la puissance du réseau mondial d’entrepreneurs de myGlobalVillage et l’impact de ses actions collectives pour vos projets internationaux ',
        mostPopular: false,
    },

    {
        name: 'Offre d’essai Membership 12 mois',
        id: 'membership-12',
        href: '#',
        priceMonthly: '590€',
        description: 'offre   d’essai membership. Bénéficiez d’un accès annuel avec 40% de réduction valable pour votre cercle d’intelligence collective sur un        plan d’action sectoriel de votre choix', mostPopular: false,
    },

    {
        name: 'Offre d’essai Membership 24 mois',
        id: 'membership-24',
        href: '#',
        priceMonthly: '990€',
        description: 'offre   d’essai membership. Bénéficiez d’un accès annuel avec 40% de réduction valable pour votre cercle d’intelligence collective sur un        plan d’action sectoriel de votre choix', mostPopular: false,
        mostPopular: false,
    },
    {
        name: 'Offre d’essai : Gold Vertical Partner',
        id: 'membership-24',
        href: '#',
        priceMonthly: '4490€',
        description: 'offre   d’essai membership. Bénéficiez d’un accès annuel avec 40% de réduction valable pour votre cercle d’intelligence collective sur un        plan d’action sectoriel de votre choix', mostPopular: false,
    },


    {
        name: 'Offre d’essai : Platinum Global Partner',
        id: 'platinium',
        href: '#',
        priceMonthly: '9990€',
        description: 'offre   d’essai membership. Bénéficiez d’un accès annuel avec 40% de réduction valable pour votre cercle d’intelligence collective sur un        plan d’action sectoriel de votre choix', mostPopular: false,
    },
]
const sections = [
    {
        name: 'Features',
        features: [
            // { name: 'Taille du stand', tiers: { Starter: '4m²-demi cloison', "Expert Supply": "8m²", "Leader Premium": "12m2" } },
            // { name: 'Raccordement électrique du stand sans surcôut', tiers: { Starter: false, "Expert Supply": true, "Leader Premium": true } },
            // { name: 'Intervention garantie en atelier', tiers: { Starter: false, "Expert Supply": true, "Leader Premium": true } },
            // { name: 'Prêt de matériel (table, chaine ahute, corbeille,...)', tiers: { "Leader Premium": true } },

            {
                name: 'Rejoignez le cercle et les programmes d’intelligence collective de votre secteur', tiers:
                    { Starter: false, "freemium": true, 'membership-12': true, "membership-24": true, "platinium": true },
            }
            ,
            {
                name: 'Participez aux événements, pitchez votre solution (en présentiel ou à distance) lors des webcasts de                myGlobalVillage', tiers:
                    { Starter: false, "freemium": true, 'membership-12': true, "membership-24": true, platinium: true },
            },

            //Bénéficiez de réduction sur les pass d’entrée aux événements affiliés

            {
                name: 'Bénéficiez de réduction sur les pass d’entrée aux événements affiliés', tiers:
                    { Starter: false, "freemium": true, 'membership-12': true, "membership-24": true, platinium: true },
            },

            {
                name: 'Accédez au débriefing de tech’xploration, rejoignez lesjurés ou pitchez à distance', tiers:
                    { Starter: false, "freemium": false, 'membership-12': false, "membership-24": true, platinium: true },
            },
            {
                name: 'Accédez à la base des games changers de votre plan', tiers:
                    { Starter: false, "freemium": false, 'membership-12': false, "membership-24": true, "platinium": true },
            },

            {
                name: 'Faite la promotion d’offre pour attirer les game changer dans voter écosystème', tiers:
                    { Starter: false, "freemium": false, 'membership-12': false, "membership-24": true, "platinium": true },
            },
            {
                name: 'Connectez votre salle de réunion, votre incubateur/accélérateurs ou studio de webcast et créer l’événement toute l’année en direct de +30 événements par an', tiers:
                    { Starter: false, "freemium": false, 'membership-12': false, "membership-24": false, "platinium": true },
            },

            //Accédez à l’annuaire mondial des coordinateurs pays et thematiques de myGlobalVillage
            {
                name: 'Accédez à l’annuaire mondial des coordinateurs pays et thematiques de myGlobalVillage', tiers:
                    { Starter: false, "freemium": false, 'membership-12': false, "membership-24": false, "platinium": true },
            },
        ],
    },
    // {
    //     name: 'Features',
    //     features: [
    //         { name: 'Integrations', tiers: { Starter: true, Essential: true, Premium: true } },
    //         { name: 'Shared links', tiers: { Starter: true, Essential: true, Premium: true } },
    //         { name: 'Importing and exporting', tiers: { Essential: true, Premium: true } },
    //         { name: 'Team members', tiers: { Essential: 'Up to 20 users', Premium: 'Up to 50 users' } },
    //     ],
    // },
    // {
    //     name: 'Reporting',
    //     features: [
    //         { name: 'Advanced analytics', tiers: { Starter: true, Essential: true, Premium: true } },
    //         { name: 'Basic reports', tiers: { Essential: true, Premium: true } },
    //         { name: 'Professional reports', tiers: { Premium: true } },
    //         { name: 'Custom report builder', tiers: { Premium: true } },
    //     ],
    // },
    // {
    //     name: 'Support',
    //     features: [
    //         { name: '24/7 online support', tiers: { Starter: true, Essential: true, Premium: true } },
    //         { name: 'Quarterly product workshops', tiers: { Essential: true, Premium: true } },
    //         { name: 'Priority phone support', tiers: { Essential: true, Premium: true } },
    //         { name: '1:1 onboarding tour', tiers: { Premium: true } },
    //     ],
    // },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Pricing() {
    return (<Layout>
        <div className="bg-neutral-900 py-24 sm:py-32">
            <div className="mx-auto  px-6 lg:px-8 pt-5">
                <div className="mx-auto max-w-4xl text-center">
                    <h2 className="text-base font-semibold leading-7 text-indigo-400">Notre offre</h2>
                    <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
                        MyglobalVillage Access
                    </p>
                </div>
                <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-300 hidden">
                    Distinctio et nulla eum soluta et neque labore quibusdam. Saepe et quasi iusto modi velit ut non voluptas in.
                    Explicabo id ut laborum.
                </p>

                {/* xs to lg */}
                <div className="mx-auto mt-12 max-w-md space-y-8 sm:mt-16 lg:hidden">
                    {tiers.map((tier) => (
                        <section
                            key={tier.id}
                            className={classNames(
                                tier.mostPopular ? 'rounded-xl bg-white/5 ring-1 ring-inset ring-white/10' : '',
                                'p-8'
                            )}
                        >
                            <h3 id={tier.id} className="text-sm font-semibold leading-6 text-white">
                                {tier.name}
                            </h3>
                            <p className="mt-2 flex items-baseline gap-x-1">
                                <span className="text-4xl font-bold text-white">{tier.priceMonthly}</span>
                                <span className="text-sm font-semibold text-gray-300 hidden">/month</span>
                            </p>
                            <a
                                href={tier.href}
                                aria-describedby={tier.id}
                                className={classNames(
                                    tier.mostPopular
                                        ? 'bg-indigo-500 text-white hover:bg-indigo-400 focus-visible:outline-indigo-500'
                                        : 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white',
                                    'mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                                )}
                            >
                                Buy plan
                            </a>
                            <ul role="list" className="mt-10 space-y-4 text-sm leading-6 text-white">
                                {sections.map((section) => (
                                    <li key={section.name}>
                                        <ul role="list" className="space-y-4">
                                            {section.features.map((feature) =>
                                                feature.tiers[tier.id] ? (
                                                    <li key={feature.name} className="flex gap-x-3">
                                                        <CheckIcon className="h-6 w-5 flex-none text-indigo-400" aria-hidden="true" />
                                                        <span>
                                                            {feature.name}{' '}
                                                            {typeof feature.tiers[tier.id] === 'string' ? (
                                                                <span className="text-sm leading-6 text-gray-400">({feature.tiers[tier.id]})</span>
                                                            ) : null}
                                                        </span>
                                                    </li>
                                                ) : null
                                            )}
                                        </ul>
                                    </li>
                                ))}
                            </ul>
                        </section>
                    ))}
                </div>

                {/* lg+ */}
                <div className="isolate mt-20 hidden lg:block">
                    <div className="relative -mx-8">
                        {tiers.some((tier) => tier.mostPopular) ? (
                            <div className="absolute inset-x-4 inset-y-0 -z-10 flex">
                                <div
                                    className="flex w-1/4 px-4"
                                    aria-hidden="true"
                                    style={{ marginLeft: `${(tiers.findIndex((tier) => tier.mostPopular) + 1) * 25}%` }}
                                >
                                    <div className="w-full rounded-t-xl border-x border-t border-white/10 bg-white/5" />
                                </div>
                            </div>
                        ) : null}
                        <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
                            <caption className="sr-only">Pricing plan comparison</caption>
                            <colgroup>
                                <col className=" w-1/6" />
                                <col className="w-1/6" />
                                <col className="w-1/6" />
                                <col className="w-1/6" />
                                <col className="w-1/6" />
                                <col className="w-1/6" />

                            </colgroup>
                            <thead>
                                <tr>
                                    <td />
                                    {tiers.map((tier) => (
                                        <th key={tier.id} scope="col" className="px-2 pt-6 xl:px-2 xl:pt-8">
                                            <div className="text-sm font-semibold leading-7 text-white">{tier.name}</div>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">
                                        <span className="sr-only">Price</span>
                                    </th>
                                    {tiers.map((tier) => (
                                        <td key={tier.id} className="px-2 pt-2 xl:px-2">
                                            <div className="flex items-baseline gap-x-1 text-white">
                                                <span className="text-4xl font-bold">{tier.priceMonthly}</span>
                                                <span className="text-sm font-semibold leading-6 hidden">/month</span>
                                            </div>
                                            <a
                                                href={tier.href}
                                                className={classNames(
                                                    tier.mostPopular
                                                        ? 'bg-indigo-500 hover:bg-indigo-400 focus-visible:outline-indigo-600'
                                                        : 'bg-white/10 hover:bg-white/20 focus-visible:outline-white',
                                                    'mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                                                )}
                                            >
                                                Buy plan
                                            </a>
                                        </td>
                                    ))}
                                </tr>
                                {sections.map((section, sectionIdx) => (
                                    <Fragment key={section.name}>
                                        <tr>
                                            <th
                                                scope="colgroup"
                                                colSpan={4}
                                                className={classNames(
                                                    sectionIdx === 0 ? 'pt-8' : 'pt-16',
                                                    'pb-4 text-sm font-semibold leading-6 text-white'
                                                )}
                                            >
                                                {section.name}
                                                <div className="absolute inset-x-8 mt-4 h-px bg-white/10" />
                                            </th>
                                        </tr>
                                        {section.features.map((feature) => (
                                            <tr key={feature.name}>
                                                <th scope="row" className="py-4 text-sm font-normal leading-6 text-white">
                                                    {feature.name}
                                                    <div className="absolute inset-x-8 mt-4 h-px bg-white/5" />
                                                </th>
                                                {tiers.map((tier) => (
                                                    <td key={tier.id} className="px-6 py-4 xl:px-8">
                                                        {typeof feature.tiers[tier.id] === 'string' ? (
                                                            <div className="text-center text-sm leading-6 text-gray-300">
                                                                {feature.tiers[tier.id]}
                                                            </div>
                                                        ) : (
                                                            <>
                                                                {feature.tiers[tier.id] === true ? (
                                                                    <CheckIcon className="mx-auto h-5 w-5 text-indigo-400" aria-hidden="true" />
                                                                ) : (
                                                                    <MinusIcon className="mx-auto h-5 w-5 text-gray-500" aria-hidden="true" />
                                                                )}

                                                                <span className="sr-only">
                                                                    {feature.tiers[tier.id] === true ? 'Included' : 'Not included'} in {tier.name}
                                                                </span>
                                                            </>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
    )
}
