import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import useApiUrlTranslation from './useApiUrlTranslation';
import { use } from 'i18next';
import HTMLReactParser from 'html-react-parser';
import { useParams } from 'react-router-dom/cjs/react-router-dom';


const Tva = () => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    return <span className='text-sm font-normal'>20% {t('pages.store.tva_non_incluse')}</span>
}

const Product = ({ prestaType }) => {

    const { t, i18n } = useApiUrlTranslation();

    const { idEvent, idEventCycleLang } = useParams();

    useEffect(() => {

    }, [i18n.resolvedLanguage])

    return (

        <a

            className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"

            href={
                prestaType.hide_price == 1 ? `https://www.myglobalvillage.com/events/${idEvent}/signup/informations-commerciales/?form-name=informations-commerciales&formisvisible=1&id_formulaire=55&packSelection=${idEventCycleLang}` :
                    `https://www.myglobalvillage.com/events/${idEvent}/signup/delegation-myglobalvillage/?form-name=delegation-myglobalvillage&formisvisible=1&id_formulaire=54&packSelection=${idEventCycleLang}`}
        >


            <div className={`aspect-h-4 aspect-w-3 bg-gray-200 sm:aspect-none group-hover:opacity-75 sm:h-96 ${!prestaType.visuel || !/\.(jpe?g|png|gif|bmp)$/i.test(prestaType.visuel) ? "hidden" : ""}`}>
                <img
                    src={prestaType.visuel}
                    alt={prestaType.imageAlt}
                    className={`h-full w-full object-cover object-center sm:h-full sm:w-full`}
                />
            </div>
            <div className="flex flex-1 flex-col space-y-2 p-4">
                <h3 className="text-lg font-bold text-gray-900">
                    <a href={prestaType.href}>
                        <span aria-hidden="true" className="absolute inset-0" />
                        {prestaType.presta_type_nom}
                    </a>
                </h3>

                <div className='border-x border-y text-center py-2 rounded bg-pink-600 text-white font-bold border-pink-200 group-hover:border-pink-400 my-3'>
                    {prestaType.hide_price == 1 ? t('pages.store.information_reservation') : t('pages.store.acheter_pack')}
                </div>

                <p className="text-sm text-gray-500 ">{HTMLReactParser(prestaType.descriptif)}</p>
                <div className="flex flex-1 flex-col justify-end">
                    <p className="text-sm italic text-gray-500">{prestaType.options}</p>
                    {prestaType.hide_price == 0 &&
                        <p className="text-4xl font-black tracking-tighter text-pink-600 ">{prestaType.prix_unitaire_ht} €
                        </p>
                    }
                </div>
                {prestaType.hide_price == 0 && prestaType.prix_unitaire_ht != 0 && <Tva />}
            </div>
        </a>
    )
}


const Store = () => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [eventCycleLangPrestaTypeList, setEventCycleLangPrestaTypeList] = useState(false);

    const { idEventCycleLang, idEvent } = useParams()

    const [eventCycleLangList, setEventCycleLangList] = useState(false);

    const [eventCycleLang, setEventCycleLang] = useState(false);

    const getEventCycleLangPrestaType = async () => {

        const params = `WHERE id_event_cycle_lang IN(${idEventCycleLang})`;

        //const listProductIdToArray = listProductId.split('[').join('').split(']').join('').split(',')

        // const params = `WHERE id_presta_typ IN(${listProductIdToArray.map((id) => id)})`;


        await fetch(`${apiUrlTranslation}?action=getEventCycleLangPrestaType&params=${params}`)
            .then(res => res.json())
            .then(res => {
                setEventCycleLangPrestaTypeList(res)

            })
    }

    useEffect(() => {


        const getEventCycleLangList = async () => {

            await fetch(`${apiUrlTranslation}?action=getEventCycleLangList&params= WHERE id_event_cycle_lang=${idEventCycleLang}`)
                .then(res => res.json())
                .then(res => {
                    setEventCycleLangList(res)
                    setEventCycleLang(res[0])
                })
        }

        getEventCycleLangList()
        getEventCycleLangPrestaType()
    }
        , [i18n.resolvedLanguage])

    if (!eventCycleLangPrestaTypeList) return null


    return (
        <Layout>




            <div className="bg-white">

                <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">

                    <p className='text-5xl pb-5 font-bold'>{t('pages.store.formule_participation')} - {eventCycleLang?.event_cycle_lang_nom}</p>


                    <select className='hidden  mb-5 text-xl p-3 rounded'>
                        <option value="">Selectionner un pack</option>
                        <option value="">Pack fr</option>
                        <option value="">Pack int</option>
                    </select>

                    <h2 className="sr-only">Products</h2>

                    <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
                        {eventCycleLangPrestaTypeList.map((eventCycleLangPrestaType) => (
                            <Product prestaType={eventCycleLangPrestaType.presta_type} />
                        ))}
                    </div>
                </div>
            </div>



        </Layout>
    )
}

export default Store