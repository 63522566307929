import React from "react";
import Layout from "./Layout";
import PartenairesListDetails from "./PartenairesListDetails";
import useApiUrlTranslation from "./useApiUrlTranslation";

const PartenaireList = () => {

  const { t } = useApiUrlTranslation()

  return (
    <Layout>
      <PartenairesListDetails
        statut={"'partenaire_national', 'partenaire_demonstrateur', 'partenaire_officiel', 'partenaire_national', 'partenaire_demonstrateur', 'coordinateur_pitch', 'coordinateur-de-la-da-la-gation', 'partenaire_officiel',  250, 202, 217"}
        title={t('event_nav.partenaires')} />
    </Layout>
  );
}

export default PartenaireList;