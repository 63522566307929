import styled from "styled-components";
import './Loader.css'
const Loader = ({ className }) => {

  const Pentagone = styled.div`
  position: relative;
  width: 24px;
  border-width: 50px 18px 0;
 

  &:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    top: -85px;
    left: -18px;
    border-width: 0 45px 35px;
  }
  `

  return (

    <div className="d-flex justify-content-center ">
      <Pentagone id="polygone" style={{ display: 'none' }}></Pentagone>

      <div className="spinner-border text-secondary" role="status" style={{ display: 'none' }}>
        <span className="sr-only">Loading...</span>
      </div>

      <div className="loader hidden"></div>

      <div className="flex flex-col">
        <svg viewBox="0 0 100 100" style={{ width: "50px" }}>
          <polygon id="pentagon" points="50 1 95 38 77 95 23 95 5 38" fill="black">
            <animate
              attributeName="points"
              values="50 1 95 38 77 95 23 95 5 38;
                    50 1 80 38 95 95 23 95 5 38;
                    50 1 95 38 50 95 23 95 5 38;
                    50 50 95 50 77 95 23 95 5 50;
                    50 1 95 38 77 95 23 95 5 38"
              keyTimes="0; 0.25; 0.5; 0.75; 1"
              keySplines="0.215 0.610 0.355 1;
                        0.215 0.610 0.355 1;
                        0.215 0.610 0.355 1;
                        0.215 0.610 0.355 1"
              calcMode="spline"
              dur="5s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="fill"
              values="black; gray"
              dur="3s"
              repeatCount="indefinite"
            />
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 50 50"
              to="360 50 50"
              dur="5s"
              repeatCount="indefinite"
            />
          </polygon>
        </svg>
        <div> Loading data...</div>
      </div>
    </div >
  )
}

export default Loader;