import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { useFormContext, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RegistrationFormSection from './RegistrationFormSection';
import RegistrationFormRow from './RegistrationFormRow';
import LoginCheckUser from './account/LoginCheckUser';





const RegistrationFormProfileCheckerSection = ({ contact, formIsVisible, setFormIsVisible }) => {

    if (formIsVisible) {
        return null;
    }

    return <RegistrationFormSection>

        <LoginCheckUser setIsUserExistAction={setFormIsVisible} />
    </RegistrationFormSection >
}


export default RegistrationFormProfileCheckerSection;