import React, { useEffect, useState } from 'react'
import RegistrationFormSection from './RegistrationFormSection'
import { useTranslation } from 'react-i18next';
import useApiUrlTranslation from './useApiUrlTranslation';
import { RegistrationFormSubtitleSection, RegistrationFormSupertitleSection } from './RegistrationFormStyleSection';
import HTMLReactParser from 'html-react-parser';
import { removeDuplicateObjectFromArray, removeDuplicateObjectsByProperty } from '../utils';

const OnboardingFormProgrammeInnovation = () => {
    const { t } = useApiUrlTranslation()

    return <div>
        {HTMLReactParser(t('form.sections.contact_communaute_section.section_notabene'))}
    </div>
}

const Visuel = ({ cycleLang }) => {

    const img = cycleLang.attachedFilesCollection.find(item => item.id_attached_file_type == "65").tiny
    return (
        <img
            className="w-10 border h-10 m-1"
            src={cycleLang.attachedFilesCollection.find(item => item.id_attached_file_type == "65").tiny}
            alt=""
        />
    )
}


const ConfEventCycleLangItem = ({ confEventCycleLang }) => {

    const { i18n, apiUrlTranslation } = useApiUrlTranslation()
    const [cycleLang, setCycleLang] = useState(null)

    useEffect(() => {

        const getCycleLang = async (id_cycle_lang) => {

            await fetch(`${apiUrlTranslation}?action=getCycleLangByIdCycleLang&id_cycle_lang=${confEventCycleLang.id_cycle_lang.id_cycle_lang}`)
                .then(res => res.json())
                .then(res => {
                    setCycleLang(res)
                })
        }

        getCycleLang()

    }, [])

    return <div>

        {//cycleLang && cycleLang.id_cycle_lang
        }

        <li className="pl-10"><b> - {confEventCycleLang.id_cycle_lang.nom_cycle_lang}</b>

            <div className="hidden">) id: {confEventCycleLang.id_cycle_lang.id_cycle_lang}</div>

            {cycleLang && cycleLang.children && <div>
                <ul className="pl-5">
                    {cycleLang.children?.map(enfant =>
                        <label className="flex items-center font-normal border-b" key={enfant.id_cycle_lang}>
                            <input type="checkbox" name="" id="" />
                            <Visuel cycleLang={enfant} />
                            {enfant.nom_cycle_lang}
                            <span className="hidden"> (id: {enfant.id_cycle_lang})</span>
                        </label>)}
                </ul>
            </div>
            }
        </li>
    </div>
}

const ConfEvent = ({ confEvent, presta, idCycleLangList, setIdCycleLangList }) => {

    const { i18n, apiUrlTranslation } = useApiUrlTranslation()

    const [confEventCycleLangList, setConfEventCycleLangList] = useState(null)

    useEffect(() => {

        const getConfEventCycleLang = async () => {

            await fetch(`${apiUrlTranslation}?action=getConfEventCycleLang&params=WHERE id_conf_event=${confEvent.id_conf_event}`)
                .then(res => res.json())
                .then(res => {

                    return res
                        .map(confEventCycleLang => {
                            return ({
                                ...confEventCycleLang,
                                idCycleLang: confEventCycleLang.id_cycle_lang.id_cycle_lang
                            })
                        })
                })
                .then(res => {
                    const uniqueData = removeDuplicateObjectFromArray(res, "idCycleLang")
                    setConfEventCycleLangList(uniqueData)

                    res.map(confEventCycleLang => {

                        setIdCycleLangList([...idCycleLangList, confEventCycleLang.idCycleLang])
                    })

                })
        }


        confEvent && getConfEventCycleLang()
    }, [confEvent])

    if (confEvent === null) return null

    return <div>
        <div className="hidden">
            <div>Démo : {presta.presta_nom} </div>
            <div> <b>Session :</b> {confEvent.cel_titre}</div>
            <div> <b>Session id_conf_event : </b>{confEvent.id_conf_event}</div>
            <div> <b>Session date : </b>{confEvent.conf_event_date}</div>
            <b>Collectif/plan d'actions :</b>
        </div>
        <div>
            <ul>
                {confEventCycleLangList && confEventCycleLangList.map(confEventCycleLang =>
                    <>
                        <ConfEventCycleLangItem
                            key={confEventCycleLang.id_conf_event_cycle_lang}
                            confEventCycleLang={confEventCycleLang}
                        />
                    </>
                )}
            </ul>
        </div>
    </div >
}

const RegistrationFormContactCommunauteSection = ({ contact }) => {

    const [juryDemoList, setJuryDemoList] = useState(null)


    const [cycleLang, setCycleLang] = useState(null)

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation()

    const [showBody, setShowBody] = useState(true);

    const [idCycleLangList, setIdCycleLangList] = useState([]);

    useEffect(() => {

        const getCycleLang = async () => {

            const filter = `  id_cycle_lang IN ( SELECT id_cycle_lang FROM event_cycle_lang WHERE id_event_cycle_lang=6954) AND `

            await fetch(`${apiUrlTranslation}?action=getCycleLang&filter=${filter}`)
                .then(res => res.json())
                .then(res => {
                    setCycleLang(res[0])
                })
        }


        const getJuryDemoList = async () => {

            const reqSuite = `AS jd
            JOIN jurys AS j ON j.id_jury = jd.id_jury
            JOIN jury_events AS je ON je.id_jury = j.id_jury
            JOIN conf_event_cycle_lang AS cecl ON cecl.id_conf_event = je.id_conf_event
            JOIN cycle_lang AS cl ON cl.id_cycle_lang = cecl.id_cycle_lang
            WHERE jd.id_demo IN (
                SELECT id_presta
                FROM prestas
                WHERE id_contact = '${contact.id_contact}'
            )
            GROUP BY cl.id_cycle_lang`;

            const reqSuite2 = `WHERE id_demo IN(SELECT id_presta FROM prestas WHERE id_contact=${contact.id_contact})`;

            await fetch(`${apiUrlTranslation}?action=getJuryDemoList&params= ${reqSuite}`)
                .then(res => res.json())
                .then(res => {

                    setJuryDemoList(res.map(juryDemo => ({ ...juryDemo, idConfEvent: juryDemo.jury_event.conf_event.id_conf_event })))
                })
        }

        contact && getJuryDemoList()

        getCycleLang();

    }, [contact])

    if (contact === null) return null

    return (
        <RegistrationFormSection
            className="bg-red-500"
            supertitle={<RegistrationFormSupertitleSection>Vos leviers de développement prioritaires </RegistrationFormSupertitleSection>}

            title={t('form.sections.contact_communaute_section.section_title')}
            subtitle_={<RegistrationFormSubtitleSection>{t('form.sections.contact_communaute_section.section_subtitle')}</RegistrationFormSubtitleSection>}
            instructions_={HTMLReactParser(t('form.sections.contact_communaute_section.section_instructions'))}>


            <label className="font-normal pt-3 block">
                <input
                    name='contact_communaute_reveal'
                    type="radio"
                    onChange={() => setShowBody(!showBody)}
                    checked={showBody} /> {t('form.sections.contact_communaute_section.section_body_reveal_input')}

                {
                    "	"
                    //t('form.sections.global_funding_connector.cherche_revendeurs')
                }
            </label>

            <label className="font-normal block">
                <input
                    name='contact_communaute_reveal'
                    type="radio"
                    onChange={() => setShowBody(!showBody)}
                />  Je ne cherche pas a aquérir de nouveau client en chassant en groupe


                {
                    "	"
                    //t('form.sections.global_funding_connector.cherche_revendeurs')
                }
            </label>

            {showBody && <>

                {/* 
                <RegistrationFormSubtitleSection>{t('form.sections.contact_communaute_section.section_subtitle')}</RegistrationFormSubtitleSection>
                */}
                <div className="alert alert-info"> {HTMLReactParser(t('form.sections.contact_communaute_section.section_instructions'))}</div>
                <p className="pb-3">
                    {t('form.sections.contact_communaute_section.communaute_list_title')}
                </p>

                {cycleLang && cycleLang.children && <div>
                    <ul className="pl-5">
                        {cycleLang.children?.filter(enfant => ["collectif_programme"].includes(enfant.cycle_lang_type)).map(enfant =>
                            <label className="flex items-center font-normal border-b p-2" key={enfant.id_cycle_lang}>

                                <input className='mr-2' type="checkbox" name="" id="" />  <img src={enfant.logo_better_url} className="w-10 mx-2" />  {enfant.nom_cycle_lang}

                                <span className="hidden"> (id: {enfant.id_cycle_lang}) {enfant.cycle_lang_type}</span>
                            </label>)}
                    </ul>
                </div>
                }


                {/*juryDemoList && removeDuplicateObjectFromArray(juryDemoList, 'idConfEvent').map(juryDemo =>

                    <>
                        <div className="border py-3 p-3" >

                            <ConfEvent
                                presta={juryDemo.presta}
                                confEvent={juryDemo.jury_event.conf_event}
                                setIdCycleLangList={setIdCycleLangList}
                                idCycleLangList={idCycleLangList} />
                        </div>
                    </>
                )*/}

                {/* <OnboardingFormProgrammeInnovation /> */}
            </>
            }

        </RegistrationFormSection >
    )
}

export default RegistrationFormContactCommunauteSection