import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';

function generateSlides(length = 10, sig = 0) {
    return Array.from({ length }).map((value, index) => {
        index = sig || index;

        return {
            src: `https://source.unsplash.com/random/800x450?sig=${index}`,
            alt: `Image ${index}`,
        };
    });
}


const TestSplide = () => {
    return <div className="wrapper">
        <h2>Autoplay</h2>

        <Splide
            options={{
                type: 'loop',
                gap: '1rem',
                autoplay: true,
                pauseOnHover: false,
                resetProgress: false,
                arrows: 'slider',
                height: '15rem',
            }}
            hasSliderWrapper
            hasAutoplayControls
            hasAutoplayProgress
        >
            {generateSlides().map(slide => (
                <SplideSlide key={slide.src}>
                    <img src={slide.src} alt={slide.alt} />
                </SplideSlide>
            ))}
        </Splide>
    </div>
};

export default TestSplide;
