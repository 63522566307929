import React, { useEffect, useState } from 'react'
import PageSection from './PageSection'
import { use } from 'i18next'
import useApiUrlTranslation from './useApiUrlTranslation';
import Loader from './Loader';
import { ArrowRightCircleIcon, ArrowRightIcon } from '@heroicons/react/24/solid';


const ConfEventItem = ({ confEvent }) => {

    const [logo, setLogo] = useState(null);

    useEffect(() => {

        // const newLogo = confEvent.id_event.logos.large ? confEvent.id_event.logos.large : confEvent.id_event.event_ligne.el_visuels.large;
        // setLogo(newLogo);

    }, [])

    return <a className='group' target="_blank" href={`/events/${confEvent.id_event.id_event}/programm/${confEvent.id_conf_event}/programm-details`}>

        <div className="flex bg-neutral-950 p-2 justify-between group-hover:bg-neutral-900">
            <div className='flex'>
                <div className="flex items-center space-x-5 w-96   ">
                    <img src={confEvent.id_event.event_ligne.el_visuels.large} alt="" className="w-16 rounded" />
                    <div className='text-white'>{confEvent.id_event.nom}</div>
                </div>

                <div className="flex flex-col   ">
                    <div className='text-left text-pink-500'>{confEvent.conf_event_date} - {confEvent.heure_debut} - {confEvent.heure_fin}</div>
                    <div className="text-white">{confEvent.conf_event_lang.cel_titre}</div>
                </div>

            </div>

            <div className=" flex  items-center text-3xl group-hover:text-pink-600"> &gt; </div>
        </div>

    </a >
}

const ProgrammItemHilight = () => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [confEventList, setConfEventList] = useState(null);

    const getConfEvent = async () => {

        await fetch(`${apiUrlTranslation}?action=getConfEvent& filter= (type IN(96) OR filme IN('y','vs','s') )  AND conf_event_date >= DATE(NOW())`)
            .then((res) => res.json())
            .then((res) => {
                setConfEventList(res);
            })
    }

    useEffect(() => {
        getConfEvent();
    }, [])

    // if confeventlist is array and length is 0, return null

    if (confEventList && confEventList.length === 0) return null;

    return (
        <PageSection title="Prochains webcasts">

            {!confEventList && <Loader />}

            <div className="max-w-8xl mx-auto container divide-y divide-neutral-800">
                {confEventList && confEventList.map(confEvent =>
                    <ConfEventItem confEvent={confEvent} />
                )}
            </div>
        </PageSection>
    )
}

export default ProgrammItemHilight