import React from "react";
import Layout from "./Layout";

function ConnectedStudios() {
  return (
    <Layout>
      <div>ConnectedStudios</div>
    </Layout>
  );
}

export default ConnectedStudios;
