import React, { useContext, useState } from 'react';
import RegistrationFormSection from './RegistrationFormSection';
import useApiUrlTranslation from './useApiUrlTranslation';
import { useFormContext } from 'react-hook-form';
import EventContext from '../contexts/EventContext';

const RegistrationFormCooptation = ({ instructions = "", title }) => {

    const [invites, setInvites] = useState([]);

    const [newInvite, setNewInvite] = useState({});

    const { t, i18n } = useApiUrlTranslation();

    const { setValue } = useFormContext();

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    const handleUpdateNewInvite = (e) => {

        e.preventDefault();

        setNewInvite({ ...newInvite, [e.target.name]: e.target.value })
    }

    const handleSaveNewInvite = (e) => {

        e.preventDefault();

        setInvites([...invites, newInvite]);
        setValue('onboardedContactList', [...invites, newInvite]);
    }

    const handleDeleteInvite = (e) => {

        const currentIndex = e.currentTarget.getAttribute('index');

        console.log(currentIndex);

        e.preventDefault();

        setInvites([...invites].filter((item, index) => index != currentIndex));
    }

    return <RegistrationFormSection
        title={title}
        instructions={instructions}>

        <div className="row">
            <div className="col-md-1">

                <select name="" id="" onChange={(e) => handleUpdateNewInvite(e)} className="form-select" style={{ width: '100px' }}>
                    <option value="">Statut</option>
                </select>
            </div>

            <div className="col-md-2">
                <input type="text"
                    placeholder={t('form.sections.contact_section.prenom')}
                    name="prenom"
                    className="form-control capitalize"
                    onChange={(e) => handleUpdateNewInvite(e)} />
            </div>
            <div className="col-md-2 mb-3">
                <input type="text" placeholder={t('form.sections.contact_section.nom')}
                    name="nom"
                    className="form-control capitalize "
                    onChange={(e) => handleUpdateNewInvite(e)} />
            </div>
            <div className="col-md-2 mb-3">
                <input type="text"
                    placeholder="E-mail"
                    name="email"
                    className="form-control"
                    onChange={(e) => handleUpdateNewInvite(e)} />
            </div>
            <div className="col-md-2 mb-3">
                <input type="text"
                    placeholder="Mobile"
                    name="port"
                    className="form-control capitalize "
                    onChange={(e) => handleUpdateNewInvite(e)} />
            </div>
            <div className="col-md-2 mb-3">
                <input type="text"
                    placeholder={t('form.sections.contact_section.fonction')}
                    name="fonction"
                    className="form-control capitalize"
                    onChange={(e) => handleUpdateNewInvite(e)} />
            </div>
            <div className="col"><button className="btn btn-primary btn-sm" onClick={(e) => handleSaveNewInvite(e)}>{t('global.ajouter')}</button></div>
        </div>

        <table className="table table-bordered table-sm table-striped capitalize">
            <thead>
                <tr>
                    <th style={{ width: "20px" }}></th>
                    <th>{t('form.sections.contact_section.prenom')} </th>
                    <th>{t('form.sections.contact_section.nom')} </th>
                    <th>E-mail</th>
                    <th>Mobile</th>
                    <th>{t('form.sections.contact_section.fonction')} </th>
                </tr>
            </thead>
            <tbody className="capitalize">
                {invites && invites.map((invite, index) => <tr key={index}>
                    <td><button key={index} className="btn btn-secondary btn-sm" index={index} onClick={(e) => handleDeleteInvite(e)}>X</button></td>
                    <td>{invite.prenom}</td>
                    <td>{invite.nom}</td>
                    <td>{invite.email}</td>
                    <td>{invite.port}</td>
                    <td>{invite.fonction}</td>
                    <td>
                        <button type="button" class="btn btn-secondary btn-sm d-none" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            E-mail notification preview
                        </button>
                    </td>
                </tr>)}
            </tbody>
        </table>

        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">E-mail preview</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p>Bonjour,</p>
                        <p>Vous recevez cet e-mail dans le cadre de....</p>

                        <p>
                            Cordialement <br />
                            L'équipe {currentEvent?.nom}
                        </p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                    </div>
                </div>
            </div>
        </div>


    </RegistrationFormSection>
}

export default RegistrationFormCooptation;