import parse from 'html-react-parser';
import React, { useEffect, useState } from "react";
import { API_URL } from "../config";
import Layout from "./Layout";
import { Link } from 'react-router-dom/cjs/react-router-dom';
import moment from 'moment';



const Event = ({ event, eventList }) => {

  return <div class="flex border-neutral-900 border-x border-y rounded-2xl space-x-4 mb-2">

    <div class="basis-1/6">
      <img src={event.event_ligne.el_visuels.small} alt="" className="rounded-l-2xl" style={{ width: "100%" }} />
    </div>

    <div class="basis-5/6 text-white">
      <h5 className="text-xl mb-2"> {event.event_ligne.event_ligne_nom}</h5>
      <h5 className="text-xl mb-2"> {event.event_ligne.id_event_ligne}</h5>
      <div className=''> {parse(event.event_ligne.descriptif_mgv ?? "")}</div>


    </div>
  </div>

}

function EventLigne({ eventLigne, eventList }) {

  const [lastEvent, setLastEvent] = useState(false);

  useEffect(() => {
    setLastEvent(eventList && eventList[eventList.length - 1]);
  }, [eventList]);


  return <div class="flex bg-neutral-800/10 border-x border-neutral-900 border-y rounded-2xl space-x-4 mb-2 py-3">

    <div class="basis-1/6 flex flex-col justify-center pl-4">
      <img src={eventLigne.el_visuels.small} alt="" className="" style={{ width: "100%" }} />
    </div>

    <div class="basis-4/6 text-white py-3">
      <h5 className="text-xl mb-2 font-bold"> {eventLigne.event_ligne_nom}</h5>
      <div className=''> {parse(eventLigne.descriptif_mgv ?? "")}</div>

      {lastEvent && <div className="mt-3">

        <p className="pb-3 font-sm text-gray-400">
          {moment(lastEvent.event_start).isBefore(moment()) ? 'Dernière édition ' : 'Prochaine édition '}
          :  {moment(lastEvent.event_start).format('YYYY-MM-DD')}</p>


      </div>}

    </div>
    <div className="flex flex-col items-center justify-center basis-1/6">
      {lastEvent ? (
        lastEvent.event_statut == "optionne" ? (
          <div className="text-pink-400">A découvrir prochainement</div>
        ) : (
          <Link to={`/events/${lastEvent.id_event}/`} className='hover:bg-pink-700 hover:text-white border-pink-700 border-x  border-y px-4 py-2 rounded-full text-gray-400'>
            {moment(lastEvent.event_start).isBefore(moment()) ? 'Dernière édition ' : 'Prochaine édition '}
          </Link>
        )
      ) : null}


    </div>
  </div>

}

function LeadingEvents() {

  const [eventList, setEventList] = useState();

  const [eventLigneList, setEventLigneList] = useState(null);

  const getEventLigne = async () => {

    await fetch(`${API_URL}?action=getEventLigne&filter= WHERE id_event_format="1" `)
      .then(response => response.json())
      .then(data => {
        setEventLigneList(data);
      })
  }


  const fetchEvent = async () => {

    const params = ` LEFT JOIN event_ligne el ON el.id_event_ligne=events.id_event_ligne

    WHERE
    (el. id_event_format="1")
 
    AND events.id_event NOT IN(399)
    AND afficher !='n'
    AND `;

    await fetch(`${API_URL}?action=getEvents&params=${params} `)
      .then(res => res.json())
      .then(res => {

        setEventList(res)

      })
  }
  useEffect(() => {
    fetchEvent();
    getEventLigne();
  }, []);

  return (
    <Layout title="Evénements leaders">

      <div className="px-10">
        {eventLigneList && eventLigneList.map(eventLigne => <EventLigne
          key={eventLigne.id_event_ligne}
          eventList={eventList && eventList
            //order b y event_start

            .sort((a, b) => new Date(a.event_start) - new Date(b.event_start))
            .filter(event => event.event_ligne.id_event_ligne === eventLigne.id_event_ligne)}
          eventLigne={eventLigne} />)}

      </div>
    </Layout>
  );
}

export default LeadingEvents;
