import React, { useEffect, useState } from 'react';
import RegistrationFormSection from "./RegistrationFormSection";
import RegistrationFormRow from './RegistrationFormRow';
import { RegistrationFormSupertitleSection } from './RegistrationFormStyleSection';
import useApiUrlTranslation from './useApiUrlTranslation';
import { use } from 'i18next';

const RegistrationFormValorisationTerritoireSection = ({ formulaire, contact }) => {
    return (
        <RegistrationFormSection
            formSectionId={38}
            supertitle={<RegistrationFormSupertitleSection>
                <div className="flex items-center">
                    <img src="https://www.mlg-consulting.com/manager_cc/docs/archives/thumbs/241028123159_cerlce-de-d-cideurs-r-cup-r-53_100x0.png" className="w-20" alt='' />
                    <div>{formulaire.valorisation_territoire_section_supertitle}</div>
                </div>
            </RegistrationFormSupertitleSection>}
            title={formulaire.valorisation_territoire_section_title}
            instructions={<div className="flex space-x-3 items-center">
                <div><img src={formulaire.valorisation_territoire_section_visuel_thumbs.small} alt="" className="w-72" /></div>
                <div>{formulaire.valorisation_territoire_section_instructions}</div>
            </div>}
        >
            <AttractivenessForm contact={contact} />
        </RegistrationFormSection>
    );
};

export default RegistrationFormValorisationTerritoireSection;

function AttractivenessForm({ contact }) {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [formData, setFormData] = useState({
        attractivenessPack: '',
        brochure: null,
        video: null,
        image: null,
        strength1: '',
        strength2: '',
        strength3: '',
        welcomePack: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];
        setFormData({ ...formData, [name]: file });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission, such as sending data to backend
        console.log(formData);
    };

    useEffect(() => {

    }
        , [i18n.resolvedLanguage]);

    return (
        <div className="max-w-full mx-auto p-6 bg-white rounded-lg shadow-md"  >

            <p className="font-xl font-bold my-3 text-xl">
                {t("form.sections.valorisation_territoire.brochure_section_title")}
            </p>
            <div className="flex flex-wrap mb-4">
                <label className="w-full md:w-1/2 pr-4 mb-4 md:mb-0 flex items-center">
                    <span className="mr-4 basis-2/6 text-right font-normal">

                        {t("form.sections.valorisation_territoire.outils")}
                    </span>
                    <select
                        name="valorisation_territoire_pack_attractivite_option_tool hidden"
                        className="w-full border p-2 rounded"
                        value={formData.attractivenessPack}
                        onChange={handleChange}
                    >
                        {/* <option value="">-- Sélectionnez une option --</option>
                        <option value="1">Oui</option>
                        <option value="0">Non</option> */}
                        <option value="outils_option_none">
                            {t("form.sections.valorisation_territoire.outils_option_none")}
                        </option>
                        <option value="outils_option_generaliste">
                            {t("form.sections.valorisation_territoire.outils_option_generaliste")}
                        </option>
                        <option value="outils_option_specialise">
                            {t("form.sections.valorisation_territoire.outils_option_specialise")}
                        </option>
                        <option value="outils_option_vertical">
                            {t("form.sections.valorisation_territoire.outils_option_vertical")}
                        </option>

                    </select>
                </label>
                {/* 
                <label className="w-full md:w-1/2 mb-4 md:mb-0 flex items-center">
                    <span className="mr-4 basis-2/6 font-normal">

                        {t("form.sections.valorisation_territoire.upload")}</span>
                    <input
                        type="file"
                        name="valorisation_territoire_welcomepack_brochure"
                        accept="application/pdf"
                        className="w-full border p-2 rounded"
                        onChange={handleFileChange}
                    />
                </label> */}

                <RegistrationFormRow
                    labelClassName="text-right font-normal"
                    required={false}
                    inputType="file"
                    name='valorisation_territoire_welcomepack_brochure'
                    placeholder={t("form.sections.valorisation_territoire.upload")}
                    helper=""
                    defaultValue={contact?.valorisation_territoire_welcomepack_brochure}
                    accept={[
                        ".pdf",

                    ]} />


            </div>

            <p className="text-xl font-bold my-3">
                {t("form.sections.valorisation_territoire.iillustration_section_title")} </p>


            <div className="flex flex-wrap mb-4">

                {/* <RegistrationFormRow

                    inputType="file"
                    name='video'
                    placeholder="J'uploade une video selon l'ordre de priorité suivant "
                    helper="video sans texte, sinon video avec textes en anglais si pas possible video dans une autre langue "
                    defaultValue={contact?.port}
                    onChange={handleFileChange} /> */}

                {/* <label className="w-full md:w-1/2 pr-4 mb-4 md:mb-0 flex items-center">
                    <span className="mr-4 basis-2/6 font-normal text-right">

                        {t("form.sections.valorisation_territoire.upload_video")} : </span>
                    <div className="flex flex-col w-full">
                        <input
                            type="file"
                            name="valorisation_territoire_welcomepack_video"
                            accept="video/*"
                            className="w-full border p-2 rounded"
                            onChange={handleFileChange}
                        />
                        <span className="font-normal text-gray-400 text-sm">
                            {t("form.sections.valorisation_territoire.upload_video_helper")}
                        </span>
                    </div>
                </label> */}


                <RegistrationFormRow
                    labelClassName="text-right font-normal"
                    required={false}
                    inputType="file"
                    name='valorisation_territoire_welcomepack_video'
                    placeholder={t("form.sections.valorisation_territoire.upload_video")}
                    helper={t("form.sections.valorisation_territoire.upload_video_helper")}
                    defaultValue={contact?.upload_video_helper}
                    accept={[
                        ".mp4",
                        ".mov",
                        ".avi",
                        ".mpg",
                        ".mpeg",
                        ".wmv",
                    ]} />

                {/* <label className="w-full md:w-1/2 mb-4 md:mb-0 flex items-center">
                    <span className="mr-4 basis-2/6 font-normal text-right">

                        {t("form.sections.valorisation_territoire.upload_visuel")} </span>
                    <input
                        type="file"
                        name="valorisation_territoire_welcomepack_visuel"
                        accept="image/*"
                        className="w-full border p-2 rounded"
                        onChange={handleFileChange}
                    />
                </label> */}

                <RegistrationFormRow
                    labelClassName="text-right font-normal"
                    required={false}
                    inputType="file"
                    name='valorisation_territoire_welcomepack_visuel'
                    placeholder={t("form.sections.valorisation_territoire.upload_visuel")}
                    helper=""
                    defaultValue={contact?.valorisation_territoire_welcomepack_visuel}
                    accept={[
                        ".png",
                        ".jpg",
                        ".jpeg",
                        ".gif",
                    ]} />

            </div>

            <p className="text-xl  font-bold mb-2">   {t("form.sections.valorisation_territoire.points_forts_section")}</p>
            <div className="flex flex-wrap mb-4">
                {[1, 2, 3].map((num) => (
                    // <label key={num} className="w-full md:w-1/3 pr-4 mb-4 md:mb-0 flex items-center">
                    //     <span className="mr-4 basis-2/6 font-normal text-right">{t("form.sections.valorisation_territoire.point_fort")} {num}</span>
                    //     <input

                    //         type="text"
                    //         name={`strength${num}`}
                    //         maxLength="70"
                    //         className="w-full border p-2 rounded"
                    //         value={`${contact?.[`valorisation_territoire_point_fort_${num}`]}`}
                    //         onChange={handleChange}
                    //     />
                    // </label>
                    <>
                        <RegistrationFormRow
                            labelClassName="text-right font-normal"
                            required={false}
                            name={`valorisation_territoire_point_fort_${num}`}
                            placeholder={`${t(`form.sections.valorisation_territoire.point_fort`)} ${num}`}
                            defaultValue={contact?.[`valorisation_territoire_point_fort_${num}`]}
                        />
                    </>
                ))}
            </div>

            <p className="font-bold text-xl py-2 "> {t("form.sections.valorisation_territoire.welcomepack_section_title")}</p>

            {/* <label className="block mb-4 flex items-center">
                <span className="mr-4 basis-2/6 font-normal text-right">
                    {t("form.sections.valorisation_territoire.welcomepack_description")}:
                </span>
                <textarea
                    name="welcomePack"
                    className="w-full border p-2 rounded"
                    value__={contact?.valorisation_territoire_welcomepack_is_available}
                    value={contact?.valorisation_territoire_welcomepack_description}
                >


                </textarea>



            </label> */}
            <RegistrationFormRow
                isRegister
                labelClassName="text-right font-normal"
                required={false}
                inputType="textarea"
                name='valorisation_territoire_welcomepack_description'
                placeholder={t("form.sections.valorisation_territoire.welcomepack_description")}
                helper=""
                defaultValue={contact?.valorisation_territoire_welcomepack_visuel}
            />


        </div>
    );
}
