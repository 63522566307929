import parse from 'html-react-parser';
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import EventContext from '../contexts/EventContext'

const Divider = styled.div` 
    height: 3rem;
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15); 
  `
const HomeHeader = styled.div`
background-color: #026CED;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%230274FF' stroke-width='1.5'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%230274FF'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
`
const HeaderCover = styled.div`
height: 50vh;
	background: url(//www.mlg-consulting.com/manager_cc/docs/archives/211015152050_header-event-03.png) no-repeat center center;
	background-size: cover !important;
`
const CallToAction = styled(Link)`
width:100%;
margin:5px;
`

const EventHeader = ({ event }) => {

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    const [urlCollectif, setUrlCollectif] = useState('')

    const logo = event.logos.large !== '' ? event.logos.large : event.event_ligne.el_visuels.large

    const { t, i18n } = useTranslation();

    useEffect(() => {

        if (currentEvent) {

            switch (currentEvent.id_event) {
                case '1723': setUrlCollectif(`/collectif/2293`)
                    break;

                case '1703': setUrlCollectif(`/collectif/2297`)
                    break;

            }
        }
    }, [currentEvent]);

    const navA = <div className="container col-xxl-8 px-4 py-5">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
            <div className="col-10 col-sm-8 col-lg-6">
                <img src={logo} className="d-block mx-lg-auto img-fluid" alt="" loading="lazy" style={{ width: '100%' }} />
            </div>
            <div className="col-lg-6">
                <h1 className="display-5 fw-bold lh-1 mb-3">{event?.nom}</h1>
                <p className="lead">{parse(currentEvent.event_ligne.descriptif)}</p>
                <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                    <button type="button" className="btn btn-primary btn-lg px-4 me-md-2" style={{ display: "none" }}>Inscription</button>


                    {currentEvent.event_etat !== "bilan" && <Link className='btn btn-primary text-white btn-lg px-4' to={`/events/${currentEvent.id_event}/signup`}>INSCRIPTION</Link>}
                </div>
            </div>
        </div>
    </div>

    // const navB = <div style={{ background: '#f1f1f1', display: "block" }}><div className="container py-5">
    //     <div className="row">
    //         <div className="col-md-2">
    //             <img src={`http://www.mlg-consulting.com/manager_cc/events/img_uploaded/${event.logo}`} alt="" style={{ width: "100%" }} /></div>
    //         <div className="col-md-10 text-left text-start"><h1>{event?.nom}</h1>
    //             <p>{event.precision_date}</p>
    //             <p>{event.lieu.lieu_nom} - {event.lieu.lieu_ville}</p>
    //             <p>{event.id_event} </p>
    //         </div>
    //     </div>
    // </div>
    // </div>background-color: #026CED;

    const navC = <HomeHeader
        className="container-fluid vh-50">

        <div className="container py-5">
            <img className='my-3' src="//www.mlg-consulting.com/manager_cc/docs/archives/220128145502_logo-vecto-2021-02.png" alt="logo My Global Village" style={{ width: "300px" }}></img>
            <h1 className="display-1 fw-bold lh-1 mb-3 text-white">{event?.nom}</h1>
            <div className="fs-3 fw-bold lh-1 mb-3 text-white py-3">
                <p> {event?.lieu.lieu_nom}, {event?.lieu?.lieu_pays}</p>
                {event?.precision_date}
            </div>
            <div className='py-5'><a a href="./pitch-registration" className='btn btn-danger btn-lg mx-2'>Inscription candidat</a>
                <a className='btn btn-danger btn-lg'>Inscription jury</a>
            </div>
        </div>
    </HomeHeader >

    const StyledCallToAction = ({ to, children }) => <CallToAction to={to} className='p-3 btn btn-primary btn-lg btn-block d-none'>{children}</CallToAction>

    const navCover = <HeaderCover
        className="container-fluid vh-50"
        style={{ background: `url(https://www.mlg-consulting.com/manager_cc/docs/archives/${event.event_ligne.visuel_1024})` }}>

        <div className="row h-100" style={{ backgroundImage: "linear-gradient(to right, rgba(0,0,0,0), rgba(0, 0, 0, 0.9))" }}>
            <div className="col-md-9"></div>
            <div className="col-md-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div className='bordered  vh-50' style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }} >
                    {/** 
                   * <StyledCallToAction to={`/events/${currentEvent.id_event}/signup/startup${urlCollectif}`}>{t('event_nav.devenir_candidat')}</StyledCallToAction>
                    <StyledCallToAction to={`/events/${currentEvent.id_event}/signup/jury${urlCollectif}`}>{t('event_nav.devenir_jury')}</StyledCallToAction>
              */}
                    <div className='text-white fs-2'><i className="far fa-calendar-minus"></i> {currentEvent.precision_date} | <i className="fas fa-map-marker-alt"></i> {currentEvent.lieu.lieu_nom}</div>

                    <StyledCallToAction to={`/events/${currentEvent.id_event}/signup/`}>{t('event_nav.inscription')}</StyledCallToAction>
                </div>
            </div>
        </div>
    </HeaderCover >

    return (event.event_ligne.visuel_1024 && event.id_event !== 1715 ? navCover : navA)
}

export default EventHeader