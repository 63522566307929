import React, { useEffect, useReducer } from "react";
import useLocalStorage from "../components/Hooks/useLocalStorage";
import useApiUrlTranslation from "../components/useApiUrlTranslation";
import { API_URL } from "../config";
import UserContext from "./UserContext";
import UserReducer from "./UserReducer";
import { useHistory, Redirect } from 'react-router-dom';

const UserProvider = props => {

    const { i18n } = useApiUrlTranslation()

    const history = useHistory();

    const [user, setUser] = useLocalStorage('user', null);

    const [userCycleLangList, setUserCycleLangList] = useLocalStorage('userCycleLangList', null);

    const [userContactConferencierList, setUserContactConferencierList] = useLocalStorage('userContactConferencierList', null);

    const [userEventList, setUserEventList] = useLocalStorage('userEventList', null);

    const [userRoleList, setUserRoleList] = useLocalStorage('userRoleList', null);

    const initialState = {
        user: user,
        userCycleLangList: userCycleLangList,
        userContactConferencierList: userContactConferencierList,
        userEventList: userEventList,
        userRoleList: userRoleList
    }

    const [state, dispatch] = useReducer(UserReducer, initialState)

    const handleSuccessuflAuth = (user) => {

        setUser(user)

        dispatch({
            type: 'SET_USER',
            user: user,
        })

    }

    const getUserRoles = async (id_contact) => {

        await fetch(`${API_URL}?action=getUserRoles&id_contact=${id_contact} `)
            .then(res => res.json())
            .then(res => {
                localStorage.removeItem("userRoleList");
                dispatch({
                    type: 'SET_USER_ROLE_LIST',
                    payload: res,
                })
                setUserRoleList(res)
            })
    }

    const fetchContactCycleLang = async (id_contact) => {

        await fetch(`${API_URL}?action=fetchContactCycleLang&filter= c.id_contact=${id_contact} AND`)
            .then(res => res.json())
            .then(res => {
                localStorage.removeItem("userContactConferencierList");
                dispatch({
                    type: 'SET_USER_CYCLE_LANG_LIST',
                    payload: res,
                })
                setUserCycleLangList(res)
            })
    }

    const getUserContactConferencierList = async (id_contact) => {

        //setContactConferencierListIsLoading(true);

        await fetch(`${API_URL}?action=getContactConferencierList&filter= AND c.id_contact=${id_contact} AND DATE(cf.date_creation)>2018`)
            .then(res => res.json())
            .then(res => {
                dispatch({
                    type: 'SET_USER_CONTACT_CONFERENCIER_LIST',
                    payload: res,
                })
                localStorage.removeItem("userContactConferencierList");
                setUserContactConferencierList(res)
            })
        //.then(() => setContactConferencierListIsLoading(false))

    }

    const getUserEventsList = async (id_contact) => {

        //setContactConferencierListIsLoading(true);

        await fetch(`${API_URL}?action=getContactConferencierList&filter= AND c.id_contact=${id_contact} GROUP BY id_event`)
            .then(res => res.json())
            .then(res => {
                dispatch({
                    type: 'SET_USER_EVENT_LIST',
                    payload: res,
                })
                localStorage.removeItem("userEventList");
                setUserEventList(res.map(item => item.event))
            })
        //.then(() => setContactConferencierListIsLoading(false))

    }

    const userInit = async () => {

        if (user) {

            fetchContactCycleLang(user.id_contact)
            getUserRoles(user.id_contact)
            getUserContactConferencierList(user.id_contact)
            getUserEventsList(user.id_contact)
        }

    }



    const handleLogin = async ({ e, login, password, setMessage }) => {

        e.preventDefault();

        try {
            dispatch({ type: 'SENDING_REQUEST' })

            await fetch(`${API_URL}?action=userAuth`, {
                method: 'POST',
                body: JSON.stringify({
                    login: login,
                    password: password,
                }),
            }
            )
                .then(res => res.json())
                .then(response => {
                    try {

                        if (response.accessToken === '1') {

                            setMessage(<div className="alert alert-success">Connexion...</div>);

                            fetchContactCycleLang(response.user.id_contact)
                            getUserRoles(response.user.id_contact)
                            getUserContactConferencierList(response.user.id_contact)
                            getUserEventsList(response.user.id_contact)
                            //getContactConferencierList(response.user.id_contact)
                            handleSuccessuflAuth(response.user)



                        } else {

                            setMessage(<div className="alert alert-danger">Mauvaise combinaison</div>);
                        }

                    } catch (err) {
                        alert(err.message);
                    }
                })

        } catch (err) {
            console.log(err, 'errr')
        }

    }

    const logout = async () => {

        setUser(null);
        localStorage.removeItem("user");
        localStorage.removeItem("userCycleLangList");
        localStorage.removeItem("userContactConferencierList");
        localStorage.removeItem("userEventList");
        localStorage.removeItem("userRoleList");



        dispatch({
            type: "SET_USER",
            user: null
        });

    };


    useEffect(() => {

    }, [i18n.resolvedLanguage])

    return <UserContext.Provider value={{
        user: state.user,
        userCycleLangList: state.userCycleLangList,
        userContactConferencierList: state.userContactConferencierList,
        userEventList: state.userEventList,
        userRoleList: state.userRoleList,
        logout: logout,
        handleLogin: handleLogin,
        handleSuccessuflAuth: handleSuccessuflAuth,
        getUserContactConferencierList: getUserContactConferencierList,
        getUserRoles: getUserRoles,
        getUserEventsList: getUserEventsList,
        userInit: userInit
    }}>
        {props.children}
    </UserContext.Provider>
}


export default UserProvider