const eventReducer = (state, action) => {

    switch (action.type) {

        case 'SET_EVENTS':

            return {
                ...state,
                events: action.payload
            }

        case 'SET_EVENT':

            return {
                ...state,
                currentEvent: action.payload,
            }

        case 'SET_EVENT_CYCLE_LANG_LIST':

            return {
                ...state,
                currentEventEventCycleLangList: action.payload,
            }

        case 'SET_EVENT_CONTACT_LIST':

            return {
                ...state,
                currentEventContactList: action.payload,
            }

        case 'SENDING_REQUEST':

            return {
                ...state,
                loading: true
            }
        case 'REQUEST_FINISHED':

            return {
                ...state,
                loading: false
            }

        default: return state;
    }
}

export default eventReducer