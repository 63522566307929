import { createContext } from "react";

const eventContext = createContext()


export default eventContext;





/*
import React, { createContext, useReducer, useEffect } from 'react'
import { API_URL } from '../config';

import { EventReducer } from '../reducers/EventReducer';

export const EventContext = createContext()

export const EventProvider = (props) => {

    const [event, dispatch] = useReducer(EventReducer);

    const id_event = 1678;

    useEffect(async () => {

        await fetch(`${API_URL}?action=getEvents&params= WHERE id_event=${id_event} AND `)
            .then(res => res.json())
            .then(events => dispatch({ type: 'FETCH_SUCCESS', payload: events[0] }))

    }, [])

    return (<>
        {event && <EventContext.Provider value={event} >
            {props.children}
        </EventContext.Provider>
        } </>
    )
}*/