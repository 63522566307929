import React, { useEffect, useState, useRef } from 'react'
import useApiUrlTranslation from './useApiUrlTranslation';
import PageSection from './PageSection';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useParams } from "react-router-dom";
import useGetCarrousel from '../customHooks/useGetCarrousel';



const Carrousel = ({ title, destinationList }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const { idEvent } = useParams();

    const [carrouselList] = useGetCarrousel({ idEvent, destinationList });

    const primaryRef = React.useRef();
    const secondaryRef = React.useRef();

    const primarySplideRef = useRef();
    const secondarySplideRef = useRef();

    useEffect(() => {


        if (primarySplideRef.current && secondarySplideRef.current) {
            primarySplideRef.current.sync(secondarySplideRef.current.splide);
            secondarySplideRef.current.sync(primarySplideRef.current.splide);
        }

    }, [i18n.resolvedLanguage])

    return (
        <PageSection title={title}>
            <h1 className=" text-3xl lg:text-6xl  animatedgradient  mb-2 text-center  dark:text-white hidden">Au programme</h1>

            <div className="px-10 mx-auto max-w-7xl ">
                {/* <Splide
                    ref={primaryRef}
                    options={{
                        pagination: false,
                        type: 'loop',
                        autoplay: true,
                        pauseOnHover: false,
                        resetProgress: false,
                        arrows: 'slider',
                        perPage: 1,
                        breakpoints: {
                            1000: {
                                perPage: 1,
                            },
                            500: {
                                perPage: 1,
                            },
                        },

                    }}
                    hasSliderWrapper
                    hasAutoplayProgress
                >

                    {carrouselList && carrouselList.map((carrousel, index) => {

                        return <SplideSlide>

                            <div key={carrousel.id_carrousel} className="card bg-neutral-950 ">
                                <img src={`https://www.mlg-consulting.com/manager_cc/docs/archives/${carrousel.file}`} className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="text-white text-xl pb-3">{carrousel.titre}</h5>
                                    <h5 className="text-gray-400">{carrousel.descriptif}</h5>
                                </div>
                            </div></SplideSlide>
                    })}

                </Splide>
 */}



                <Splide
                    ref={primarySplideRef}
                    options={{
                        autoplay: true,
                        type: 'loop',
                        perPage: 1,
                        pagination: false,
                        start: 0,
                        transition: 'fade',
                        focus: 'center',
                    }}>
                    {carrouselList && carrouselList.map((carrousel, index) => (
                        <SplideSlide key={index} className='rounded-xl'>
                            <div
                                className='bg-cover
                                bg-fit bg-center  rounded-t-xl w-100'
                                style={{
                                    height: '400px',
                                    backgroundImage: `url(${carrousel.files.large})`
                                }}>
                                <div
                                    style={{ background: 'linear-gradient(90deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%)' }}
                                    className='bg-gradient-to-r from-neutral-950 h-full w-full flex justify-start p-10'>
                                    <div className="lg:w-50 w-50 flex  flex-col justify-center items-center">
                                        <h5 className="text-white text-xl lg:text-3xl pb-3 text-left font-bold">{carrousel.titre}</h5>
                                        <div className="text-white text-xs lg:text-sm  text-left w-100 hidden">{carrousel.descriptif}</div>
                                    </div>
                                </div>
                            </div>
                        </SplideSlide>
                    ))}
                </Splide>

                <Splide ref={secondarySplideRef} options={{ type: 'slide', perPage: 5, pagination: false }}>
                    {carrouselList && carrouselList
                        .sort((a, b) => a.ordre_affichage - b.ordre_affichage)

                        .map((carrousel, index) => (

                            <SplideSlide key={index} onClick={() => primarySplideRef.current.splide.go(index)} className="cursor-pointer">

                                <img src={`${carrousel.files.large}`} alt="..." />

                            </SplideSlide>
                        ))}
                </Splide>

            </div>

        </PageSection >
    )
}

export default Carrousel