import { useEffect, useState } from "react";
import useApiUrlTranslation from "../components/useApiUrlTranslation";

const useGetCycleLangByIdCycleLang = ({ idCycleLang }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [cycleLang, setCycleLang] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    const getCycleLang = async () => {

        await fetch(`${apiUrlTranslation}?action=getCycleLangByIdCycleLang&id_cycle_lang=${idCycleLang}`)
            .then(res => res.json())
            .then(res => setCycleLang(res))
    }

    useEffect(() => {
        getCycleLang().then(() => {
            setIsLoading(false);
        });

    }, [i18n.resolvedLanguage])

    return { cycleLang, isLoading }
}

export default useGetCycleLangByIdCycleLang;