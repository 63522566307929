import React, { useContext, useEffect } from "react";

import Layout from "./Layout";

import parse from 'html-react-parser';

import EventContext from '../contexts/EventContext';

import useApiUrlTranslation from "./useApiUrlTranslation";
import Carrousel from "./Carrousel";
import Highligths from "./Highligths";

const EventDescription = () => {

  const { t, i18n } = useApiUrlTranslation()

  const eventContext = useContext(EventContext);

  const { currentEvent } = eventContext;

  const GetResume = () => {

    if (currentEvent.resume) {

      return parse(currentEvent.resume)

    } else {

      return parse(currentEvent.event_ligne.descriptif ?? "")
    }

  }

  useEffect(() => {

  }, [currentEvent, i18n.resolvedLanguage]);

  return <Layout title={t('event_nav.evenement_qq_mots')} className="bg-white">
    <div className="container">
      {currentEvent && <GetResume />}

      <Carrousel idEvent={currentEvent?.id_event} destinationList="event-description" />

    </div>
  </Layout>
}

export default EventDescription;