import React from "react";
import Layout from "./Layout";

function CommiteOrganisation() {
  return (
    <Layout>
      <div>CommiteOrganisation</div>
    </Layout>
  );
}

export default CommiteOrganisation;
