import React, { useContext, useEffect, useState } from 'react'
import LayoutAccount from './LayoutAccount2'
import useApiUrlTranslation from '../useApiUrlTranslation'
import UserContext from '../../contexts/UserContext'

const Networking = () => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation()

    const [parcoursEval, setParcoursEval] = useState(false);

    const userContext = useContext(UserContext);


    const { user } = userContext;

    const getParcoursEval = async () => {


        //await   fetch(`${API_URL}?action=getParcoursEval&params= AND id_event=${currentEvent.id_event} AND id_contact=${user.id_contact}`)
        await fetch(`${apiUrlTranslation}?action=getParcoursEval&params= WHERE meeting=1 AND  id_presta = (SELECT id_presta FROM prestas WHERE id_contact =${user.id_contact} )`)
            .then(res => res.json())
            .then(parcoursEval => {
                setParcoursEval(parcoursEval)
            })
    }

    useEffect(() => {
        getParcoursEval()
    }, [])

    return (
        <LayoutAccount title="Mes demande des mise en relation">

            <div className="flex flex-col bg-green-300 p-2 my-5">
                Les contacts ci-dessous souhaitent entrer en relation avec vous</div>

            <table className="min-w-full divide-y divide-gray-300 mt-5">
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">

                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Prenom
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Nom
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Société
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Email
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Event
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Date
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">Edit</span>
                        </th>
                    </tr>
                </thead>
                {parcoursEval && parcoursEval.map(parcoursEval =>


                    <tbody className="divide-y divide-gray-200">
                        <tr className="divide-x divide-gray-200" key={parcoursEval.id_parcours_eval}>
                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 ">
                                {/* {parcoursEval.presentation} */}
                                &nbsp;
                            </td>
                            <td className="whitespace-nowrap p-2 text-sm text-gray-500">  {parcoursEval.prenom}</td>
                            <td className="whitespace-nowrap p-2 text-sm text-gray-500">  {parcoursEval.nom}</td>
                            <td className="whitespace-nowrap p-2 text-sm text-gray-500">  {parcoursEval.societe}</td>
                            <td className="whitespace-nowrap p-2 text-sm text-gray-500">  {parcoursEval.email}</td>
                            <td className="whitespace-nowrap p-2 text-sm text-gray-500">{parcoursEval.event.nom}</td>

                            <td className="whitespace-nowrap p-2 text-sm text-gray-500">{parcoursEval.date_creation}</td>
                        </tr>
                    </tbody>)}

            </table>
        </LayoutAccount>
    )
}

export default Networking