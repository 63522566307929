import { createRef, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import useApiUrlTranslation from "./useApiUrlTranslation";

const OrganismeVideoUrlChecker = ({ videoUrl, fieldName }) => {

    const [organismeVideoUrlWatched, setOrganismeVideoUrlWatched] = useState("");

    const { register, formState, watch, getValues } = useFormContext();

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();


    const dialogRef = createRef();

    const toggleShowDialog = (e) => {

        e.preventDefault();

        if (dialogRef.current.open) {
            dialogRef.current.close();
            setOrganismeVideoUrlWatched(false);
        }
        else {
            dialogRef.current.showModal();
        }
    }

    const getYoutybeId = (url) => {

        const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

        const match = url.match(regex);

        if (match) {
            return match[1];
        }

        return false;
    }


    const handleCheckVideo = (e) => {

        e.preventDefault();

        //let src = watch('organisme_video_url');

        let src = watch(fieldName);

        const youtubeId = getYoutybeId(src)

        const newSrc = `https://www.youtube.com/embed/${youtubeId}?autoplay=1&mute=1&rel=0&loop=1&modestbranding=1&playlist=${youtubeId}`

        setOrganismeVideoUrlWatched(newSrc);

        // console.log(youtubeId)

        toggleShowDialog(e);
    }

    useEffect(() => {

    }, [i18n.resolvedLanguage, watch(fieldName)])

    return <div>
        <a onClick={handleCheckVideo} className="text-sm rounded-full px-4 py-1 text-white bg-emerald-500 cursor-pointer"> {t('form.sections.organisme_video.video_checker_button_libelle')}</a>
        <dialog ref={dialogRef} className=" backdrop:bg-black/50 backdrop:backdrop-blur-md">
            <div className='text-gray-500'>
                {/* {JSON.stringify(organismeVideoUrlWatched)} */}
            </div>
            <iframe className="border" width="560" height="315" src={organismeVideoUrlWatched} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

            <button onClick={toggleShowDialog}>{t('global.fermer')}</button>
        </dialog>
        {videoUrl}
    </div>

}

export default OrganismeVideoUrlChecker;