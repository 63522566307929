import HTMLReactParser from "html-react-parser";
import Shop from "./Shop";


const packList = [
    {
        name: '<span className="text-xl font-normal">Décideurs, investisseurs, importateurs et exportateurs</span> <br/> Pack GLOBAL CONNECT',
        price: 1290,
        priceId: 'price_1QPlsbBO68gVmJAUShIOxNB1',
        features: [
            'Bénéficiez de gratuité, de réductions ou d\'accès VIP pour accéder aux événements mondiaux de la tech ou de votre secteur',
            'Networkez 365 jours par an avec les game changers, les investisseurs, les importateurs et les exportateurs de la tech mondiale',
            'Accédez à la base des game changers et bénéficiez d\'un radar mondial des solutions de ruptures de votre secteur grâce à une présence de Xplorateurs de mGV sur 70 salons dans 20 verticales',
            'Participez aux "Xploration" sur les événements du circuit annuel myGlobalVillage, chassez les innovations et reportez vos découvertes lors des webcasts verticaux de myGlobalVillage',
            'Assistez aux débriefings et enregistrez vos découvertes lors des tech\'Xplorations de votre choix',
            'Rejoignez les jurys des sessions de pitch de votre choix (présentiel et à distance) et soyez les premiers à découvrir les innovations présentes'
        ],
        'form_link': 'https://www.myglobalvillage.com/signup/delegation-myglobalvillage-c-permanent/?form-name=delegation-myglobalvillage-c-permanent&formisvisible=1&id_formulaire=89&packSelection=7932'
    },
    {
        name: '<span className="text-xl font-normal">Startup et entreprise innovante </span><br/> <br/>  Pack GAME CHANGER',
        price: 2490,
        priceId: 'price_1QPlsbBO68gVmJAUShIOxNB1',
        features_intro: 'Bénéficiez de tout le pack GLOBAL CONNECT et également :',
        features: [


            'Présentez vos offres au réseau mondial des game changers et des importateurs/exportateurs de solutions innovantesS sur 5 continents.',
            'Connectez-vous avec des relais de confiance lors des actions collective de de myglobalvillage lors de tous les salons où vous êtes présents.',
            'Accédez à un annuaire exclusif des investisseurs et revendeurs partenaires de myGlobalVillage.',
            'Intervenez régulièrement (tous les 4 mois minimum), en présentiel ou à distance, dans le cadre des X’port Hubs et des Vertical Actions.',
            'Enregistrez  en webcast vos études de cas, vos présentations investiieurs/partenaires, et bénéficiez d’une mise en avant auprès des cercles de distributeurs et revendeurs clés.',
            'Pitchez vos solutions et études de cas à distance (Webcast) tout au long de l’année, lors d’événements 5 continents.',
            'Participez à des actions collectives et à des campagnes de marketing collaboratif (développement commercial, relations publiques, marketing audience, etc.), ainsi qu’à des opérations de prospection en groupe (Vertical Shows et Showrooms).'

        ],
        form_link: 'https://www.myglobalvillage.com/signup/delegation-myglobalvillage-permanent/?form-name=delegation-myglobalvillage-permanent&formisvisible=1&id_formulaire=87&packSelection=7932'
    },
    {
        name: '<span className="text-xl font-normal">Agence d’attractivité,  service de promotion des investissements, open innovation… </span> <br/> Pack ACTIVE LANDING',
        price: 4490,
        priceId: 'price_1QPlsbBO68gVmJAUShIOxNB1',
        features_intro: 'Bénéficiez de tous le pack GLOBAL CONNECT et également :',
        features: [
            "Présentez toute l’année votre territoire ou écosystème aux centaines de sociétés en accélération internationales détectées par myGlobalVillage (video, prise de parole, brochures d’attractivité…)",
            "Offrez des welcome-packs  aux entreprises innovantes qui s’internationalisent pour les attirer sur votre territoire ou dans votre écosystème",
            "Précisez vos domaines d’excellence et positonnez votre écosystème comme base d’atterissage exclusive pour un pays ou plusieurs verticales de myGlobalVillage"

        ],
        form_link: 'https://www.myglobalvillage.com/signup/delegation-myglobalvillage-b-permanent/?form-name=delegation-myglobalvillage-b-permanent&formisvisible=1&id_formulaire=88&packSelection=7932'
    },
];


export default function Pricing({ cycleLang }) {

    if (!cycleLang) return null

    return (
        <div className="">

            <div className="container mx-auto py-20">

                <div className="text-center text-white">

                    <h1 className="text-4xl font-bold">Rejoignez les plans d'actions collective <span className="text-pink-600">{cycleLang.nom_cycle_lang} </span>de myGlobalVillage !</h1>

                    <div className="flex justify-center mx-auto text-center py-4">
                        <img src={cycleLang.attachedFilesCollection.find(attachedFile => attachedFile.id_attached_file_type == 65).small} alt="" className="w-20" /> </div>

                    <p className="mt-4">Sélectionnez la formule de participation annuelle correspondant à vos objectifs</p>


                    { }
                    <div className="flex justify-center mt-12 space-x-4">

                        {packList.map((pack, index) => (

                            <div key={index} className="flex flex-col justify-between w-100 bg-neutral-400/10 p-8 rounded-lg shadow-lg text-white border-neutral-800 border-x border-y ">

                                <div>

                                    <p className="text-2xl font-bold">{HTMLReactParser(pack.name)}</p>

                                    <p className="text-4xl mt-4 text-pink-700">{pack.price}€ / Year</p>

                                    {pack.features_intro && <p className="text-sm text-left font-bold mt-4">{pack.features_intro}</p>}

                                    <ul className="mt-6">

                                        {pack.features.map((feature, index) => (

                                            <li key={index} className="flex  mt-2 text-sm text-left">

                                                <div className=" w-6 h-6 rounded-full flex items-center justify-center mr-2">

                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon"
                                                        className="mx-auto h-5 w-5 text-pink-600">
                                                        <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z" clip-rule="evenodd"></path></svg>
                                                </div>

                                                {feature}

                                            </li>

                                        ))}
                                    </ul>
                                </div>
                                <div>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`${pack.form_link}&id_cycle_lang_preselection_list=2304`}
                                        className="block bg-pink-700 text-white px-6 py-2 rounded-lg mt-6">Buy Now</a>

                                    <Shop
                                        priceId={pack.priceId} />
                                </div>
                            </div>

                        ))}
                    </div>

                </div>

            </div>

        </div>

    )
}

