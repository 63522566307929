import React, { useContext, useEffect, useState } from 'react'
import Layout from './Layout'
import EventContext from "../contexts/EventContext";
import { API_URL } from '../config';
import { useParams } from 'react-router-dom';
import Loader from './Loader';
import HTMLReactParser from 'html-react-parser';

const RegistrationConfirmation = () => {

    const { token } = useParams();

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    const [confirmationResponse, setConfirmationResponse] = useState(null)

    const registrationValidation = async () => {

        await fetch(`${API_URL}?action=RegistrationConfirmation&token=${token}`)
            .then(res => res.json())
            .then(res => setConfirmationResponse(res))
    }

    useEffect(() => {

        registrationValidation();

    }, [currentEvent])



    return (
        <Layout>

            <div className="bg-indigo-700 ">
                <div className="mx-auto max-w-2xl py-16 px-4 text-center sm:py-20 sm:px-6 lg:px-8">

                    {confirmationResponse ? HTMLReactParser(confirmationResponse.message) :
                        <h2 className="animate-pulse  ext-3xl font-bold tracking-tight text-white sm:text-4xl">
                            Traitement en cours merci de bien vouloir patienter ...</h2>}
                    {confirmationResponse && <a
                        href="../login"
                        className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-indigo-600 hover:bg-indigo-50 sm:w-auto"
                    >
                        Accéder à votre espace privé
                    </a>}
                </div>
            </div>
        </Layout>
    )
}

export default RegistrationConfirmation