import React from 'react'
import { PaperClipIcon } from '@heroicons/react/solid'
import HTMLReactParser from 'html-react-parser'

const GetRow = ({ data, label }) => {

    // if data == 0, data = none


    if (data == 0) data = "Non"
    if (data == 1) data = "Oui"

    if (data.includes('.pptx')) {

        data = <a href={data} target="_blank" className="text-blue-500" >Télécharger la présentation</a>

    } else if (data.includes('.png') || data.includes('.jpg') || data.includes('.jpeg')) {

        data = <><img src={data} style={{ width: "100px" }}></img> <a className="text-blue-500" href={data} target="_blank">Télécharger le visuel</a></>

    }
    else if (data == '//www.mlg-consulting.com/manager_cc/docs/archives/') {

        data = "";
    } else {
        data = HTMLReactParser(data)
    }



    return <div className="container">
        <div className='row bg-light border-b '>
            <div className="col-md-3"><span className='text-sm font-medium text-gray-500'>{label}</span></div>
            <div className="col-md-9"><span className='text-sm text-gray-900 '>{data}</span></div>
        </div>
    </div>

}

const DemoDescription = ({ demo }) => {

    return (
        <div className="mt-3 rounded">
            <GetRow data={demo.presta_accroche} label="Descriptif de la technologie, service ou produit innovant" />
            <GetRow data={demo.equipauto_marches_applications} label=" Marché(s) d'application " />
            <GetRow data={demo.equipauto_date_lancement} label=" Date de lancement" />
            <GetRow data={demo.equipauto_innovation_description} label="En quoi ce produit ou service est-il innovant ? " />
            <GetRow data={demo.equipauto_solution_deja_presentee} label="Votre produit ou service a-t-il déjà été présenté ? " />
            <GetRow data={demo.equipauto_solution_deja_presentee_description} label="Si oui, à quelle occasion ?" />
            <GetRow data={demo.equipauto_expose} label=" Votre société est-elle exposante à EQUIP AUTO 2022 ? " />
            <GetRow data={demo.equipauto_responsable_produit} label=" Responsable produit:" />
            <GetRow data={demo.equipauto_pack_exposant} label=" Choix du Pack exposant " />
            <GetRow data={demo.presta_visuel} label="Visuel illustrant ma technologie, service ou produit innovant" />
            <GetRow data={demo.presta_ppt} label="Support de présentation au format Powerpoint" />

        </div>
    )
}

export default DemoDescription