import React, { useState, useEffect } from "react";
import { API_URL } from "../config";
import { useParams } from "react-router-dom";
import Layout from "./Layout";
import parse from 'html-react-parser';
import PartenaireCard from "./PartenaireCard";
import SallePresentation from "./account/SallePresentation";
import useApiUrlTranslation from "./useApiUrlTranslation";

const ConfEventCycleLangList = ({ conf_event, confEventCycleLangList, getName }) => {

  const { apiUrlTranslation, i18n } = useApiUrlTranslation()

  const GetVisuelType = ({ }) => {

    return <div className="">
      {confEventCycleLangList && confEventCycleLangList.map(cecl => cecl.id_cycle_lang.attachedFilesCollection

        .map(file => {

          const img = <img src={file.small} style={{ maxWidth: "100%" }} className="me-3" />

          if (["83"].includes(conf_event.type) && file.id_attached_file_type === 87) {
            return img
          }

          if (["91"].includes(conf_event.type) && file.id_attached_file_type === 92) {
            return img
          }

          if (['78'].includes(conf_event.type) && file.id_attached_file_type === "93") {
            return img
          }

          if (["67"].includes(conf_event.type) && file.id_attached_file_type === "84") {
            return img
          }

        }))
      }</div>
  }

  useEffect(() => {

  }, [i18n.resolvedLanguage, confEventCycleLangList])

  if (!confEventCycleLangList) return false;

  if (getName) {

    return <div className=" flex">
      {
        confEventCycleLangList && confEventCycleLangList.map(cecl => <div className="text-neutral-400 rounded-2xl bg-neutral-400/10 px-3 py-1 mx-2 flex w-fit ">

          {cecl.id_cycle_lang.attachedFilesCollection.filter(file => file.id_attached_file_type == "65").map(file => file.small ? <img src={file.small} style={{ width: "20px", height: "20px" }} className="me-3 rounded-full" /> : '')}

          {cecl.id_cycle_lang.nom_cycle_lang} </div>)}
    </div>
  }

  return <GetVisuelType />

}


function ProgrammDetails() {
  let { idEvent } = useParams();
  let { id_conf_event } = useParams();

  const [Partenaires, setPartenaires] = useState([]);
  const [confEventList, setConfEventList] = useState([]);
  const [confEventCycleLangList, setConfEventCycleLangList] = useState(null)

  const { t, i18n, apiUrlTranslation } = useApiUrlTranslation()

  useEffect(() => {
    const fetchConfEvent = async () => {
      await fetch(`${apiUrlTranslation}?action=getConfEvent&filter=   id_conf_event=${id_conf_event}`)
        .then((res) => res.json())
        .then((res) =>
          setConfEventList(
            res.filter((res) => id_conf_event === res.id_conf_event)
          )
        )
    }

    const fetchPartenaires = async () => {
      await fetch(
        `${apiUrlTranslation}?action=getPartenaires&params=AND id_conf_event=${id_conf_event}`
      )
        .then((res) => res.json())
        .then((res) => setPartenaires(res));
    }

    const getConfEventCycleLang = async () => {

      await fetch(`${apiUrlTranslation}?action=getConfEventCycleLang&params=WHERE%20id_conf_event =${id_conf_event}`)
        .then(response => response.json())
        .then(data => {
          setConfEventCycleLangList(data)
          //console.log('getConfEventCycleLang', conf_event.id_conf_event, data);
        })
    }

    getConfEventCycleLang()
    fetchConfEvent();
    fetchPartenaires();
  }, [idEvent, id_conf_event, i18n.resolvedLanguage]);

  return (
    <Layout darkmode>
      <div className="container">
        {confEventList.map((confEvent) => (
          <div>
            <div className="mt-4 text-4xl font-extrabold  tracking-tight text-white sm:text-5xl sm:leading-[3.5rem]">
              {parse(confEvent.conf_event_lang.cel_titre)}
            </div>

            {!["1820"].includes(idEvent) && <ConfEventCycleLangList
              confEvent={confEvent}
              confEventCycleLangList={confEventCycleLangList}
              getName />}

            <div className="h5 p-3 my-3 rounded">
              <p className="text-4xl py-3 hidden"> <i className="fa fa-calendar" ariaHidden="true"></i>  {confEvent.conf_event_date}</p>

              <div className="text-2xl text-white"> {parse(confEvent.localTimeZone)}</div>
              <div className={`flex flex-wrap text-sm mt-2 ${["1820"].includes(idEvent) ? 'hidden' : ''}`}>
                {parse(confEvent.otherTimeZones ?? "")}
              </div>
            </div>
            <div className="py-3">{parse(confEvent.conf_event_lang.cel_texte)}</div>
            <div className="">{parse(confEvent.conf_event_lang.cel_chap)}</div>
            <div className="grid md:grid-cols-6   gap-2">
              {Partenaires
                .filter(partenaire => !["143"].includes(partenaire.conferencier_statut.id_event_contact_type))
                .map((partenaire) => (
                  <PartenaireCard Partenaire={partenaire} />
                ))}
            </div>
            <SallePresentation confEvent={confEvent} />

            {Partenaires && Partenaires
              .filter(partenaire => ["143"].includes(partenaire.conferencier_statut.id_event_contact_type)).length > 0 && <div className="">
                <div className="text-4xl animatedgradient font-extrabold py-3">Participants</div>
                <div className="grid md:grid-cols-8  grid-cols-2  gap-4">
                  {Partenaires
                    .filter(partenaire => ["143"].includes(partenaire.conferencier_statut.id_event_contact_type))
                    .map((Partenaire) => (
                      <PartenaireCard Partenaire={Partenaire} />
                    ))}
                </div>
              </div>
            }


          </div>
        ))}
      </div>
    </Layout>
  );
}

export default ProgrammDetails;
