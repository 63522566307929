import parse from 'html-react-parser';
import React, { useState, useEffect, useContext, useRef } from 'react';
import Loader from './Loader';
import { API_URL } from '../config';
import EventContext from '../contexts/EventContext';
import Layout from './Layout';
import { removeDuplicateObjectFromArray, sortByAttribute } from '../utils';
import useApiUrlTranslation from './useApiUrlTranslation';
import { Link } from 'react-router-dom';

import { useParams } from 'react-router-dom';
import { ClipboardDocumentCheckIcon, PaperAirplaneIcon, ClipboardIcon, LinkIcon } from '@heroicons/react/24/outline';
import { use } from 'i18next';
import HTMLReactParser from 'html-react-parser';
import ContactCycleLangSection from './ContactCycleLangSection';


const Article = ({ eventCycleLang, presta_type, getEventCycleLangPrestaType }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();


    const [prixFinal, setPrixFinal] = useState(Number(presta_type.montant_ht_part_mlg) + Number(presta_type.montant_ht_retrocession_partenaire));

    const [retrocession, setRetrocession] = useState(presta_type.montant_ht_retrocession_partenaire);

    const updateEventPresta = async ({
        prix_unitaire_ht,
        montant_ht_retrocession_partenaire
    }) => {

        await fetch(`${API_URL}?action=updatePrestaType&get_req=y`, {

            method: 'POST',

            body: JSON.stringify({
                id_presta_type: presta_type.id_presta_type,
                montant_ht_retrocession_partenaire: montant_ht_retrocession_partenaire,
                prix_unitaire_ht: prix_unitaire_ht
            })
        })
            //.then(res => res.json())
            .then(res => {
                getEventCycleLangPrestaType()
            })
    }



    const handleRetrocession = (e) => {

        const retrocession = e.target.value;

        const newFinalPrice = Number(presta_type.montant_ht_part_mlg) + Number(retrocession);

        if (retrocession) {
            setPrixFinal(newFinalPrice)
        }

        if (newFinalPrice == '') {

            setPrixFinal();
        }

        updateEventPresta({
            prix_unitaire_ht: newFinalPrice,
            montant_ht_retrocession_partenaire: retrocession

        })

        console.log(presta_type.prix_unitaire_ht)
    }

    return (
        <tr className="divide-x ">
            <td className="text-left ">

                <img className="w-10 mx-4" src={presta_type.visuels.small} alt="" />
            </td>
            <td className="text-left text-sm mx-2">{presta_type.presta_type_nom}</td>
            <td className="">{presta_type.prix_unitaire_ht_barre}</td>
            <td>{presta_type.montant_ht_part_mlg}</td>
            <td className="py-2 mx-2">
                <input

                    min="0"
                    defaultValue={presta_type.montant_ht_retrocession_partenaire}
                    className="border rounded py-2 px-2"
                    type="number"
                    placeholder='Part de votre revenu'
                    onKeyUp={handleRetrocession}
                    onChange={handleRetrocession} />
            </td>
            <td>{prixFinal} </td>
        </tr>
    )


}


const ArticleList = ({ eventCycleLang }) => {

    const { idEvent } = useParams()
    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [eventCycleLangPrestaTypeList, setEventCycleLangPrestaTypeList] = useState(null);

    const getEventCycleLangPrestaType = async () => {

        const params = `WHERE id_event_cycle_lang IN(${eventCycleLang.id_event_cycle_lang})`;

        //const listProductIdToArray = listProductId.split('[').join('').split(']').join('').split(',')

        // const params = `WHERE id_presta_typ IN(${listProductIdToArray.map((id) => id)})`;


        await fetch(`${apiUrlTranslation}?action=getEventCycleLangPrestaType&params=${params}`)
            .then(res => res.json())
            .then(res => {
                setEventCycleLangPrestaTypeList(res)

            })
    }


    useEffect(() => {



        getEventCycleLangPrestaType()
    }
        , [i18n.resolvedLanguage])

    return <div>
        <table className="w-full">
            <thead className="font-bold">
                <tr className="divide-x">
                    <td></td>
                    <td>Nom</td>
                    <td>PRIX PUBLIC BARRE</td>
                    <td>PART MLG</td>
                    <td>PART  DE VOTRE REVENU</td>
                    <td>PRIX AFFICHE FINAL POUR VOTRE ECOSYSTEME</td>
                </tr>
            </thead>

            <tbody className='divide-y '>
                {eventCycleLangPrestaTypeList &&
                    eventCycleLangPrestaTypeList
                        .map(eventCycleLangPrestaType =>
                            <Article
                                getEventCycleLangPrestaType={getEventCycleLangPrestaType}
                                eventCycleLang={eventCycleLangPrestaType.eventCycleLang}
                                presta_type={eventCycleLangPrestaType.presta_type}
                            />)}
            </tbody>
        </table>
    </div>

}

const RegistrationCible = ({ name, href, libelle, visuels, eventCycleLang }) => {

    return <div href_={href}
        className="group flex flex-col justify-between rounded-lg my-5 border-neutral-300 border-x border-y   text-center py-4 h-full w-full  mx-auto  "
    >
        <div className="flex space-x-5 items-center">
            <div className='basis-1/5'>
                <img src={visuels?.medium} alt="" className="w-20 mx-auto" />
            </div>
            <div className="text-left">
                <div className="text-pink-300   font-bold ">{name}</div>
                <div className="text-base font-normal leading-6  mb-3 ">{libelle}</div>
            </div>
        </div>

        <ArticleList
            eventCycleLang={eventCycleLang} />
    </div>


}




const Offre = () => {

    const [eventCycleLangList, setEventCycleLangList] = useState(false);

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const { idEvent } = useParams();

    const eventContext = useContext(EventContext);

    const { getEventByIdEvent, currentEvent } = eventContext;



    useEffect(() => {

        const getEventCycleLangList = async () => {

            await fetch(`${apiUrlTranslation}?action=getEventCycleLangList&params= WHERE id_event=${idEvent} AND forum="packs" AND publier!="n"`)
                .then(res => res.json())
                .then(res => {
                    setEventCycleLangList(res)
                })
        }

        getEventCycleLangList()
    }, [i18n.resolvedLanguage])

    return <div className="bg-white mx-5 rounded-2xl py-4 p-5 mb-3">
        {eventCycleLangList && eventCycleLangList
            .map(eventCycleLang => <RegistrationCible
                eventCycleLang={eventCycleLang}
                name={eventCycleLang.event_cycle_lang_nom}
                libelle={eventCycleLang.registration_link_text}
                visuels={eventCycleLang.visuels}
                href={`${currentEvent ? `/events/${currentEvent.id_event}/store/${eventCycleLang.id_event_cycle_lang}` : ''} `}>
                {eventCycleLang.event_cycle_lang_nom}
            </RegistrationCible>)
        }
    </div>
}

const DisplayFormulaireList = ({ formulaireList, event, contactCycleLangList, idContact, title }) => {

    const { t, i18n } = useApiUrlTranslation();

    const [eventCycleLangList, setEventCycleLangList] = useState(null);

    const getConfEventCycleLang = async () => {

        const params = `WHERE id_conf_event IN (SELECT id_conf_event FROM conf_event WHERE id_event = ${event.id_event})`;

        await fetch(`${API_URL}?action=getConfEventCycleLang&params=${params}`)
            .then(res => res.json())
            .then(res => {
                setEventCycleLangList(res)
            })
    }

    useEffect(() => {

        getConfEventCycleLang()

    }, [i18n.resolvedLanguage])

    return <div className="bg-white mx-5 rounded-2xl py-4 p-5 mb-3">

        <p className=" text-xl py-3 font-bold ">{title}</p>

        <div className="divide-y divide-neutral-400 ">

            <div className="bg-slate-100 p-4 my-3 divide-y">

                <table className=" ">
                    <thead className="">
                        <tr class="divide-x">
                            <th></th>
                            <th>Heure</th>
                            <th>Titre</th>
                        </tr>
                    </thead>

                    <tbody>
                        {eventCycleLangList && eventCycleLangList
                            .filter(eventCycleLang => contactCycleLangList.map(contactCycleLang => contactCycleLang.cycle_lang.id_cycle_lang).includes(eventCycleLang.id_cycle_lang.id_cycle_lang))
                            .map(eventCycleLang => {
                                return <tr className="divide-x">
                                    <td className="px-2 text-sm">
                                        <img src={eventCycleLang.id_cycle_lang.logo_better.small} className="w-8" alt="" />
                                    </td>
                                    <td className="px-2 text-sm">
                                        {eventCycleLang.id_conf_event.heure_debut} - {eventCycleLang.id_conf_event.heure_fin}
                                    </td>
                                    <td className="px-2 text-sm">
                                        {eventCycleLang.id_conf_event.conf_event_lang?.cel_titre}
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>

            {formulaireList && formulaireList.map(formulaire => {

                const idCycleLangPreselectionList = contactCycleLangList ? `& id_cycle_lang_preselection_list=` + contactCycleLangList.map(contactCycleLang => contactCycleLang.cycle_lang.id_cycle_lang).join(",") : null;

                return <><div className="py-1 flex space-x-4  ">
                    <div className=" flex basis-9/12 items-center space-x-4">

                        <img src={formulaire.visuels.large} className="w-8   bg-black" alt="" />
                        <div>
                            <p className="text-xs"> {formulaire.contexte_utilisation}</p>
                            <p className="font-bold"> {formulaire.page_title}</p>
                        </div>
                    </div>

                    <div className="flex basis-3/12 space-x-3 items-center">
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href={`/events/${event.id_event}/signup/${formulaire.route}/?form-name=${formulaire.route}&formisvisible=1&id_formulaire=${formulaire.id_formulaire}&lng=en&auteur=${idContact}${idCycleLangPreselectionList}&id_conf_event_list=${eventCycleLangList && eventCycleLangList
                                .filter(eventCycleLang => contactCycleLangList.map(contactCycleLang => contactCycleLang.cycle_lang.id_cycle_lang).includes(eventCycleLang.id_cycle_lang.id_cycle_lang))
                                .map(eventCycleLang => {

                                    return eventCycleLang.id_conf_event.id_conf_event
                                })}`
                            }
                            className="h-10 cursor-pointer flex ring-2  ring-pink-200 rounded-full text-xs px-4 py-2  hover:bg-pink-600 hover:text-white text-pink-700  space-x-2 items-center" >
                            <LinkIcon className='w-4 mx-1 ' /> {t("pages.coordinateur_geographique_tools.afficher_le_formulaire")}
                        </a >

                        <div className="hidden cursor-pointer flex bg-pink-200 rounded-full px-4 text-xs  py-2  hover:bg-pink-600 hover:text-white text-pink-700  space-x-2 items-center">
                            <PaperAirplaneIcon className='w-4 ' />
                            Send form
                        </div>
                        <div className="text-gray-400 text-xs">
                            ID Form : {formulaire.id_formulaire}
                        </div>
                    </div >
                </div >
                </>
            })}
        </div >
    </div >
}

const DisplayContactCycleLang = ({ contactCycleLangList }) => {

    return (
        <div className="grid space-x-4">
            {contactCycleLangList && contactCycleLangList
                //dedup by contactCycleLang.cycle_lang.id_cycle_lang
                .filter((contactCycleLang, index, self) =>
                    index === self.findIndex((t) => (
                        t.cycle_lang.id_cycle_lang === contactCycleLang.cycle_lang.id_cycle_lang
                    ))
                )
                .filter(contactCycleLang => !['collectif_programme'].includes(contactCycleLang.cycle_lang.cycle_lang_type))
                .map(contactCycleLang => <div className="flex items-center space-x-4 text-xs">

                    <div className=""> {contactCycleLang.event_contact_type.libelle} :   <span className="text-green-600">{contactCycleLang.cycle_lang_2.nom_cycle_lang} - {contactCycleLang.cycle_lang.nom_cycle_lang}</span></div>

                </div>)}
        </div>
    )
}

const DisplayContactCycleLangDescriptif = ({ contactCycleLangList }) => {

    return (
        <div className="flex flex-col m-3 mx-5 space-y-5">
            {contactCycleLangList && contactCycleLangList
                //dedup by contactCycleLang.cycle_lang.id_cycle_lang
                .filter((contactCycleLang, index, self) =>
                    index === self.findIndex((t) => (
                        t.cycle_lang.id_cycle_lang === contactCycleLang.cycle_lang.id_cycle_lang

                    ))
                )
                .filter(contactCycleLang => ['collectif_partenaire'].includes(contactCycleLang.cycle_lang.cycle_lang_type))
                .map(contactCycleLang => <div className="">
                    <div className=" dark:text-gray-200">
                        {HTMLReactParser(contactCycleLang.cycle_lang.cycle_lang_descriptif ?? "")}

                    </div>
                </div>)}
        </div>
    )
}

const Packs = () => {


}



const Onboarding = ({ idFormulaireList, event, idContact }) => {

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    const [formulaireList, setFormulaireList] = useState(null);

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [contactConferencierList, setContactConferencierList] = useState(false);

    const [contactCycleLangList, setContactCycleLangList] = useState(false);

    const [contactStatutList, setContactStatutList] = useState(false);


    const [activationLinkList, setActivationLinkList] = useState([
        {
            title: "Lien d'activation de mon écosystème import, export et global… ",
            instructions: "myGlobalVillage sera amené tout au long de l'année à solliciter les membres de votre écosystème afin de participer aux actions collectives correspondant à leurs objectifs. Je prépositionne les membres de mon écosystème avec les liens ci-dessous regroupé en 3 grandes catégories :",
            sectionList: [
                {
                    title: "",
                    instructions: "Ecosystème de décollage des solutions innovantes : je coopte avec les liens ci-dessous au sein de mes contacts/écosystème les solutions innovantes ainsi que tous les acteurs susceptibles d’aider à l’export de la solution vers l’international :",
                    cercleList: [
                        { idCycleLang: 3218, idFormulaireList: [24] },
                        { idCycleLang: 3236, idFormulaireList: [24] },
                        { idCycleLang: 3217, idFormulaireList: [24] },
                    ]
                },
                {
                    title: "",
                    instructions: "Ecosystème d’atterrissage vertical des solutions innovantes : je coopte avec les liens ci-dessous au sein de mes contact les acteurs susceptibles d’aider à l’atterrissage de solutions innovantes en provenance d’autres pays de myGlobalVillage :  ",
                    cercleList: [
                        { idCycleLang: 3236, idFormulaireList: [24] },
                    ]
                },
                {
                    title: "",
                    instructions: "Ecosystème global d’accélération des solutions innovantes : je coopte ci-dessous au sein de mes contacts/écosystème les grands groupes, les investisseurs de classe mondiale, les grands acteurs de la tech…. Susceptible d’accélérer directement une scale-up solide  ",
                    cercleList: [
                        { idCycleLang: 3236, idFormulaireList: [24] },
                    ]
                }
            ]
        }
    ]);



    const fetchContactCycleLang = async ({ idContact }) => {

        await fetch(`${apiUrlTranslation}?action=fetchContactCycleLang&filter= c.id_contact=${idContact} AND  contact_cycle_lang.id_cycle_lang !=0  AND  contact_cycle_lang.id_cycle_lang_2 !=0 AND  `)

            .then(res => res.json())
            .then(res => {
                setContactCycleLangList(res);
            })
    }

    const getFormulaireList = async () => {

        await fetch(`${apiUrlTranslation}?action=getFormulaireList&params= WHERE id_formulaire IN (${idFormulaireList.join(",")})`)
            .then(response => response.json())
            .then(data => {
                setFormulaireList(data);
            })
    }


    const getContactStatutList = async ({ idContact }) => {

        await fetch(`${apiUrlTranslation}?action=getContactStatutList&filter= WHERE  id_contact=${idContact}`)
            .then(res => res.json())
            .then(res => {
                setContactStatutList(res);
            })
    }

    const getContactConferencierList = async ({ idContact, currentEvent }) => {

        const join = '';
        const group = `&group=GROUP BY cf.id_contact`;
        const order = ''
        const limit = ''

        await fetch(`${apiUrlTranslation}?action=getContactConferencierList&
            filter= AND id_event=${currentEvent.id_event}  AND cf.id_contact=${idContact}  AND
            (
            cf.statut IN('coordinateur_geographique', 'contributeur-a-distance')
            OR cf.statut IN( SELECT event_contact_type_nom FROM event_contact_type WHERE id_event_contact_type IN(58, 143, 135, 237, 352, 353, 262)  )
            OR cf.statut IN(58, 143, 135,237,  352, 353, 262)
            )
            ${join} ${group} ${order} ${limit}`)
            .then(res => res.json())
            .then(res => {
                setContactConferencierList(res[0]);
            })
    }

    useEffect(() => {

        currentEvent && getContactConferencierList({ idContact, currentEvent });
        fetchContactCycleLang({ idContact });
        getFormulaireList();
        getContactStatutList({ idContact });

    }, [i18n.resolvedLanguage, currentEvent, idContact])

    if (!event || !formulaireList) return null;

    return <div>

        <div className="text-white mx-5 py-3 text-2xl font-bold">

            {/* {t("pages.coordinateur_geographique_tools.phase_1")} */}
            1 - Rappel de mon positionnement
        </div>

        {contactConferencierList && <div className='text-white mx-5 flex justify-between items-center space-x-5 my-5'>

            <div className="flex items-center space-x-5">
                <img src={contactConferencierList.contact.photos.large} alt="" className="rounded-full h-32 w-32 object-cover" />

                <div className="">
                    <p className="text-xl font-bold"> {contactConferencierList.contact.prenom}  {contactConferencierList.contact.nom}</p>
                    <p className="">{contactConferencierList.contact.fonction}</p>
                    <p className="uppercase">{contactConferencierList.contact.societe}</p>

                    <p className="text-pink-500 hidden"> {contactConferencierList.statut.libelle}</p>


                    <div className="text-green-600 text-xs">

                        {contactStatutList && contactStatutList.map(contactStatut => {
                            const visuel = contactStatut.statut.attachedFileCollection.find(attachedFile => attachedFile.id_attached_file_type.id_attached_file_type == "103");

                            return <div className="flex space-x-4 py-1">
                                <img src={visuel.file_name} alt="" className="w-6 h-6 rounded-full bg-white" />
                                <div> {contactStatut.statut.statut_nom}</div>
                            </div>
                        }

                        )}
                    </div>

                    <p className="mt-3">
                        <a
                            className="bg-pink-500 text-white px-4 py-2 rounded-full cursor-pointer hover:bg-pink-600   "
                            href={`
                            https://www.myglobalvillage.com/signup/plan_action_annuel_evenements_leaders/c/${contactConferencierList.contact.id_contact}?form-name=plan_action_annuel_evenements_leaders&formisvisible=1&id_formulaire=53&lng=en`}>
                            Modifier mes coordonnées</a>
                    </p>
                </div>
            </div>

            <div>
                <DisplayContactCycleLang contactCycleLangList={contactCycleLangList} />
            </div>
        </div>
        }

        {contactCycleLangList && <ContactCycleLangSection filter={` id_cycle_lang IN (${contactCycleLangList.map(contactCycleLang => contactCycleLang.id_cycle_lang).join(',')}) AND id_cycle_lang_2 IN (${contactCycleLangList.map(contactCycleLang => contactCycleLang.id_cycle_lang_2).join(',')})&flex`} />}

        {contactConferencierList && <DisplayContactCycleLangDescriptif contactCycleLangList={contactCycleLangList} />}



        <div className="text-white mx-5 py-3 text-2xl font-bold">
            {/* {t("pages.coordinateur_geographique_tools.phase_2")} */}

            2 - Validation de mes créneaux de participation à l'événement

            <p className="font-normal text-base">

                Je valide mes propres créneaux de participation lors de l'événement avec ce formulaire pre-rempli :
                <ul className="pl-10 list-disc">
                    <li>Je pitch pour présenter une de mes solutions : lien game changer pre personnalisé  <a href="#81" className="text-pink-400">Je finalise ma participation</a></li>
                    <li>Je rejoins les jurés  : cercle de Xplorateurs <a href="#90" className="text-pink-400">Je finalise ma participation</a></li>
                    <li>Je coordonne mais ne participe pas</li></ul>
            </p>
        </div>

        <div className="text-white mx-5 py-3 text-2xl font-bold">
            {/* {t("pages.coordinateur_geographique_tools.phase_2")} */}

            3 - Activation et inscription  de mon écosystème
        </div>

        {/* {
            activationLinkList && activationLinkList.map(activationLink => {

                return <div className="bg-white p-10">

                    {activationLink.title}
                    <div>{activationLink.instructions}</div>

                    {activationLink.sectionList && activationLink.sectionList.map(section => {
                        return <div>

                            <div> {section.instructions}</div>

                            {section.cercleList && section.cercleList.map(cercle => {

                                const idFormList = cercle.idFormulaireList.join(",");
                                return <DisplayFormulaireList
                                    title={t("pages.coordinateur_geographique_tools.lien_contacts_confirmes") + ' >>> Activation de mon ecosysteme SPORT tech'}
                                    formulaireList={formulaireList.filter(formulaire => idFormList.includes(formulaire.id_formulaire))}
                                    idContact={idContact}
                                    event={event}
                                    contactCycleLangList={contactCycleLangList} />
                            })}
                        </div>
                    })
                    }



                </div>
            }

            )
        } */}

        <DisplayFormulaireList
            title={t("pages.coordinateur_geographique_tools.lien_contacts_confirmes") + ' >>> Activation de mon ecosysteme SPORT tech'}
            formulaireList={formulaireList.filter(formulaire => ["81", "67", "68", "69", "70", "82"].includes(formulaire.id_formulaire))}
            idContact={idContact}
            event={event}
            contactCycleLangList={contactCycleLangList} />


        <DisplayFormulaireList
            title={t("pages.coordinateur_geographique_tools.lien_contacts_non_confirmes")}
            formulaireList={formulaireList.filter(formulaire => ["24"].includes(formulaire.id_formulaire))}
            idContact={idContact}
            event={event}
            contactCycleLangList={contactCycleLangList} />

        {/* <Offre /> */}


        <div className="bg-white mx-5 rounded-2xl py-4 p-5 hidden">

            <p className=" text-xl py-3 font-bold hidden">Send form</p>

            <div className="divide-y divide-neutral-400 ">

                {formulaireList && formulaireList.map(formulaire => {

                    const idCycleLangPreselectionList = contactCycleLangList ? `&id_cycle_lang_preselection_list=` + contactCycleLangList.map(contactCycleLang => contactCycleLang.cycle_lang.id_cycle_lang).join(",") : null;

                    return <>

                        <div className="py-1 flex space-x-4  ">
                            <div className=" flex basis-9/12 items-center space-x-4">

                                <img src={formulaire.visuels.large} className="w-8   bg-black" alt="" />
                                <div>
                                    <p className="text-xs "> {formulaire.contexte_utilisation}</p>
                                    <p className="font-bold"> {formulaire.page_title}</p>
                                </div>
                            </div>

                            <div className="flex basis-3/12 space-x-3 items-center">
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href={`/events/${event.id_event}/signup/${formulaire.route}/?form-name=${formulaire.route}&formisvisible=1&id_formulaire=${formulaire.id_formulaire}&lng=en&auteur=${idContact}${idCycleLangPreselectionList}`}
                                    className="h-10 cursor-pointer flex ring-2  ring-pink-200 rounded-full text-xs px-4 py-2  hover:bg-pink-600 hover:text-white text-pink-700  space-x-2 items-center">
                                    <LinkIcon className='w-4 mx-1 ' /> {t("pages.coordinateur_geographique_tools.afficher_le_formulaire")}
                                </a>

                                <div className="hidden cursor-pointer flex bg-pink-200 rounded-full px-4 text-xs  py-2  hover:bg-pink-600 hover:text-white text-pink-700  space-x-2 items-center">
                                    <PaperAirplaneIcon className='w-4 ' />
                                    Send form
                                </div>
                            </div>

                        </div>


                    </>
                })}
            </div>
        </div>
    </div >
}


export default Onboarding;