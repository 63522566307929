import React from "react";
import Layout from "./Layout";

function PressEvent() {
  return (
    <Layout>
      <div>PressEvent</div>
    </Layout>
  );
}

export default PressEvent;
