import React, { useContext, useEffect, useState, Fragment } from 'react'
import { API_URL } from '../../../config'
import TexplorationLayout from './TechxplorationLayout'
import EventContext from '../../../contexts/EventContext'
import { StarIcon, CheckCircleIcon } from '@heroicons/react/20/solid'
import { CheckIcon, ChevronUpDownIcon, CiSearch } from '@heroicons/react/20/solid'

import {
    Link,
    useParams

} from "react-router-dom";
import LayoutAccount from '../LayoutAccount2';
import Layout from '../../Layout';
import LayoutAccountEvent from '../LayoutAccountEvent'
import axios from 'axios'
import StarRating from '../../StarRating'
import TexplorationNavigation from './TechxplorationNavigation'
import { BreadcrumbsTechxploration } from '../Breadcrumbs'
import parse from "html-react-parser";
//import data from '../../../data/ces_import.json'
import data from '../../../data/sitl_2024.json'
import { set } from 'react-hook-form'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import Moment from 'react-moment'
import Loader from '../../Loader'



/* 
stand > Location
societe > Company Name
*/

const collectifs = [

]

const reviews = [

    {
        id: 1,
        title: "Can't say enough good things",
        rating: 5,
        content: `
        <p>I was really pleased with the overall shopping experience. My order even included a little personal, handwritten note, which delighted me!</p>
        <p>The product quality is amazing, it looks and feel even better than I had anticipated. Brilliant stuff! I would gladly recommend this store to my friends. And, now that I think of it... I actually have, many times!</p>
      `,
        author: 'Risako M',
        date: 'May 16, 2021',
        datetime: '2021-01-06',
    },
    // More reviews...
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Section = (props) => {

    return <div className={`mb-2 px-2 bg-neutral-100   shadow-3 py-2  ${props.className}`}>
        {props.title && <div className="text-base font-semibold leading-7 text-gray-900 py-2.5 "> {parse(props.title)}</div>}
        <div className="bg-white rounded-xl p-2 ">

            {props.children}</div>
    </div>
}


const createTexploration = async (event, cycleLangEventLigneList) => {

    //createContact > >id_contact

    //createPresta > id_presta 

    //createConfEvent > id_conf_event

    //createJuryDemo
}


const Programme = ({ newTexploration, setNewTexploration }) => {

    const { idEvent } = useParams();

    const [confEventList, setConfEventList] = useState(null);

    const handleSelectProgrammeItem = (e) => {

        let idJuryEvent = e.target.value

        let newProgrammeItemSelection = newTexploration.idJuryEventListSelection.find(item => item == e.target.value) ? newTexploration.idJuryEventListSelection.filter(item => item != e.target.value) : [...newTexploration.idJuryEventListSelection, e.target.value]

        setNewTexploration({ ...newTexploration, ['idJuryEventListSelection']: newProgrammeItemSelection })

        console.log(idJuryEvent, newProgrammeItemSelection);
    }

    const getConfEvent = async () => {

        await fetch(`${API_URL}?action=getConfEvent&filter= id_event=${idEvent} AND type IN(96) AND publier!='n'`)
            .then(res => res.json())
            .then(res => {
                setConfEventList(res)
            })
    }

    useEffect(() => {
        idEvent && getConfEvent(idEvent);
    }, [])


    if (!confEventList) {
        return <Loader />
    }
    return (
        <div className='w-full divide-y'>

            {/* {JSON.stringify(newTexploration.idJuryEventListSelection)} */}

            {confEventList && confEventList.map((item, index) => <div key={index} className="even:bg-gray-50 flex items-center py-2">
                <div>
                    <input
                        onChange={(e) => handleSelectProgrammeItem(e)}
                        value={item.jury_event.id_jury_event}
                        id={item.id_conf_event}
                        name="comments"
                        type="checkbox"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    />
                </div>

                <label htmlFor={item.id_conf_event} className="w-full text-sm font-normal text-gray-700">
                    {item.conf_event_lang.cel_titre}
                    <span className="mt-1 w-full flex justify-between text-xs  text-gray-400"><Moment format="dddd D MMMM YYYY">{item.conf_event_date}</Moment>  {item.heure_debut}</span>


                </label>
            </div>)}
        </div>

    )
}

const CesImport = ({ activeCompany, setActiveCompany, newTexploration, setNewTexploration }) => {

    const [companyList, setCompanyList] = useState(null)

    const [raisonSocialFieldName, setRaisonSocialFieldName] = useState('Company name');

    const searchCompagny = (e) => {

        const newList = raisonSocialFieldName && e.target.value.length > 2 && data && data.filter(item => item[raisonSocialFieldName].toLowerCase().includes(e.target.value.toLowerCase()));

        setCompanyList(newList)
    }

    const Company = ({ company }) => {

        const handleSelectCompany = (company) => {

            console.log('handleSelectCompany', company)
            setActiveCompany(company)
            setCompanyList(null)

            setNewTexploration({ ...newTexploration, ['societe']: company[raisonSocialFieldName], ['numero_stand']: company['Location'] });
        }

        return <div onClick={() => handleSelectCompany(company)}
            className={classNames(

                "text-sm border-b py-1 px-3 hover:bg-indigo-600 hover:text-white flex justify-start")}>  {company[raisonSocialFieldName]} - {company.Country}

            {activeCompany && activeCompany[raisonSocialFieldName] == company[raisonSocialFieldName] ? (
                <span
                    className={classNames(

                        activeCompany[raisonSocialFieldName] == company[raisonSocialFieldName] ? 'text-indigo-600 hover:bg-indigo-600' : 'text-indigo-600',
                        '  inset-y-0 left-0 flex items-center pl-1.5'
                    )}
                >
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                </span>
            ) : null}
        </div>
    }

    useEffect(() => {
        console.log('data', data)
    }, [])

    return (
        <div>
            <div className="flex border rounded items-center focus-within:border-blue-900 focus-within:ring-2 focus-within:ring-blue-700">
                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400 mx-2" aria-hidden="true" />
                <input autoFocus type="text" onChange={searchCompagny} className="focus:outline-none text-sm w-100 p-3 rounded " placeholder='Rechercher une entreprise, N° stand...' />
            </div>
            {activeCompany && <div className="hidden text-sm py-2 text-green-600">Company selected : ${activeCompany[raisonSocialFieldName]}</div>}

            {
                companyList &&
                <div className="border w-100 mt-4 overflow-hidden rounded-md bg-white py-1 shadow-xl shadow-black/5 ring-1 ring-slate-700/10">

                    {companyList.map((item, index) => <Company key={index} company={item} />)}</div>
            }
        </div>
    )
}

const CycleLangList = ({ event, newTexploration, setNewTexploration }) => {

    const [cycleLangEventLigneList, setCycleLangEventLigneList] = useState();

    const getCycleLangEventLigne = async () => {

        await fetch(`${API_URL}?action=getCycleLangEventLigne&params= WHERE id_event_ligne=${event?.event_ligne.id_event_ligne} AND id_cycle_lang IN(3060, 3056, 3058, 3059, 3057, 3055)`)
            .then(res => res.json())
            .then(res => {
                setCycleLangEventLigneList(res)
            })
    }

    const handleSelectCycleLang = (e) => {

        let cycleLang = e.target.value

        let newCycleLangSelection = newTexploration.cycleLangSelection.find(item => item == e.target.value) ? newTexploration.cycleLangSelection.filter(item => item != e.target.value) : [...newTexploration.cycleLangSelection, e.target.value]

        setNewTexploration({ ...newTexploration, ['cycleLangSelection']: newCycleLangSelection })

        console.log(cycleLang, newCycleLangSelection)
    }

    useEffect(() => {
        getCycleLangEventLigne()
    }, [])


    if (!cycleLangEventLigneList) {
        return false;
    }

    return <>

        {cycleLangEventLigneList && cycleLangEventLigneList.map((item, index) =>

            <div key={index}
                className="py-1 flex items-center border-b-2">
                <div>
                    <input
                        onChange={(e) => handleSelectCycleLang(e)}
                        id={item.id_cycle_lang.id_cycle_lang}
                        value={item.id_cycle_lang.id_cycle_lang}
                        name="comments"
                        type="checkbox"
                        className="focus:ring-indigo-500 h-6 w-6 text-indigo-600 border-gray-300 rounded"
                    />
                </div>
                <label htmlFor={item.id_cycle_lang.id_cycle_lang} className=" block text-sm font-medium text-gray-700">
                    {item.id_cycle_lang.nom_cycle_lang}
                </label>
            </div>
        )}
    </>
}

const Texploration = () => {

    const { idEvent } = useParams();

    const eventContext = useContext(EventContext);

    const [file, setFile] = useState()

    const [video_hosted, set_video_hosted] = useState()

    const [carte_visite, setCarteVisite] = useState()

    const [flashMessage, setFlashMessage] = useState()

    const [formIsSubmited, setFormIsSubmited] = useState(null)

    const [activeCompany, setActiveCompany] = useState(null)

    const [numeroStandNew, setNumeroStandNew] = useState(null)

    const user = JSON.parse(window.localStorage.getItem('user'))

    const [juryDemoList, setJuryDemoList] = useState()

    const { getEventByIdEvent, currentEvent } = eventContext;

    const { displayDemoDescription, setDisplayDemoDescription } = useState(false);

    const [newTexploration, setNewTexploration] = useState(
        {
            id_event: idEvent,
            statut: "offreur_de_solution",
            type: 92,
            auteur: user?.id_contact,
            cycleLangSelection: [],
            idJuryEventListSelection: []
        });

    const handleChange = (e) => {

        e.preventDefault();

        setNewTexploration({ ...newTexploration, [e.target.name]: e.target.value });

        console.log(e.target.name, e.target.value)
        console.log(newTexploration)
    }

    const handleChangeRate = ({ ratingName, ratingValue }) => {
        setNewTexploration({ ...newTexploration, [ratingName]: ratingValue });
    }

    function handleChangeFile(event, updateFunction) {
        updateFunction(event.target.files[0])
    }

    const updatePresta = async ({ idPresta, fileName, newFile }) => {

        const url = `${API_URL}?action=updatePresta`;
        const formData = new FormData();
        formData.append(fileName, newFile);
        formData.append('fileName', newFile?.name);
        formData.append('file_name', fileName);
        formData.append('id_presta', idPresta);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        axios.post(url, formData, config).then((response) => {
            console.log(response.data);
        });
    }

    const handleSubmit = () => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Cookie", "PHPSESSID=j8lqo7sk3l2l10ntoq4r2mgo4r");

        var urlencoded = new URLSearchParams();
        urlencoded.append("prenom", "David");
        urlencoded.append("nom", "Nom test 3");
        urlencoded.append("societe", "Soiété test");
        urlencoded.append("mail", "E-mail test");
        urlencoded.append("presta_nom", "Presta démo");
        urlencoded.append("conf_event_titre", "je suis une conférence test");
        urlencoded.append("type", "92");
        urlencoded.append("source", "texploration");
        urlencoded.append("id_event", "399");
        urlencoded.append("description", "ihepdufcydfgxshisxgudpuifxpiqkufxoyedkfxbsbx ousfxvk");
        urlencoded.append("statut", "offreur_de_solution");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(newTexploration),
            redirect: 'follow'
        };

        fetch(`${API_URL}?action=createTexploration`, requestOptions)
            .then(response => response.json())
            .then(result => {
                updatePresta({ idPresta: result.id_presta, fileName: "presta_visuel", newFile: file });
                updatePresta({ idPresta: result.id_presta, fileName: "video_hosted", newFile: video_hosted });
                updatePresta({ idPresta: result.id_presta, fileName: "carte_visite", newFile: carte_visite });

            })
            .then(() => {
                setNewTexploration({
                    id_event: idEvent,
                    statut: "techxplorateur",
                    type: 92,
                    auteur: user.id_contact,
                    cycleLangSelection: [],
                    idJuryEventListSelection: []
                });
                setFormIsSubmited(true);
            })
            .catch(error => console.log('error', error));
    }

    const getJuryDemoList = async (idEvent) => {

        await fetch(`${API_URL}?action=getJuryDemoList&params=WHERE id_event=${idEvent}`)
            .then(res => res.json())
            .then(res => setJuryDemoList(res.filter(juryDemo => juryDemo.presta.numero_stand != '')))
    }

    useEffect(() => {

        idEvent && getEventByIdEvent(idEvent);
        idEvent && getJuryDemoList(idEvent);

        console.log('activeCompany', activeCompany)
    }, [activeCompany])


    if (!currentEvent) {
        return false
    }
    return (
        <LayoutAccountEvent className="bg-red-400" >

            <div className="pt-1 ">
                <BreadcrumbsTechxploration />
            </div>

            {/* {JSON.stringify(newTexploration)} */}

            {
                formIsSubmited && <div className="rounded-md bg-green-50 p-4 mt-2">

                    <p className="flex pb-5 text-2xl font-bold text-green-800 ">
                        <CheckCircleIcon className="h-5 w-5 text-green-400 mr-3" aria-hidden="true" />
                        Votre Tech'xploration à bien été enregistrée
                    </p>
                    <Link
                        type="button"
                        className="rounded-xl bg-blue-500 px-2 py-3 text-xl font-medium text-white hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                        to={`./techxploration`}> Créer une nouvelle Tech'xploration</Link>
                </div>
            }

            {
                formIsSubmited != true && <div className='bg-neutral-100'>



                    <Section title="<!-- Tech'mapper --> Ma solution detectée">

                        <CesImport
                            setNewTexploration={setNewTexploration}
                            newTexploration={newTexploration}
                            setActiveCompany={setActiveCompany}
                            activeCompany={activeCompany} />



                        {displayDemoDescription && <div className="sm:col-span-6">
                            <label htmlFor="presta_accroche" className="block text-sm font-medium text-gray-700 d-none">
                                Description de la solution
                            </label>
                            <div className="mt-1">
                                <textarea
                                    id="presta_accroche"
                                    name="presta_accroche"
                                    rows={3}
                                    className="p-3 border  block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    defaultValue={''}
                                    onChange={handleChange}
                                    placeholder="Description de la solution, moins de 500 caractères"
                                />
                            </div>
                        </div>
                        }

                        {
                    /*<select
                        name="numero_stand"
                        onChange={handleChange}
                        id="numero_stand"
                        className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                        <option value="">Sélectionner un numéro de stand</option>

                        {juryDemoList && juryDemoList.map((juryDemo) => <option>{juryDemo.presta.presta_nom}</option>)}
                        <option value="autre">Autre</option>
                    </select>
                    */}

                        <div className='flex'>
                            <div className="sm:col-span-6 basis-2/6">
                                <label htmlFor="numero_stand" className="block text-sm font-medium text-gray-700 d-none">
                                    Numéro de stand
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="numero_stand"
                                        name="numero_stand"
                                        type="text"
                                        autoComplete="numero_stand"
                                        className="p-3 border block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
                                        onChange={handleChange}
                                        placeholder="N° stand"
                                        defaultValue={activeCompany ? activeCompany['Location'] : ""}
                                    />
                                </div>
                            </div>


                            <div className="sm:col-span-4 basis-5/6">
                                <div className="mt-1">
                                    <input
                                        id="societe"
                                        name="societe"
                                        type="societe"
                                        autoComplete="societe"
                                        className="p-3 border  block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
                                        onChange={handleChange}
                                        defaultValue={activeCompany ? activeCompany['Company name'] : ""}
                                        placeholder="Raison sociale"
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="sm:col-span-4">
                            <div className="mt-1">
                                <input
                                    id="presta_nom"
                                    name="presta_nom"
                                    type="presta_nom"
                                    autoComplete="presta_nom"
                                    className="p-3 border block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
                                    onChange={handleChange}
                                    placeholder="Nom de la solution"
                                />
                            </div>
                        </div>



                        <div className="sm:col-span-6 hidden">
                            <label htmlFor="visuel_principal" className="block text-sm font-bold text-gray-700 pt-4">
                                Illustration de la solution
                            </label>
                            <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                                <div className="space-y-1 text-center">
                                    <svg
                                        className="mx-auto h-12 w-12 text-gray-400"
                                        stroke="currentColor"
                                        fill="none"
                                        viewBox="0 0 48 48"
                                        aria-hidden="true"
                                    >
                                        <path
                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    <div className="flex text-sm text-gray-600">
                                        <label
                                            htmlFor="visuel_principal"
                                            className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                        >
                                            <span>Prendre une photo ou télécharger </span>
                                            <input id="visuel_principal" name="visuel_principal" type="file" className="sr-only" onChange={(e) => handleChangeFile(e, setFile)} />
                                        </label>

                                    </div>
                                    <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                                </div>
                            </div>
                        </div>




                        {/* <h5 className='py-3 font-bold'>Cartographie des usages</h5>
                        <CycleLangList event={currentEvent}
                            setNewTexploration={setNewTexploration}
                            newTexploration={newTexploration}
                        /> */}

                    </Section>


                    <Section title={"Présentation vidéo de la solution"} className="">

                        <label htmlFor="video_hosted" className="flex items-center space-x-2 text-sm font-medium text-gray-700 py-2">
                            <span>  Option 1 : Prendre une vidéo au format horizontal / paysage</span>

                            <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 py-2">
                                <div className="space-y-1 text-center">
                                    <svg
                                        className="mx-auto h-10 w-10 text-gray-400"
                                        stroke="currentColor"
                                        fill="none"
                                        viewBox="0 0 48 48"
                                        aria-hidden="true"
                                    >
                                        <path
                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    <div className="flex text-sm text-gray-600">
                                        <label
                                            htmlFor="video_hosted"
                                            className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                        >
                                            <div> </div>
                                            <input id="video_hosted" name="video_hosted" type="file" className="sr-only" onChange={(e) => handleChangeFile(e, set_video_hosted)} />
                                        </label>

                                    </div>
                                    <p className="text-xs text-gray-500 d-none">PNG, JPG, GIF up to 10MB</p>
                                </div>
                            </div>
                        </label>

                        <div className="sm:col-span-4">
                            <div className="mt-1">

                                <input
                                    name='video_url'
                                    placeholder="Option 2 : Lien youtube  "
                                    helper={"Ex. : https://www.youtube.com/embed/98UEHIHTTG"}
                                    type="text"
                                    autoComplete="societe"
                                    className="p-3 border  block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </Section>

                    <Section title="<!--Tech'caster--> Qui recontacter ?" className="hidden">
                        <div className="sm:col-span-4">

                            <div className="mt-1">
                                <input
                                    id="prenom"
                                    name="prenom"
                                    type="prenom"
                                    autoComplete="prenom"
                                    className="p-3 border block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    onChange={handleChange}
                                    placeholder="Prénom"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-4">

                            <div className="mt-1">
                                <input
                                    id="nom"
                                    name="nom"
                                    type="nom"
                                    autoComplete="nom"
                                    className="p-3 border block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    onChange={handleChange}
                                    placeholder="Nom"

                                />
                            </div>
                        </div>
                        <div className="sm:col-span-4">

                            <div className="mt-1">
                                <input
                                    id="societe"
                                    name="societe"
                                    type="societe"
                                    autoComplete="societe"
                                    className="p-3 border  block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    onChange={handleChange}
                                    defaultValue={activeCompany ? activeCompany['Company name'] : ""}
                                    placeholder="Raison sociale"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-4">

                            <div className="mt-1">
                                <input
                                    id="port"
                                    name="port"
                                    type="port"
                                    autoComplete="port"
                                    className="p-3 border  block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    onChange={handleChange}
                                    placeholder="Téléphone"
                                    value={newTexploration.port}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-4">

                            <div className="mt-1">
                                <input
                                    id="mail"
                                    name="mail"
                                    type="mail"
                                    autoComplete="mail"
                                    className="p-3 border  block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    onChange={handleChange}
                                    placeholder="E-mail"
                                    value={newTexploration.mail}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-6 " >
                            <label htmlFor="carte_visite" className="block text-sm font-bold text-gray-700 pt-4">
                                Carte de visite
                            </label>
                            <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                                <div className="space-y-1 text-center">
                                    <svg
                                        className="mx-auto h-12 w-12 text-gray-400"
                                        stroke="currentColor"
                                        fill="none"
                                        viewBox="0 0 48 48"
                                        aria-hidden="true">
                                        <path
                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    <div className="flex text-sm text-gray-600">
                                        <label
                                            htmlFor="carte_visite"
                                            className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                        >
                                            <span>Prendre une photo ou télécharger </span>
                                            <input id="carte_visite" name="carte_visite" type="file" className="sr-only" onChange={(e) => handleChangeFile(e, setCarteVisite)} />
                                        </label>
                                    </div>
                                    <p className="text-xs text-gray-500 d-none">PNG, JPG, GIF up to 10MB</p>
                                </div>
                            </div>
                        </div>
                    </Section>

                    <Section
                        title="A présenter lors des debriefing : "
                    >
                        <Programme
                            newTexploration={newTexploration}
                            setNewTexploration={setNewTexploration}
                        />
                    </Section>

                    <Section title="Ranking">
                        <StarRating
                            setFunction={setNewTexploration}
                            attributeName='presentation'
                            object={newTexploration}
                        />
                    </Section>

                    <div className="px-2">
                        <button
                            onClick={() => handleSubmit()}
                            className='bg-green-800 w-full p-3 text-white text-xl rounded-full mt-2'>VALIDER</button>
                    </div>
                </div>
            }
        </LayoutAccountEvent >
    )
}

export default Texploration