import parse from 'html-react-parser'
import React, { useState, useEffect } from 'react'
import { DIR_ARCHIVES } from '../config'
import { removeDuplicateObjectFromArray } from '../utils'
import Layout from './Layout'
import useApiUrlTranslation from './useApiUrlTranslation'


const ProgrammeContact = ({ contact, roleList, contactCycleLangList }) => {

    return <div className="row border-top">
        <div className="col-md-1" > <img className='rounded rounded-circle' src={contact.photos.small} alt="" style={{ width: "100px", height: "100px", objectFit: "cover" }} /></div >
        <div className="col-md-1"><img src={contact.logos.small} alt="" style={{ width: "100%" }} /></div>
        <div className="col">
            <strong>{contact.prenom} {contact.nom}</strong> <br />
            {contact.fonction}</div>
        <div className="col"> {
            contactCycleLangList && contactCycleLangList
                .filter(contactCycleLang => contactCycleLang.id_contact === contact.id_contact)
                .map(item => <span class="mx-1 badge rounded-pill bg-secondary">{item.event_contact_type.libelle}</span>)

        }</div>
    </div >
}

const Programme = ({ programme }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [contactCycleLangList, setContactCycleLangList] = useState(null);
    const [roleList, setRoleList] = useState(null);

    useEffect(() => {

        const fetchContactCycleLang = async () => {
            await fetch(`${apiUrlTranslation}?action=fetchContactCycleLang&filter=id_cycle_lang=${programme.id_cycle_lang} AND `)
                .then(res => res.json())
                .then(res => {
                    setContactCycleLangList(res);
                    setRoleList(res.map(item => item.event_contact_type));
                })
        }
        fetchContactCycleLang()
    }, [i18n.resolvedLanguage]);

    return <div className='card bg-white mb-2'>
        <div className="card-title bg-secondary text-white">
            <div
                className="row"
                key={programme.id_demande_referencement_programme_innovation}>
                <div className='col-md-1'><img src={`${DIR_ARCHIVES}${programme.logo}`} alt="" style={{ width: "100%" }} /></div>
                <div className='col-md-11'><h2>{programme.programm_title}</h2>
                </div>
            </div>
        </div>
        <div className="card-body">

            {contactCycleLangList && removeDuplicateObjectFromArray(contactCycleLangList, 'id_contact').map(item => <ProgrammeContact
                contactCycleLangList={contactCycleLangList}
                roleList={roleList}
                contact={item.contact} />)}
        </div>
    </div>
}

const ProgrammesPlansAction = () => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [programmes, setProgrammes] = useState([]);

    useEffect(() => {

        const fetchProgrammes = async () => {
            await fetch(`${apiUrlTranslation}?action=getDemandesReferencement`)
                .then(res => res.json())
                .then(res => setProgrammes(res.filter(res => ["745", "746", "747"].includes(res.id_demande_referencement_programme_innovation))))
        }
        fetchProgrammes()
    }, [i18n.resolvedLanguage]);

    return (
        <Layout title="soutiens et contributeurs du plan d’actions PRETS"
            className="bg-light">
            <div className="container">

                <table className="table table-bordered">
                    <tbody>
                        {programmes.map(programme =>

                            <Programme programme={programme} />
                        )}
                    </tbody>
                </table>
            </div>
        </Layout>
    )
}



export default ProgrammesPlansAction