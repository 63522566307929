const userReducer = (state, action) => {

    switch (action.type) {

        case 'SET_USER':
            return {
                ...state,
                user: action.user,
            };

        case 'SET_USER_CYCLE_LANG_LIST':
            return {
                ...state,
                userCycleLangList: action.payload,
            }

        case 'SET_USER_CONTACT_CONFERENCIER_LIST':
            return {
                ...state,
                userContactConferencierList: action.payload,
            }


        case 'SET_USER_ROLE_LIST':
            return {
                ...state,
                userRoleList: action.payload,
            }

        case 'SET_USER_EVENT_LIST':
            return {
                ...state,
                userEventList: action.payload,
            }



        case 'SENDING_REQUEST':

            return {
                ...state,
                loading: true
            }

        case 'REQUEST_FINISHED':

            return {
                ...state,
                loading: false
            }

        default:
            return state;

    }
}

export default userReducer