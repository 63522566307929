
import parse from 'html-react-parser'
import React, { useState, useEffect } from 'react'
import { DIR_ARCHIVES } from '../config'
import Layout from './Layout'
import Loader from './Loader'
import useApiUrlTranslation from './useApiUrlTranslation'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'


const Programme = ({ programme }) => {


    return <li >
        <Link
            className="group"
            to={`/collectif-categorie/collectif_programme/${programme.id_cycle_lang}`}>

            <div className="flex-col  lg:flex-row group-hover:border-pink-700 border-neutral-800 border-2 space-x-5 rounded-2xl flex my-3 bg-neutral-900 pl-0  items-center">

                <img src={`${programme.attachedFilesCollection.find(file => file.id_attached_file_type == 65).small}`} alt="" className="lg:rounded-l-2xl lg:rounded-r-none  rounded-2xl" />
                <div className="py-3">
                    <b className="text-lg text-gray-300">{programme.nom_cycle_lang}</b>
                    <div className=" text-gray-400">{programme.resume} {programme.ambition}</div>
                </div>
                <div>
                    <p className="my-3 mr-3 group-hover:bg-pink-700  group-hover:text-white  border-neutral-700 border-2 rounded-full px-2 py-1 w-36 text-center">En savoir plus</p>
                </div>
            </div>
        </Link>
    </li>
}



const ProgrammesInnovation = () => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [programmes, setProgrammes] = useState(null);

    useEffect(() => {

        const fetchProgrammes = async () => {
            await fetch(`${apiUrlTranslation}?action=getCycleLang&filter=cycle_lang_type="collectif_programme" AND cycle_lang_state NOT IN('hidden') AND `)
                .then(res => res.json())
                .then(res => setProgrammes(res))
        }
        fetchProgrammes()
    }, [i18n.resolvedLanguage])


    return (
        <Layout title={t('pages.programmes_innovation.title')} darkmode>
            <div className="px-10 mx-auto mt=3 pb-5">

                <div className="text-lg text-gray-300 max-w-7xl mx-auto"> {parse(t('pages.programmes_innovation.intro'))}</div>

                {!programmes ? <Loader /> :
                    <div className="max-w-7xl mx-auto pb-5">

                        <h2 className="font-bold mt-5 animatedgradient">Les programmes actifs</h2>

                        <ul className="mt-5 pt-2">

                            {programmes
                                .filter(programme => programme.cycle_lang_state == "active")
                                .map(programme =>
                                    <Programme programme={programme} />)}

                        </ul>

                        <h2 className="font-bold mt-5 pt-5 animatedgradient">Les programmes en cours de développement</h2>

                        <ul className="mt-5 pt-2">

                            {programmes
                                .filter(programme => programme.cycle_lang_state == "draft")
                                .map(programme =>
                                    <Programme programme={programme} />)}

                        </ul>
                    </div>


                }
            </div>
        </Layout>
    )
}

export default ProgrammesInnovation
