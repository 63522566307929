import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Layout from "./Layout";
import PageSection from "./PageSection";
import SocialMedias from "./SocialMedias";

function PressPartenaireEvent() {
  const { idEvent } = useParams();
  const [mediaList, setMediaList] = useState(null);

  const fetchPartenaireList = async () => {
    const req = `getEventPrestas&id_event=${idEvent}`;

    await fetch(
      `https://www.mlg-consulting.com/smart_territory/form/api.php?action=${req}`
    )
      .then((res) => res.json())
      .then((partenaireList) => {
        console.log(partenaireList);
        setMediaList(partenaireList);
      });
  };

  useEffect(() => {
    fetchPartenaireList();
  }, []);

  return (
    <Layout className="container">
      <PageSection title="Presse">
        <p className="container">
          Vous souhaitez vous tenir informé de l'actualité du salon, consulter
          les communiqués ou dossier de presse, effectuer une demande
          d’accréditation ou encore entrer en contact avec nous ? Cet espace est
          fait pour vous.
        </p>
      </PageSection>
      <PageSection title="On en parle dans la presse">
        {mediaList && (
          <table className="table table-striped container">
            <thead>
              <tr>
                <th scope="col">Média</th>
                <th scope="col">Nom</th>
                <th scope="col">Pays</th>
                <th scope="col">Date parution</th>
                <th scope="col">Lien vers l'article</th>
              </tr>
            </thead>
            <tbody>
              {mediaList.map((partenaire) => (
                <tr key={partenaire.id_event_presta}>
                  <td>{partenaire.media_nom}</td>
                  <td>{partenaire.event_presta_nom}</td>
                  <td>
                    {partenaire.event_presta_pays}{" "}
                    <img
                      style={{ width: "3vw", marginLeft: "5px" }}
                      src={partenaire.flag}
                      alt="partenaire flag"
                    />
                  </td>
                  <td>{partenaire.date_parution}</td>
                  <td>
                    <a target="_blank" rel="noreferrer" href={partenaire.event_presta_url_externe}>
                      Lire l'article
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </PageSection>
      <PageSection title="Réseaux sociaux">
        <SocialMedias />
      </PageSection>
      {/* <PageSection title="Demande d'accréditation presse"><p>Pour recevoir votre accréditation à Village Francophone - CES - Las Vegas + Phygital World connected studio 2022 veillez cliquer sur le lien ci-dessous : </p></PageSection>
      <PageSection title="Kit média"></PageSection> */}
    </Layout>
  );
}

export default PressPartenaireEvent;
