import HTMLReactParser from 'html-react-parser';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { removeTags, removeDuplicateObjectFromArray, removeDuplicateObjectsByProperty } from '../utils';
import Layout from './Layout';
import LieuInfos from './LieuInfos';
import Loader from './Loader';
import useApiUrlTranslation from './useApiUrlTranslation';

const DisplayProgramm = ({ program, salle }) => {

    return <table class="table table-bordered table-striped table-sm">
        <thead class="table-dark">
            <tr>
                <th>Date</th>
                <th>Temps fort</th>
                <th>Emplacement</th>
            </tr>
        </thead>
        <tbody>
            {
                program.filter(program => program.id_salle === salle.id_salle).map(program_item => {

                    const debut = `${program_item.conf_event_date} ${program_item.heure_debut.replace('h', ':')}`;
                    const fin = `${program_item.conf_event_date} ${program_item.heure_fin.replace('h', ':')}`;

                    const conf_event_route = ["48", "57", "63", "64", "66", "67", "77"].includes(program_item.type)
                        ? `/events/${program_item.id_event.id_event}/programm/${program_item.id_conf_event}/parcours-details`
                        : `/events/${program_item.id_event.id_event}/programm/${program_item.id_conf_event}/programm-details`;

                    return <tr>
                        <td><small>{program_item.conf_event_date}</small></td>
                        <td>
                            <a target="_blank" rel="noreferrer" href={conf_event_route}>

                                <div style={{ fontSize: "13px" }}>{HTMLReactParser(program_item.localTimeZone)}</div>
                                <div className='text-blue-500 font-bold'>{HTMLReactParser(removeTags(program_item.conf_event_lang.cel_titre))}</div></a><br />


                            <div className="flex flex-wrap pt-2 text-xs">
                                {HTMLReactParser(program_item.otherTimeZones)}
                            </div>
                        </td>
                        <td>
                            {program_item.salle.configuration != "" ? <img src={program_item.salle.configuration} alt="" /> : ''}
                            {program_item.salle.salle_nom}</td>
                    </tr>
                })}
        </tbody>
    </table>
}


const Salle = ({ prog, salle }) => {

    const { t } = useApiUrlTranslation();

    const id_lieu = salle.salle.id_lieu.id_lieu

    return <>
        <h3 className="py-3">{salle.salle.id_lieu.lieu_nom}</h3>

        <div className="row">
            <div className="col-md-3">
                <img alt='visuel du lieu' src={`${salle.salle.id_lieu.visuel_principal}`} style={{ width: "100%" }} />

                { /*  <i className="fas fa-map-marker-alt"></i> {salle.salle.id_lieu.lieu_adresse}   {salle.salle.id_lieu.lieu_cp}  <br />
                {salle.salle.id_lieu.lieu_ville}  <br />
                {salle.salle.id_lieu.lieu_pays}*/}
            </div>
            <div className="col-md-9">
                <div>
                    <ul className="nav nav-tabs" id={`myTab-${id_lieu}`} role="tablist">

                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link active"
                                id="profile-tab"
                                data-bs-toggle="tab"
                                data-bs-target={`#profile-${id_lieu}`}
                                type="button" role="tab"
                                aria-controls={`profile-${id_lieu}`}
                                aria-selected="false">{t('event_nav.info_pratiques')}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id={`home-tab-${id_lieu}`}
                                data-bs-toggle="tab"
                                data-bs-target={`#home-${id_lieu}`}
                                type="button"
                                role="tab"
                                aria-controls={`home-${id_lieu}`}
                                aria-selected="true">{t('event_nav.programme')} </button>
                        </li>

                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id={`profile-${id_lieu}`} role="tabpanel" aria-labelledby="profile-tab">
                            <LieuInfos salle={salle.salle} />
                        </div>
                        <div className="tab-pane fade"
                            id={`home-${id_lieu}`} role="tabpanel"
                            aria-labelledby={`home-tab-${id_lieu}`}>
                            <DisplayProgramm program={prog} salle={salle} />
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <hr />
    </>
}

const ProgrammPlaces = () => {

    let { idEvent } = useParams();

    const [salleList, setSalleList] = useState(null);

    const [programme, setProgramme] = useState(null);

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const fetchProgramme = async () => {

        fetch(`${apiUrlTranslation}?action=getConfEvent&id_event=${idEvent}`)
            .then(res => res.json())
            .then(prog => {

                console.log(prog);

                const filteredProg = prog
                    .filter(item =>
                        item.jury_event.thematique !== "y"
                        && item.id_salle !== 0
                        && !["87"].includes(item.type)

                    )
                    .map(item => { return { ...item, id_lieu: item.salle.id_lieu.id_lieu } });

                //const salle_list = removeDuplicateObjectFromArray(filteredProg, 'id_salle');

                const salle_list = removeDuplicateObjectsByProperty(filteredProg, 'id_lieu');

                console.log(filteredProg)

                console.log(salle_list)

                setSalleList(salle_list);
                setProgramme(filteredProg);
            })
    }

    useEffect(() => {
        fetchProgramme()

    }, [i18n.resolvedLanguage])


    return (
        <Layout title={t('event_nav.info_pratiques')} className="bg-white">
            <div className="container">
                {programme ? salleList.filter(salle => salle.salle.id_salle).map(salle =>

                    <Salle key={salle.salle.id_salle} salle={salle} prog={programme} />) : <Loader />}
            </div>
        </Layout>
    )
}

export default ProgrammPlaces