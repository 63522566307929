import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import Layout from "./Layout";
import { API_URL, DIR_DOCS_EVENT } from "../config";
import useApiUrlTranslation from "./useApiUrlTranslation";
import HTMLReactParser from "html-react-parser";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Moment from "react-moment";
import AgendaAnnuel from "./AgendaAnnuel";
import ContactsPhotoSlider from "./ContactsPhotoSlider";
import JoinMyglobalVillage from "./JoinMyglobalVillage";
import Pricing2 from "./Pricing2";
import Shop from "./Shop";
import { ArrowRightCircleIcon, ArrowRightEndOnRectangleIcon, ArrowRightStartOnRectangleIcon, PlayCircleIcon } from "@heroicons/react/24/solid";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/20/solid";

const VideoListSection = () => {

  const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

  const [videoList, setVideoList] = useState(null);

  const [videoSelected, setVideoSelected] = useState(null);

  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const handleSelectVideo = (video) => {

    setVideoSelected(video)

    setDialogIsOpen(true);

  }

  const getVideosEvents = async () => {

    await fetch(`${apiUrlTranslation}?action=getVideoList&params=WHERE id_video IN (9647,9648 , 9649, 9650)`)
      .then((res) => res.json())
      .then(res => setVideoList(res))
  }

  useEffect(() => {

    getVideosEvents();
  }
    , [i18n.resolvedLanguage]);

  return <div className="px-4">

    <h2 className="animatedgradient font-extrabold py-3">
      Vidéos
    </h2>

    <p>Il soutiennent, investissent et médiatisent les sessions de pitch de l'X'port Hub. myGlobalVillage est fiers d'accueillir des personnalités iconiques de la tech, des grandes entreprises mondiale et des médias au sein de son cercles de Tech'Xporter.</p>



    <div className="grid grid-1 lg:grid-cols-4 gap-4 ">
      {videoList && videoList.map(video => <div
        onClick={() => handleSelectVideo(video)}
        className="group cursor-pointer  hover:border-x-pink-600   hover:border-y-pink-600  overflow-hidden bg-neutral-600/10  rounded-2xl border-x border-y border-neutral-900">

        <div className="  relative">
          <img
            src={video.thumbnail_large_url}
            alt=""
            className="transform transition-transform duration-300 group-hover:scale-110 w-full h-auto"
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <PlayCircleIcon className="w-12 h-12 text-red-700 transform transition-transform duration-300  group-hover:scale-150" />
          </div>
        </div>

        <div className="p-4 text-gray-400">{video.titre}</div>
      </div>)}

    </div>

    {dialogIsOpen && <dialog className=" w-screen h-screen fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4">

      <div className="bg-white rounded-lg overflow-hidden shadow-lg w-full max-w-4xl">
        <button
          className="absolute top-0 right-0 p-2 text-2xl text-white bg-black bg-opacity-50"
          onClick={() => { setDialogIsOpen(false) }}>X</button>
        <iframe
          src={videoSelected && videoSelected.embed_url}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Démarrer la vidéo"
          className="w-full h-96"
        ></iframe>
      </div>
    </dialog>
    }


  </div>
}

const ContactsSection = ({ cycleLang }) => {

  const { i18n, apiUrlTranslation } = useApiUrlTranslation();

  const [contactCycleLangList, setContactCycleLangList] = useState(null);

  const getContactCycleLang = async () => {

    const filter = ` ((id_cycle_lang=${cycleLang.id_cycle_lang} AND id_cycle_lang_2 !=0) OR (id_cycle_lang_2=${cycleLang.id_cycle_lang} AND id_cycle_lang !=0))`;

    await fetch(`${apiUrlTranslation}?action=getContactCycleLang&filter= ${filter}`)
      .then((res) => res.json())
      .then(res => setContactCycleLangList(res))
  }

  useEffect(() => {
    getContactCycleLang();
  }
    , [i18n.resolvedLanguage, cycleLang]);

  return (
    <div>
      <ContactsPhotoSlider
        title="Coordinateurs"
        contactList={contactCycleLangList && contactCycleLangList.map(contactCycleLang => contactCycleLang.contact)}
      />
    </div>
  )
}

const DisplayCycleLangList = ({ cycleLangList, cycle_lang_type, title }) => {

  return <div>
    <h2 className="m-3 animatedgradient font-extrabold">{title} actifs</h2>
    <div className="m-3 flex  flex-col flex-wrap ">
      {!cycleLangList ? '' :
        cycleLangList
          .sort((a, b) => a.nom_cycle_lang.localeCompare(b.nom_cycle_lang))
          .filter((cycle_lang) => ['active'].includes(cycle_lang.cycle_lang_state))
          .map((cycle_lang, i) => (
            <CycleLangItem
              cycleLang={cycle_lang} key={cycle_lang.id_cycle_lang}
              cycle_lang_type={cycle_lang_type}
            />
          ))}
    </div>

    {!cycleLangList && <><h2 className="m-3 animatedgradient font-extrabold">{title} en cours de développement</h2>
      <div className="m-3 flex  flex-col flex-wrap ">
        {!cycleLangList ? '' :
          cycleLangList
            .sort((a, b) => a.nom_cycle_lang.localeCompare(b.nom_cycle_lang))
            .filter((cycle_lang) => ['draft'].includes(cycle_lang.cycle_lang_state))
            .map((cycle_lang, i) => (
              <CycleLangItem
                cycleLang={cycle_lang} key={cycle_lang.id_cycle_lang}
                cycle_lang_type={cycle_lang_type}
              />
            ))}
      </div>
    </>}

  </div>
}
const CycleLangItem = ({ cycleLang, cycle_lang_type }) => {

  return <a href={`/collectif-categorie/collectif_programme/${cycleLang.id_cycle_lang}`}>
    <div className={`group mx-auto flex items-center px-3 py-2 border-x-1 border-2 border-neutral-400 hover:border-pink-500 border-transparent text-base font-medium rounded-2xl shadow-sm text-white justify-center`}>

      <img className={`m-1 w-24 h-24 basis-1/12 bg-white  rounded-2xl shadow-lg`}
        alt=""
        title={cycleLang.nom_cycle_lang}
        src={cycleLang.attachedFilesCollection.find(file => file.id_attached_file_type == 65).small}
      />


      <div className="basis-10/12">

        <p className={`  ${cycle_lang_type == "collectif_programme" ? '' : 'font-bold text-xl'}`}> {cycleLang.nom_cycle_lang}</p>
        <div className="dark:text-gray-400">
          {cycleLang.ambition}
        </div>
      </div>

      <div className={`basis-2/12 mx-5 ${cycleLang.cycle_lang_state != "active" ? 'hidden' : ''}`}>
        <div className="text-center border-neutral-700 text-gray-400  rounded-full border-2 px-3 py-2 group-hover:bg-pink-600 group-hover:text-white" >en savoir plus</div>
      </div>
    </div>
  </a>
}
const EventItem = ({ event }) => {

  const logo = event.logo ? DIR_DOCS_EVENT + event.logo : event.event_ligne.el_visuels.small;

  return <div className="group hover:border-pink-700 bg-neutral-100 dark:bg-neutral-900 dark:border-neutral-900 border-neutral-200   rounded-2xl border-x border-y">
    <div className="flex">
      <Link className="flex" rel="noreferrer" target="_blank" to={`/events/${event.id_event}`}>
        <img alt="" src={logo} className=" rounded-l-2xl w-40 object-cover " />
        <div className="m-2 p=5">
          <strong className="uppercase dark:text-gray-100 ">{event.nom}</strong>

          <div className="text-gray-500">
            {event.precision_date ? event.precision_date :
              <>
                <Moment format="dddd D MMMM YYYY">{event.event_start}</Moment>
                {/* <Moment format="DD/MM/YYYY">{event.event_start}</Moment> */}
              </>
            }
            <br /> {event.lieu.lieu_nom}

          </div>


          <p className="border-x border-y group-hover:bg-pink-700  group-hover:text-white border-neutral-600  dark:border-neutral-800 rounded-full mt-3 py-1 px-4 ">En savoir plus</p>

        </div>
      </Link>
    </div>

  </div>
}
function CollectifDetails() {

  const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();


  let { id_cycle_lang } = useParams();
  let { cycle_lang_type } = useParams();

  const joinMyglobalVillageRef = useRef(null);

  const [cycleLang, setCycleLang] = useState(null);
  const [cycleLangList, setCycleLangList] = useState(null);
  const [programmList, setProgrammList] = useState(null);
  const [coordinateurList, setCoordinateurList] = useState([]);
  const [eventList, setEventList] = useState(null);
  const [allEvents, setAllevents] = useState([]);
  const [eventListAnte, setEventListAnte] = useState(null);
  const [acheteurs, setAcheteurs] = useState([]);
  const [offreurs, setOffreurs] = useState([]);

  const acheteursRolesList = [
    "coordinateur_collectif",
    "coordinateur_geographique",
    "coordinateur_programme",
    "jury",
    "soutien_collectif",
    "referent-lieu",
    "coordinateur_collectif_partenaire",
    "president_collectif"
  ];

  const fetchCycleLang = async () => {
    const url = `${apiUrlTranslation}?action=getCycleLangByIdCycleLang&id_cycle_lang=${id_cycle_lang}`;

    await fetch(url)
      .then((res) => res.json())
      .then((cycle_lang) => {
        setCycleLang(cycle_lang);
      });
  };

  const fetchContactCycleLangByCycleLang = async () => {
    await fetch(
      `${apiUrlTranslation}?action=fetchContactCycleLangByCycleLang&id_cycle_lang=${id_cycle_lang}`
    )
      .then((res) => res.json())
      .then((contactCycleLangList) => {
        contactCycleLangList.map((contact_cycle_lang) => {
          fetchContact({ contact_cycle_lang });
          return '';
        });
      });
  };


  const fetchContact = async ({ contact_cycle_lang }) => {
    const role = contact_cycle_lang.event_contact_type?.event_contact_type_nom;

    const collectifTypeTest = cycle_lang_type.split("_").includes("collectif") ? cycle_lang_type.split("_")[1] : false;
    const coordinateurTypeTest = role.split("_").includes("coordinateur") ? role.split("_")[1] : false;

    await fetch(
      `${apiUrlTranslation}?action=getContact&id_contact=${contact_cycle_lang.id_contact}`
    )
      .then((res) => res.json())
      .then((contact) => {
        if (
          ["coordinateur_collectif",
            "coordinateur_programme",
            "coordinateur_geographique",
            "coordinateur_programme",
            "coordinateur_collectif_partenaire",
            "president_collectif"].includes(role) || (coordinateurTypeTest && collectifTypeTest === coordinateurTypeTest)
        ) {
          setCoordinateurList((coordinateurList) => [
            ...coordinateurList,
            contact,
          ]);
        } else {
          if (
            acheteursRolesList.includes(role) || (coordinateurTypeTest && collectifTypeTest === coordinateurTypeTest)
          ) {
            setAcheteurs((contactList) => [
              ...contactList,
              contact?.photos?.tiny,
            ]);
          } else {
            setOffreurs((contactList) => [
              ...contactList,
              contact?.photos?.tiny,
            ]);
          }
        }
      });
  };

  const getAllCollectifContacts = async () => {
    await fetch(
      `${apiUrlTranslation}?action=getAllCollectifContacts&id_cycle_lang=${id_cycle_lang}`
    )
      .then((res) => res.json())
      .then((cycle_lang_list) => {
        setCycleLangList(cycle_lang_list);
      });
  };

  const fetchEvents = async () => {

    const reqSuite = `(SELECT id_event FROM conferenciers WHERE id_contact IN( SELECT id_contact  FROM contact_cycle_lang WHERE id_cycle_lang = ${id_cycle_lang}))`

    console.log(coordinateurList)

    const params5 = `WHERE
    (
    (events.id_event_ligne IN(SELECT id_event_ligne FROM cycle_lang_event_ligne WHERE id_cycle_lang=${id_cycle_lang})
    OR id_event IN ${reqSuite}
    ))
    AND date(NOW()) < CONCAT(event_an,'-',event_mois,'-',event_jour)
    AND events.id_event NOT IN(399)
    AND afficher !='n'
    AND
    `;

    const params6 = `WHERE
    (
    (events.id_event_ligne IN(SELECT id_event_ligne FROM cycle_lang_event_ligne WHERE id_cycle_lang=${id_cycle_lang}))

    || id_event IN (SELECT id_event FROM conf_event_cycle_lang cecl, conf_event ce WHERE cecl.id_conf_event = ce.id_conf_event AND  id_cycle_lang=${id_cycle_lang})
    )
      
    AND event_start >= NOW()
    AND events.id_event NOT IN(399)
    AND afficher !='n'
    AND
    `;

    const params7 = `WHERE

    id_event IN(SELECT id_event FROM event_cycle_lang WHERE id_cycle_lang =${id_cycle_lang})
     AND event_start >= NOW()
    AND events.id_event NOT IN(399)
    AND afficher !='n'
    AND
    `;

    const req_suite = encodeURIComponent(params7);
    const req_suite_ante = (params6);

    await fetch(`${apiUrlTranslation}?action=getEvents&params=${req_suite}`)
      .then((res) => res.json())
      .then((eventList) => {
        setEventList(eventList)
        setAllevents((allEvents) => [...allEvents, ...eventList])
      });

    await fetch(`${apiUrlTranslation}?action=getEvents&params=${encodeURIComponent(req_suite_ante)}`)
      .then((res) => res.json())
      .then((eventList) => {
        setEventListAnte(eventList)
        setAllevents((allEvents) => [...allEvents, ...eventList])

      });
  }

  const fetchProgrammes = async () => {
    await fetch(
      `${API_URL}?action=getDemandesReferencement&id_cycle_lang=${id_cycle_lang}`
    )
      .then((res) => res.json())
      .then((programmeList) => {
        console.log(programmeList)
        setProgrammList(programmeList);
      });
  };



  useEffect(() => {
    fetchCycleLang();
    fetchContactCycleLangByCycleLang().then(res => fetchEvents());
    getAllCollectifContacts()

    fetchProgrammes();
  }, [i18n.resolvedLanguage]);

  return (
    <Layout darkmode>
      <div className="container">
        {cycleLang && (
          <>
            <div className="flex items-center m-3">
              <img
                alt="Logo collecti"
                width="10%"
                className="rounded-2xl"
                src={cycleLang.logo_better_url}
              />
              <p className="py-3 m-3 text-6xl font-extrabold animatedgradient">{cycleLang.nom_cycle_lang}</p>
            </div>








            <nav className=" bg-light nav-fluid nav mt-3 py-3 text-white  sticky-top
            d-flex align-items-center border d-none"  style={{


                top: "0px",
                zIndex: "999",
                background: "white none repeat scroll 0% 0%",
                boxShadow: "rgba(214, 214, 214, 0.5) 0px 6px 10px -5px"

              }}>
              <img
                alt="Logo collectif"
                width="80px"
                src={cycleLang.logo_better_url}
              />
              <a className="nav-link text-dark" aria-current="page">
                <h1 className="m-3 fw-bolder">{cycleLang.nom_cycle_lang}</h1>
              </a>
              {/* <a class="nav-link text-dark" aria-current="page" href="#territoires">Territories</a>*/}
              <a className="nav-link text-dark" href="#events">Events</a>
              <a className="nav-link text-dark" href="#programmes">Programms</a>
            </nav>

            <p className="my-3  p-3 text-lg">{HTMLReactParser(cycleLang.cycle_lang_descriptif)}</p>


            {cycleLang && cycleLang.cycle_lang_type !== 'collectif_partenaire' && <JoinMyglobalVillage cycleLang={cycleLang} />}

          </>
        )}
        {/* <div className=" py-3 rounded my-2  ">
          <h2 className="m-3 animatedgradient font-extrabold">  {t("pages.collectif_details.coordinateurs")}</h2>
          <div className="row">
            <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8 lg:gap-y-2">
              {coordinateurList.length == 0 ? "" :
                coordinateurList.map((coordinateur, i) => {

                  const photo = coordinateur.photos?.small;

                  return <>

                    <li class="sm:py-8 py-2">
                      <div class="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0 dark:border-neutral-900 border-x border-y rounded  ">
                        <div class="aspect-w-1 aspect-h-1  overflow-hidden"  >
                          <img class="rounded-l-2xl  shadow-lg w-100 h-52 object-cover " src={photo} alt="" />
                        </div>
                        <div class="sm:col-span-2 pt-3 pr-3">
                          <div class="space-y-4">
                            <div class="text-lg leading-6 font-medium space-y-1">
                              <h3>{coordinateur.prenom}  {coordinateur.nom} </h3>
                              <p class="text-pink-500">{coordinateur.fonction} - {coordinateur.societe}  </p>
                            </div>
                            <div class="">
                              <p class="text-gray-500">
                                {HTMLReactParser(coordinateur.edito_court ? coordinateur.edito_court.substring(0, 200) + '...' : '')}

                              </p>
                            </div>
                            <ul role="list" class="flex space-x-5">
                              {coordinateur.sn_twitter && <li>
                                <a href={coordinateur.sn_twitter} class="text-gray-400 hover:text-gray-500">
                                  <span class="sr-only">Twitter</span>
                                  <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                    <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84"></path>
                                  </svg>
                                </a>
                              </li>
                              }
                              {coordinateur.sn_linkedin && <li>
                                <a href={coordinateur.sn_linkedin} class="text-gray-400 hover:text-gray-500">
                                  <span class="sr-only">LinkedIn</span>
                                  <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clip-rule="evenodd"></path>
                                  </svg>
                                </a>
                              </li>
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li >





                    <div class="space-y-4 d-none">
                      <div class="aspect-w-3 aspect-h-2">
                        <img class="object-cover shadow-lg rounded-lg" src={coordinateur.photos?.small} alt="" />
                      </div>
                      <div class="text-lg leading-6 font-medium space-y-1">
                        <h3>{coordinateur.prenom}  {coordinateur.nom} </h3>
                        <p class="text-indigo-600">{coordinateur.fonction} </p>
                      </div>
                      <div class="text-lg">
                        <p class="text-gray-500">{coordinateur.edito_long} {coordinateur.edito_court}  </p>
                      </div>

                      <ul role="list" class="flex space-x-5">
                        {coordinateur.sn_twitter && <li>
                          <a href={coordinateur.sn_twitter} class="text-gray-400 hover:text-gray-500">
                            <span class="sr-only">Twitter</span>
                            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                              <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84"></path>
                            </svg>
                          </a>
                        </li>
                        }

                        {coordinateur.sn_linkedin && <li>
                          <a href={coordinateur.sn_linkedin} class="text-gray-400 hover:text-gray-500">
                            <span class="sr-only">LinkedIn</span>
                            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                              <path fill-rule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clip-rule="evenodd"></path>
                            </svg>
                          </a>
                        </li>
                        }
                      </ul>
                    </div>
                    <div key={i} className="d-none col-md-3 align-items-center text-center">
                      <img
                        alt="Coordinateur"
                        className=""
                        src={coordinateur.photos?.small}
                        width="100vw"
                        height="100vw"
                        style={{ objectFit: "cover" }}
                      />
                      <p className="m-2">
                        {coordinateur.prenom +
                          " " +
                          coordinateur.nom +
                          " - " +
                          coordinateur.societe}
                        {coordinateur.edito_court}
                        {coordinateur.edito_long}
                      </p>
                    </div>

                  </>

                })}
            </ul>
          </div>
        </div> */}

        <VideoListSection />



        <div className="shadow-sm py-3 rounded my-2 hidden">
          <h2 className="m-3">  {t("pages.collectif_details.soutiens")} </h2>
          <h5 className="m-3">{t('pages.collectif_details.offreurs')} :</h5>
          <div className="m-3 d-flex flex-wrap">
            {offreurs.length === 0 ? '' :
              offreurs.map((contactImg, i) => (
                <div key={i}>
                  <img
                    className="m-1 rounded rounded-circle"
                    src={contactImg}
                    alt=""
                    width="50vw"
                    height="50vw"
                  />
                </div>
              ))}
          </div>
          <h5 className="m-3">{t('pages.collectif_details.acheteurs')} : </h5>
          <div className="m-3 d-flex flex-wrap">
            {acheteurs.length === 0 ? '' :
              acheteurs.map((contactImg, i) => (
                <div key={i}>
                  <img
                    className="m-1 rounded rounded-circle w-16 h-16"
                    src={contactImg}
                    alt=""

                  />
                </div>
              ))}
          </div>
        </div>

        <div className="shadow-sm py-3 rounded my-2 hidden" id="territoires">
          <h2 className="m-3 animatedgradient font-extrabold">  {t("pages.collectif_details.territoires")}</h2>
          <div className="m-3 flex flex-wrap">
            {!cycleLangList ? '' :
              cycleLangList.map((cycleLang, i) => (
                <img
                  className="m-1 w-32"
                  key={i}
                  alt=""
                  src={cycleLang.logo_better.small}

                />
              ))}
          </div>
        </div>

        <div className="px-3 py-3 my-10">
          <h2 className="animatedgradient font-extrabold py-3"> Ils soutiennent / ils coordonnent le plan d'actions de myGlobalVillage</h2>

          <div className="py-3">
            Mobilisé pour détecter des solutions innovantes, pour aider au financement,, au décollage ou à l'atterrissage des solutions innovantes les coordinateurs du myGlobalVillage sont mobilisés toute l'année. Il permettent seront présents ou connectés à distance lors de l'ensemble des temps forts de l'événement
          </div>
          <ContactsSection cycleLang={cycleLang} />
        </div>

        <AgendaAnnuel cycleLang={cycleLang} />

        {/* {allEvents && allEvents.length > 0 &&

          <div className=" py-5 rounded my-2" id="events">

            <h2 className="m-3 animatedgradient font-extrabold py-3">
              {cycle_lang_type && cycle_lang_type == "collectif_programme" ? "Èvénements liés" : "Agenda événementiel"
                //t("pages.collectif_details.events"
              }
            </h2>

            <div className="grid lg:grid-cols-3 grid-cols-1 gap-3">
              {!allEvents ? '' :
                allEvents
                  .filter((event, index, self) =>
                    index === self.findIndex((t) => (
                      t.id_event === event.id_event
                    ))
                  )
                  .sort((a, b) => new Date(a.event_start) - new Date(b.event_start))
                  .map((event) => {

                    return <EventItem event={event} key={event.id_event} />
                  }
                  )}
            </div>
          </div>} */}



        {/* 
          <p className="text-white text-2xl">rattachement à la ligne</p>
          <div className="grid lg:grid-cols-3 grid-cols-1 gap-3">
            {!eventListAnte ? '' :
              eventListAnte.map((event) => {

                return <EventItem event={event} key={event.id_event} />
              }
              )}
          </div>
             */}

        <div className=" py-3 rounded" id="programmes">

          {cycleLang && cycleLang.children && (
            <>
              {(cycleLang.children.filter(child => child.cycle_lang_type === 'collectif_programme')).length > 0 && (
                <DisplayCycleLangList
                  title={cycle_lang_type && cycle_lang_type === "collectif_programme" ? "Thématiques de sourcing" : t("pages.collectif_details.programmes")}
                  cycle_lang_type="collectif_programme"
                  cycleLangList={cycleLang.children.filter(child => child.cycle_lang_type === 'collectif_programme')}
                />
              )}

              {(cycleLang.children.filter(child => child.cycle_lang_type === 'collectif_geographique')).length > 0 && (
                <DisplayCycleLangList
                  title="Territoires connectés"
                  cycle_lang_type="collectif_geographique"
                  cycleLangList={cycleLang.children.filter(child => child.cycle_lang_type === 'collectif_geographique')}
                />
              )}
              {
                cycleLang.children.filter(child => child.cycle_lang_type == 'collectif_partenaire').length > 0 && <DisplayCycleLangList
                  title="Ecosystèmes"
                  cycle_lang_type="collectif_partenaire"
                  cycleLangList={cycleLang.children.filter(child => child.cycle_lang_type == 'collectif_partenaire')}
                />}

              {
                cycleLang.children.filter(child => child.cycle_lang_type == 'collectif_planaction').length > 0 && <DisplayCycleLangList
                  title="Les plans d'action liés"
                  cycle_lang_type="collectif_planaction"
                  cycleLangList={cycleLang.children.filter(child => child.cycle_lang_type == 'collectif_planaction')}
                />}
            </>
          )}
          {cycleLang && cycleLang.cycle_lang_type !== 'collectif_partenaire' && <Pricing2 cycleLang={cycleLang} />}


        </div>
      </div>
    </Layout>
  )
}


export default CollectifDetails;