import React from 'react'

import LayoutAccount from './LayoutAccount'
import NavProgramme from './NavProgramme'
import UserProgrammes from './UserProgrammes'

const ThematiquesByUserProgramme = () => {

    return <LayoutAccount
        title="Table des solutions affectées à un programme d’innovation/zone de démonstrateur"
        NavProgramme={<NavProgramme />}>
        <UserProgrammes />
    </LayoutAccount>

}

export default ThematiquesByUserProgramme