import React from 'react';

const LinkedInAuth = () => {

    const CLIENT_ID = '78eqa2rddcgy4s';

    const URL_DE_REDIRECTION = 'http://localhost:3000/linkedin-redir';

    const linkedinRedirectUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${process}&scope=openid,profile,email`


    const handleLogin = () => {
        // Remplacez par votre propre URL de redirection et votre client ID
        const linkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${URL_DE_REDIRECTION}&scope=openid,profile,email`;
        window.location.href = linkedInAuthUrl;
    };

    return (
        <button onClick={handleLogin}>Se connecter avec LinkedIn</button>
    );
};

export default LinkedInAuth;
