import React, { useEffect, useReducer } from "react";
import useApiUrlTranslation from "../components/useApiUrlTranslation";
import EventContext from "./EventContext";
import EventReducer from "./EventReducer";

const EventProvider = props => {

    const { i18n, apiUrlTranslation } = useApiUrlTranslation()

    const initialState = {

        events: [],
        currentEvent: null,
        currentEventEventCycleLangList: [],
        currentEventContactList: [],
        loading: true
    }

    const [state, dispatch] = useReducer(EventReducer, initialState)

    const getEvents = async (filter = '') => {

        try {
            dispatch({ type: 'SENDING_REQUEST' })

            await fetch(`${apiUrlTranslation}?action=getEvents&params=where%20id_event!=399%20and%20id_event_ligne!=0%20and%20${filter}`)
                .then(res => res.json())
                .then(events => {
                    dispatch({ type: 'REQUEST_FINISHED' })
                    dispatch({ type: 'SET_EVENTS', payload: events });
                })

        } catch (err) {
            console.log(err)
        }
    }

    const getEventByIdEvent = async id_event => {

        try {

            dispatch({ type: 'SENDING_REQUEST' })

            if (!id_event) return

            await fetch(`${apiUrlTranslation}?action=getEvents&params=where id_event=${id_event} AND`)
                .then(res => res.json())
                .then(events => {
                    dispatch({ type: 'REQUEST_FINISHED' })
                    dispatch({ type: 'SET_EVENT', payload: events[0] });
                    document.title = events[0].nom
                })

        } catch (err) {
            console.log(err)
        }
    }

    const getEventCycleLangList = async id_event => {
        await fetch(`${apiUrlTranslation}?action=getEventCycleLangList&id_event=${id_event}`)
            .then(res => res.json())
            .then(res => {
                const filteredList = res.filter(item => !["delegation_territoire", "mega_demonstrateur", "pays_region_entreprise_invite"].includes(item.forum))
                //setEventCycleLangList(res)
                dispatch({ type: 'SET_EVENT_CYCLE_LANG_LIST', payload: res });
                //setEventCycleLangEventLigneListFiltered(filteredList)
            })
    }

    const getPartenaires = async idEvent => {

        await fetch(`${apiUrlTranslation}?action=getPartenaires&params= AND id_event=${idEvent} AND societe NOT LIKE '%test%'  `)
            .then(res => res.json())
            .then(res => {

                dispatch({ type: 'SET_EVENT_CONTACT_LIST', payload: res });
            })
    }

    useEffect(() => {

    }, [i18n.resolvedLanguage])

    return <EventContext.Provider value={{
        events: state.events,
        currentEvent: state.currentEvent,
        currentEventEventCycleLangList: state.currentEventEventCycleLangList,
        currentEventContactList: state.currentEventContactList,
        loading: state.loading,
        getEvents: getEvents,
        getEventByIdEvent: getEventByIdEvent,
        getEventCycleLangList: getEventCycleLangList,
        getPartenaires: getPartenaires

    }}>
        {props.children}
    </EventContext.Provider>





}


export default EventProvider