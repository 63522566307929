import React, { useState, useEffect } from 'react'
import Loader from '../Loader'
import useApiUrlTranslation from '../useApiUrlTranslation'
import LayoutAccount from './LayoutAccount'
import { useParams } from 'react-router-dom'
import DemoCycleLangList from './DemoCycleLangList'
import UserProgrammeItem from './UserProgrammeItem'
import NavProgramme from './NavProgramme'
import { useLocation } from 'react-router-dom';
import { API_URL } from '../../config'
import AllProgramms from './AllProgramms'



const ThematiquesByUserProgramme = ({ programme }) => {

    useEffect(() => {
        console.log(programme.cycle_lang.children)
    }

        , [])

    return (
        <div>
            <h4>Thématiques</h4>
            {programme && programme.cycle_lang.children?.map(item =>

                <div><span className="badge rounded-pill text-bg-primary d-block mb-4 p-2" style={{ width: "500px" }}>{item.nom_cycle_lang}</span>
                    <DemoCycleLangList id_cycle_lang={item.id_cycle_lang} /></div>
            )}
        </div>
    )

}

const UserProgrammes = (props) => {

    let { idContact } = useParams();
    const location = useLocation();

    const uza = idContact ? { id_contact: idContact } : JSON.parse(window.localStorage.getItem('user'))

    const [programmes, setProgrammes] = useState(null)

    const { apiUrlTranslation } = useApiUrlTranslation()

    useEffect(() => {
        const getContact = async () => {
            await fetch(`${apiUrlTranslation}?action=fetchContactCycleLang&filter= AND id_contact=211262`)
                .then(res => res.json())
                .then(all => { console.log(all); setProgrammes(all) })
        }

        const getProgrammes = async () => {
            await fetch(`${apiUrlTranslation}?action=getProgrammeInnovation&id_contact=${uza.id_contact}`)
                .then(res => res.json())
                .then(all => { setProgrammes(all); console.log(all) })
        }
        getProgrammes()

    }, [])

    return (

        <table className="table table-bordered table-sm table-striped">
            <tr>
                <td> </td>
            </tr>
            <tbody>
                {programmes ? programmes.map((item, index) => <UserProgrammeItem index={index} key={index} programme={item}>

                    {location.pathname == '/account/presta-by-user-programme' && <DemoCycleLangList id_cycle_lang={item.id_cycle_lang} getDemoDescription={true} />}
                    {location.pathname == '/account/my-programms' && <DemoCycleLangList id_cycle_lang={item.id_cycle_lang} getDemoDescription={false} />}
                    {location.pathname == '/account/thematiques-by-user-programme' && <ThematiquesByUserProgramme programme={item} />}
                    {location.pathname == '/all-programms' && <AllProgramms />}
                </UserProgrammeItem>) : <Loader />}
            </tbody>
        </table>

    )
}

export default UserProgrammes