import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API_URL } from '../../config';
import UserContext from '../../contexts/UserContext';
import { removeDuplicateObjectFromArray } from '../../utils/';
import "./SideBar.css"
import {
    CalendarIcon,
    ChartBarIcon,
    FolderIcon,
    HomeIcon,
    InboxIcon,
    UsersIcon,
    CalendarDaysIcon,
    GlobeAltIcon,
    LightBulbIcon,

    LockOpenIcon as LogoutIcon,
    UserGroupIcon,
    CpuChipIcon,
    TrophyIcon
} from '@heroicons/react/24/outline'

import { updateLocale } from 'moment/moment';
import Loader from '../Loader';
import NavEvent from '../NavEvent';
import { useParams } from "react-router-dom";
import EventContext from '../../contexts/EventContext';
import { UserIcon } from '@heroicons/react/24/solid';

const findCommonElements = (arr1, arr2) => {
    return arr1.some(item => arr2.includes(item))
}



function navActiveEvent({ currentEvent, contactConferencierList }) {

    let nav = []

    if (!currentEvent) return []

    nav = [
        { name: currentEvent.nom, href: `/account/event/${currentEvent.id_event}/`, icon: false, current: true, className: 'bg-gray-100 text-gray-900', isHeader: true },
    ]

    nav.push({ name: " - Mes rôles et engagements", href: `/account/event/${currentEvent.id_event}/roles`, icon: false, current: true })

    if (contactConferencierList && findCommonElements(contactConferencierList.map(item => item.statut.id_event_contact_type), ["207"])) {

        nav.push(
            { name: ' - Annuaire global des jurés', href: `/account/event/${currentEvent.id_event}/annuaire/jury`, icon: false, current: true },
            { name: ' - Performance de mes exposants', href: `/account/event/${currentEvent.id_event}/dashboard-pitch`, icon: false, current: true })

        nav.push({ name: "- Classement global", href: `/account/event/${currentEvent.id_event}/palmares`, icon: false, current: true })
        nav.push({ name: "- Annaire des Game Changers", href: `/account/event/${currentEvent.id_event}/annuaire/game-changers`, icon: false, current: true })
    }

    if (contactConferencierList && findCommonElements(contactConferencierList.map(item => item.statut.id_event_contact_type), ["234"])) {
        nav.push({ name: " - Mes tech'Xplorations", href: `/account/event/${currentEvent.id_event}/techxploration`, icon: false, current: true })

    }

    return nav;
}

const UpdateNavItemList = ({ navItemList, contactStatutList, contactCycleLangList,
    contactConferencierList, setNavItemList, currentEvent }) => {

    const { idEvent } = useParams();

    const userContext = useContext(UserContext);

    const { logout } = userContext;

    useEffect(() => {

        let newNavItemList = [...navItemList];

        console.log('contactStatutList', contactStatutList)

        // alert(currentEvent?.id_event);

        //console.log('contactCycleLangList', contactCycleLangList)

        /* 
        
        //programme d'innovation collectif

        contactStatutList && contactCycleLangList
              &&
              (findCommonElements(contactStatutList.map(item => item.id_statut), ["56", "80", "167"])
                  ||
                  findCommonElements(contactCycleLangList.map(item => item.id_event_contact_type), ["56", "80", "167"]))
  
              && newNavItemList.push({ name: "Mes programmes d'Innovation Collectifs", href: '/account/presta-by-user-programme', icon: FolderIcon, current: false })
  */

        contactStatutList && contactCycleLangList
            &&
            (findCommonElements(contactStatutList.map(item => item.id_statut), ["146", "228"])
                ||
                findCommonElements(contactCycleLangList.map(item => item.id_event_contact_type), ["146", "228"]))

            &&
            newNavItemList.push({ name: "Mes collectifs  & plans d'action", href: '/account/my-collectives', icon: GlobeAltIcon, current: false })

        if (contactConferencierList && findCommonElements(contactConferencierList.map(item => item.statut.id_event_contact_type), ["39", "178", "208"])) {
            newNavItemList.push({ name: 'Mes démos et solutions ', href: '/account/demos', icon: LightBulbIcon, current: false });
            newNavItemList.push({ name: "Mes attentes", href: '/account/attentes', icon: UserGroupIcon, current: false })
            newNavItemList.push({ name: "Mes demandes de mise en relation", href: '/account/networking', icon: UserGroupIcon, current: false })
        }

        if (contactConferencierList && findCommonElements(contactConferencierList.map(item => item.statut.id_event_contact_type), ["39", "40", "178", "208", "92", "143", "234"])) {
            newNavItemList.push({ name: "Mes événements", href: '/account/events', icon: CalendarDaysIcon, current: false })
        }

        if (idEvent) {

            navActiveEvent({ currentEvent, contactConferencierList }).map(item => newNavItemList.push(item))
        }

        // newNavItemList.push({ name: "Mes contacts ", href: '/account/contacts', icon: UsersIcon, current: false })

        setNavItemList(removeDuplicateObjectFromArray(newNavItemList, 'name'))

    }, [contactStatutList, contactCycleLangList, contactConferencierList, idEvent, currentEvent])

    return false
}

const nav = [

    { name: 'Mon profil', href: '/account/profil/', icon: HomeIcon, current: true },
]

const SideBar = ({ media, classNames }) => {

    const { idEvent } = useParams();

    const [contactConferencierListIsLoading, setContactConferencierListIsLoading] = useState(false);

    //    const [localNavItemList, setLocalNavItemList] = useState(JSON.parse(window.localStorage.getItem('localNavItemList')));

    const [contactStatutList, setContactStatutList] = useState(null);

    const [contactStatutListIsLoading, setContactStatutListIsLoading] = useState(false);

    const [contactCycleLangListIsLoading, setContactCycleLangListIsLoading] = useState(false);

    const [navItemList, setNavItemList] = useState(nav);

    const userContext = useContext(UserContext);

    const { getEventByIdEvent, currentEvent } = useContext(EventContext);

    const { user, userContactConferencierList, userCycleLangList, userRoleList, getUserRoles, logout } = userContext;

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const getContactStatutList = async () => {

        setContactStatutListIsLoading(true);
        await fetch(`${API_URL}?action=getContactStatutList&filter= WHERE id_contact=${user.id_contact}`)
            .then(res => res.json())
            .then(res => setContactStatutList(res))
            .then(() => setContactStatutListIsLoading(false))
    }

    const MobileNavigation = ({ navItemList }) => {

        return <nav className="space-y-1 px-2">

            {navItemList.map((item) => (
                <a
                    key={item.name}
                    href={item.href}
                    onClick={(e) => item.onClick && item.onClick(e)}
                    className={classNames(
                        item.current
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                        'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                    )}
                > {item.isHeader && <div className="text-gray-400 text-xs font-bold py-3">{item.name}</div>}
                    {item.icon && <item.icon
                        className={classNames(
                            item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                            'mr-4 flex-shrink-0 h-6 w-6'
                        )}
                        aria-hidden="true"
                    />}
                    {item.name}
                </a>
            ))}
        </nav>

    }

    const DesktopNavigation = ({ navItemList }) => {

        return <nav className="flex-1 space-y-1 px-2 pb-4">
            {navItemList.map((item) => {

                if (item.isDivider) { return <div className="border-t text-gray-400 text-xs font-bold pb-2 pt-1">{item.isDivider}</div> }

                if (item.isHeader) { return <div className="border-t  bg-pink-700 text-white px-3 text-xs font-bold pb-2 pt-3">{item.name}</div> }

                return <a
                    key={item.name}
                    href={item.href}
                    onClick={(e) => item.onClick && item.onClick(e)}
                    className={classNames(
                        item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                        'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                    )}
                >
                    {item.icon && <item.icon
                        className={classNames(
                            item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                            'mr-3 flex-shrink-0 h-6 w-6'
                        )}
                        aria-hidden="true"
                    />}
                    {item.name}
                </a>
            })}
        </nav>
    }

    useEffect(() => {
        // user && getContactConferencierList()
        user && getContactStatutList();
        //user && fetchContactCycleLang();

        user && getUserRoles(user.id_contact);

        getEventByIdEvent(idEvent)

        setNavItemList([{ name: 'Mon profil', href: '/account/profil/', icon: UserIcon, current: true }]);
    }, [])

    return <div>
        {/* 
        {JSON.stringify(userRoleList.map(item => { return { id_event_contact_type: item.statut.id_event_contact_type, libelle: item.statut.libelle } }))}
         */}
        {user &&
            !contactConferencierListIsLoading &&
            !contactStatutListIsLoading &&
            !contactCycleLangListIsLoading &&

            <UpdateNavItemList
                currentEvent={currentEvent}
                navItemList={navItemList}
                contactStatutList={contactStatutList}
                contactCycleLangList={userCycleLangList}
                contactConferencierList={userRoleList}

                setNavItemList={setNavItemList} />}

        {media = 'desktop' ? <DesktopNavigation
            navItemList={[...navItemList,
            { isDivider: true },
            { name: 'Logout', href: '#', icon: LogoutIcon, onClick: logout }]} /> :
            <MobileNavigation
                navItemList={
                    [...navItemList,
                    { isDivider: true },
                    { name: 'Logout', href: '#', icon: LogoutIcon, onClick: logout }]} />}

        {(contactCycleLangListIsLoading || contactConferencierListIsLoading || contactConferencierListIsLoading) && <Loader />}

    </div>
}

export default SideBar