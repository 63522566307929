import React, { useEffect, useState } from 'react'
import { API_URL } from '../../config';
import { arrayFiltered, removeDuplicateObjectFromArray } from '../../utils';
import DemoDescription from './DemoDescription';



const DemoCycleLangList = ({ id_cycle_lang, getDemoDescription }) => {

    const [demoCycleLangList, setDemoCycleLangList] = useState(null);
    const [demoCycleLangListDedupByIdContact, setDemoCycleLangListDedupByIdContact] = useState(null);

    const fetchDemoCycleLang = async () => {
        await fetch(`${API_URL}?action=fetchPrestaCycleLang&params= WHERE id_cycle_lang=${id_cycle_lang} OR 
        id_cycle_lang IN (SELECT id_child FROM cycle_lang_child WHERE id_cycle_lang=${id_cycle_lang})`)
            .then(res => res.json())
            .then(all => {
                setDemoCycleLangList(all)
                setDemoCycleLangListDedupByIdContact(removeDuplicateObjectFromArray(all, 'id_presta'))
            })
    }

    const displayThematiques = ({ demoCycleLang }) => {

        return demoCycleLangList.filter(item => item.presta.id_presta == demoCycleLang?.presta.id_presta && demoCycleLang.cycle_lang.cycle_lang_type != 'collectif_programme').map(item =>
            <span className="badge rounded-pill text-bg-primary"> {
                item.cycle_lang.nom_cycle_lang.length < 40 ? item.cycle_lang.nom_cycle_lang
                    : `${item.cycle_lang.nom_cycle_lang.substr(0, 40)}...`} </span>
        )
    }

    useEffect(() => {
        fetchDemoCycleLang()

    }, [])

    if (!demoCycleLangList && demoCycleLangList?.length == 0) return null;

    return (
        <tr>
            <td colSpan="2">


                <table className='table table-sm table-bordered table-hover'>

                    <thead>
                        <tr className='bg-light'>
                            <th>Société</th>
                            <th>Contact</th>
                            <th>Démo/solution</th>
                            <th>Thématiques</th>
                        </tr>
                    </thead>

                    <tbody>
                        {demoCycleLangListDedupByIdContact &&
                            demoCycleLangListDedupByIdContact
                                .filter(demoCycleLangList => demoCycleLangList.presta.id_contact != 222406)
                                .map(demoCycleLang => {

                                    const demo = demoCycleLang.presta;
                                    return <tr title={demoCycleLang.presta.id_contact}>
                                        <td style={{ width: '150px' }}>
                                            <b>{demoCycleLang.presta.contact.societe}</b><br />
                                            {demoCycleLang.presta.id_contact}
                                        </td>
                                        <td>        {demoCycleLang.presta.contact.prenom} {' '}
                                            {demoCycleLang.presta.contact.nom}
                                            <p className='text-muted small'>
                                                {demoCycleLang.presta.contact.mail} <br /> {demoCycleLang.presta.contact.port} <br />

                                            </p></td>
                                        <td style={{ width: '900px' }}>
                                            <b>  {demoCycleLang.presta.presta_nom.length < 100 ? demoCycleLang.presta.presta_nom
                                                : demoCycleLang.presta.presta_nom.substr(0, 100) + '...'}
                                            </b>
                                            {getDemoDescription && <DemoDescription demo={demo} />}


                                        </td>
                                        <td>{displayThematiques({ demoCycleLang })}</td>
                                    </tr>
                                }
                                )}
                    </tbody>
                </table>
            </td>
        </tr>
    )
}

export default DemoCycleLangList