import useApiUrlTranslation from "./useApiUrlTranslation"

const Flag = ({ src }) => {

    return <img src={src} className="w-4 h-6 ml-3" />
}

const EnableLanguages = ({ confEvent }) => {

    const { t } = useApiUrlTranslation()

    return <div className="flex text-neutral-400 text-xs">

        {/*confEvent.conf_event_type.conf_event_type_nom*/}
        {/*confEvent.conf_event_type.id_conf_event_type*/} {t('global.se_deroulera_en')} :
        {confEvent.is_langue_fr == 1 ? <Flag src="https://www.iplocate.com/assets/img/flag/128/fr.png" /> : ''}
        {confEvent.is_langue_en == 1 ? <Flag src="https://www.iplocate.com/assets/img/flag/128/gb.png" /> : ''}

    </div>
}

export default EnableLanguages;