import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import LayoutAccount from './LayoutAccount2';
import HTMLReactParser from 'html-react-parser';


const AccountPalmares = ({ getAllCandidats = true }) => {

    const { idEvent = false } = useParams();

    const [palmares, setPalmares] = useState(false);

    const getPalmares = async () => {

        //const url =`https://www.mlg-consulting.com/websites/g_event_beta/views/all_winners/index.php${idEvent ? `?id_event=${idEvent}` : ``}${getAllCandidats ? `&get_all_candidats=1` : ``}`;

        const url = `https://www.mlg-consulting.com/websites/g_event_beta/views/all_winners/index.php?id_event=${idEvent}&get_all_candidats=1`;

        await fetch(url)
            .then(res => res.json())
            .then(res => setPalmares(res))
    }

    useEffect(() => {
        getPalmares()
    }, []);


    return <LayoutAccount><div>{palmares && HTMLReactParser(palmares)}</div></LayoutAccount>
};

export default AccountPalmares;
