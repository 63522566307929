import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { API_URL } from '../config';
import useApiUrlTranslation from './useApiUrlTranslation';
import { capitalize, sortByAttribute } from '../utils';
import { useParams } from 'react-router-dom';

const RegistrationFormCountry = ({ formulaire, required, contact }) => {

    const { idEvent } = useParams();

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const { register, formState } = useFormContext();

    const [paysList, setPaysList] = useState();

    const fetchPays = async () => {

        await fetch(`${apiUrlTranslation}?action=getCountries`)
            .then((res) => res.json())
            .then((res) => { setPaysList(sortByAttribute(res, 'label')) });
    }

    useEffect(() => {
        fetchPays()
    }, [i18n.resolvedLanguage]);

    if (!paysList) return null;

    return <div className={`form-group row mb-1 py-2 ${formState.errors['pays'] ? 'bg-danger  text-dark bg-opacity-10' : ''}`}>
        <label htmlFor="pays" className="col-sm-3 col-form-label">
            {formulaire && formulaire.id_formulaire == 44 && idEvent == 2078 ? "Country" : t('form.sections.organisme_section.pays')}<span className="p-2 text-danger">*</span></label>
        <div className="col-sm-9">
            <select
                defaultValue={contact?.pays}
                {...register("pays", { required: required })}
                className='rounded-3xl  block w-full p-2 px-3 border-x border-y border-gray-400 ' id="pays">
                <option value="">{capitalize(t('form.sections.organisme_section.pays'))}</option>
                {paysList && paysList.map((pays, index) => <option key={index} value={pays.value}>{pays.label}</option>)}
            </select>
        </div>

    </div>
}

export default RegistrationFormCountry;