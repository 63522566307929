import React, { useEffect } from 'react'

import { NavTranslationLanguageSelect } from '../NavTranslation'
import useApiUrlTranslation from '../useApiUrlTranslation'
import LayoutAccount from './LayoutAccount2'

const Settings = () => {

    const { i18n } = useApiUrlTranslation()

    useEffect(() => {

    }, [i18n.resolvedLanguage])

    return (
        <LayoutAccount>

            <h3 className="text-lg font-medium leading-6 text-gray-900">Mon compte</h3>
            <p className="max-w-2xl text-sm text-gray-500 mb-4">Manage how information is displayed on your account.</p>
            <div className="w-full flex justify-between  divide-gray-200 border-t py-3">
                <div className="text-sm font-medium text-gray-500">Langue</div>
                <div> <NavTranslationLanguageSelect displayLanguageName="true" /></div>
            </div>
        </LayoutAccount>
    )
}

export default Settings