import { useEffect, useState } from "react";
import useApiUrlTranslation from "../components/useApiUrlTranslation";

const useGetCarrousel = ({ idEvent, destinationList }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [carrouselList, setCarrouselList] = useState(null);

    const [isLoading, setIsLoading] = useState(true);



    const getCarrousel = async () => {

        const destinations = destinationList && destinationList.includes(',') ? destinationList.split(',').map(dest => `emplacements LIKE '%${dest}%'`).join(' OR ') : `emplacements LIKE '%${destinationList}%'`

        await fetch(`${apiUrlTranslation}?action=getCarrousel&filter=WHERE id_event=${idEvent} AND ( ${destinations}) ORDER BY ordre_affichage ASC`)
            .then(res => res.json())
            .then(res => setCarrouselList(res))
    }

    useEffect(() => {
        getCarrousel().then(() => {
            setIsLoading(false);
        });

    }, [i18n.resolvedLanguage])

    return [carrouselList, isLoading]

}

export default useGetCarrousel;