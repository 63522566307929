import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Moment from 'react-moment'
import 'moment-timezone';

const RemainTime = styled.div`
text-align: center;
padding: 10px;
 
 
margin: 2px;
`
const RemainTimeContainer = styled.div`
display: flex;

`
const RemainTimeData = styled.div`
font-weight: 800;
 
`
const RemainTimeLibelle = styled.div`
font-size: 15px;
 
`

const DisplayTime = ({ data, libelle, big }) => {

    return <RemainTime className="dark:bg-neutral-800/10 p-3 rounded-full w-4/4 bg-pink-600">

        <RemainTimeData big={big} className="text-white text-5xl lg:text-8xl ">{data}</RemainTimeData>
        <RemainTimeLibelle className="text-white">{libelle}</RemainTimeLibelle>
    </RemainTime>
}

const EventTimer = ({ date, heure_debut, ...props }) => {

    const { t, i18n } = useTranslation();

    const [timer, setTimer] = useState();
    const [remain, setRemain] = useState({ jours: '', hours: '', minutes: '', seconds: '' })

    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;
    const birthday = `${date}T${heure_debut.replace("h", ":")}`;
    const countDown = new Date(birthday).getTime();

    useEffect(() => {

        let intervalId;

        intervalId = setInterval(() => {

            const now = new Date().getTime();
            const distance = countDown - now;
            const newDay = Math.floor(distance / (day))
            const hours = Math.floor((distance % (day)) / (hour))
            const minutes = Math.floor((distance % (hour)) / (minute))
            const seconds = Math.floor((distance % (minute)) / second)

            setRemain({ jours: newDay, hours: hours, minutes, seconds })

        }, second);

        return () => clearInterval(intervalId);
    }, [timer, heure_debut])

    return (
        <div className=''>
            {props.children}
            <RemainTimeContainer className=' justify-content-center'>
                {remain.jours > 0 && <DisplayTime big={props.big} data={remain.jours} libelle='jour' />}
                {remain.hours > 0 && <DisplayTime big={props.big} data={remain.hours} libelle='Hr' />}
                <DisplayTime big={props.big} data={remain.minutes} libelle='Min' />
                <DisplayTime big={props.big} data={remain.seconds} libelle='Sec' />

                {/*
                <Moment tz={props.timezone} className="text-danger">
                </Moment>
                */}

            </RemainTimeContainer>
        </div>
    )
}

export default EventTimer