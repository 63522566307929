import React, { useState } from "react"

import { useForm, FormProvider, useFormContext } from 'react-hook-form';

import Layout from "./Layout"

const FormComponent = ({ children }) => {
    const methods = useForm();

    const onSubmit = data => {
        console.log(data);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                {children}
                <button type="submit">Submit</button>
            </form>
        </FormProvider>
    );
};

const ChildComponent = () => {
    const { register, watch } = useFormContext();
    const watchedValue = watch("inputName"); // Remplacez "inputName" par le nom de votre champ

    return (
        <div>
            <input {...register("inputName")} placeholder="Type something..." />
            <p>Watched Value: {watchedValue}</p>
        </div>
    );
};




function TestWatch() {

    const {
        register,
        watch,
        formState: { errors },
        handleSubmit,
    } = useForm();


    const watchShowAge = watch("showAge", false) // you can supply default value as second argument
    const watchAllFields = watch() // when pass nothing as argument, you are watching everything
    const watchFields = watch(["showAge", "age", "prenom", 'inputTest']) // you can also target specific fields by their names

    const inputName = watch("inputName");

    // Callback version of watch.  It's your responsibility to unsubscribe when done.
    React.useEffect(() => {
        const subscription = watch((value, { name, type }) =>
            console.log(value, name, type)
        )
        return () => subscription.unsubscribe()
    }, [watch, inputName])

    const onSubmit = (data) => console.log(data)

    return (
        <Layout>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="bg-green-500 p-5">{JSON.stringify(watchFields)}</div>


                <div className="bg-blue-200 p-5">inputName : {inputName}</div>

                <input type="checkbox" {...register("showAge")} />

                {/* based on yes selection to display Age Input*/}
                {watchShowAge && (
                    <input type="number" {...register("age", { min: 50 })} />
                )}

                <input type="text" {...register("prenom")} />

                <input type="submit" />
            </form>

            <FormComponent>
                <ChildComponent />

                <input type="text"  {...register("inputTest")} placeholder="Type something..." />

            </FormComponent>

        </Layout>
    )
}

export default TestWatch