import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const FormInputTextareaRichEditor = ({
    setObject,
    object,
    fieldName,
    fieldValue,
    handleSubmit,
    defaultValue }) => {

    return <><CKEditor

        editor={ClassicEditor}
        data={fieldValue}
        onReady={editor => {
            //  store the "editor" and use when it is needed.
            //console.log('Editor is ready to use!', editor);
        }}
        onChange={(event, editor) => {

            const data = editor.getData();
            const updatedObject = { ...object, [fieldName]: data }

            setObject(updatedObject)
            //console.log(updatedObject)

            // console.log("wowwwwwww" + data)

            handleSubmit && handleSubmit({ data, object, fieldName })
            // console.log({ event, editor, data, contact, contactUpdated });
        }}
        onBlur={(event, editor) => {
            //console.log('Blur.', editor);
        }}
        onFocus={(event, editor) => {
            //console.log('Focus.', editor);
        }}
    /></>
}

export default FormInputTextareaRichEditor