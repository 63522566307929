import React, { useEffect, useState } from "react";
import { API_URL } from "../../config";
import ThematiqueContacts from "./ThematiqueContacts";

const Thematique = ({ child }) => {

  const handleSubmit = async (nom_cycle_lang) => {
    const data = {
      cycleLang: {
        nom_cycle_lang: nom_cycle_lang,
        id_cycle_lang: child.id_cycle_lang,
      },
    };

    await fetch(
      `${API_URL}?action=updateCycleLang`,
      {
        method: "POST",
        body: JSON.stringify(data),
      }
    );
  };

  return (
    <div className="m-2">
      <input
        type="text"
        className="form-control"
        defaultValue={child.nom_cycle_lang}
        onChange={(e) => handleSubmit(e.target.value)}
      />

      <ThematiqueContacts cycle_lang={child} />
    </div>
  );
};

function UserProgrammeThrematiques2({ id_cycle_lang }) {

  const [thematique, setThematique] = useState();
  const [newThematique, setNewThematique] = useState("");

  const getCycleLangByIdCycleLang = async () => {
    await fetch(`${API_URL}?action=getCycleLangByIdCycleLang&id_cycle_lang=${id_cycle_lang}`)
      .then((res) => res.json())
      .then((res) => {
        setThematique(res);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updateCycleLangChild = async (id_child) => {
      await fetch(`${API_URL}?action=updateCycleLangChild&id_child=${id_child}&idcl=${id_cycle_lang}`,
        {
          method: "POST",
        }
      ).then((res) => getCycleLangByIdCycleLang());
    };

    const formData = {
      nom_cycle_lang: newThematique,
      cycle_lang_type: "thematique",
    };

    await fetch(`${API_URL}?action=createCycleLang&nom_cycle_lang=${newThematique}&cycle_lang_type=thematique`,
      {
        method: "POST",
        body: JSON.stringify(formData),
      }
    )
      .then((res) => res.json())
      .then((id_child) => updateCycleLangChild(id_child));
  };

  const handleChange = (e) => {
    setNewThematique(e.target.value);
  };

  useEffect(() => {
    getCycleLangByIdCycleLang();
  }, []);

  return (
    <div>


      <div className="card">
        <div className="card-header">

          <form
            className="m-2"
            id="form_user-programme-thematique"
            onSubmit={(e) => handleSubmit(e)}
          >
            <div>
              <label>
                <span className="m-2 pb-2">Ajouter une nouvelle thématique</span>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  onChange={(e) => handleChange(e)}
                  value={newThematique}
                />
              </label>
              <input className="btn btn-primary m-2 mb-3" type="submit" value="Ajouter" />
            </div>
          </form>
        </div>

        <table className="table table-sm table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>Thématique</th>
            </tr>
          </thead>
          <tbody>
            {thematique &&
              thematique?.children?.map((child, i) => (
                <tr>
                  <th>{i + 1}</th>
                  <td><Thematique key={child.id_cycle_lang} child={child} /></td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default UserProgrammeThrematiques2;
