import React from 'react'
import './PageSection.css';

const PageSection = (props) => {

    const style = { ...props.style, borderBottom: "" }

    return <section className={`${props.className} page-section py-5 dark:bg-neutral-950`} style={style} >
        {props.title && <h1
            className={` text-3xl lg:text-6xl  animatedgradient  mb-2 text-center  ${props.darkmode ? `dark:text-white` : ''}`}
        >{props.title}</h1>}
        {props.children} </section >
}

export default PageSection
