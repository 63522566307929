import React from 'react'
import { BreadcrumbsParcours } from './Breadcrumbs'
import LayoutAccountEvent from './LayoutAccountEvent'

const UserEventJuryEvents = () => {
    return (
        <LayoutAccountEvent>
            <BreadcrumbsParcours />
            <div class="flex flex-row mb-10">

                <div class="basis-1/2">
                    <button className='btn btn-primary'>Positionner ma démo sur un parcours de visite</button></div>
            </div>

            <table className='table'>
                <thead>
                    <tr>
                        <th>Horaire</th>
                        <th>Nom</th>
                    </tr>
                </thead>
            </table>
        </LayoutAccountEvent>

    )
}

export default UserEventJuryEvents