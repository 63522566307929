
const NavBeta = () => {

    return (
        <>
        </>
    );
}

export default NavBeta;
