import darkModeContext from "./contexts/DarkModeProvider";
import App from './App';
import { useContext } from "react";
import DarkModeProvider from "./contexts/DarkModeProvider";




const Root = () => {


    return (
        <DarkModeProvider>

            <App />

        </DarkModeProvider>
    );
};

export default Root;