import React, { useState, useEffect } from 'react'
import Layout from './Layout'
import useApiUrlTranslation from './useApiUrlTranslation';
import { useParams } from 'react-router-dom';
import { Divider } from '@material-ui/core';
import { sortByAttribute } from '../utils';

const Ecosystem = () => {

    const { idEvent } = useParams();

    const { i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [ecosystem, setEcosystem] = useState(false);

    const EcosystemItem = ({ item }) => {

        return <div className='border-2 border-neutral-800  hover:border-neutral-700  rounded flex flex-col justify-between'>

            <div className='flex flex-col justify-center p-5'>

                {item.contact.logos.small && <div className='flex justify-center'><img src={item.contact.logos.small} alt={item.contact.societe} /></div>}
            </div>
            <div className='h-200 bg-neutral-900 p-3'>
                <span className='text-white font-bold uppercase'>{item.contact.societe}</span>
                <p className='text-neutral-500 text-xs'>{item.contact.mail} <br />{item.contact.port}</p>
            </div>
        </div>
    }

    const getPartenaires = async () => {

        await fetch(`${apiUrlTranslation}?action=getPartenaires&params= AND id_event=${idEvent} AND societe NOT LIKE '%test%' GROUP BY societe`)
            .then(res => res.json())
            .then(res => {

                res.sort((a, b) => a.contact.societe.localeCompare(b.contact.societe));

                setEcosystem(res);
            })
    }

    useEffect(() => {
        getPartenaires();
    }, [i18n.resolvedLanguage])

    return (
        <Layout className="bg-neutral-950">
            <div className='grid grid-cols-1 md:grid-cols-6 gap-4 flex-wrap'>
                {
                    ecosystem && ecosystem.map((item, index) => <EcosystemItem key={index} item={item} />)
                }
            </div>
        </Layout>
    )
}

export default Ecosystem