import React from 'react'
import { Link } from 'react-router-dom'
import DemoCycleLangList from './DemoCycleLangList'

const UserProgrammeItem = (props) => {

    return <><tr className="programme-item table-dark" title={props.programme.id_cycle_lang}>
        <td>
            <img src={`//www.mlg-consulting.com/manager_cc/docs/archives/${props.programme.logo}`} alt="" style={{ "width": '50px' }} className="pe-3" />
            {props.programme.programm_title} </td>
        <td><Link to={`programmes/${props.programme.id_demande_referencement_programme_innovation}`} className="text-white"><i className="fas fa-cog"></i> éditer</Link> </td>
    </tr>

        {props.children}
    </>
}


export default UserProgrammeItem