import React, { useEffect } from 'react';

const LinkedInCallback = () => {
    useEffect(() => {
        const code = new URLSearchParams(window.location.search).get('code');

        if (code) {
            getAccessToken(code);
        }
    }, []);

    const getAccessToken = async (code) => {
        try {
            const response = await fetch('https://www.linkedin.com/oauth/v2/accessToken', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    grant_type: 'authorization_code',
                    code: code,
                    redirect_uri: 'http://localhost:3000/linkedin-redir',
                    client_id: '78eqa2rddcgy4s',
                    client_secret: '78eqa2rddcgy4s'
                })
            });

            const data = await response.json();
            console.log(data.access_token);
            // Utilisez le token pour faire des requêtes à l'API LinkedIn
        } catch (error) {
            console.error('Erreur lors de l’obtention du token d’accès', error);
        }
    };

    return (
        <div>Connexion LinkedIn en cours...</div>
    );
};

export default LinkedInCallback;
