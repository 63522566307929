import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { API_URL } from '../config'
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import PageSection from './PageSection';
import { useTranslation } from 'react-i18next';
import PartenairesLogoSlider from './PartenairesLogoSlider';

const EventPlanning = () => {

    const { idCycleLang } = useParams();

    const [cycleLangEventLigneList, setCycleLangEventLigneList] = useState([]);

    useEffect(() => {

        const getCycleLangEventLigne = async () => {

            await fetch(`${API_URL}?action=getCycleLangEventLigne&params= WHERE id_cycle_lang=${idCycleLang} `)
                .then(res => res.json())
                .then(res => setCycleLangEventLigneList(res))
        }

        idCycleLang && getCycleLangEventLigne()

    }, [])

    const primaryOptions = {
        type: "loop",
        perPage: 4,
        breakpoints: {
            1000: {
                perPage: 2,
            },
            500: {
                perPage: 1,
            },
        },
        perMove: 1,
        pagination: false,
        gap: 10,
        autoplay: true
    };

    return (
        <PageSection title={`Une  seule pré-sélection pour ${cycleLangEventLigneList.length}  événements annuels`}>
            <div class="px-4 py-3  text-center">
                <div class="mx-auto">
                    <p class="lead mb-4">Inscrivez vous gratuitement à l’une des sessions annuelles de pré-sélections et soyez visibble toute l’année sur les événements et les plans d’actions collaboratifs de myGlobalVillage</p>
                </div>
            </div>
            {
                cycleLangEventLigneList ? (

                    <Splide options={primaryOptions}>

                        {cycleLangEventLigneList && cycleLangEventLigneList.map(item => <SplideSlide>
                            <div className="card">
                                <img src={`https://www.mlg-consulting.com/manager_cc/docs/archives/${item.id_event_ligne.el_visuel}`} alt="" />
                            </div>
                        </SplideSlide>)}

                    </Splide>) : ('')
            }

        </PageSection>)

}

const PlanAction = () => {

    const { composant, idCycleLang } = useParams();

    const { t, i18n } = useTranslation();

    const [cycleLang, setCycleLang] = useState(null);

    const Header = () => {

        if (!cycleLang) return null;

        return <div>
            <img src={`${cycleLang.global_challenge_header.large}`} alt="" style={{ width: '100%' }} />
        </div>

    }



    useEffect(() => {

        const getCycleLangByIdCycleLang = async () => {

            await fetch(`${API_URL}?action=getCycleLangByIdCycleLang&id_cycle_lang=${idCycleLang} `)
                .then(res => res.json())
                .then(res => setCycleLang(res))
        }

        idCycleLang && getCycleLangByIdCycleLang()

    }, [])



    return (
        <div className='bg-light' >

            <div className='container bg-white' >

                <Header />

                <div className='row'>
                    <div className="col-md-3">
                        <img style={{ width: '100%' }} src="https://www.mlg-consulting.com/manager_cc/docs/archives/220504170415_plan-d-actions-icc-10.png" alt="" />
                    </div>
                    <div className="col-md-9 my-auto">
                        <p style={{ fontSize: '30px' }}>Inscrivez vous gratuitement à l’une des sessions annuelles de pré-sélections et soyez visibble toute l’année sur les événements et les plans d’actions collaboratifs de myGlobalVillage</p></div>
                </div>


                <EventPlanning />
                {/*
                <PageSection title="Partenaires" style={{ background: "#f7f8fa" }}>
                    <h3>Les décideurs, élus, intégrateurs et investisseurs majeurs du secteur interviennent et soutiennent mygloblaVillage</h3>
                </PageSection>
                */ }

                <PageSection title="Ils soutiennent myGlobalVillage">
                    <PartenairesLogoSlider
                        displayContactsCycleLang
                        params={` AND cf.statut IN('partenaire_national', 'partenaire_demonstrateur') AND id_event='' GROUP BY c.id_contact`} />
                </PageSection>
            </div>
        </div>
    )
}

export default PlanAction