

import React, { useEffect, useState } from 'react';
import useApiUrlTranslation from './useApiUrlTranslation';

const ContactCycleLangSection = ({ idCycleLangList }) => {

    const { i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [contactCycleLangList, setContactCycleLangList] = useState(null);

    const getContactCycleLang = async (idCycleLangList) => {

        await fetch(`${apiUrlTranslation}?action=getContactCycleLang&filter=id_cycle_lang IN(${idCycleLangList})`)
            .then(res => res.json())
            .then(res => setContactCycleLangList(res))
    }

    useEffect(() => {

        getContactCycleLang(idCycleLangList);

    }, [i18n.resolvedLanguage]);


    return (
        <div className="col-start-2 row-start-1 row-end-3 sm:mt-4 lg:mt-0 xl:mt-4 flex-wrap ">

            <div className="font-bold mt-5 my-3">Annuaire des membres de mon ecosystem</div>
            <dt className="sr-only">Tire</dt>
            <dd className="flex justify-end sm:justify-start lg:justify-end xl:justify-start -space-x-1.5" >

                {contactCycleLangList && contactCycleLangList
                    //deduplicate by contact.id_contact
                    .filter((contact, index, self) =>
                        index === self.findIndex((t) => (
                            t.contact.id_contact === contact.contact.id_contact
                        ) && contact.contact.id_contact != 607

                        )
                    )
                    .map(contactCycleLang => (

                        <img key={contactCycleLang.id_contact_cycle_lang} src={contactCycleLang.contact.photos.small} alt="" className="w-20 h-20 object-cover  rounded-full bg-slate-100 ring-2 ring-white dark:ring-slate-700 dark:group-hover:ring-white" loading="lazy" decoding="async" />
                    ))}
            </dd>
        </div >
    )
}

export default ContactCycleLangSection;