import React, { useState, useEffect } from "react";

/* 
import { MapContainer, Marker, Popup, TileLayer, LayersControl, LayerGroup } from "react-leaflet";
import { Icon } from "leaflet";
import * as parkData from "../resources/skateboard-parks.json";
import MapSmarterPoint from "./MapSmarterPoint"
import MapSmarterPointPlace from "./MapSmarterPointPlace"

const MapSmarter = () => {


    const [collectifs, setCollectifs] = useState([])

    const [isPosLoading, setIsPosLoading] = useState(false)

    const [lieuxList, setLieuxList] = useState();
    const [isLoadPos, setIsLoadPos] = useState(false)

    const fetchCollectif = async () => {

        setIsPosLoading(true);

        await fetch(`https://www.mlg-consulting.com/smart_territory/form/api.php?action=getCycleLang`)
            .then(res => res.json())
            .then(result => {
                const collectifGeographiqueList = result.filter(collectif => collectif.cycle_lang_type == 'collectif_geographique');
                setCollectifs(collectifGeographiqueList)
                console.log(collectifGeographiqueList)
                setIsPosLoading(false);
            })
    }

    const fetchLieux = async () => {

        setIsLoadPos(true);

        await fetch(`https://www.mlg-consulting.com/smart_territory/form/api.php?action=fetchLieux&req= WHERE connected_studio=1`)
            .then(res => res.json())
            .then(lieux => {
                setLieuxList(lieux)
                setIsLoadPos(false)
            })
    }


    useEffect(() => {
        fetchCollectif();
        fetchLieux();
    }, [])
    return (
        <>
            <h1 className="text-center my-5">
                <img style={{ width: '50px' }} src="//www.mlg-consulting.com/manager_cc/docs/archives/201124183158_pack-commerciaux-territory-fr-40.png" />
                Suivi en temps réel du déploiement des studios connectés</h1>
            {!isPosLoading && <MapContainer center={[48.858370, 0.294481]} zoom={3}>

                <LayersControl position="topright">
                    <LayersControl.BaseLayer checked name="OpenStreetMap.Mapnik">
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="OpenStreetMap.BlackAndWhite">
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                        />
                    </LayersControl.BaseLayer>

                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors <a href="http://www.mlg-consulting.com">MLG CONSULTING</a>'
                    />

                    <LayersControl.Overlay checked name="Collectifs">
                        <LayerGroup>
                            {collectifs.map(collectif => <MapSmarterPoint key={collectif.id_cycle_lang} collectif={collectif} />)}
                        </LayerGroup>
                    </LayersControl.Overlay>

                    <LayersControl.Overlay checked name="Studios connectés">
                        <LayerGroup>
                            {lieuxList && lieuxList.map(lieu => <MapSmarterPointPlace key={lieu.id_lieu} lieu={lieu} />)}
                        </LayerGroup>
                    </LayersControl.Overlay>
                </LayersControl>
            </MapContainer>}
        </>
    )
}

export default MapSmarter

*/

const MapSmarter = () => { return "" }

export default MapSmarter;