import React, { useRef, useState, useEffect, useContext } from 'react'
import Layout from '../Layout'
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import LoginCheckUser from './LoginCheckUser';
import { NavTranslationLanguageSelect } from '../NavTranslation';
import useApiUrlTranslation from '../useApiUrlTranslation';
import HTMLReactParser from 'html-react-parser';
import EventContext from '../../contexts/EventContext';
function useQuery() {

    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const LoginRedirect = ({ match, location }) => {

    const [redirectUrl, setRedirectUrl] = useState(null);

    const [idEvent, setIdEvent] = useState(null);

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [formulaire, setFormulaire] = useState(null);


    const eventContext = useContext(EventContext);

    const { getEventByIdEvent, currentEvent } = eventContext;



    let query = useQuery();

    const getFormulaireList = async ({ formName }) => {

        await fetch(`${apiUrlTranslation}?action=getFormulaireList&params=WHERE route="${formName}"`)
            .then(res => res.json())
            .then(res => {
                console.log(res[0]);
                setFormulaire(res[0])
            })
    }


    const idEventSetter = () => {
        const url = 'https://www.myglobalvillage.com/events/1789/signup/techprovider/c/{id_contact}?formisvisible=1';

        const regex = /\/events\/(\d+)/; // L'expression régulière capture un ou plusieurs chiffres après "/events/"

        const match = url.match(regex); // Cherche la correspondance de l'expression régulière dans l'URL

        if (match) {
            const idEvent = match[1]; // Le chiffre capturé se trouve dans le groupe de capture 1
            setIdEvent(idEvent);
            console.log(idEvent); // Affiche "1789" dans la console

        } else {
            console.log("Aucune correspondance trouvée.");
        }


    }
    useEffect(() => {

        const redirectUrl = query.get("redirectUrl")

        const formName = query.get("form-name")

        const url = window.location.href;

        const regex = /redirectUrl=(.*)/; // L'expression régulière capture un ou plusieurs chiffres après "/events/"

        const match = url.match(regex); // Cherche la correspondance de l'expression régulière dans l'URL

        if (match) {

            setRedirectUrl(match[1]);
        } else {
            console.log("Aucune correspondance trouvée.");
        }

        getFormulaireList({ formName })

    }, [i18n.resolvedLanguage, idEvent]);

    return (
        <Layout className="bg-white">


            {
                formulaire &&

                <div >

                    <div className='flex items-center justify-center mx-auto rounded mt-2bg-gray-200 py-2 '>
                        {t('global.select_langue')} <NavTranslationLanguageSelect />
                    </div>
                    <div className="max-w-2xl mx-auto  ">
                        <div className="flex items-center justify-center mx-auto">
                            {formulaire.visuel && <img src={formulaire.visuels.medium} className='w-20 mr-5' />}
                            <div className="text-2xl font-bold py-3">  {formulaire.page_title ?? formulaire.name}</div>
                        </div>
                        <div className='pt-5 d-none'>
                            {HTMLReactParser(formulaire.page_subtitle)}
                        </div>
                    </div>
                </div>
            }

            <LoginCheckUser query={query} redirectUrl={redirectUrl} />
            <div className='py-5 my-5'></div>
        </Layout>
    )
}

export default LoginRedirect