import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../config";
import ContactCard from "./ContactCard";

function ContactList({ statut, title, displayNumber, showFlag, displayMode }) {

  let { idEvent } = useParams();
  let { id_conf_event } = useParams();

  console.log(displayNumber)
  statut = statut.split(",");

  const statutList = [
    "candidat-pitch",
    "offreur_de_solution",
    "charge_organisation",
    "chroniqueur_tv",
    "referent-lieu",
    "curateur",
    "demande_accreditation_presse",
    "edito",
    "intervenant",
    "jury",
    "92",
    "coordinateur_jury",
    "partenaire_media",
    "president_collectif",
    "president_forum",
    "president_programme",
    "president_jury",
    "participant",
    "pack",
    "tete_affiche",
  ];

  const currentStatutList = statutList.filter((statutItem) =>
    statut.includes(statutItem),
  );

  const [contactList, setContactList] = useState([]);

  const fetchContactList = async () => {
    const req = `getContactConferencierList&filter=%20and%20id_event=${idEvent} AND id_conf_event=${id_conf_event}`;

    await fetch(
      `${API_URL}?action=${req}`
    )
      .then((res) => res.json())
      .then((contactEvent) => {
        const filteredContactEvent = contactEvent.filter((contact) =>
          currentStatutList.includes(contact.conferencier_statut)
        );
        researchInfoContact({ infoContactEvents: filteredContactEvent });
      });
  };

  const researchInfoContact = ({ infoContactEvents }) => {
    let uniqueIdInfoContactEvents = [
      ...new Set(
        infoContactEvents.map(
          (infoConctactEvent) => infoConctactEvent.id_contact
        )
      ),
    ];

    const filteredUniqueIdInfoContactEvents = uniqueIdInfoContactEvents.filter(
      (uniqueIdInfoContactEvent) => uniqueIdInfoContactEvent !== ""
    );

    setContactList(filteredUniqueIdInfoContactEvents);
  };

  useEffect(() => {
    fetchContactList();
  }, []);


  if (contactList.length === 0) {

    return false
  }

  return (
    <div>
      <section className="contact-list-fix__container ">
        <p className="text-5xl py-3 my-3  animatedgradient font-extrabold">{title}</p>

        <div className={`grid grid-cols-2 lg:grid-cols-5 gap-2`}>
          {contactList
            ? contactList.map((idContact) => (
              <ContactCard
                idContact={idContact}
                statut={statut}
                displayNumber={displayNumber}
                displayMode={displayMode}
                showFlag={showFlag}
              />
            ))
            : "Loading"}
        </div>
      </section>
    </div>
  );
}

export default ContactList;
