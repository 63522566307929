import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import useApiUrlTranslation from './useApiUrlTranslation';
import { useParams } from "react-router-dom";
import HTMLReactParser from 'html-react-parser';

const Page = () => {

    const [eventPresta, setEventPresta] = useState({ texte_contextuel: "" });
    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const { idEvent, idPage } = useParams();

    const getEventPrestas = async () => {

        await fetch(`${apiUrlTranslation}?action=getEventPrestas&filter= WHERE id_event_presta = ${idPage} `)
            .then(res => res.json())
            .then(res => {
                setEventPresta(res[0])
            })
    }


    useEffect(() => {
        getEventPrestas()
    }, [i18n.resolvedLanguage])


    return (
        <Layout>
            {HTMLReactParser(eventPresta.texte_contextuel)}
        </Layout>
    )
}

export default Page