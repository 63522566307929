import HTMLReactParser from 'html-react-parser';
import React, { useContext, useEffect, useState } from 'react'
import { API_URL } from '../config';
import Layout from './Layout'
import EventContext from '../contexts/EventContext';

const DemonstrateurPresentation = () => {

    const eventContext = useContext(EventContext);
    const { currentEvent } = eventContext;

    const [eventEventComposantList, setEventEventComposantList] = useState([]);

    useEffect(() => {

        const getEventEventComposant = async () => {

            await fetch(`${API_URL}?action=getEventEventComposant&req_suite= WHERE id_event=${currentEvent.id_event} AND id_event_composant=3 `)
                .then(res => res.json())
                .then(res => setEventEventComposantList(res))
        }

        currentEvent && getEventEventComposant();

    }, [currentEvent])


    return (
        <Layout title="">
            <div className="mx-auto mt-0 max-w-7xl  sm:mt-24 sm:px-6">
                {eventEventComposantList.map(eventEventComposant => <div className='mb-5'>
                    <h2 className='flex mb-3'>
                        <img src={eventEventComposant.visuel} alt="" className='mr-3 w-10 h-10' />{eventEventComposant.event_composant_nom}</h2>
                    <div className='mt-2 ml-16 text-base text-gray-500'>
                        {HTMLReactParser(eventEventComposant.descriptif != '' ? eventEventComposant.descriptif : eventEventComposant.event_composant.descriptif)}</div>
                </div>)}
            </div>
        </Layout>
    )

}

export default DemonstrateurPresentation