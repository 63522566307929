import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import EventTimer from "./EventTimer";
import parse from 'html-react-parser';
import useApiUrlTranslation from './useApiUrlTranslation';
import moment from 'moment';

const ConfEvent = ({ confEvent, displayReplay, setActiveConfEvent }) => {

    const formatedDate = `${confEvent.conf_event_date} ${confEvent.heure_debut.replace('h', ':')}`;

    let buttonText, buttonClass, display;

    const title = confEvent.conf_event_lang?.cel_titre;

    const formatedCelTitre = title.length > 100 ? `${title.substring(0, 100)}...` : title;

    if (moment(formatedDate).isAfter()) {
        buttonText = ' Replay';
        buttonClass = 'outline-secondary';
        display = !displayReplay ? "none" : "block";

    } else {
        buttonText = 'Lecture';
        buttonClass = 'danger';
        display = !displayReplay ? "block" : "none";
    }

    if (moment(formatedDate).isBefore()) {
        // return false // on ne montre pas les conférences passées
    }

    return <div className="hover:bg-neutral-800 bg-neutral-900 m-1 rounded flex " style={{ cursor: "pointer", textAlign: "start" }} onClick={() => { setActiveConfEvent(confEvent); }}>
        <div className="basis-1/6"><img src={`https://img.youtube.com/vi/${confEvent.youtube_id}/default.jpg`} alt="" style={{ width: "100%" }} /></div>
        <div className='p-2 basis-5/6'>
            {parse(formatedCelTitre)}<br />
            <small className="text-neutral-500">{confEvent.conf_event_jour}/{confEvent.conf_event_mois}/{confEvent.conf_event_an} - {confEvent.heure_debut} - {confEvent.heure_fin}</small>
            {moment(formatedDate).isAfter() && <EventTimer
                date={confEvent.conf_event_date}
                timezone={confEvent.salle.id_lieu.timezone}
                small
                heure_debut={confEvent.heure_debut} />}
        </div>
    </div>
}

const VideoPlanning = ({ displayReplay = false }) => {

    let { idEvent } = useParams();

    const [confEventList, setConfEventList] = useState(null)

    const [loading, setLoading] = useState(false);

    const [activeConfEvent, setActiveConfEvent] = useState();

    const { i18n, apiUrlTranslation } = useApiUrlTranslation();

    useEffect(() => {

        const fetchConfEventList = async (id_event = 1703) => {

            setLoading(true);

            const req = `getConfEvent`;

            const reqSuiteReplay = displayReplay === true ? "" : '';

            await fetch(`${apiUrlTranslation}?action=${req}&id_event=${id_event}&filter=AND youtube_id !='' ${reqSuiteReplay} `)
                .then(res => res.json())
                .then(res => {
                    setConfEventList(res);
                    setActiveConfEvent(res[0]);
                    setLoading(false);
                })
        }

        console.log('confEventList', confEventList)
        fetchConfEventList(idEvent);
    }, [displayReplay, idEvent, i18n.resolvedLanguage])

    if (confEventList === null) {

        return ' A venir';
    }

    return <div id="videoPlayerCol" className="bg-neutral-950">

        <div className="row mx-2 py-2">

            <div className="col-md-8">

                {activeConfEvent && <div>
                    <iframe id="videoPlayer" width="100%" height="600"
                        src={`https://www.youtube.com/embed/${activeConfEvent.youtube_id}?autoplay=0`}
                        title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    <h6 className="mt-2">Détails</h6>
                    <div className=" my-2 p-2  rounded">
                        <h3 className="text-left pb-3">{parse(activeConfEvent.conf_event_lang.cel_titre)}</h3>
                        <div className="text-left text-neutral-100">{parse(activeConfEvent.conf_event_lang.cel_texte)}</div>
                    </div>
                </div>}

                {!activeConfEvent && <div className="d-flex align-items-center justify-content-sm-center border bg-dark text-white rounded " style={{ minHeight: "55vh" }}> <i className="fas fa-video" style={{ marginBottom: "13px", paddingRight: "7px" }}></i>  <p>Des replays de nos conférences arrivent !</p>    </div>}
            </div>

            <div className="col-md-4">
                <div className=" rounded" style={{ maxHeight: "600px", overflowY: "scroll" }}>


                    {confEventList.length > 0 && confEventList.map(confEvent => {

                        const formatedDate = `${confEvent.conf_event_date} ${confEvent.heure_debut.replace('h', ':')}`;

                        const limit = moment().subtract(2, "hour");

                        if (moment(formatedDate).isBefore(limit)) {
                            return false
                        }

                        return <ConfEvent
                            key={confEvent.id_conf_event}
                            confEvent={confEvent}
                            displayReplay
                            setActiveConfEvent={setActiveConfEvent} />
                    })}


                    { /*<h2>Replay</h2>
                    <table className="table table-sm">

                        <tbody id="confEventList">

                            {confEventList.length > 0 && confEventList.map(confEvent => {

                                const formatedDate = `${confEvent.conf_event_date} ${confEvent.heure_debut.replace('h', ':')}`;

                                if (moment(formatedDate).isAfter()) {
                                    return false
                                }
                                return <ConfEvent
                                    key={confEvent.id_conf_event}
                                    confEvent={confEvent}
                                    displayReplay
                                    setActiveConfEvent={setActiveConfEvent} />
                            })}
                        </tbody>
                        </table>*/}
                </div>
            </div>

        </div>
    </div>


}

export default VideoPlanning;
