import React, { useEffect, useState } from "react";
import { API_URL } from "../config";
import EventCard from "./EventCard";
import Layout from "./Layout";

function Archives() {
  const [eventList, setEventList] = useState();

  const fetchPartenaires = async (year) => {
    await fetch(`${API_URL}?action=getEvents&params=where event_an = ${year} AND`)
      .then((res) => res.json())
      .then(
        (res) =>
          { 
            const events = res.filter(event => event.afficher === "y" && (event.event_ligne.id_event_format.id_event_format === "1" || event.event_ligne.id_event_format.id_event_format === "4" || event.event_ligne.id_event_format.id_event_format === "5") ).sort((a, b) => {return b.event_mois - a.event_mois;})
            setEventList(events)
          }
      );
  };

  useEffect(() => {}, []);

  return (
    <Layout>
      <div className="container">
        <div className="d-flex flex-row justify-content-around p-3">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              fetchPartenaires(2021);
            }}
          >
            2021
          </button>
          <button
            type="button"
            class="btn btn-primary"
            onClick={() => {
              fetchPartenaires(2020);
            }}
          >
            2020
          </button>
          <button
            type="button"
            class="btn btn-primary"
            onClick={() => {
              fetchPartenaires(2019);
            }}
          >
            2019
          </button>
        </div>

        {eventList
          ? eventList.map((event, i) => <EventCard key={i} event={event} />)
          : ""}
      </div>
    </Layout>
  );
}

export default Archives;