import React, { useState, useParams, useEffect } from 'react'
import { API_URL } from '../config';
import { capitalize } from '../utils'
import './ContactsPhotoSlider.css'


const ContactsAnnuaire = () => {

    const [contacts, setContacts] = useState([]);

    let { idEvent } = useParams();

    const fetchContacts = async () => {

        await fetch(`${API_URL}?action=getContactConferencierList&filter= AND id_event = ${idEvent} GROUP BY CONCAT(prenom, nom) LIMIT 12`)
            .then(res => res.json())
            .then(res => setContacts(res))
    }

    const Contact = ({ part }) => {

        const title = `${part.contact.fonction}, ${part.contact.societe}`;

        return <div className="col">
            <div className="card">
                <img src={`${part.contact.photos.small}`} className="card-img-top" alt="..." />
                <div className="card-body">
                    <h5 className="card-title"><b>{capitalize(part.contact.prenom)}  <br />{capitalize(part.contact.nom)} </b></h5>
                    <small className="text-muted">{title.substr(0, 20)}</small>
                </div>
            </div>
        </div>
    }

    useEffect(() => {
        fetchContacts()
    }, [])

    return (
        <div className="container">
            <div className="row row-cols-1 row-cols-md-6 g-4">
                {contacts.map(part => <Contact key={part.id_conferencier} part={part} />)}
            </div>
        </div>
    )
}

export default ContactsAnnuaire