
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { API_URL } from '../config'
import EventContext from '../contexts/EventContext';
import { sortByAttribute } from '../utils';
import Layout from './Layout'
import Loader from './Loader';
import useApiUrlTranslation from './useApiUrlTranslation';
import HTMLReactParser from 'html-react-parser';
import './EventCycleLangList.css'

const DisplayContactsCycleLang = ({ eventCycleLangList, title, subtitle }) => {

    const { i18n } = useApiUrlTranslation();

    useEffect(() => {

    }, [i18n.resolvedLanguage])

    return <div className='mb-5 px-5'>
        <div className={`text-white bg-black p-2 rounded-t-lg ${subtitle != "" ? "" : "hidden"}`}><h2 className="font-bold">{title}</h2>
            <h4 className={`py-2`}>{subtitle}</h4>
        </div>
        <div className="bg-white my-3 py-3 rounded">

            <table class="table">
                <tbody>
                    {sortByAttribute(eventCycleLangList, 'event_cycle_lang_nom')

                        .filter(item => item.publier != 'n')
                        .map((item, index) =>
                            <EventCycleLang
                                key={index}
                                eventCycleLang={item} />)}
                </tbody>
            </table>
        </div>
    </div >
}

const DisplayContacts = ({ eventCycleLangContacts, event }) => {

    const { i18n } = useApiUrlTranslation();

    useEffect(() => {

    }, [i18n.resolvedLanguage])

    return (

        <ul role="list" className="">
            {eventCycleLangContacts
                .filter(person => !["155", "208", "150", "250"].includes(person.conferencier_statut.id_event_contact_type))

                .map((person) => (
                    <li key={person.contact.id_contact}>

                        <div className="flex items-center space-x-4 lg:space-x-6">
                            <img className="h-16 w-16 rounded-full lg:h-20 lg:w-20 object-cover" src={person.photos.large} alt="" />
                            <div className="space-y-1 text-lg font-medium leading-6">
                                <p className='text-gray-500 text-sm'>Coordonnée par </p>
                                <p className='capitaliz font-bold'>{person.contact.prenom} {person.contact.nom}</p>
                                <p className="text-gray-700 text-sm">{person.contact.fonction} - {person.contact.societe}</p>
                                <p className="text-indigo-600 hidden" title={person.conferencier_statut.id_event_contact_type}>{person.conferencier_statut.libelle}   </p>
                            </div>
                        </div>
                    </li>
                ))}
        </ul>
    )
}

const EventCycleLangCoordinateur = ({ eventCycleLang, event, DisplayContacts }) => {

    const [eventCycleLangContacts, setEventCycleLangContacts] = useState(null)

    const { i18n, apiUrlTranslation } = useApiUrlTranslation();

    useEffect(() => {

        const getEventCyclelangContacts = async () => {

            const req = `id_event_cycle_lang IN(SELECT id_event_cycle_lang FROM event_cycle_lang WHERE  id_cycle_lang IN(  SELECT id_cycle_lang FROM event_cycle_lang WHERE  id_event_cycle_lang = ${eventCycleLang.id_event_cycle_lang})`
            const req1 = `id_event_cycle_lang=${eventCycleLang.id_event_cycle_lang} AND id_event=${event.id_event}`

            await fetch(`${apiUrlTranslation}?action=getEventCyclelangContactsEventLigne&${req1} `)
                .then(res => res.json())
                .then(res => {
                    setEventCycleLangContacts(res)
                })
        }

        eventCycleLang && getEventCyclelangContacts()
    }

        , [eventCycleLang, i18n.resolvedLanguage])

    return (
        <>
            {eventCycleLangContacts && <DisplayContacts event={event} eventCycleLangContacts={eventCycleLangContacts} />}
        </>
    )
}

const EventCycleLang = ({ eventCycleLang, }) => {

    const [logo, setLogo] = useState(null);

    const { currentEvent } = useContext(EventContext);
    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    useEffect(() => {

        setLogo(eventCycleLang.logo ? eventCycleLang.logo : eventCycleLang.cycle_lang.logo_better.small)
    }, [i18n.resolvedLanguage])

    return <tr className='hover:bg-slate-50'>
        <td className="w-1/12 bg-gray-700">
            <img className=" w-30 h-30 rounded-xl" src={logo} alt="" />
        </td>
        <td className="w-2/12">
            <div className="font-text-lg font-bold leading-6">{eventCycleLang.event_cycle_lang_nom} ({eventCycleLang.forum})</div>
        </td>
        <td><EventCycleLangCoordinateur eventCycleLang={eventCycleLang} event={currentEvent} DisplayContacts={DisplayContacts} /></td>
        <td>
            <div className="flex flex-col items-end">
                <Link
                    className="justify-center p-1 rounded-full   text-blue-800  px-3 w-auto border-blue-800 border-2"
                    target="_blank"
                    to={`/events/${currentEvent.id_event}/cl/${eventCycleLang.id_event_cycle_lang}`}>
                    {t('global.en_savoir_plus')}
                </Link>
                <a
                    target="_blank"
                    className=" justify-center p-1 rounded-full bg-blue-700 text-white  m-1 px-3"
                    href={`https://www.mlg-consulting.com/myglobalvillage/sign.php?ie=${currentEvent.id_event}&iecl=${eventCycleLang.id_event_cycle_lang}`}>

                    {t('pages.collectifs_contextuels.je_souhaite_participer')} {' '}

                </a>
            </div>
        </td>
    </tr>
}

const EventCycleLangList = () => {

    const { currentEvent } = useContext(EventContext);

    const [cycleLangEventLigneList, setCycleLangEventLigneList] = useState(null)

    const [eventCycleLangEventList, setEventCycleLangEventList] = useState(null)

    const [eventCycleLangEventLigneListFiltered, setEventCycleLangEventLigneListFiltered] = useState(null)

    const [cycleLangEventLigneListFilterd, setCycleLangEventLigneListFilterd] = useState(null)

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation()

    const searchCycleLang = async (e) => {

        if (e.target.value == null) {

            setCycleLangEventLigneListFilterd(cycleLangEventLigneList);
        }
        const filterdList = [...cycleLangEventLigneList].filter(item => { return item.id_cycle_lang.nom_cycle_lang.toLowerCase().includes(e.target.value.toLowerCase()) })

        setCycleLangEventLigneListFilterd(filterdList);

    }

    useEffect(() => {

        const getCycleLangEventLigne = async () => {
            await fetch(`${apiUrlTranslation}?action=getCycleLangEventLigne&params= WHERE id_event_ligne = ${currentEvent.event_ligne.id_event_ligne}`)
                .then(res => res.json())
                .then(res => {
                    setCycleLangEventLigneList(res)
                    setCycleLangEventLigneListFilterd(res)
                })
        }

        const getEventCycleLangList = async () => {

            await fetch(`${apiUrlTranslation}?action=getEventCycleLangList&id_event=${currentEvent.id_event}`)
                .then(res => res.json())
                .then(res => {
                    //const filteredList = res.filter(item => !["delegation_territoire", "mega_demonstrateur", "pays_region_entreprise_invite"].includes(item.forum))

                    const filteredList = res.filter(item => ["collectif_geographique"].includes(item.forum))
                    setEventCycleLangEventList(filteredList)
                    setEventCycleLangEventLigneListFiltered(filteredList)
                })
        }

        currentEvent && getEventCycleLangList()

        currentEvent && getCycleLangEventLigne()

    }, [currentEvent, i18n.resolvedLanguage])


    return (
        <Layout title={`${t('pages.collectifs_contextuels.title')} ${currentEvent?.nom}`} className="bg-white">

            <div className='insctructions hidden'>
                {HTMLReactParser(t('pages.collectifs_contextuels.instructions'))}
            </div>

            <div className='flex justify-center p-2 pb-3 d-none'>
                <input type="text" className='w-50 border rounded-2xl pl-10 py-3'
                    placeholder="Search"
                    onChange={(e) => searchCycleLang(e)} />
            </div>

            {!cycleLangEventLigneListFilterd ? <Loader /> : ''}

            {
                /*eventCycleLangEventLigneListFiltered && <DisplayContactsCycleLang
                    title={t('pages.collectifs_contextuels.delegations')}
                    subtitle=""
                    eventCycleLangList={eventCycleLangEventLigneListFiltered.filter(item => ['collectif_sectoriel'].includes(item.forum))}
            />*/
            }

            {
                eventCycleLangEventLigneListFiltered && <DisplayContactsCycleLang
                    title=""
                    subtitle=""
                    eventCycleLangList={eventCycleLangEventLigneListFiltered}
                />
            }

            {/*
            {eventCycleLangEventLigneListFiltered && <DisplayContactsCycleLang
                title={t('pages.collectifs_contextuels.collectifs_sectoriels')}
                subtitle=""
                eventCycleLangList={eventCycleLangEventLigneListFiltered.filter(item => ['collectif_sectoriel'].includes(item.forum))}
            />}

            {eventCycleLangEventLigneListFiltered && <DisplayContactsCycleLang
                title={t('pages.collectifs_contextuels.partenaires_affilies')}
                subtitle={t('pages.collectifs_contextuels.partenaires_affilies_subtitle')}
                eventCycleLangList={eventCycleLangEventLigneListFiltered.filter(item => ['partenaire_affilie'].includes(item.forum))}
            />}

            {eventCycleLangEventLigneListFiltered && <DisplayContactsCycleLang
                title={t('pages.collectifs_contextuels.stand_pavillon')}
                subtitle={t('pages.collectifs_contextuels.stand_pavillon_subtitle')}
                eventCycleLangList={eventCycleLangEventLigneListFiltered.filter(item => ['stand_pavillon'].includes(item.forum))}
            />}

            {eventCycleLangEventLigneListFiltered && <DisplayContactsCycleLang
                title={t('pages.collectifs_contextuels.pays_region')}
                subtitle={t('pages.collectifs_contextuels.pays_region_subtitle')}
                eventCycleLangList={eventCycleLangEventLigneListFiltered.filter(item => ['pays_region_entreprise_invite'].includes(item.forum))}
            />}

            */ }

        </Layout >
    )
}

export default EventCycleLangList