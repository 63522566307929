import React, { useContext } from 'react'

import darkModeContext from "../contexts/DarkModeContext";
import { LightBulbIcon, MoonIcon, SunIcon } from '@heroicons/react/24/solid';
import { isDev } from '../utils';

const DarkModeToggle = () => {
    const { darkMode, toggleDarkMode } = useContext(darkModeContext);

    return (
        <footer className="dark:bg-neutral-950 bottom-0">
            <div className='flex space-x-2 items-end p-5 justify-end'>
                <button onClick={toggleDarkMode} className={` flex justify-center w-10 rounded-full ${!darkMode ? 'bg-gray-200' : 'bg-neutral-900'}`}> <SunIcon className="h-5 w-5 text-gray-500" /></button>
                <button onClick={toggleDarkMode} className={` flex justify-center w-10 rounded-full ${darkMode ? 'bg-gray-200' : ''}`}><MoonIcon className="h-5 w-5 text-gray-500" /></button>
            </div>
        </footer >
    );
};




const LayoutFooter = () => {

    if (!isDev) return null;
    return (
        <DarkModeToggle />
    )
}

export default LayoutFooter