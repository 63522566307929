import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { API_URL } from "../config";
import { mobileCheck, removeTags } from "../utils";
import Layout from "./Layout";

function ReplayDetails() {
  let { idEvent } = useParams();
  let { id_video } = useParams();
  const [video, setVideo] = useState(null);
  const [contact, setContact] = useState(null);
  const [conference, setConference] = useState(null);
  const [demoList, setDemoList] = useState(null);

  const fetchVideos = async () => {
    const url = ` ${API_URL}?action=getVideosEvents&id_video=${id_video}`;

    await fetch(url)
      .then((res) => res.json())
      .then((video) => setVideo(video));
  };

  const fetchVideosContacts = async () => {
    const url = ` ${API_URL}?action=getVideosContacts&filter=WHERE id_video=${id_video}`;

    await fetch(url)
      .then((res) => res.json())
      .then((contact) => setContact(contact));
  };

  const fetchVideosConferences = async () => {
    const url = ` ${API_URL}?action=getVideosConferences&filter=WHERE id_video=${id_video} AND id_conf_event!=0`;

    await fetch(url)
      .then((res) => res.json())
      .then((conf) => setConference(conf));
  };

  const fetchDemo = async () => {
    const url = ` ${API_URL}?action=getVideoPresta&filter=id_video=${id_video}`;

    await fetch(url)
      .then((res) => res.json())
      .then((demoList) => setDemoList(demoList));
  };

  const DemoCard = ({ demo }) => {
    console.log(demo);
    return (
      <div
        className="d-flex  flex-column justify-content-between shadow p-1 m-2"
        style={mobileCheck() ? { maxWidth: "100vw" } : { maxWidth: "30vw" }}
      >
        <img src={demo.presta_visuel} alt="visuel de la prestation" />
        <div>
          <h4 className="p-4">{demo.presta_nom}</h4>
          <p className="p-3">
            {demo.presta_accroche.length > 300
              ? `${demo.presta_accroche.slice(0, 300)} ...`
              : demo.presta_accroche}
          </p>
        </div>
        <Link
          className="p-3"
          style={{
            textDecoration: "none",
            outline: "none",
            color: "#0094e1",
          }}
          to={`/events/${idEvent}/programm/${demo.id_presta}/demo-details`}
        >
          En savoir plus
        </Link>
      </div>
    );
  };

  const VideoContactsCard = ({ contact }) => {
    return (
      <div>
        <div
          className="card shadow border-0"
          style={{ width: "15rem", height: "25rem" }}
        >
          <img
            className="card-img-top img-fluid"
            src={contact.photos.small}
            style={{
              minWidth: "15rem",
              maxWidth: "15rem",
              minHeight: "13rem",
              maxHeight: "13rem",
              objectFit: "cover"
            }}
            alt="contact"
          />
          <div className="card-body">
            <h5 className="card-title fw-bold">
              {contact.prenom} {contact.nom}
            </h5>
            <p className="card-text">{contact.societe}</p>
            <Link
              style={{
                textDecoration: "none",
                outline: "none",
                color: "#0094e1",
              }}
              to={`/events/${idEvent}/profile/${contact.id_contact}`}
            >
              Bio
            </Link>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    fetchVideos();
    fetchVideosContacts();
    fetchVideosConferences();
    fetchDemo();
  }, []);

  return (
    <Layout>
      <div className="container">
        {video &&
          video.map((video, i) => (
            <div key={i}>
              <h1 className="fw-bold text-center">{video.id_video.titre}</h1>
              <iframe
                width="100%"
                height="500"
                src={video.id_video.embed_url}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          ))}

        {contact && contact.length > 0 && (
          <div>
            <h2 className="m-4">Contact liés</h2>
            <div className="d-flex flex-wrap">
              {contact.map((contact, i) => (
                <div className="m-3" key={i}>
                  <VideoContactsCard contact={contact.id_contact} />
                </div>
              ))}
            </div>
          </div>
        )}

        {demoList && demoList.length > 0 && (
          <div>
            <h2 className="m-4">Démos liées</h2>
            <div className="d-flex flex-wrap  justify-content-around">
              {demoList.map((demo, i) => (
                <DemoCard key={i} demo={demo.presta} />
              ))}
            </div>
          </div>
        )}

        {conference && conference.length > 0 && (
          <div>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Conférences liées</th>
                </tr>
              </thead>
              <tbody>
                {conference.map((conference, i) => (
                  <tr>
                    <th key={i} className="m-4 p-3">
                      <Link
                        className="p-3"
                        style={{
                          textDecoration: "none",
                          outline: "none",
                          color: "#0094e1",
                        }}
                        to={`/events/${idEvent}/conference-details/${conference.id_conf_event?.id_conf_event}`}
                      >
                        {removeTags(
                          conference.id_conf_event?.conf_event_lang.cel_titre
                        )}
                      </Link>
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default ReplayDetails;
