import React, { useContext, useEffect, useState } from 'react'
import useApiUrlTranslation from './useApiUrlTranslation';
import EventContext from '../contexts/EventContext';
import { removeDuplicateObjectFromArray } from '../utils';
import ConferencierItem from './ConferencierItem';


const Communaute2 = ({ eventCycleLang, idEventContactTypeList, title, smallLayout }) => {

    const eventContext = useContext(EventContext);

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [communauteList, setCommunauteList] = useState(false);

    const getCommunaute = async () => {

        // const suite = ` AND ( cf.statut IN (${idEventContactTypeList}) OR cf.statut IN ("${idEventContactTypeNomList}")) `;

        const suite = ` AND ( cf.statut IN (${idEventContactTypeList})  ) `;

        await fetch(`${apiUrlTranslation}?action=getCommunaute&filter= AND ecl.id_event_cycle_lang=${eventCycleLang.id_event_cycle_lang}&vv=${Date.now()}`, {
            cache: 'no-cache'
        })
            .then(res => res.json())
            .then(res => {

                let dedupByIdContactRes = res.map(item => {
                    const newItem = { ...item, id_contact: item.id_conferencier.contact.id_contact };

                    return newItem
                })

                dedupByIdContactRes = removeDuplicateObjectFromArray(dedupByIdContactRes, 'id_contact')

                const newRes = idEventContactTypeList ? res.filter(item => idEventContactTypeList.includes(item.id_conferencier.statut.id_event_contact_type)) : res
                setCommunauteList(newRes)
            })
    }

    useEffect(() => {

        console.log(eventCycleLang)
        getCommunaute();
    }, [i18n.resolvedLanguage])


    return <div className='md:mx-4 bg-gray-100 p-2 mb-2 rounded'>
        {title && <h3 className='text-xl font-bold mb-2'>{title}</h3>}

        <div className='flex flex-wrap'>
            {communauteList && communauteList.map((communaute, index) => <ConferencierItem
                smallLayout={smallLayout}
                conferencier={communaute.id_conferencier} />)}
        </div>
    </div>
}


const EventCycleLangItem = ({ eventCycleLang, EventCycleLangCoordinateur,
    RegistrationLinkList }) => {

    const [logo, setLogo] = useState(null);

    const { currentEvent, currentEventContactList } = useContext(EventContext);
    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    useEffect(() => {

        setLogo(eventCycleLang.logo ? eventCycleLang.logo : eventCycleLang.cycle_lang.logo_better.small)
    }, [i18n.resolvedLanguage])

    return <div className='flex flex-col  md:flex-row hover:bg-slate-50 border-b-2 py-2 m-2 border bg-white rounded p-2'>
        <div className="basis-2/12 py-2">
            <div className='uppercase font-bold pb-2'>{eventCycleLang.event_cycle_lang_nom}</div>
            <img className=" w-30 h-30 rounded-xl" src={logo} alt="" />
            <div className="text-xs">{eventCycleLang.forum}</div>
        </div>

        <div className="basis-2/12">
            {/*<EventCycleLangCoordinateur eventCycleLang={eventCycleLang} event={currentEvent} DisplayContacts={DisplayContacts} />*/}
            <Communaute2
                eventCycleLang={eventCycleLang}
                idEventContactTypeList={["250", "238", "239"]}
                title="Coordinateur" />
        </div>

        <div className="basis-4/12">
            {/*<EventCycleLangCoordinateur eventCycleLang={eventCycleLang} event={currentEvent} DisplayContacts={DisplayContacts} />*/}
            <Communaute2
                smallLayout={true}
                eventCycleLang={eventCycleLang}
                idEventContactTypeList={["92"]}
                title="Les jurés" />

            <Communaute2
                smallLayout={true}
                eventCycleLang={eventCycleLang}
                idEventContactTypeList={["178"]}
                title="Les candidats" />
        </div>

        <div className="basis-4/12">


            <div className='md:mx-4 bg-gray-100 p-2 mb-2 rounded'>
                <h3 className='text-xl font-bold mb-2'>They come from  {eventCycleLang.cycle_lang.cycle_lang_pays} </h3>
                <div className='flex flex-wrap'>
                    {currentEventContactList
                        .filter(conferencier => conferencier.contact.pays == eventCycleLang.cycle_lang.cycle_lang_pays && conferencier.contact.pays != "")
                        .reduce((accumulator, conferencier) => {
                            const existingSpeaker = accumulator.find(item => item.contact.id_contact == conferencier.contact.id_contact);
                            if (!existingSpeaker) {
                                accumulator.push(conferencier);
                            }
                            return accumulator;
                        }, [])
                        .map(conferencier => (
                            <ConferencierItem
                                key={conferencier.contact.id_contact}
                                smallLayout={true}
                                conferencier={conferencier}>
                                {conferencier.contact.nom}
                            </ConferencierItem>
                        ))}
                </div>
            </div>
        </div>

        {/* 
        <td>
                <Communaute2
            smallLayout={true}
            eventCycleLang={eventCycleLang}
            title="all" />
    </td>
        */}
        <div className="basis-2/12 hidden">

            <RegistrationLinkList eventCycleLang={eventCycleLang} />
        </div>
    </div>
}

export default EventCycleLangItem