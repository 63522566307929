import React, { useEffect, useRef, useState } from 'react'
import useApiUrlTranslation from './useApiUrlTranslation';
import RegistrationFormRow from './RegistrationFormRow';
import { useForm, useFormContext, FormProvider } from 'react-hook-form';
import { use } from 'i18next';

function FilePreview({ name }) {

    const { watch } = useFormContext();

    const [urlPreview, setUrlPreview] = useState('');

    const watchedFile = watch(name);

    useEffect(() => {
        const file = watchedFile && watchedFile[0];

        file && setUrlPreview(URL.createObjectURL(file));

    }, [watchedFile]);

    return <div className="mt-3 text-red-500 ">
        {urlPreview && <img src={urlPreview} alt="preview" />}
    </div>

}



const InputFile = ({ name, label }) => {

    const { register, watch } = useFormContext();
    const [fileContent, setFileContent] = useState('');
    const [urlPreview, setUrlPreview] = useState('');

    const watchedFile = watch(name);

    useEffect(() => {
        const file = watchedFile && watchedFile[0];
        if (file) {
            const fileReader = new FileReader();

            fileReader.onload = (event) => {
                setFileContent(event.target.result);
            };
            fileReader.readAsText(file);
        }

        file && setUrlPreview(URL.createObjectURL(file));

    }, [watchedFile]);


    return (
        <div><input type="file" id="file" {...register(name)} /> {

            fileContent && <div className="mt-3 text-red-500 ">
                {urlPreview && <img src={urlPreview} alt="preview" className="w-20" />}

                {/* <FilePreview name={name} /> */}
            </div>
        }
        </div>)
}



const Input = ({ type, name, label, placeholder, helper }) => {

    const { register } = useFormContext();

    if (!name) return null;
    return (
        <div className="sm:col-span-4">
            <label htmlFor={name} className="block text-sm font-bold leading-6 text-gray-900">
                {label}

            </label>
            <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    {/* 
                    {
                        type === 'file' ? <input
                            {...register(name)}
                            type="file"
                            name={name}
                            id={name}
                            autoComplete="username"
                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        /> : null
                    } */}


                    {type === 'file' ? <InputFile name={name} label={label} /> : null}

                    {!type || type === "text" ? <input
                        {...register(name)}
                        type="text"
                        name={name}
                        id={name}
                        autoComplete="username"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder={placeholder}
                    /> : null}


                    {type === 'textarea' ? <textarea
                        {...register(name)}
                        id={name} rows="3"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea> : null}




                </div>
                {helper && <p className="mt-3 text-xs leading-6 text-gray-600">{helper}</p>}
            </div>
        </div>
    )

}

const ScreenLeftSection = (props) => {

    return (
        <div className="basis-2/6">
            <div className=" flex flex-col space-y-10">
                {props.children}
            </div>
        </div>

    )
}

const ScreenRightSection = (props) => {

    return (
        <div className="basis-4/6 bg-neutral-950">
            {props.children}
        </div>

    )
}

const Screen = (props) => {


    return (
        <div>
            <div className="flex flex-row">
                <div className=" basis-1/12 items-center  flex  justify-center text-xl bg-neutral-900 text-white p-3 font-bold">{props.number}</div>
                <div className="basis-11/12 text-xl bg-orange-600 text-white p-3 font-bold">  {props.title}</div>
            </div>
            <div className="bg-cyan-100 my-2 p-3 rounded">Instructions</div>
            <div className="flex flex-col lg:flex-row h-screen space-x-5 my-5">
                {props.children}
            </div>
        </div>

    )
}


const RegistrationFormPreviewSection = ({ presta, contact }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const { register } = useFormContext();

    const { watch } = useFormContext();

    const watchNom = watch("nom");
    const watchPrenom = watch("prenom");
    const watchSociete = watch("societe");
    const watchPrestaAccroche = watch("presta_accroche");
    const watchPrestaTexte3 = watch("presta_texte3");
    const watchLogo = watch("logo");

    const watchFonction = watch("fonction");


    useEffect(() => {
        console.log(watchPrenom, watchNom)

        const subscription = watch((value, { name, type }) =>
            console.log(value, name, type)
        )
        return () => subscription.unsubscribe()



    }, [watch])


    return (

        <div className="">

            <Screen
                number="1/5"
                title="Présentation de votre cas client et de votre logo">
                <ScreenLeftSection>

                    <Input
                        type="file"
                        name="logo"
                        label="Chargez votre logo" />

                    <Input
                        type="textarea"
                        name="presta_accroche"
                        label="Descriptif de votre solution " helper="200 mots" />
                </ScreenLeftSection>
                <ScreenRightSection>
                    <div className="flex  h-full p-3">
                        <div className="flex items-center basis-1/2 text-3xl text-white">{watchPrestaAccroche}</div>
                        <div className="flex items-center basis-1/2 text-2xl text-white">  <img src={contact?.logos.large} alt="" className="w-100" />

                            <FilePreview name="logo" />
                        </div>
                    </div>

                </ScreenRightSection>
            </Screen>


            <Screen
                number="2/5"
                title="Présentation de la problématique de votre cas client">
                <ScreenLeftSection>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                        <div className="col-span-full">
                            <label htmlFor="presta_text3" className="block text-sm font-bold leading-6 text-gray-900">Chargez les Trois mini phrase de 40 caractères maximum pour décrire les enjeux ou la problématique de votre client ou de votre propre projet</label>
                            <div class="mt-2">
                                <textarea
                                    {...register("presta_text3")}
                                    id="presta_texte3" rows="3"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                            </div>
                            <p className="mt-3 text-sm leading-6 text-gray-600">Write a few sentences about yourself.</p>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="nom" className="block text-sm font-bold leading-6 text-gray-900">
                                Nom
                            </label>
                            <div className="mt-2">
                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">

                                    <input
                                        {...register("nom")}
                                        type="text"
                                        name="nom"
                                        id="nom"
                                        autoComplete="username"
                                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        placeholder="janesmith"
                                    />
                                </div>
                            </div>
                        </div>



                        <div className="sm:col-span-4">
                            <label htmlFor="prenom" className="block text-sm font-bold leading-6 text-gray-900">
                                Prénom
                            </label>
                            <div className="mt-2">
                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                    <input
                                        {...register("prenom")}
                                        type="text"
                                        name="prenom"
                                        id="nprenomom"
                                        autoComplete="username"
                                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        placeholder="janesmith"
                                    />
                                </div>
                            </div>
                        </div>




                    </div>
                </ScreenLeftSection>

                <ScreenRightSection>

                    <div
                        style={{
                            backgroundImage: 'url("https://storage.googleapis.com/cdn.vivatechnology.com/_vt-prd/medias/medium_Adobe_Stock_711463523_9c39a4ec09/medium_Adobe_Stock_711463523_9c39a4ec09.jpeg")'

                        }}

                        className='p-3 h-full bg-no-repeat bg-cover flex flex-col justify-between text-2xl'>

                        <div className="font-bold  text-white"> {watchPrestaAccroche}</div>
                        <div className="bg-neutral-950 text-white p-3 font-bold text-xl">
                            {watchPrenom}   {watchNom} - {watchFonction}, {watchSociete}

                            { }
                        </div>
                    </div>

                </ScreenRightSection>
            </Screen>


            <Screen
                number="3/5"
                title="Présentation de votre solution mise en œuvre">
                <ScreenLeftSection>
                    <Input label="Chargez la video (lien et upload) qui défilera sans le son pendant votre présentation" />
                    <Input label="Ainsi qu'une image fixe qui sera présentée dans la bibliothèque (et en support d'illutration si vous n'avez pas de video)" />
                </ScreenLeftSection>
                <ScreenRightSection></ScreenRightSection>
            </Screen>


            <Screen
                number="4/5"
                title="Présentation des résultats obtenus">
                <ScreenLeftSection>
                    <Input
                        name="societe"
                        label="Trois phrases de 40 caractères maximum CHACUNE pour décrire les résultats concrets obtenus et le bénéfices générés par la mise en place de votre solution" />

                    <Input
                        name={'presta_texte3'}
                        value={presta?.presta_texte3}
                    />
                </ScreenLeftSection>
                <ScreenRightSection></ScreenRightSection>
            </Screen>

            <Screen
                number="5/5"
                title="Chargement de votre cas client ou votre brochure pdf">
                <ScreenLeftSection>
                    <Input label="Chargez un pdf présentant votre cas client qui sera envoyé aux décideurs qui cliquent sur le bouton « télécharger le business case »" />
                </ScreenLeftSection>
                <ScreenRightSection></ScreenRightSection>
            </Screen>

        </div>

    )
}

export default RegistrationFormPreviewSection