import HTMLReactParser from 'html-react-parser'
import React from 'react'
import useApiUrlTranslation from '../useApiUrlTranslation'


const SallePresentation = ({ confEvent }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation()


    if (!confEvent.salle.id_lieu.lieu_nom) {

        return false
    }

    return <div className="m-3 p-3 bg-slate-700  rounded-lg">

        <div className="flex">
            <img src={confEvent.salle.img} alt="" className="max-h-52 rounded-lg " />
            <div className="p-3">
                <p className="mt-1 text-lg font-semibold text-white sm:text-slate-900 md:text-3xl dark:sm:text-white"> {t('pages.parcours-details.lieu_tempsforts')} </p>
                <p className="mt-1 text-lg font-semibold text-white sm:text-slate-900 md:text-2xl dark:sm:text-white">  {confEvent.salle.salle_nom}</p>
                <div className="text-slate-400">
                    {confEvent.salle.id_lieu.lieu_nom}
                    {confEvent.salle.id_lieu.lieu_adresse}  {confEvent.salle.id_lieu.lieu_cp}  {confEvent.salle.id_lieu.lieu_pays}
                </div>
            </div>
        </div>
    </div>
}



export default SallePresentation

