import React, { useState, useEffect } from 'react'
import Layout from './Layout'
import useApiUrlTranslation from './useApiUrlTranslation';
import { useParams } from "react-router-dom";
import Loader from './Loader';
import parse from "html-react-parser";


const Planning = ({ contactList, conferencier }) => {

    return <div>

        {contactList && contactList
            .filter(item => item.contact.id_contact == conferencier.contact.id_contact)
            .map(item => <div className="flex items-center">{item.event.id_event}
                <div className="flex items-center"> {conferencier.id_conferencier}

                    - {conferencier.id_contact}
                    {/* {JSON.stringify(item.conf_event)} */}
                    <div className="w-3 h-3 rounded-full bg-green-500 mr-2"></div>
                    <div className="text-xs text-gray-500"> {item.conf_event.heure_debut} - {item.conf_event.heure_fin}</div>
                </div>
                <div className="px-2"> {item.conf_event_date}</div>
                <div className="px-2"> {item.conf_event.con_event_lang.cel_titre}</div>
            </div>)}
    </div>
}

const DisplayContactCycleLang = ({ contactCycleLangList, displayMode }) => {

    if (displayMode == "logo") {


        return <div className='flex flex-wrap w-100 '>

            {contactCycleLangList && contactCycleLangList.map(contactCycleLang =>
                <div className="flex flex-col items-center w-100 text-center">

                    <img src={contactCycleLang.cycle_lang.logo_better.small} alt="" className="w-52 max-h-52 object-cover rounded-2xl" />
                    <div className=''>{contactCycleLang.cycle_lang.nom_cycle_lang_court ?? contactCycleLang.cycle_lang.nom_cycle_lang}

                    </div>

                </div>)}
        </div>
    }

    return <div className='flex flex-wrap'>

        {contactCycleLangList && contactCycleLangList.map(contactCycleLang => <div className="flex items-center">

            <div className="flex items-center space-x-2 rounded-full py-1 px-3  bg-orange-400 text-white text-sm whitespace-nowrap">
                <img src={contactCycleLang.cycle_lang.logo_better.small} alt="" className='w-6 rounded ' />
                <div> {contactCycleLang.cycle_lang.nom_cycle_lang}</div>
            </div>

        </div>)}
    </div>
}

const Contact = ({ contact, conferencier, contactList, contactCycleLangList }) => {

    return <div className=" " communautes="">
        <div className="card mb-3">

            <div className="row no-gutters ">
                <div className="col-md-2 flex flex-col items-center justify-center">
                    <DisplayContactCycleLang contactCycleLangList={contactCycleLangList} displayMode="logo" />
                </div>

                <div className="col-md-2 flex  flex-col items-center justify-center">
                    <img className="w-52 max-h-52 object-cover rounded-2xl" alt='visuel du contact' src={contact.photos.large} />
                </div>
                <div className="col-md-6">
                    <div className="card-body">
                        <div className='flex flex-wrap'>
                            <span className="border rounded-full py-1 px-3 bg-slate-400 text-white text-sm whitespace-nowrap">{conferencier.statut.libelle}
                                {/* ({conferencier.statut.id_event_contact_type}) */}
                            </span>
                            <DisplayContactCycleLang contactCycleLangList={contactCycleLangList} />
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <p className="pb-0 mb-0 text-left pt-3" style={{ fontWeight: "bold" }}>{contact.prenom}
                                    <span className="px-2" style={{ textTransform: "uppercase" }}>{contact.nom} </span>
                                </p>
                                <p>{parse(contact.edito_court)}</p>
                                <span className="comite_orga__fonction text-left">{contact.fonction} </span>
                                <p className="text-pink-600" > {contact.societe}</p>

                                <div className="text-muted flex items-center"><img className="mr-2" alt='' src={contact.flag} style={{ width: "20px", position: "relative", top: "-2px" }} /> {contact.pays}</div>

                                {/* <Planning contactList={contactList} conferencier={conferencier} /> */}

                            </div>

                        </div>
                    </div>
                </div>
                <div className='col-md-2 flex flex-col items-center justify-center'> <img className="w-100" alt='logo du contact' src={contact.logos.large} /></div>
            </div>
        </div>
    </div >

}

const Speakers = ({ pageTitle = "", statutsList = ['intervenant', 'animateur',
    'charge_de_conf', 'chroniqueur', 'chroniqueur_tv',
    'conferencier_attente_1', 'keynote_speaker', 'jury', 'coordinateur_jury', 'intervenant_temoins', 207, 204, 263, 124, 92] }) => {

    let { idEvent } = useParams();

    const { i18n, apiUrlTranslation } = useApiUrlTranslation()

    const [contactList, setContactList] = useState(null);

    const [contactCycleLangList, setContactCycleLangList] = useState(null);

    const filter = ` AND id_event=${idEvent} AND cf.statut IN (${statutsList.map(statut => `'${statut}'`).join(',')})`;

    const url = `${apiUrlTranslation}?action=getContactConferencierList&filter= ${filter} ORDER BY nom, prenom`

    const fetchContactCycleLang = async (fetchContactRes) => {

        const idContactList = fetchContactRes.map(item => item.contact.id_contact).join(',')

        await fetch(`${apiUrlTranslation}?action=fetchContactCycleLang&filter=c.id_contact IN(${idContactList}) AND  `)
            .then(res => res.json())
            .then(contactCycleLang => {
                setContactCycleLangList(contactCycleLang)
            })
    }

    useEffect(() => {

        const fetchContacts = async () => {
            await fetch(url)
                .then(res => res.json())
                .then(fetchContactRes => {
                    //dedup by id_contact.id_contact
                    const unique = [...new Map(fetchContactRes.map(item => [item.contact.id_contact, item])).values()];

                    const filteredList = unique.filter(item => item.contact.certified == 1)
                    setContactList(filteredList);

                    fetchContactCycleLang(fetchContactRes);
                })
        }


        fetchContacts();
    }, [i18n.resolvedLanguage]);


    return (
        <Layout title={pageTitle}>
            <div className="container">
                {contactList ? contactList
                    //dedup by item.contact.id_contact
                    .filter((item, index, self) =>
                        index === self.findIndex((t) => (
                            t.contact.id_contact === item.contact.id_contact
                        ))
                    )
                    .map(item => <Contact
                        contactCycleLangList={contactCycleLangList && contactCycleLangList
                            .filter(contactCycleLang => contactCycleLang.id_contact == item.contact.id_contact
                                && ["167"].includes(contactCycleLang.id_event_contact_type)
                                && contactCycleLang.cycle_lang.cycle_lang_state == "active")}
                        contact={item.contact}
                        conferencier={item}
                        contactList={contactList} />) : <Loader />}
            </div>
        </Layout>
    )
}

export default Speakers