import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { API_URL } from '../config'
import Layout from './Layout'
import LieuInfos from './LieuInfos'

const PasswordRecovery = () => {

    const [email, setEmail] = useState(null);

    const [notification, setNotification] = useState(null);

    const [isValidEmail, setIsValidEmail] = useState(false);

    const [passswordSended, setPassswordSended] = useState(null);

    const handleChange = (e) => {

        isAnEmail(e.target.value) ? setIsValidEmail(true) : setIsValidEmail(false)
        setEmail(e.target.value)
    }

    const isAnEmail = (email) => {

        if (email != null) {
            return email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        }
    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        await fetch(`${API_URL}?action=passwordRecovery&email=${email}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email
                })
            }).then(res => res.json())
            .then(data => {


                setPassswordSended(true)
            })
    }

    return (
        <Layout className="bg-gray-100 h-full " >

            <form className="sm:mx-auto sm:w-full sm:max-w-md bg-white p-5 rounded shadow-sm">


                <Link to="/login" className='py-3  text-blue-600'> {`<<`} S'identifier</Link>



                {passswordSended == true && <div className='bg-emerald-100 text-emerald-500 p-3 my-3 rounded'>

                    <p className='py-2 font-bold'>  Mot de passe envoyé</p>

                    <p> Si votre email est reconnu, vous recevrez un email pour réinitialiser votre mot de passe.</p>
                    <p className="py-2">Pensez à vérifier votre boîte de courriers indésirables.</p>

                </div>}


                {passswordSended != true && <div>
                    <h1 className="text-3xl  mt-4 font-bold">Mot de passe oublié ?</h1>
                    <p className="py-5">  Entrez votre adresse e-mail et nous vous enverrons votre mot de passe</p>


                    <input type="text" className="border w-full my-2  mb-3 p-3 rounded text-2xl" placeholder="Votre e-mail"
                        onChange={handleChange} required />

                    {isValidEmail == true && <button className="p-3 bg-blue-600 w-full text-white rounded text-2xl mb-4" onClick={handleSubmit}>Valider</button>}




                </div>
                }
            </form>
        </Layout>
    )
}

export default PasswordRecovery