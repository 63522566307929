import React, { useEffect, useState } from 'react'
import { API_URL } from '../../config'
import { useParams } from "react-router-dom";
import EventTimer from '../EventTimer';
import { sortByAttribute } from '../../utils';
import moment from 'moment';

const JuryEvent = ({ juryEvent }) => {

    useEffect(() => {

        const m = moment(`${juryEvent.conf_event.heure_debut.replace('h', ':')} `).isAfter(moment().format('HH:mm'))

        console.log(m)
    }, [juryEvent])

    if (moment(`${juryEvent.conf_event.conf_event_date} ${juryEvent.conf_event.heure_debut.replace('h', ':')}`).isBefore(moment())) {

        // return false

    }

    return <div className={`h-screen ${moment(`${juryEvent.conf_event.conf_event_date} ${juryEvent.conf_event.heure_debut.replace('h', ':')}`).isAfter(moment()) ? '' : 'd-none'}`
    }>
        <div className="bg-blue-900 text-white p-5 text-5xl flex flex-row justify-between">

            <div className='basis-3/4 align-bottom pt-2'>Prochain départ : {juryEvent.conf_event.heure_debut}</div>
            <div >

                <EventTimer
                    className=''
                    date={juryEvent.conf_event.conf_event_date}
                    heure_debut={juryEvent.conf_event.heure_debut}
                />
            </div>
        </div>
        <div className='flex flex-row'>
            <div className='basis-3/4 '>
                <div className="text-2xl p-4 text-center">Pour vous inscrire gratuitement flashez ce code :</div>
                <img src={juryEvent.qrcode_webapp} alt="" className='shadow' />
            </div>
            <div className='border-t border-gray-200 p-3'>
                <div className="text-gray-500 text-5xl text-blue-600">{juryEvent.conf_event.type.conf_event_type_nom}</div>
                <div className="text-7xl">{juryEvent.conf_event.conf_event_lang.cel_titre}</div>
            </div>
        </div>

    </div>
}

const Departure = ({ filter }) => {

    const { idEvent } = useParams();
    const [juryEventList, setJuryEventList] = useState([])

    useEffect(() => {

        const updateList = (jeList) => {
            const interval = setInterval(() => {

                console.log(moment().format('HH:mm:ss'));

                console.log(jeList);

                if (!jeList) {
                    return false
                    console.log(jeList)
                }

                const formatedList = [...jeList]
                    .filter(item => item.conf_event.conf_event_date === moment().format('YYYY-MM-DD'))
                    .filter(item => moment(`${item.conf_event.conf_event_date} ${item.conf_event.heure_debut.replace('h', ':')}`).isAfter(moment()))


                setJuryEventList(sortByAttribute(filter ? formatedList : jeList, 'conf_event_heure'))
            }, 1000);

            return () => clearInterval(interval);

        }


        const getJuryEventList = async () => {

            await fetch(`${API_URL}?action=getJuryEventList&params=where id_event=${idEvent}`)
                .then(res => res.json())
                .then(res => {

                    let formatedList = [...res]

                    formatedList = res
                        .map(item => { return { ...item, conf_event_heure: item.conf_event.heure_debut } })
                        .filter(item => ['66', '63'].includes(item.conf_event.type.id_conf_event_type))
                        .filter(item => item.conf_event.conf_event_date === moment().format('YYYY-MM-DD'))
                        .filter(item => moment(`${item.conf_event.conf_event_date} ${item.conf_event.heure_debut.replace('h', ':')}`).isAfter(moment())
                        )

                    let formatedListWhithoutDateFilter = res
                        .map(item => { return { ...item, conf_event_heure: item.conf_event.heure_debut } })
                        .filter(item => ['66', '63'].includes(item.conf_event.type.id_conf_event_type))

                    const formatedListBeta = [...formatedList,
                    {
                        id_jury_event: 998,
                        conf_event: {
                            conf_event_date: moment().format('YYYY-MM-DD'),
                            heure_debut: moment().add(1, 'minutes').format('HH:mm').replace(':', 'h'),
                            type: { conf_event_type_nom: "Init..." },
                            conf_event_lang: { cel_titre: "Init..." }

                        },
                        conf_event_heure: moment().add(1, 'minutes').format('HH:mm').replace(':', 'h'),
                    },
                    {
                        id_jury_event: 999,
                        conf_event: {
                            conf_event_date: moment().format('YYYY-MM-DD'),
                            heure_debut: moment().add(2, 'minutes').format('HH:mm').replace(':', 'h'),
                            type: { conf_event_type_nom: "Init..." },
                            conf_event_lang: { cel_titre: "Init..." }

                        },
                        conf_event_heure: moment().add(2, 'minutes').format('HH:mm').replace(':', 'h'),
                    }
                    ]

                    setJuryEventList(sortByAttribute(filter ? formatedList : formatedListWhithoutDateFilter, 'conf_event_heure'));

                    return sortByAttribute(filter ? formatedList : formatedListWhithoutDateFilter, 'conf_event_heure');
                }
                )
                .then(res => updateList(res))
        }

        idEvent && getJuryEventList()


    }, [])

    if (!idEvent) {
        return false
    }

    return (
        <div className=''>

            <div className="min-w-full border-separate" style={{ borderSpacing: 0 }}>

                { /*activeJuryEvent && <JuryEvent juryEvent={activeJuryEvent} />*/}
                {juryEventList && juryEventList.map((juryEvent, i) => <JuryEvent key={i} juryEvent={juryEvent} />)}

            </div>
        </div>
    )
}

export default Departure