import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { API_URL } from "../config";
import Layout from "./Layout";
import HTMLReactParser from "html-react-parser";

function Profile() {
  let { idEvent } = useParams();
  let { id_contact } = useParams();
  const [contact, setContact] = useState(null);
  const [videos, setVideos] = useState(null);
  const [demoList, setDemoList] = useState([]);
  const [partenaires, setPartenaires] = useState([]);

  const fetchContact = async () => {
    const url = ` ${API_URL}?action=getContact&id_contact=${id_contact}`;

    await fetch(url)
      .then((res) => res.json())
      .then((contact) => {
        setContact(contact);
      });
  };

  const fetchPartenaires = async () => {
    const url = ` ${API_URL}?action=getPartenaires&params=AND%20cf.id_event=${idEvent}%20AND%20c.id_contact=${id_contact}`;

    await fetch(url)
      .then((res) => res.json())
      .then((contact) => {
        setPartenaires(contact);
      });
  };

  const fetchVideosContacts = async () => {
    const url = ` ${API_URL}?action=getVideosContacts&filter=WHERE id_contact=${id_contact}`;

    await fetch(url)
      .then((res) => res.json())
      .then((linkedVideos) => setVideos(linkedVideos));
  };

  const fetchPrestaList = async () => {
    const url = ` ${API_URL}?action=getPrestaList&params= WHERE id_contact=${id_contact}`;

    await fetch(url)
      .then((res) => res.json())
      .then((demo) => {
        setDemoList(demo);
      });
  };

  useEffect(() => {
    fetchContact();
    fetchPartenaires();
    fetchVideosContacts();
    fetchPrestaList();
  }, []);

  return (
    <Layout darkmode>
      <div className="container">
        {contact && (
          <div className="container-fluid">
            <div
              className="flex lg:flex-row flex-col justify-between  text-white"
            >

              <div className="flex lg:flex-row flex-col">

                <img
                  className=" rounded-xl w-1/3 object-cover"
                  src={contact.photos?.large}
                  alt="contact"

                />

                <div
                  className=" flex flex-col  justify-content-around p-4"

                >
                  <div className="text-7xl font-black">
                    {contact.prenom} {contact.nom}
                  </div>
                  <div
                    className="text-xl py-2"
                    style={{
                      textTransform: "uppercase",
                    }}
                  >
                    {contact.societe} - {contact.fonction}
                  </div>

                  <h2 className="py-3  font-bold ">Social Media</h2>
                  <div className="d-flex">


                    {contact.sn_facebook && (
                      <a
                        style={{
                          textDecoration: "none",
                          outline: "none",
                          color: "#0094e1",
                          margin: "5px",
                        }}
                        href={contact.sn_facebook}
                      >
                        <i className="fab fa-facebook"></i>
                      </a>
                    )}
                    {contact.sn_linkedin && (
                      <a
                        style={{
                          textDecoration: "none",
                          outline: "none",
                          color: "#0094e1",
                          margin: "5px",
                        }}
                        href={contact.sn_linkedin}
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    )}
                    {contact.sn_twitter && (
                      <a
                        style={{
                          textDecoration: "none",
                          outline: "none",
                          color: "#0094e1",
                          margin: "5px",
                        }}
                        href={contact.sn_twitter}
                      >
                        <i className="fab fa-twitter-square"></i>
                      </a>
                    )}
                  </div>

                  {contact.web && (
                    <a
                      target="_blank"
                      style={{
                        textDecoration: "none",
                        outline: "none",
                        color: "#0094e1",
                      }}
                      className="fw-bold"
                      href={`${contact.web && !contact.web.includes("//") ? `//${contact.web}` : contact.web}`}
                    >
                      <i className="fa fa-globe" aria_hidden="true" />{" "}
                      {contact.web}
                    </a>
                  )}
                </div>
              </div>

              <div className="flex justify-end "><img

                className="p-3 bg-white rounded-xl w-50 h-50 object-contain"
                src={contact.logos.large}
                alt="logo d'entreprise"
              /></div>
            </div>
            {contact.edito_long && (
              <div>
                <h2 className="py-3 font-bold">Bio</h2>
                <p>{HTMLReactParser(contact.edito_long ?? "")}</p>
              </div>
            )}
            {partenaires && (
              <div>
                <h2 className="py-3 font-bold">Conférences liées</h2>
                <table class="divider">

                  <tbody>
                    {partenaires.map((conference) => (
                      <tr>
                        <td
                          className="m-4 p-3"
                          key={conference.id_conf_event?.id_conf_event}
                        >
                          <Link
                            style={{
                              textDecoration: "none",
                              outline: "none",
                              color: "#0094e1",
                            }}
                            className="p-3"
                            to={`/events/${idEvent}/conference-details/${conference.id_conf_event?.id_conf_event}`}
                          >
                            {conference.id_conf_event.conf_event_lang.cel_titre}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
        {videos && videos.length > 0 && (
          <div className="m-3">
            <h2 className="py-3 font-bold">Vidéos rattachées au contact</h2>
            <div className="d-flex flex-row flex-wrap">
              {videos.map((video) => (
                <div className="group">
                  <div className="card m-2    border-neutral-800 hover:border-pink-500 border-2 rounded-2xl p-2 bg-neutral-900  " style={{ width: "18rem" }}>
                    <Link
                      className=""
                      to={`/events/${idEvent}/replay/${video.id_video.id_video}/replay-details`}
                    ><img
                        className="card-img-top"
                        src={video.id_video.thumbnail_medium_url}
                        alt="thumbnail de la video"
                      />
                      <div className="card-body">
                        <h5 className="card-title text-white">{video.id_video.titre}</h5>
                        <p className="card-text text-gray-400 text-xs">{video.id_video.date_creation}</p>

                        <div className="   text-gray-300 group-hover:text-pink-500 text-center pt-3"> En savoir plus</div>

                      </div>

                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {demoList && demoList.length > 0 && (
          <div>
            <h2>Démos rattachées au contact</h2>
            <div className="d-flex flex-row flex-wrap">
              {demoList.map((demo) => (
                <div className="card m-2" style={{ width: "18rem" }}>
                  <img
                    className="card-img-top"
                    src={demo.presta_visuel}
                    alt="demo"
                  />
                  <div className="card-body">
                    <h5 className="card-title">{demo.presta_nom}</h5>
                    <Link
                      target="_blank"
                      className="btn btn-primary m-3"
                      to={`/events/${idEvent}/programm/${demo.id_presta}/demo-details`}
                    >
                      En savoir plus
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </Layout >
  );
}

export default Profile;
