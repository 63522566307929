import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { removeDuplicateObjectFromArray } from "../utils";
import Loader from "./Loader";
import PageSection from "./PageSection";
import useApiUrlTranslation from "./useApiUrlTranslation";
import HTMLReactParser from 'html-react-parser';
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const PartenaireCardDetailsBeta = ({ contact, conferencier }) => {

  return (
    <div class="group ">
      <div
        className="border-neutral-800 hover:border-pink-500 border-2 rounded-2xl p-2 bg-neutral-900  "

      >
        <Link
          className="   text-gray-300 group-hover:text-pink-500"
          to={`./profile/${contact.id_contact}`}>
          <img
            src={contact?.logos?.large}
            className="object-contain h-40 w-100 bg-white p-3 rounded-2xl"
            alt="entreprise logo"
          />
          {conferencier?.statut?.libelle}
          <h5 className="text-xl font-bold hidden">
            {contact?.societe.toUpperCase()}
          </h5>

          <div className="pt-3  ">En savoir plus</div>
        </Link>
      </div >
    </div>
  )
}


const PartenaireCardDetails = ({ contact }) => {

  return (
    <div
      className="card mb-3 shadow"
      style={{ maxWidth: "100%", overflow: "hidden" }}
    >
      <div className="row no-gutters">
        <div className="col-md-4 d-flex justify-content-center align-items-center p-3">
          <img
            src={contact?.logos?.large}
            className="object-contain h-40 w-40"
            alt="entreprise logo"
          />
        </div>
        <div className="col-md-6">
          <div className="card-body">
            <h5 className="text-xl font-bold">
              {contact?.societe.toUpperCase()}
            </h5>
            <p className="text-left">{HTMLReactParser(contact?.edito_long ?? "")}</p>
            <a href="${partenaire?.contact?.web}">
              {contact?.web}
            </a>
          </div>
        </div>
        <div className="col-md-2">
          <div class="flex gap-x-4 p-2">
            <img class="h-12 w-12 flex-none rounded-full bg-gray-50" src={contact?.photos?.large} alt="" />
            <div class="min-w-0 flex-auto">
              <p class="text-sm font-semibold leading-6 text-gray-900">{contact?.prenom} {contact?.nom}</p>
              <p class="mt-1 truncate text-xs leading-5 text-gray-500">{contact?.fonction} </p>
              <p class="mt-1 truncate text-xs leading-5 text-gray-500">{contact?.societe}</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

function PartenairesListDetails({ statut, title }) {

  const { i18n, apiUrlTranslation } = useApiUrlTranslation();
  const { idEvent } = useParams();
  const [partenaireList, setPartenaireList] = useState([]);
  const [partenairesPermanents, setPartenairesPermanents] = useState([]);

  useEffect(() => {

    const fetchPartenaireList = async () => {
      const req = `getPartenaires&params=AND id_event=${idEvent} AND cf.statut in(${statut}) GROUP BY societe`;

      await fetch(
        `${apiUrlTranslation}?action=${req}`
      )
        .then((res) => res.json())
        .then((partenaireList) => {
          partenaireList.sort();

          const part = partenaireList.map(partenaire => {

            return {
              contact: partenaire.contact,
            }
          })

          setPartenaireList(part);
        })
    }

    const fetchContactsCycle = async () => {

      await fetch(`${apiUrlTranslation}?action=fetchContactCycleLang&filter= id_event_contact_type IN(227) AND & group_by=GROUP BY societe`)
        .then(res => res.json())
        .then(res => {

          const dedupArrayByICL = removeDuplicateObjectFromArray(res, 'id_cycle_lang');

          const part = dedupArrayByICL.map(partenaire => {

            return {
              contact: partenaire.contact
            }
          })

          setPartenairesPermanents(part);
        })
    }

    fetchPartenaireList(idEvent, statut);
    fetchContactsCycle()
  }, [i18n.resolvedLanguage]);

  return (
    <PageSection title={title} darkmode>
      <div className="grid grid-cols-1 gap-3 mx-auto max-w-7xl lg:grid-cols-3">
        {partenaireList ? (

          //   [...partenaireList, ...partenairesPermanents].map((contact, i) => (
          [...partenaireList].map((contact, i) => (
            contact && <PartenaireCardDetailsBeta
              key={contact.id_contact}
              contact={contact.contact}
              conferencier={contact}
            />
          ))
        ) : (
          <Loader />
        )}
      </div>
    </PageSection>
  );
}

export default PartenairesListDetails;
