import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import { API_URL } from '../config';

const CompanyList = ({ pageTitle = "", statutList = [] }) => {

    //const { idEvent } = useParams();
    const idEvent = 1635;

    const [partenaires, setPartenaires] = useState([]);

    const fetchPartenaires = async () => {

        await fetch(`${API_URL}?action=getPartenaires&params= AND id_event=${idEvent} AND cf.statut IN('${statutList.join("','")}') GROUP BY c.id_contact`)
            .then(res => res.json())
            .then(partenaires => setPartenaires(partenaires))
    }


    const Company = ({ company }) => {

        return <div class="col-md-4  mb-3">

            <div class="card shadow-sm rounded">
                <div class="text-center">
                    <img src={company.logos.small} class=" " alt="company logo" />
                </div>
                <div class="card-body exposant_item_body">
                    <h5 class="card-title" style={{ textTransform: "uppercase" }}>{company.contact.societe}</h5>
                    <p class="card-text">{company.contact.edito_long.substring(0, 300)}...</p>
                    <a target="_blank" rel="noreferrer" href="////village-francophone.com/1635/6443/live_218725/actu-ia.html"><i class="fas fa-video"></i></a>
                </div>
                <div class="card-footer text-center"><a href="////village-francophone.com/1635/6443/partenaire_218725/actu-ia.html" class="btn btn-primary">En savoir plus</a>
                </div>
            </div>
        </div>
    }

    useEffect(() => {
        fetchPartenaires();
    }, [])



    return (
        <Layout title={pageTitle}>
            <div className="container">
                <div className="row">
                    {partenaires.map(partenaire => {
                        return <Company company={partenaire} />
                    })
                    }
                </div>
            </div>
        </Layout>
    )
}

export default CompanyList
