import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import { use } from 'i18next';
import useApiUrlTranslation from './useApiUrlTranslation';
import ContactCard from './ContactCard';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Loader from './Loader';


const Cyclelang = ({ cycleLang, contactCycleLangList, contactCycleLangFullList }) => {

    const { idEvent } = useParams()

    return <li className=" flex   space-x-5 py-3">

        <div style={{ width: "175px" }} className="basis-1/12">
            <img className="  rounded-2xl object-cover" src={cycleLang.logo_better.small} alt="" />
        </div>
        <div className="basis-3/12">
            {contactCycleLangList && contactCycleLangList

                .filter(contactCycleLang => contactCycleLang.cycle_lang.id_cycle_lang === cycleLang.id_cycle_lang)
                .filter((contactCycleLang, index, self) =>
                    index === self.findIndex((t) => (
                        t.contact.id_contact === contactCycleLang.contact.id_contact
                    ))
                )
                .map(contactCycleLang => {
                    const contact = contactCycleLang.contact;
                    return <li className=" flex   space-x-5">

                        <img className="aspect-[3/2] w-20 h-20 rounded-circle object-cover" src={contact.photos.large} alt="" />

                        <div><h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900 dark:text-white">{contact.prenom} {contact.nom}</h3>
                            <p className="text-sm leading-7 text-gray-600 dark:text-gray-600">{contact.fonction} - {contact.societe}</p>
                        </div>  </li>
                }
                )}
        </div>
        <div className="basis-7/12">

            <div className='flex flex-wrap'>
                {
                    contactCycleLangFullList ? contactCycleLangFullList
                        .filter(contactCycleLangFullListItem => contactCycleLangFullListItem.cycle_lang.id_cycle_lang === cycleLang.id_cycle_lang)
                        //filter  contactCycleLangFullListItem id_contact not  in contactCycleLangList
                        .filter(contactCycleLangFullListItem => !contactCycleLangList.map(contactCycleLang => contactCycleLang.contact.id_contact).includes(contactCycleLangFullListItem.contact.id_contact))

                        .map(contactCycleLang => <ContactPresentation contactCycleLang={contactCycleLang} />) : <Loader className='bg-white' />}
            </div>
        </div>
        <div className="basis-1/12 flex items-center">
            <a href={`/events/${idEvent}/coordinateurs-collectif/${cycleLang.id_cycle_lang}`}
                className='bg-neutral-900 hover:bg-pink-600 hover:ring-pink-600 ring-2 ring-pink-400 rounded-full px-3 py-2 text-white text-xs'>
                En savoir plus
            </a>
        </div>
    </li >

}



const ContactPresentation = ({ contactCycleLang }) => {

    const contact = contactCycleLang.contact;

    return <li className=" flex space-x-5 w-[200px]">

        <img className="aspect-[3/2] w-10 h-10 rounded-circle object-cover" src={contact.photos.large} alt="" />
        <div className="">
            <h3 className=" text-sm font-semibold leading-8 tracking-tight dark:text-white  ">{contact.prenom} {contact.nom}</h3>
            <p className="text-xs text-gray-600  dark:text-gray-600 ">{contact.fonction} - {contact.societe}</p>
        </div>
    </li>
}


const Presentation = ({ contactCycleLang }) => {

    const contact = contactCycleLang.contact;


    return <li className="bg-white flex   space-x-5">


        <div style={{ width: "175px" }} className="basis-1/3">
            <img className="  rounded-2xl object-cover" src={contactCycleLang.cycle_lang.logo_better.small} alt="" />
        </div>
        <div className="basis-2/3">
            <img className="aspect-[3/2] w-20 h-20 rounded-circle object-cover" src={contact.photos.large} alt="" />
            <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">{contact.prenom} {contact.nom}</h3>
            <p className="text-base leading-7 text-gray-600">{contact.fonction} - {contact.societe}</p>

        </div>
    </li>



    return <li className="bg-white">
        <img className="aspect-[3/2] w-full rounded-2xl object-cover" src={contact.photos.large} alt="" />
        <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">{contact.prenom} {contact.nom}</h3>
        <p className="text-base leading-7 text-gray-600">{contact.fonction} - {contact.societe}</p>
        <div className="flex items-center space-x-3 bg-neutral-100 rounded-full">
            <div>
                <img src={contactCycleLang.cycle_lang.logo_better.small} alt="" className='w-10 rounded' /></div>
            <div className='text-pink-600 text-sm'> {contactCycleLang.cycle_lang.nom_cycle_lang}</div>
        </div>
    </li>
}



const EventCoordinateursCollectifs = () => {

    const { i18n, apiUrlTranslation } = useApiUrlTranslation()

    const [contactCycleLangList, setContactCycleLangList] = useState(false);

    const [contactCycleLangFullList, setContactCycleLangFullList] = useState(false);

    const { idEvent } = useParams()

    const fetchContactCycleLangFullList = async ({ idEvent }) => {

        const url = new URL(window.location.href);
        const idCycleLangPreselectionList = url.searchParams.get('id_cycle_lang_preselection_list');

        let filter = `id_cycle_lang IN(${idCycleLangPreselectionList})   AND`;

        // filter = `  c.id_contact IN(SELECT id_contact FROM conferenciers WHERE id_event=${idEvent}) AND 
        // id_event_contact_type IN(207) AND  `;

        filter = `  c.id_contact IN(SELECT id_contact FROM conferenciers WHERE id_event=${idEvent}) AND 
        id_event_contact_type NOT IN(207) AND  `;


        await fetch(`${apiUrlTranslation}?action=fetchContactCycleLang&filter=${filter}   `)
            .then(res => res.json())
            .then(res => setContactCycleLangFullList(res))
    }



    const fetchContactCycleLang = async ({ idEvent }) => {

        const url = new URL(window.location.href);
        const idCycleLangPreselectionList = url.searchParams.get('id_cycle_lang_preselection_list');

        let filter = `id_cycle_lang IN(${idCycleLangPreselectionList})   AND`;

        filter = `  c.id_contact IN(SELECT id_contact FROM conferenciers WHERE id_event=${idEvent}) AND 
        id_event_contact_type IN(207) AND  `;

        await fetch(`${apiUrlTranslation}?action=fetchContactCycleLang&filter=${filter}   `)
            .then(res => res.json())
            .then(res => setContactCycleLangList(res))
    }

    useEffect(() => {

        fetchContactCycleLang({ idEvent })
        fetchContactCycleLangFullList({ idEvent })
    }
        , [i18n.resolvedLanguage])

    return (
        <Layout title="Les comités de coordination géographiques" className="">

            <div className="">
                <div className="mx-auto px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0">
                        <p className="mt-6 text-lg leading-8 text-gray-600 hidden">
                            We’re a dynamic group of individuals who are passionate about what we do and dedicated to delivering the
                            best results for our clients.
                        </p>
                    </div>
                    <ul
                        role="list"
                        className="divide-y divide-gray-900  mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-1"
                    >
                        {contactCycleLangList && contactCycleLangList
                            //dedup by contactCycleLang.cycle_lang.id_cycle_lang

                            .filter((contactCycleLang, index, self) =>
                                index === self.findIndex((t) => (
                                    t.cycle_lang.id_cycle_lang === contactCycleLang.cycle_lang.id_cycle_lang
                                ))
                            )
                            .filter(contactCycleLang => ['207'].includes(contactCycleLang.id_event_contact_type)
                                && contactCycleLang.cycle_lang.cycle_lang_type == "collectif_geographique" && contactCycleLang.cycle_lang.cycle_lang_categorie == "pays")
                            .map(contactCycleLang => {
                                return <Cyclelang
                                    cycleLang={contactCycleLang.cycle_lang}
                                    contactCycleLangList={contactCycleLangList}
                                    contactCycleLangFullList={contactCycleLangFullList} />
                            })}
                    </ul>
                    <hr className='py-10 my-10' />

                    {/* <ul
                        role="list"
                        className="  mx-auto   grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-2"
                    >
                        {contactCycleLangList && contactCycleLangList
                            //dedup by contactCycleLang.contact.id_contact

                            .filter((contactCycleLang, index, self) =>
                                index === self.findIndex((t) => (
                                    t.contact.id_contact === contactCycleLang.contact.id_contact
                                ))
                            )
                            .filter(contactCycleLang => ['207'].includes(contactCycleLang.id_event_contact_type)
                                && contactCycleLang.cycle_lang.cycle_lang_type == "collectif_geographique")
                            .map(contactCycleLang => {
                                return <Presentation contactCycleLang={contactCycleLang} />
                            })}
                    </ul> */}
                </div>
            </div>
        </Layout>
    )
}

export default EventCoordinateursCollectifs