import React, { useState, useEffect } from "react";
import { API_URL } from "../config";
import { useParams } from "react-router-dom";
import Layout from "./Layout";
import { Link } from "react-router-dom";
import Loader from "./Loader";
import parse from "html-react-parser";
import ContactList from "./ContactList";
import EntrepriseLogo from "./EntrepriseLogo";
import DemoList from "./DemoList";
import SallePresentation from "./account/SallePresentation";
import './ParcoursDetails.css'
import useApiUrlTranslation from "./useApiUrlTranslation";


const FilteredContactList = ({ partenaires, contactCycleLang, collectif, idEventContactTypeList }) => {

  return <>  {partenaires && partenaires
    .filter(partenaire => idEventContactTypeList ? idEventContactTypeList.includes(partenaire.statut) : true)
    .filter(partenaire =>
      contactCycleLang
        .filter(contactCycleLangItem => contactCycleLangItem.cycle_lang.id_cycle_lang == collectif.id_cycle_lang)
        .map(contactCycleLang => contactCycleLang.contact.id_contact)
        .includes(partenaire.contact.id_contact)
    )
    .map(partenaire =>

      <Contact contact={partenaire.contact}
        partenaire={partenaire} />)}

  </>
}

const Contact = ({ contact, partenaire }) => {


  return <div className="flex space-x-3 items-center">
    <div>
      {contact.photos && <img src={contact.photos.small} alt="" className="w-10 h-10 rounded-full" />}
    </div>
    <div>

      {contact.prenom} {contact.nom}</div>
    {/* [{partenaire.statut}] [{partenaire.id_conferencier}] */}
  </div>
}

const Reacap = ({ confEvent }) => {

  const { apiUrlTranslation, i18n } = useApiUrlTranslation();

  const [partenaires, setPartenaires] = useState(null);

  const [collectifgeographiqueList, setCollectifgeographiqueList] = useState(null);

  const [contactCycleLang, setContactCycleLang] = useState(null);

  const fetchContactCycleLang = async ({ idContactList }) => {

    await fetch(`${apiUrlTranslation}?action=fetchContactCycleLang&filter=c.id_contact IN (${idContactList}) AND `)
      .then(res => res.json())
      .then(res => {
        setContactCycleLang(res);

        setCollectifgeographiqueList(res
          .filter(contactCycleLang => contactCycleLang.cycle_lang.cycle_lang_type === 'collectif_geographique')
          //dedup by cycle_lang
          .reduce((acc, current) => {
            const x = acc.find(item => item.cycle_lang.id_cycle_lang === current.cycle_lang.id_cycle_lang);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, [])
          .map(contactCycleLang => contactCycleLang.cycle_lang))

      })
  }

  const getPartenaires = async () => {

    await fetch(`${apiUrlTranslation}?action=getPartenaires&params=AND  id_conf_event=${confEvent.id_conf_event}`)
      .then(res => res.json())
      .then(res => {
        setPartenaires(res)
        fetchContactCycleLang({
          idContactList: res
            .filter(partenaire => ["239", "208"].includes(partenaire.conferencier_statut.id_event_contact_type))
            .map(partenaires => partenaires.contact.id_contact).join(',')
        })
      })
  }

  useEffect(() => {
    getPartenaires()
  }
    , [])


  return (
    <div className="">

      <p className="text-5xl py-3 my-3  animatedgradient font-extrabold">Pays et écosystèmes partenaires engagés dans la compétition de pitch:</p>

      <div className="rounded p-5 border-y border-x border-neutral-800 my-3">
        <table className="w-full">

          <thead className="">
            <tr>
              <th>Pays et partenaires engagés</th>
              <th>Coordinateurs des candidats</th>
              <th>Demandes de participation reçues</th>
              <th>Candidat représentant</th>
            </tr>
          </thead>

          <tbody className=" divide-y divide-neutral-800">
            {collectifgeographiqueList && collectifgeographiqueList.map(collectif => <tr
              className="hover:bg-neutral-900">
              <td className="py-3">

                <div className="flex space-x-3 items-center">
                  <img src={collectif.logo_better.large} alt="" className="w-10 h-10 rounded-full" />
                  <span>{collectif.nom_cycle_lang}</span>
                </div>
              </td>



              <td className="py-3 px-3">
                {/* {contactCycleLang && contactCycleLang.map(contactCycleLang => contactCycleLang.contact.nom_cycle_lang)} */}

                {/* <FilteredContactList
                  collectif={collectif}
                  partenaires={partenaires}
                  contactCycleLang={contactCycleLang}
                /> */}

                <FilteredContactList
                  collectif={collectif}
                  partenaires={partenaires}
                  contactCycleLang={contactCycleLang}
                  idEventContactTypeList={["239"]} />
              </td>
              <td className="py-3 px-3">

                <div className="flex flex-col space-y-2">
                  <FilteredContactList
                    collectif={collectif}
                    partenaires={partenaires}
                    contactCycleLang={contactCycleLang}
                    idEventContactTypeList={["208"]} />

                  <a href="#" className="border-y border-x px-4 py-2 rounded-full text-white bg-pink-700  hover:bg-pink-600  border-neutral-800">Je souhaite candidater pour pitcher dans cette catégorie</a>
                </div>

              </td>

              <td className="px-3">
                pending...
              </td>
            </tr>)}
          </tbody>
        </table>
      </div>


    </div >)

}

const ConfEventCycleLangList = ({ conf_event, confEventCycleLangList, getName }) => {

  const { apiUrlTranslation, i18n } = useApiUrlTranslation()

  const GetVisuelType = ({ }) => {

    return <div className="">
      {confEventCycleLangList && confEventCycleLangList.map(cecl => cecl.id_cycle_lang.attachedFilesCollection

        .map(file => {

          const img = <img src={file.small} style={{ maxWidth: "100%" }} className="me-3" />

          if (["83"].includes(conf_event.type) && file.id_attached_file_type === 87) {
            return img
          }

          if (["91"].includes(conf_event.type) && file.id_attached_file_type === 92) {
            return img
          }

          if (['78'].includes(conf_event.type) && file.id_attached_file_type === "93") {
            return img
          }

          if (["67"].includes(conf_event.type) && file.id_attached_file_type === "84") {
            return img
          }

        }))
      }</div>
  }

  useEffect(() => {

  }, [i18n.resolvedLanguage, confEventCycleLangList])

  if (!confEventCycleLangList) return false;

  if (getName) {

    return <div className=" flex flex-wrap ">
      {
        confEventCycleLangList && confEventCycleLangList.map(cecl => <Link className="group" to={`/collectif-categorie/collectif_programme/${cecl.id_cycle_lang.id_cycle_lang}`}>
          <div className="border-2 border-neutral-800 group-hover:border-pink-700 min-w-fit text-neutral-400 rounded-2xl bg-neutral-400/10 pe-3 mx-2 my-2 flex items-center ">

            {cecl.id_cycle_lang.attachedFilesCollection
              .filter(file => file.id_attached_file_type == "65")
              .map(file => file.small ? <img src={file.small}
                style={{ width: "120px", height: "120px" }}
                className="me-3 rounded-l-2xl" /> : '')}

            <div>        <div> {cecl.id_cycle_lang.nom_cycle_lang} </div>

              <div className="my-3 w-fit group-hover:border-pink-700  group-hover:bg-pink-700 group-hover:text-white bg-neutral-800 rounded-full px-3 border-2 border-neutral-400  ">en savoir plus</div>
            </div>
          </div>
        </Link>)}
    </div>
  }

  return <GetVisuelType />

}


const ParcoursDetails = () => {

  let { idEvent } = useParams();
  let { id_conf_event } = useParams();
  const { t, i18n, apiUrlTranslation } = useApiUrlTranslation()

  const [confEventList, setConfEventList] = useState();
  const [confEventCycleLangList, setConfEventCycleLangList] = useState(null)
  const [confEvent, setConfEvent] = useState(null)


  useEffect(() => {
    const fetchConfEvent = async () => {

      await fetch(`${apiUrlTranslation}?action=getConfEvent&filter= id_conf_event=${id_conf_event}`)
        .then((res) => res.json())
        .then(res => {
          setConfEventList(res)
          setConfEvent(res[0])
        })
    };

    const getConfEventCycleLang = async () => {

      await fetch(`${apiUrlTranslation}?action=getConfEventCycleLang&params=WHERE%20id_conf_event =${id_conf_event}`)
        .then(response => response.json())
        .then(data => {
          setConfEventCycleLangList(data)
          //console.log('getConfEventCycleLang', conf_event.id_conf_event, data);
        })
    }

    getConfEventCycleLang()

    fetchConfEvent(idEvent);
  }, [i18n.resolvedLanguage]);


  if (!confEvent) return <Loader />;

  return (
    <Layout darkmode>
      <div className="dark:bg-neutral-950 px-3 mx-auto pb-48">

        <div key={confEvent.id_event.id_event}>
          <div className="mt-4 text-4xl font-extrabold  tracking-tight text-white sm:text-5xl sm:leading-[3.5rem]">
            {parse(confEvent.conf_event_lang.cel_titre)}
          </div>

          <div className=" rounded mb-5 my-3">
            <p className="hidden"><i className="fa fa-calendar" aria-hidden="true"></i>{" "}
              {confEvent.conf_event_date}
              {/**<Moment format="dddd, MMMM Do YYYY">{new Date()}</Moment> */}
            </p>
            {" "}
            <div className="font-bold text-xl text-white -700">
              {parse(confEvent.localTimeZone)}
            </div>
            <div className=" text-sm flex flex-wrap mt-2">
              {parse(confEvent.otherTimeZones)}
            </div>
          </div>


          <div className="text-white">
            {parse(confEvent.conf_event_lang.cel_texte)}
          </div>


          <div className="d-flex flex-wrap">


            <DemoList confEventList={confEventList} isNomine />
            <DemoList confEventList={confEventList} />

          </div>
          <SallePresentation confEvent={confEvent} />


          {confEventCycleLangList
            && confEventCycleLangList
              .filter(confEventCycleLang => confEventCycleLang.id_cycle_lang.cycle_lang_type != 'collectif_planaction').length > 0 &&
            <>
              <p className="text-5xl animatedgradient font-extrabold py-3">Programmes liés</p>
              <ConfEventCycleLangList
                conf_event={confEvent}
                confEventCycleLangList={confEventCycleLangList.filter(confEventCycleLang => confEventCycleLang.id_cycle_lang.cycle_lang_type != 'collectif_planaction')} getName />
            </>}

        </div>

        {/* <EntrepriseLogo statut="jury,curateur" />*/}

        <ContactList
          statut="president_collectif,president_forum,president_programme,president_jury"
          title="Présidents"
          displayNumber="1"
          displayMode="horizontal"
          showFlag="true"
        />

        <ContactList
          statut="jury,coordinateur_jury,intervenant, participant,92, 202,207, 239, 124"
          title="Les membres du jury"
          displayNumber="5"
          showFlag="true"
        />
        <Reacap confEvent={confEvent} />

      </div>

    </Layout>
  );
}

export default ParcoursDetails;
