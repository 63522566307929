import HTMLReactParser from 'html-react-parser';

import React from 'react'

import Layout from './Layout'
import useApiUrlTranslation from './useApiUrlTranslation'

const Whatis = () => {

    const { t } = useApiUrlTranslation();

    return (
        <Layout>
            <div class="container" style={{ background: "#fff" }}>
                <h2><span style={{ color: "#993366" }}> {t('pages.a_propos.title')} </span></h2>
                <table className="table">
                    <tbody>
                        <tr>
                            <td><img src="//www.mlg-consulting.com/manager_cc/docs/archives/201201175413_49357355213-3cd1345544-c.jpg" alt="" height="100%" /></td>
                            <td><img src="//www.mlg-consulting.com/manager_cc/docs/archives/201201175909_49390422401-e37f040740-w.jpg" alt="" height="100%" /></td>
                        </tr>
                        <tr>
                            <td colspan="2"> {HTMLReactParser(t('pages.a_propos.body'))} </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <p><img style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} src="//www.mlg-consulting.com/manager_cc/docs/archives/201201175721_49389947493-a2d2973383-b.jpg" alt="" width="100%" /></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Layout>
    )
}

export default Whatis
