import { ChevronRightIcon } from '@heroicons/react/20/solid'
import Layout from './Layout'


import { useContext, useEffect, useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

import EventContext from '../contexts/EventContext'
import Moment from 'react-moment';
import "moment/locale/fr";
import { useTranslation } from 'react-i18next';
import useApiUrlTranslation from './useApiUrlTranslation';
import HTMLReactParser from 'html-react-parser'
import { Link } from '@material-ui/core'

const navigation = [
    { name: 'Product', href: '#' },
    { name: 'Features', href: '#' },
    { name: 'Marketplace', href: '#' },
    { name: 'Company', href: '#' },
]


const IMAGES = [

    {
        //Silicon 
        id_conf_event: "176450", pictures: [
            "https://www.mlg-consulting.com/manager_cc/docs/archives/231229143634_1.jpg",
            "https://www.mlg-consulting.com/manager_cc/docs/archives/231229143634_2.jpg",
            "https://www.mlg-consulting.com/manager_cc/docs/archives/231229143634_3.jpg",
            "https://www.mlg-consulting.com/manager_cc/docs/archives/231229163741_dedede.png",
            "https://www.mlg-consulting.com/manager_cc/docs/archives/231229163741_defrde.png"
        ]
    },
    {
        id_conf_event: "176581", pictures: [
            "https://www.mlg-consulting.com/manager_cc/docs/archives/231229184643_dsc-5100.jpg",
            "https://www.mlg-consulting.com/manager_cc/docs/archives/231229184643_dsc-5241.jpg",
            "https://www.mlg-consulting.com/manager_cc/docs/archives/231229184643_dsc-5048.jpg",
            "https://www.mlg-consulting.com/manager_cc/docs/archives/231229184643_dsc-5150.jpg",
            "https://www.mlg-consulting.com/manager_cc/docs/archives/231229184643_dsc-5232.jpg"
        ]
    }
    , {
        id_conf_event: "176579", pictures: [
            "https://www.mlg-consulting.com/manager_cc/docs/archives/231229143649_img-20231229-wa0009.jpg",
            "https://www.mlg-consulting.com/manager_cc/docs/archives/231229143648_img-20231229-wa0008.jpg",
            "https://www.mlg-consulting.com/manager_cc/docs/archives/231229143648_img-20231229-wa0010.jpg",
            "https://www.mlg-consulting.com/manager_cc/docs/archives/231229143648_img-20231229-wa0007.jpg",
            "https://static.wixstatic.com/media/70ca53_5b7bcf0e6a6c4ffbb332fdbe00692da3~mv2.webp"
        ]
    }
    , {
        // interstellar
        id_conf_event: "176451", pictures: [
            "https://static.wixstatic.com/media/70ca53_211d4d3b5cae479abab214cef5f38252~mv2.webp",
            "https://www.mlg-consulting.com/manager_cc/docs/archives/231229143634_4.jpg",
            "https://static.wixstatic.com/media/70ca53_5b7bcf0e6a6c4ffbb332fdbe00692da3~mv2.webp",
            "https://static.wixstatic.com/media/70ca53_a682e05a2361460eab90ee4ea78497d5~mv2.webp",
            "https://www.mlg-consulting.com/manager_cc/docs/archives/231229143501_incredevent-event-655ce11fb3f29.png"
        ]
    }
]


const Interstellar = ({ confEvent }) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    useEffect(() => {

    }, [])



    return (
        <div className="bg-white">
            {/* {confEvent.id_conf_event} */}
            <main>
                <div className="relative isolate">
                    <svg
                        className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
                        aria-hidden="true"
                    >
                        <defs>
                            <pattern
                                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                                width={200}
                                height={200}
                                x="50%"
                                y={-1}
                                patternUnits="userSpaceOnUse"
                            >
                                <path d="M.5 200V.5H200" fill="none" />
                            </pattern>
                        </defs>
                        <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                            <path
                                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                                strokeWidth={0}
                            />
                        </svg>
                        <rect width="100%" height="100%" strokeWidth={0} fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
                    </svg>
                    <div
                        className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
                        aria-hidden="true"
                    >
                        <div
                            className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
                            style={{
                                clipPath:
                                    'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
                            }}
                        />
                    </div>
                    <div className="overflow-hidden">
                        <div className="mx-auto max-w-7xl px-6 pb-18 pt-36 sm:pt-60 lg:px-8 lg:pt-10">
                            <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                                <div className="relative w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                                    <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                                        {HTMLReactParser(confEvent.conf_event_lang.cel_titre)}
                                    </h1>

                                    <p className="text-pink-600 pt-5 font-bold"> {HTMLReactParser(confEvent.localTimeZone.replace("text-nowrap", ""))} </p>
                                    <p className="text-pink-600 pt-5 font-bold hidden">


                                        {
                                            //      <Moment format="dddd, MMMM Do YYYY">{confEvent.conf_event_date_local}</Moment> au format anglais en
                                            //<Moment format="dddd Do MMMM  YYYY" locale="span">{confEvent.conf_event_date_local}</Moment>
                                        }

                                    </p>




                                    <p className="mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                                        {HTMLReactParser(confEvent.conf_event_lang.cel_texte)}
                                    </p>

                                    <div className="mt-10 flex items-center gap-x-6">
                                        <a
                                            href="https://www.mlg-consulting.com/myglobalvillage/sign.php?ie=1789&iecl=7083"
                                            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            Register
                                        </a>
                                        <a href={`/events/${confEvent.id_event.id_event}/programm/${confEvent.id_conf_event}/programm-details`} className="text-sm font-semibold leading-6 text-gray-900">
                                            Learn more <span aria-hidden="true">→</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">

                                    <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                                        <div className="relative">
                                            <img
                                                src={IMAGES.find(image => image.id_conf_event == confEvent.id_conf_event).pictures[0]}
                                                alt=""
                                                className="hover:scale-110 transform duration-200 aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                            />
                                            <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                        </div>
                                    </div>
                                    <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                                        <div className="relative">
                                            <img
                                                src={IMAGES.find(image => image.id_conf_event == confEvent.id_conf_event).pictures[1]}
                                                alt=""
                                                className="hover:scale-110 transform duration-200 aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                            />
                                            <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                        </div>
                                        <div className="relative">
                                            <img
                                                src={IMAGES.find(image => image.id_conf_event == confEvent.id_conf_event).pictures[2]}
                                                alt=""
                                                className="hover:scale-110 transform duration-200 aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                            />
                                            <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                        </div>
                                    </div>
                                    <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                                        <div className="relative">
                                            <img
                                                src={IMAGES.find(image => image.id_conf_event == confEvent.id_conf_event).pictures[3]}
                                                alt=""
                                                className=" hover:scale-110 transform duration-200 aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                            />
                                            <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                        </div>
                                        <div className="relative">
                                            <img
                                                src={IMAGES.find(image => image.id_conf_event == confEvent.id_conf_event).pictures[4]}
                                                alt=""
                                                className=" hover:scale-110 transform duration-200 aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                            />
                                            <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}





export default function Soirees() {

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    const [confEventList, setConfEventList] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    const { formName } = useParams();

    const { i18n, apiUrlTranslation } = useApiUrlTranslation()

    const fetchConfEventList = async () => {

        await fetch(`${apiUrlTranslation}?action=getConfEvent&filter=id_event=${currentEvent.id_event} AND publier !='n' AND selectable !='n' AND type  IN (84)`)
            .then(res => res.json())
            .then(res => setConfEventList(res))
    }

    useEffect(() => {
        currentEvent && fetchConfEventList()
    }, [currentEvent, i18n.resolvedLanguage])

    return (<Layout>

        {confEventList && confEventList.map((confEvent, index) => <Interstellar confEvent={confEvent} />)}

    </Layout>)
}