import React from 'react'
import Layout from './Layout'
import { useState } from 'react';
import useApiUrlTranslation from './useApiUrlTranslation';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import HTMLReactParser from 'html-react-parser';
import { sortByAttribute } from '../utils';
import Loader from './Loader';

const DisplayCommunaute = ({ communaute }) => {

    return (
        <div className="py-4 flex ">

            {communaute

                //dedup by communaute.contact.id_contact
                .filter((communaute, index, self) =>
                    index === self.findIndex((t) => (
                        t.contact.id_contact === communaute.contact.id_contact
                    ))
                )

                .map(communaute => {

                    const contact = communaute.contact;

                    return <div className='flex'>

                        {/* {JSON.stringify(communaute.id_communaute.forum)} */}
                        <img src={contact.photos.small} alt="" className="w-10 h-10 rounded-full" />
                        <div className="ml-4">
                            {contact.prenom}  {contact.nom}
                            <p className="text-sm text-gray-500"> {contact.fonction} <br />{contact.societe}</p>
                            <p className="text-sm text-pink-600">{communaute.id_conferencier.statut.libelle}</p>
                        </div>
                    </div>
                })}
        </div>
    )
}

const CycleLangProgramme = ({ communaute }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [programme, setProgramme] = useState(false);

    const getConfEvent = async () => {
        await fetch(`${apiUrlTranslation}?action=getConfEvent&filter=id_event=1789 AND publier !='n' AND selectable !='n' AND type NOT IN (92) AND type IN (67) AND 
        `)
            .then(res => res.json())
            .then(res => {

                let sortedList = sortByAttribute(res, 'conf_event_date_local', 'heure_debut_local');

                sortedList = sortedList.filter(confEvent =>
                    confEvent.publier != 'n' &&
                    confEvent.selectable != 'n'
                    //  &&  ['67'].includes(confEvent.type)
                );

                setProgramme(sortedList);
            })
    }

    useEffect(() => {
        getConfEvent();
    }, [i18n.resolvedLanguage])

    if (!programme) return <Loader />
    return <div>
        {programme && programme.map(confEvent => <div className='text-left border'>{confEvent.conf_event_lang.cel_titre}  </div>)}
    </div>

}

const CycleLang = ({ cycleLang, className, isChild }) => {

    const { idEvent } = useParams();

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    return <tr className={`hover:bg-neutral-100 ${className}`}>

        <td className={`border-l-2 border-neutral-400  ${isChild ? 'pl-10' : 'pl-3'}`}>

            <div className="flex space-x-3 ">

                {isChild && <li class="group relative flex gap-x-4 overflow-hidden">
                    <div class="absolute left-0 top-0 flex w-6 justify-center -bottom-6">
                        <div class="w-px bg-neutral-200"></div></div>
                    <div class="relative flex h-6 w-6 flex-none items-center justify-center ">
                        <div class="h-1.5 w-1.5 rounded-full bg-neutral-200 ring-1 ring-neutral-200 group-hover:ring-pink-800 "></div></div>
                </li>}

                <div className="flex items-center">
                    <img src={cycleLang.attachedFilesCollection.find(item => item.id_attached_file_type == 65).small} className={`${isChild ? 'w-8 h-8 ' : 'w-14 h-14 '} rounded`} />
                    <span className="font-bold ml-3">{cycleLang.nom_cycle_lang}</span>  <span className="ml-2 text-gray-400 text-xs">({cycleLang.cycle_lang_categorie})</span>
                </div>
            </div>

        </td>

        <td className="whitespace-nowrap border-l-2 border-neutral-400 text-center">
            <a className="rounded-full bg-blue-500 hover:bg-blue-700 text-white py-2 px-3 text-sm"
                href={`https://www.myglobalvillage.com/events/${idEvent}/login-redirect?redirectUrl=https://www.myglobalvillage.com/events/${idEvent}/signup/jury/c/{id_contact}?formisvisible=1&id_formulaire=11&form-name=jury&lng=fr&id_cycle_lang_preselection_list=${cycleLang.id_cycle_lang}`}>
                {t('event_nav.devenir_jury')}
            </a>
        </td>
        <td className="whitespace-nowrap border-l-2 border-neutral-400 text-center">
            <a className=" rounded-full bg-blue-500 hover:bg-blue-700 text-white py-2 px-3 text-sm "
                href={`https://www.myglobalvillage.com/events/${idEvent}/login-redirect?redirectUrl=https://www.myglobalvillage.com/events/${idEvent}/signup/startup-onboarding-fast/c/{id_contact}?formisvisible=1&id_formulaire=28&form-name=startup-onboarding-fast&lng=fr&id_cycle_lang_preselection_list=${cycleLang.id_cycle_lang}`}>
                {t('event_nav.devenir_candidat')}
            </a>
        </td>
    </tr>
}

const EventCycleLang = ({ eventCycleLang }) => {

    const { idEvent } = useParams();

    const [communaute, setCommunaute] = useState([]);

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    useState(() => {

        const getCommunaute = async () => {

            await fetch(`${apiUrlTranslation}?action=getCommunaute&filter= AND ecl.id_event_cycle_lang=${eventCycleLang.id_event_cycle_lang}`)

                .then(response => response.json())
                .then(data => {
                    setCommunaute(data);
                })
        }

        getCommunaute(eventCycleLang.id_event_cycle_lang);


    }, [eventCycleLang.id_event_cycle_lang])

    return <tr key={eventCycleLang.id_event_cycle_lang} className="hover:bg-neutral-100">

        <td className="whitespace-nowrap  border-l-2 border-neutral-400 pl-3">
            <div className="flex   items-center">
                <img src={eventCycleLang.cycle_lang.attachedFilesCollection.find(item => item.id_attached_file_type == 65).small} className="w-10 h-10 rounded" />
                <span className="font-bold ml-3">{eventCycleLang.event_cycle_lang_nom}</span>
            </div>
        </td>

        <td className="whitespace-nowrap border-l-2 border-neutral-400 text-center">
            <a className="rounded-full bg-blue-500 hover:bg-blue-700 text-white py-2 px-3 text-sm"
                href={`https://www.myglobalvillage.com/events/${idEvent}/login-redirect?redirectUrl=https://www.myglobalvillage.com/events/${idEvent}/signup/jury/c/{id_contact}?formisvisible=1&id_formulaire=11&form-name=jury&lng=fr&id_cycle_lang_preselection_list=${eventCycleLang.cycle_lang.id_cycle_lang}`}>
                {t('event_nav.devenir_jury')}
            </a>
        </td>
        <td className="whitespace-nowrap border-l-2 border-neutral-400 text-center">
            <a className=" rounded-full bg-blue-500 hover:bg-blue-700 text-white py-2 px-3 text-sm "
                href={`https://www.myglobalvillage.com/events/${idEvent}/login-redirect?redirectUrl=https://www.myglobalvillage.com/events/${idEvent}/signup/startup-onboarding-fast/c/{id_contact}?formisvisible=1&id_formulaire=28&form-name=startup-onboarding-fast&lng=fr&id_cycle_lang_preselection_list=${eventCycleLang.cycle_lang.id_cycle_lang}`}>
                {t('event_nav.devenir_candidat')}
            </a>
        </td>
        <td className="whitespace-nowrap border-l-2 border-neutral-400 text-center hidden">
            <CycleLangProgramme communaute={communaute} />
        </td>
        <td className="whitespace-nowrap border-l-2 border-neutral-400 pl-3 hidden">
            <DisplayCommunaute communaute={communaute} />
        </td>
    </tr>
}



const BecomeCandidate = () => {

    const [eventCycleLangList, setEventCycleLangList] = useState([]);

    const [cycleLangList, setCycleLangList] = useState(false);

    const [cycleLangListFiltered, setCycleLangListFiltered] = useState(false);

    const [childrenFiltered, setChildrenFiltered] = useState(false);

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const { idEvent } = useParams();


    const handleSearchCycleLang = (e) => {

        const search = e.target.value;

        //si nombre de caractères < 3 on affiche tout

        if (search.length < 3) {
            setCycleLangListFiltered(cycleLangList);
            return;
        }


        if (search == '') {
            setCycleLangListFiltered(cycleLangList);
            return;
        }

        //filter cycleLang.children


        const childrenFiltered = cycleLangList.filter(cycleLang => cycleLang.children && cycleLang.children.filter(child => child.nom_cycle_lang.toLowerCase().includes(search.toLowerCase())));



        const filtered = cycleLangList.filter(cycleLang => cycleLang.nom_cycle_lang.toLowerCase().includes(search.toLowerCase()));
        console.log(filtered)
        setCycleLangListFiltered(childrenFiltered);
    }


    const getEventContactTypeList = async () => {

        await fetch(`${apiUrlTranslation}?action=getEventCycleLangList&params=WHERE id_event="${idEvent}" `)
            .then(response => response.json())
            .then(data => {
                setEventCycleLangList(data);
            })
    }

    const getCycleLang = async () => {

        await fetch(`${apiUrlTranslation}?action=getCycleLang&filter= cycle_lang_type IN ("collectif_geographique") AND cycle_lang_state IN("active") AND cycle_lang_categorie NOT IN('ville', 'province', 'agglomeration', 'etat', 'pays', 'region') AND `)

            .then(response => response.json())
            .then(res => {

                //order by nom_cycle_lang
                const data = sortByAttribute(res, 'nom_cycle_lang');

                setCycleLangList(data);
                setCycleLangListFiltered(data);
            })
    }

    useEffect(() => {
        //getEventContactTypeList();
        getCycleLang();
    }, [i18n.resolvedLanguage])

    return (
        <Layout title={t('pages.coordinateurs_pitch.title')} className="bg-white">



            <div className='mt mb-5 max-w-7xl mx-auto bg-blue-100 border-t-4 border-blue-500 rounded-b text-blue-700 px-4 py-3 shadow-md'>
                {HTMLReactParser(t('pages.coordinateurs_pitch.instructions'))}
            </div>
            <div className="bg-white  max-w-7xl  mx-auto">

                <p className="py-10 text-xl font-bold">Choisissez votre pays dans la liste des collectifs myglobalvillage ci-dessous</p>

                {/* {t("pages.participation_pitch.th_pays")} */}

                <input className=" border border-pink-500 p-2 w-full rounded my-3" type="text" placeholder="Rechercher un territoire" onChange={handleSearchCycleLang} />


                {/* 
                
                // filtre eventCycleLangList 
                {eventCycleLangList.
                    filter(eventCycleLang => ["collectif_geographique", "pays_region_entreprise_invite"].includes(eventCycleLang.forum))
                    .length == 0 ? 'hidden' : ''
                } */}
                <table className={`mx-auto min-w-full `}>

                    <thead>
                        <tr className="bg-gray-300">
                            <th className="p-2 text-center"></th>
                            <th className="text-center border-l-2 border-neutral-400">{t("pages.participation_pitch.th_jury")}</th>
                            <th className="text-center border-l-2 border-neutral-400">{t("pages.participation_pitch.th_startup")}</th>
                            <th className="text-center border-l-2 border-neutral-400 hidden">Programme</th>
                            <th className="text-center border-l-2 border-neutral-400 hidden">{t("pages.participation_pitch.th_contacts")}</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y">
                        {cycleLangListFiltered && cycleLangListFiltered.map(cycleLang => {
                            return (
                                <>
                                    <CycleLang cycleLang={cycleLang} />
                                    {cycleLang.children && cycleLang.children
                                        //order by nom_cycle_lang
                                        .sort((a, b) => a.nom_cycle_lang.localeCompare(b.nom_cycle_lang))
                                        .map(child => <CycleLang
                                            isChild
                                            className="bg-neutral-50  ml-10 pl-10 text-xs" cycleLang={child} />)}
                                </>)
                        })}

                        {/* {eventCycleLangList
                            .filter(eventCycleLang => ["collectif_geographique", "pays_region_entreprise_invite"].includes(eventCycleLang.forum))
                            .map(eventCycleLang =>
                                <EventCycleLang eventCycleLang={eventCycleLang} />
                            )} */}
                    </tbody>
                </table>


                <p className="py-10 text-xl font-bold">Si votre pays ne figure pas dans la liste ci-dessus</p>

                <div className='grid grid-cols-2 gap-10 pb-10'>
                    <a className=" rounded-full bg-blue-500 hover:bg-blue-700 text-white py-3 px-3 text-sm text-center "
                        href={`https://www.myglobalvillage.com/events/${idEvent}/login-redirect?redirectUrl=https://www.myglobalvillage.com/events/${idEvent}/signup/startup-onboarding-fast/c/{id_contact}?formisvisible=1&id_formulaire=28&form-name=startup&lng=${i18n.resolvedLanguage}`}>  {t('event_nav.devenir_candidat')}</a>

                    <a className=" rounded-full bg-blue-500 hover:bg-blue-700 text-white py-3  px-3 text-sm text-center"
                        href={`https://www.myglobalvillage.com/events/${idEvent}/login-redirect?redirectUrl=https://www.myglobalvillage.com/events/${idEvent}/signup/jury/c/{id_contact}?formisvisible=1&id_formulaire=11&form-name=jury&lng=${i18n.resolvedLanguage}`}>  {t('event_nav.devenir_jury')}</a>
                </div>


            </div>
        </Layout >
    )
}

export default BecomeCandidate