import HTMLReactParser from 'html-react-parser';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { API_URL } from '../config';
import { removeDuplicateObjectFromArray } from '../utils';
import Layout from './Layout';
import LieuInfos from './LieuInfos';
import useApiUrlTranslation from "./useApiUrlTranslation";

const Salle = ({ salle }) => {

    const { t, i18n } = useApiUrlTranslation();

    return <>

        <div class="row">
            <div class="col-md-2">
                <img alt='visuel du lieu' src={`${salle.salle.id_lieu.visuel_principal}`} style={{ width: "100%" }} />
            </div>

            <div class="col">
                <h2> {salle.salle.id_lieu.lieu_nom}</h2>
                {salle.salle.id_lieu.lieu_adresse}   {salle.salle.id_lieu.lieu_cp}
                {salle.salle.id_lieu.lieu_ville}
                {salle.salle.id_lieu.lieu_pays}
                <LieuInfos salle={salle.salle} />

            </div>

        </div>
        <hr />
    </>
}

const Places = () => {

    let { idEvent } = useParams();

    const [salleList, setSalleList] = useState(null)
    const fetchSalles = async () => {

        fetch(`${API_URL}?action=getConfEvent&id_event=${idEvent}`)
            .then(res => res.json())
            .then(prog => {

                const salle_list = removeDuplicateObjectFromArray(prog, 'id_salle');

                setSalleList(salle_list);
            })
    }

    useEffect(() => {
        fetchSalles()

    }, [])



    return (
        <Layout title="Lieux">

            <div className="row">
                {salleList && salleList.map(salle => {

                    return <Salle salle={salle} />
                })}
            </div>


        </Layout>
    )
}

export default Places